import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import EcareManager from 'containers/Configurations/EcareManager.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/enums.js';
import TranslationServices from 'containers/Configurations/TranslationServices.jsx';
import { groupTypes } from 'constants/integrationEnums.js';

const IntegrationWrapper = ({ selectedHealthSystem, pagination, onHealthSystemChange, onPaginationChange, isLoading }) => {
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const shouldShowTranslationServicesTab = () =>
		getUserRole() !== UserRoles.SUPER_USER &&
		getSomeConfigurationsValues(companyConfigurations, [IntegrationTypesSettings.GLOBO, IntegrationTypesSettings.AMN]);
	const [currentTab, setCurrentTab] = useState(0);

	return (
		<Tabs activeIndex={currentTab} onChange={setCurrentTab}>
			<TabList>
				<Tab className='cursor-pointer'>{translate(groupTypes.ECM.title)}</Tab>
				<Tab className='cursor-pointer'>{translate(groupTypes.SMART_FHIR.title)}</Tab>
				<Tab className='cursor-pointer'>{translate(groupTypes.NURSE_CALLING.title)}</Tab>
				{shouldShowTranslationServicesTab() && (
					<Tab className='cursor-pointer'>{translate(groupTypes.TRANSLATION_SERVICES.title)}</Tab>
				)}
			</TabList>
			<TabPanels>
				<TabPanel>
					<EcareManager group={groupTypes.ECM} />
				</TabPanel>
				<TabPanel>
					<EcareManager group={groupTypes.SMART_FHIR} />
				</TabPanel>
				<TabPanel>
					<EcareManager group={groupTypes.NURSE_CALLING} />
				</TabPanel>
				<TabPanel>
					{shouldShowTranslationServicesTab() && (
						<TabPanel>
							<TranslationServices
								healthSystems={allHealthSystems}
								selectedHealthSystem={selectedHealthSystem}
								pageSize={pagination.size}
								pageIndex={pagination.index}
								setSelectedHealthSystem={onHealthSystemChange}
								onPaginationChange={onPaginationChange}
								isLoading={isLoading}
							/>
						</TabPanel>
					)}
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default IntegrationWrapper;
