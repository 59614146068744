import { getPatientCentricReport } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import CustomTable from 'components/CustomTable.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { formatDateHeader } from 'constants/dashboard.js';
import translate from 'i18n-translations/translate.jsx';
import { timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

const PatientCentricReport = ({
	dateRange,
	selectedHealthSystem,
	selectedHospital,
	selectedDepartment,
	selectedFloor,
	selectedRoom,
	currentTab,
	searchValue,
	selectedTimezone,
	selectedLevel,
	additionalFilters,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [patients, setPatients] = useState([]);
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const controller = useRef(null);

	const fetchPatientsReport = useCallback(
		debounce(async (params, signal) => {
			const response = await getPatientCentricReport(params, signal);
			if (!response.error) {
				setPagination(prevState => ({ ...prevState, totalCount: response.pagination.total }));
				setIsLoading(false);
				setPatients(prevState => [...prevState, ...response.patients]);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
				setIsLoading(false);
			}
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			from: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			to: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
			search: searchValue || null,
			healthSystemId: selectedHealthSystem,
			hospitalId: selectedHospital?.value ?? null,
			regionId: selectedHospital?.regionId ?? null,
			departmentId: selectedDepartment?.value ?? null,
			floorId: selectedFloor?.value ?? null,
			roomId: selectedRoom?.value ?? null,
		};

		setIsLoading(true);
		fetchPatientsReport(params, signal);
	}, [
		searchValue,
		pagination.pageIndex,
		pagination.pageSize,
		selectedRoom,
		selectedFloor,
		selectedDepartment,
		selectedHospital,
		selectedHealthSystem,
		selectedTimezone,
		dateRange,
		fetchPatientsReport,
	]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	useEffect(() => {
		setPatients([]);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	}, [searchValue, selectedRoom, selectedFloor, selectedDepartment, selectedHospital, selectedHealthSystem, dateRange]);

	const openDetailedReport = patient => {
		history.push({
			pathname: `/dashboard/detailed-report`,
			state: {
				prevPath: location.pathname,
				prevSearch: location.search,
				patient,
				healthSystemId: selectedHealthSystem,
				filters: {
					dateRange,
					search: searchValue,
					healthSystem: selectedHealthSystem ?? null,
					hospital: selectedHospital ?? null,
					department: selectedDepartment ?? null,
					floor: selectedFloor ?? null,
					room: selectedRoom ?? null,
					selectedTimezone: selectedTimezone ?? null,
					selectedLevel,
					additionalFilters,
				},
			},
		});
	};

	const getLocation = patient =>
		patient && (
			<div>
				{patient.healthSystemName && <span>{patient.healthSystemName}</span>}
				{patient.hospitalName && (
					<span>
						{' >'} {patient.hospitalName}
					</span>
				)}
				{patient.departmentName && (
					<span>
						{' >'} {patient.departmentName}
					</span>
				)}
				{patient.floorName && (
					<span>
						{' >'} {patient.floorName}
					</span>
				)}
				{patient.roomName && (
					<span>
						{' >'} {patient.roomName}
					</span>
				)}
			</div>
		);

	const displayPatients = () => {
		if (!patients || patients.length === 0) {
			return [];
		}
		return patients.map((patient, index) => ({
			id: patient.patientId + index,
			patient: (
				<>
					<div className='flex image-container'>
						<ProfilePicture
							fullName={patient.fullName}
							profilePicture={patient.profilePicture ?? null}
							className='provider-intervening-img-wrapper'
						/>
						<p>{patient.fullName}</p>
					</div>
					{patient.mrn && (
						<p style={{ margin: '0 0 0 var(--spacing-sl)', color: 'var(--blue-2)', padding: '0' }}>MRN {patient.mrn}</p>
					)}
				</>
			),
			location: getLocation(patient),
			admittedDate: utcToTimezone(patient.admissionDate, selectedTimezone.zone),
			dischargedDate: patient.dischargeDate ? utcToTimezone(patient.dischargeDate, selectedTimezone.zone) : 'N/A',
			actions: (
				<div className='flex position-relative table-buttons'>
					<Button
						className='button white'
						onClick={() => openDetailedReport(patient)}
						text={intl.formatMessage({ id: 'viewSessions' })}
					/>
				</div>
			),
		}));
	};

	return (
		<>
			<CustomTable
				headers={[
					{ title: translate('patient'), id: 'patient' },
					{ title: translate('location'), id: 'location' },
					{ title: formatDateHeader(intl, 'admittedDate', selectedTimezone.zone), id: 'admittedDate' },
					{ title: formatDateHeader(intl, 'dischargedDate', selectedTimezone.zone), id: 'dischargedDate' },
					{ title: '', id: 'actions' },
				]}
				rows={displayPatients()}
				isLoading={isLoading}
				stickyHeader={true}
				setPagination={setPagination}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PatientCentricReport;
