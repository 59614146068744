import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import classNames from 'classnames';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';
import {
	getAlertDescription,
	getImageUrl,
	getItemComment,
	getItemProperties,
	getTranslatedComment,
	typesWithConfigsDetails,
} from 'infrastructure/helpers/alertsHelper.js';
import { formatAlertConfigLog } from 'infrastructure/helpers/alertsHelper.js';
import Button from 'components/Button.jsx';
import EvidenceReport from 'containers/Monitoring/EvidenceReport.jsx';
import { CallWorkflowType } from 'constants/configurationEnums.js';
import { PatientAiSetting } from 'constants/enums.js';
import { EvidenceIcon } from 'calls/icons/index.js';
import { FallPreventionSettingTypes } from 'constants/ai.js';

const AlertDetails = ({ itemDetails, isEvidenceEnabled }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const intl = useIntl();
	const darkMode = useSelector(state => state.user?.darkMode);
	const { activities, detailedIntervention } = itemDetails;

	const activitiesWithIds = activities?.map((item, index) => ({
		...item,
		id: index + 1,
	}));

	const getParsedConfigurations = () => {
		const parsedSnapshot = detailedIntervention?.patientAiSettingSnapshot
			? JSON.parse(detailedIntervention.patientAiSettingSnapshot)
			: [];
		const filteredConfigurations =
			parsedSnapshot?.filter(item => [CallWorkflowType.MONITORING, CallWorkflowType.ROUNDING].includes(item.workflowType)) ?? [];
		return filteredConfigurations.length > 0 ? filteredConfigurations[0] : null;
	};

	const shouldShowEvidence = comment =>
		isEvidenceEnabled &&
		detailedIntervention &&
		!comment &&
		[PatientAiSetting.PRESSURE_INJURY, ...FallPreventionSettingTypes].includes(getParsedConfigurations()?.settingTypeId);

	return (
		<>
			{activitiesWithIds?.length === 0 && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='full-width'>
				{activitiesWithIds?.length > 0 &&
					activitiesWithIds?.map(item => {
						const properties = getItemProperties(item);
						const comment = getItemComment(properties, intl);
						const label = typesWithConfigsDetails(intl).find(type => type.id === detailedIntervention?.typeName)?.label;
						const shouldShowConfigs = item?.aiAlertActivityType && !properties?.Comment && label && getParsedConfigurations();
						return (
							<div
								key={item?.id}
								className={classNames(
									'view-request-history-item alert-details alert-history flex full-width flex-space-between',
									{ 'one-alert-history': activitiesWithIds?.length === 1 }
								)}>
								<div className='flex'>
									<div className='alert-history-modal-icon-wrapper'>{getImageUrl(item, darkMode)}</div>
									<p className='alert-history-desc'>
										<span>
											{item?.causer?.firstName} {item?.causer?.lastName}
										</span>
										{getAlertDescription(item)} {properties?.Name && !properties?.Comment && comment}
										{properties?.Comment && getTranslatedComment(comment, intl)}
										{shouldShowConfigs && (
											<span className='config-text'>{formatAlertConfigLog(label, getParsedConfigurations(), intl)}</span>
										)}
										{shouldShowEvidence(comment) && (
											<Button
												className='evidence-btn'
												onClick={() => setIsModalOpen(true)}
												text={translate('evidence')}
												svgIcon={<EvidenceIcon />}
												color='var(--blue-light-5)'
											/>
										)}
									</p>
									<p className='alert-time no-padding'>{formattedDate(item.createdAt)}</p>
								</div>
							</div>
						);
					})}
			</div>
			{isModalOpen && (
				<EvidenceReport
					setIsModalOpen={setIsModalOpen}
					evidenceDetails={detailedIntervention}
					configurationValue={getParsedConfigurations()?.value ?? null}
					isSlideshow={getParsedConfigurations()?.settingTypeId !== PatientAiSetting.PRESSURE_INJURY}
				/>
			)}
		</>
	);
};

export default AlertDetails;
