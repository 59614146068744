import { LOCALES } from 'i18n-translations/locales.js';

const keys = {
	[LOCALES.SPANISH]: {
		conversation: 'conversación',
		files: 'archivos',
		health: 'Salud',
		visits: 'visitas',
		roundingVisits: 'visitas',
		healthRecords: 'registros de salud',
		hello: 'Hola',
		hideProfile: 'Ocultar el perfil de {value},',
		showProfile: 'Mostrar el perfil de {value},',
		familyPhysician: 'Médico de cabecera',
		assignedToMe: 'Asignado a mí',
		otherRequests: 'Otras solicitudes',
		requestSentFrom: 'Solicitud enviada desde',
		healthConcernsDescription: 'Descripción de problemas de salud',
		symptomsSigns: 'Síntomas y Signos',
		viewProfile: 'Ver perfil',
		viewAlerts: 'Ver alertas',
		requestedAppointment: 'Cita solicitada',
		meetingPlace: 'Lugar de encuentro',
		location: 'Localización',
		dateOfBirth: 'Fecha de nacimiento',
		sex: 'Género',
		accepted: 'Aceptado',
		acceptRequest: 'Aceptar',
		wrapUp: 'Envolver',
		rejected: 'Rechazado',
		canceled: 'Cancelado',
		completed: 'Completado',
		unassigned: 'Sin asignar',
		rejectRequest: 'Rechazar solicitud',
		male: 'Varón',
		female: 'Hembra',
		nonBinary: 'no binario',
		gender: 'Género',
		helloHealthAppointmentRequest: 'Solicitud de cita de HELLO/HOLAcare',
		appointmentConfirmation: 'Confirmación de la cita',
		areYouSureAcceptFollowingAppointment: '¿Está seguro de que desea aceptar la siguiente cita?',
		addToGoogleCalendar: 'Añadir a de Google Calendar',
		addToOutlookCalendar: 'Agregar a OutlookCalendar',
		clientNotAuthenticated: 'Cliente no autenticado',
		appointments: 'Citas',
		calendarOverview: 'Resumen del calendario',
		appointmentRequests: 'Solicitudes de cita',
		appointmentsHistory: 'Historial de citas',
		nextAppointmentAt: 'Próxima cita a las',
		noUpcomingAppointment: 'No tienes cita próxima',
		yesterday: 'Ayer',
		today: 'Hoy',
		lastAmountOfDays: 'Últimos {value} días',
		lastMonth: 'El mes pasado',
		lastYear: 'El año pasado',
		lastOnline: 'Último en línea',
		monthToDate: 'Mes hasta la fecha',
		quarterToDate: 'Trimestre hasta la fecha',
		yearToDate: 'El año hasta la fecha',
		firstQuarterOf: '1er trimestre de {value}',
		secondQuarterOf: '2do trimestre de {value}',
		thirdQuarterOf: '3er trimestre de {value}',
		fourthQuarterOf: 'Cuarto trimestre de {value}',
		customRange: 'Rango personalizado',
		selectRange: 'Seleccionar rango',
		jul: 'Jul',
		unauthorize: 'Desautorizar',
		authorize: 'Autorizar',
		connectGoogleAccount: 'Conectar cuenta de Google',
		connectOutlookAccount: 'Conectar cuenta de Outlook',
		googleAccount: 'Cuenta Google',
		outlookAccount: 'Cuenta de Outlook',
		calendarAccounts: 'Cuentas de calendario',
		waitingRoom: 'Sala de espera',
		primaryCare: 'Atención primaria',
		healthcareProgressAndDiagnosisChecking: 'Comprobación de la evolución y el diagnóstico de la asistencia sanitaria',
		yourMobileDevice: 'Tu dispositivo móvil',
		clinic: 'Clínica',
		days: 'días',
		anErrorOccurred: 'Ocurrió un error.',
		assignCompanionDeviceError: 'Solo se puede seleccionar un dispositivo para cada modo complementario a la vez.',
		recaptchaLoadError: 'Error al cargar el script de recaptcha.',
		noAppointmentsToShowClickAuth:
			'No hay citas para mostrar. Haga clic en "Autorizar" para obtener las citas asociadas con su cuenta.',
		noAppointmentsToShowClickCreateAppointment: 'No tienes citas. Por favor, haga clic en "Crear citas" para crear la cita.',
		createAppointment: 'Crear cita',
		inAMoment: 'En un momento',
		personsProfile: 'Perfil de {value}',
		topic: 'Tema',
		inviteMember: 'Miembro invitado',
		fullName: 'Nombre completo',
		facility: 'Instalación',
		profession: 'Profesión',
		familyDoctor: 'Médico de familia',
		birthday: 'Cumpleaños',
		address: 'Dirección',
		phone: 'Teléfono',
		audioCall: 'llamada de audio',
		videoCall: 'Videollamada',
		cameraFeed: 'Alimentación de la cámara',
		writeMessage: 'Escribe un mensaje',
		rapidCall: 'Llamada Rápida',
		rapidCallDescription:
			'Cuando esto esté habilitado, la opción para iniciar una llamada rápida estará disponible en el lado del carrito.',
		regularCall: 'Llamada normal',
		regularCallDescription:
			'Cuando esto esté habilitado, la opción para iniciar una llamada regular estará disponible en el lado del carrito.',
		admissionCall: 'Llamada de admisión',
		admissionCallDescription:
			'Cuando esto esté habilitado, la opción para iniciar una llamada de admisión estará disponible en el lado del carrito.',
		submitNewCase: 'Enviar Nuevo Caso',
		submitNewCaseDescription: 'Cuando esto está habilitado, en la pantalla aparecerá solo una opción para iniciar una sesión.',
		sharedQueue: 'Cola Compartida',
		sharedQueueDescription:
			'Cuando esto esté habilitado, las llamadas iniciadas se enviarán a todos los médicos disponibles dentro de un hospital.',
		sessionAlreadyReceived: 'Esta sesión ya ha sido recibida por otro proveedor.',
		join: 'Unirse',
		type: 'Escribe',
		name: 'Nombre',
		dateTime: 'Fecha y hora',
		sentBy: 'Enviado por',
		noFilesFound: 'Has compartido no archivos con {value}',
		noFilesParagraph: '¡Intenta enviar una foto, un video o un documento!',
		deleteMsgTitle: 'Borrar mensaje para mi',
		deleteMsgWarning: '¿Seguro que quieres borrar este mensaje?',
		deleteMediaTitle: 'Borrar medios para mí',
		deleteMediaWarning: '¿Está seguro de que desea eliminar este medio?',
		actionUndone: 'Esta acción no se puede deshacer.',
		summary: 'Resumen',
		heart: 'Corazón',
		heartRate: 'Ritmo cardiaco',
		beatsPerMinute: 'latidos por minuto',
		mmOfMercury: 'milímetros de mercurio',
		percentage: 'porcentaje',
		bloodPressure: 'Presión arterial',
		perfusionIndex: 'índice de perfusión',
		activity: 'Actividad',
		steps: 'Pasos',
		flightsClimbed: 'Vuelos subidos',
		walkingRunningDistance: 'Caminar + Distancia de carrera',
		bodyMeasurements: 'Medidas corporales',
		weight: 'Peso',
		scale: 'Escala',
		pinFeed: 'Pin a la pantalla principal',
		unpinFeed: 'desprender de la pantalla principal',
		bodyTemperature: 'Temperatura corporal',
		leanBodyMass: 'Masa corporal magra',
		bodyFatPercentage: 'Porcentaje de grasa corporal',
		bmi: 'Indice de Masa Corporal',
		bMI: 'IMC',
		respiratory: 'Respiratorio',
		oxygenSaturation: 'Saturación de oxígeno',
		respiratoryRate: 'La frecuencia respiratoria',
		sleep: 'Dormir',
		sleepAnalysis: 'Análisis del sueño',
		bloodGlucose: 'glucosa en sangre',
		stethoscope: 'Estetoscopio',
		noVisits: 'No hay visitas para esta habitación.',
		hasVisited: '{value1} ha visitado {value2}',
		selectVisitsDate: 'Seleccionar fecha de visitas',
		enterDetailsOfVisitReason: 'Escriba el motivo por el cual el paciente está aquí. ',
		allergy: 'Alergia',
		medication: 'Medicamento',
		condition: 'Condición',
		observation: 'Observación',
		immunization: 'Inmunización',
		procedure: 'Procedimiento',
		device: 'Dispositivo',
		carePlan: 'Plan de cuidado',
		manifestation: 'Manifestación',
		criticality: 'Criticidad',
		substance: 'Sustancia',
		status: 'Estado',
		medicationName: 'Nombre del medicamento',
		dateWritten: 'fecha escrita',
		startDate: 'Fecha de inicio',
		endDate: 'Fecha final',
		prescriber: 'prescriptor',
		diagnosis: 'Diagnóstico',
		dateRecorded: 'Fecha de registro',
		severity: 'Gravedad',
		category: 'Categoría',
		value: 'Valor',
		report: 'Informar',
		performer: 'Intérprete',
		date: 'Fecha',
		vaccineType: 'tipo de vacuna',
		procedureName: 'Nombre del procedimiento',
		deviceType: 'Tipo de dispositivo',
		deviceModel: 'modelo de dispositivo',
		deviceUDI: 'Dispositivo UDI',
		deviceStatus: 'Estado del dispositivo',
		expiryDate: 'Fecha de vencimiento',
		diseases: 'Enfermedades',
		categories: 'categorías',
		goals: 'Objetivos',
		appointmentDate: 'Fecha de cita',
		noAppointmentsAvailableSelectDifferentDate: 'No hay citas disponibles, por favor seleccione una fecha diferente.',
		noAppointmentsAvailableSelectDifferentDoctor: 'No hay citas disponibles, por favor seleccione otro doctor.',
		setUpAvailabilityHours: 'Configure las horas de disponibilidad antes de programar una cita.',
		whereMeetingPlace: '¿Dónde le gustaría que se llevara a cabo esta reunión',
		recurringMeeting: 'reunión recurrente',
		recurrenceEndDate: 'Fecha de finalización de recurrencia',
		description: 'Descripción',
		unavailable: 'Indisponible',
		selectAnotherColor: 'Selecciona otro color',
		interfaceThemeColor: 'Color del tema de la interfaz',
		authNone: 'Ninguno',
		authExternalProvider: 'Autenticador',
		authEmail: 'Correo electrónico',
		invalidCode: 'Codigo invalido. ¡Por favor inténtelo de nuevo!',
		emailChanged:
			'Su correo electrónico de HELLO/HOLAcare se cambió con éxito. Vuelva a iniciar sesión con su nuevo correo electrónico.',
		goToLoginPage: 'Haz clic aquí para ir a la página de inicio de sesión',
		currentEmail: 'Correo electrónico actual',
		newEmail: 'Nuevo Email',
		enableMultipleFeedAudio: 'Habilitar audio para múltiples feeds',
		available: 'Disponible',
		changeLanguage: 'Cambiar idioma',
		english: 'Inglés',
		albanian: 'Albanés',
		editProfile: 'Editar perfil',
		accountSettings: 'Configuraciones de la cuenta',
		logOut: 'Cerrar sesión',
		noNotifications: 'No tienes notificaciones',
		noConversations: 'No tienes conversaciones con pacientes',
		conversationWithPatient: 'Conversación con el Paciente',
		toggledPatientCamera: 'Ha desactivado la cámara del paciente.',
		turnCameraOn: 'Enciende cámara',
		turnCameraOff: 'Apaga la cámara',
		initiatingCall: 'Iniciando llamada',
		endCall: 'Finalizar llamada',
		turnOnMic: 'Enciende micró',
		turnOffMic: 'Apagar micró',
		hidePTZ: 'Ocultar PTZ',
		showPTZ: 'Mostrar PTZ',
		showHealth: 'Mostrar salud',
		hideHealth: 'Ocultar Salud',
		takeScreenshot: 'Tomar una captura de pantalla',
		otoscopeCamera: 'otoscopio',
		selectBackground: 'Seleccione Fondo',
		streamSettings: 'Configuración de transmisión',
		streamSettingsModalTitle: 'Comprueba tus ajustes de audio y vídeo',
		usingDifferentMic: 'El uso de diferentes dispositivos de micrófono y altavoz puede causar eco.',
		streamSettingsSpeaker: 'Vocero',
		filterNone: 'altavoz',
		filterBlur: 'nublarse',
		tvControls: 'Controles de TV',
		startScreenSharing: 'Iniciar pantalla compartida',
		stopScreenSharing: 'Dejar de compartir la pantalla',
		presenting: 'Presentación',
		you: 'Ustedes',
		patient: 'Paciente',
		disableNightvision: 'Deshabilitar la visión nocturna',
		enableNightvision: 'Habilitar visión nocturna',
		aiControls: 'controles de IA',
		connect: 'Conectar',
		measure: 'Medida',
		connecting: 'Conectando',
		measuring: 'Medición',
		overview: 'Resumen',
		seeMoreData: 'Ver más datos',
		historicalData: 'Datos históricos',
		first: 'primero',
		second: 'segundo',
		third: 'tercera',
		daily: 'Diario',
		weeklyOn: 'Semanal en ',
		monthlyThe: 'Mensual la',
		annuallyOn: 'Anualmente en',
		everyWorkday: 'Todos los días laborables (lunes a viernes)',
		prev: 'Anterior',
		next: 'Próximo',
		switchHealthSystem: 'Cambiar el sistema de salud',
		createNewHealthSystem: 'Crear un nuevo sistema de salud',
		editHealthSystem: 'Editar el sistema de salud',
		createHospital: 'Crear un Hospital',
		communicateWithARoom: 'Con el fin de comunicarse con una habitación',
		monitorRoom: 'Con el fin de monitorear las habitaciones',
		clickAddIconInTree: 'en la vista de árbol, haga clic en el ícono Agregar al lado del nombre de la habitación',
		openHospitalChooseDepartment: 'por favor abre un hospital, elige el departamento',
		clickFloorSelectRoom: 'haga clic en un piso y seleccione una habitación',
		scheduleMeeting: 'Programar una reunión',
		makeAppointment: 'Haga una cita',
		switchHSorRegion: 'Cambiar Sistema de Salud o Región',
		selectHealthSystem: 'Seleccione Sistema de Salud',
		selectIntegrationType: 'Seleccionar tipo de integración',
		selectGroupRole: 'Seleccionar rol de grupo',
		selectHealthSystemApply: 'Seleccione el sistema de salud para aplicar el directorio activo',
		selectGroupRoleApply: 'Seleccionar rol para aplicar el directorio activo',
		selectRegion: 'Seleccione región',
		newDevice: 'Nuevo dispositivo',
		enterSerialNumber: 'Ingrese número de serie',
		deviceName: 'Nombre del dispositivo',
		deviceMode: 'Modo de dispositivo',
		deviceOfflineStatus: 'Es posible que algunos dispositivos estén desconectados.',
		version: 'Versión',
		deviceFamilyName: 'Nombre de la familia del dispositivo',
		deviceLocation: 'Ubicación del dispositivo',
		activationCode: 'Código de activación',
		savedSessions: 'Vistas Guardadas',
		updateSession: 'Actualizar sesión',
		saveSession: 'Guardar esta vista',
		currentSession: 'Vista actual',
		loadingSession: 'Cargando Sesión',
		alreadyMonitoringPatients: 'Ya estás en una llamada o monitoreando pacientes.',
		tryAgainAfterSessionEnds: 'Vuelva a intentarlo después de finalizar esa sesión.',
		disconnectedFromMonitoring: 'Te han desconectado de la monitorización',
		youCantSwitchCam:
			'No puedes volver a {value} porque los botones físicos de privacidad se han habilitado. Estos botones sólo se pueden desactivar manualmente. Por favor contacte a su administrador.',
		monitoringEndSoon:
			'Las vistas de supervisión o de enfermería finalizarán al salir de esta página. Estás seguro que quieres irte?',
		cameraBlockedTitle: 'Permitir que HELLO/HOLAcare use tu cámara',
		microphoneBlockedTitle: 'Permitir que HELLO/HOLAcare use tu micrófono',
		cameraAndMicrophoneBlockedTitle: 'Permitir que HELLO/HOLAcare use tu cámara y micrófono',
		cameraBlockedMessage:
			'hellocare necesita acceso a su cámara para que los pacientes puedan verlo. Verifique la parte superior izquierda de la pantalla y haga clic en Permitir.',
		microphoneBlockedMessage:
			'hellocare necesita acceso a su micrófono para que los pacientes puedan escucharlo. Verifique la parte superior izquierda de la pantalla y haga clic en Permitir.',
		cameraAndMicrophoneBlockedMessage:
			'hellocare necesita acceso a su cámara y micrófono para que los pacientes puedan escucharlo. Verifique la parte superior izquierda de la pantalla y haga clic en Permitir.',
		microphoneBlockedTitleGeneric: 'El micrófono está bloqueado',
		microphoneBlockedMessageGeneric:
			'hellocare requiere acceso a su micrófono. Haga clic en el icono de cámara bloqueada en la barra de direcciones de su navegador y luego actualice.',
		cameraBlockedTitleGeneric: 'La camara esta bloqueada',
		cameraBlockedMessageGeneric:
			'hellocare requiere acceso a su cámara. Haga clic en el icono de cámara bloqueada en la barra de direcciones de su navegador y luego actualice.',
		cantFindMicrophoneTitle: 'No encuentro tu microfono',
		cantFindMicrophoneMessage:
			'Verifique en su sistema si se ha conectado un micrófono y si ha otorgado permisos de micrófono. De lo contrario, conecte uno o permita permisos y reinicie su navegador. Los pacientes no podrán escucharlo hasta que lo haga.',
		cantFindCameraTitle: 'No encuentro tu cámara',
		cantFindCameraMessage:
			'Verifique en su sistema si se ha conectado una cámara y si ha otorgado permisos de cámara. De lo contrario, conecte uno o permita permisos y reinicie su navegador. Los pacientes no podrán verte hasta que lo hagas.',
		popUpCameraBlockedTitle: 'Tu cámara está bloqueada',
		popUpCameraBlockedMessage: 'Haga clic en el ícono Cámara bloqueada en la parte superior derecha de su pantalla',
		popUpMicrophoneBlockedTitle: 'Tu Micrófono está bloqueado',
		popUpMicrophoneBlockedMessage: 'Haga clic en el icono de Micrófono bloqueado en la parte superior derecha de su pantalla',
		popUpCameraAndMicrophoneBlockedTitle: 'Tu cámara está bloqueada',
		popUpCameraAndMicrophoneBlockedMessage: 'Haga clic en el ícono Cámara bloqueada en la parte superior derecha de su pantalla',
		deviceUnavailable: 'Dispositivo no disponible',
		virtualCaseProviderIsInCall:
			'Un proveedor de atención virtual está en una llamada con este dispositivo. Podrá utilizar estos controles tan pronto como finalice la llamada.',
		browserPeerConnectionsLimitReached:
			'Se ha alcanzado el límite del navegador de número máximo de conexiones. Por favor, cierre y vuelva a abrir todas las pestañas de hellocare.',
		sessionName: 'Ver nombre',
		enterSessionName: 'Ingrese el nombre de la vista',
		delete: 'Borrar',
		deleteSession: 'Eliminar esta vista',
		sureDeleteSession: '¿Está seguro de que desea eliminar esta vista?',
		failedToReconnect: 'No se pudo volver a conectar a la red.',
		sessionEndedByAdmin: 'Esta sesión fue finalizada por un administrador.',
		disconnectedByCall:
			'Esta fuente ha sido desconectada por una sesión de llamada, se volverá a conectar tan pronto como finalice la llamada.',
		maximumNumberOfMonitoringSessions:
			' El dispositivo está siendo monitoreado por {value}. Se ha alcanzado el número máximo para esta habitación.',
		beingMonitoredBy: 'Este paciente está siendo monitoreado por',
		pleaseRefreshThePage: 'Actualice la página.',
		sessionUpdatedSuccessfully: 'Sesión actualizada con éxito',
		save: 'Guardar',
		cancel: 'Cancelar',
		apply: 'Aplicar',
		suspiciousBehavior: 'Comportamientos sospechosos',
		suicideWatch: 'reloj de suicidio',
		watch: 'El reloj',
		aIWithCamera: 'IA con cámara',
		falls: 'Caídas',
		behavioral: 'conductual',
		isolation: 'Aislamiento',
		seizure: 'Convulsión',
		hospice: 'Hospicio',
		detox: 'desintoxicación',
		fallRiskCheckPatient: 'Riesgo de caídas. Por favor revise al paciente.',
		multiPersonFallDetection: 'Detección de caída de múltiples personas',
		fallDetection: 'Detección de caídas',
		turnOffPrivacyMode: 'Desactiva el modo de privacidad',
		turnOnPrivacyMode: 'Activa el modo de privacidad',
		allowPermission: 'Permitir permiso',
		mute: 'Silencio',
		unmute: 'Activar sonido',
		muteAudio: 'Silenciar el audio',
		unMuteAudio: 'Activar audio',
		reinitiateCallPermissions: 'Para reiniciar esta llamada, debe permitir los permisos de sonido',
		for: 'por',
		myRequests: 'Mis solicitudes',
		newRequest: 'Nueva solicitud',
		acceptedRequests: 'Solicitudes aceptadas',
		pendingRequests: 'Solicitudes pendientes',
		rejectedRequests: 'Solicitudes rechazadas',
		pending: 'esperando',
		requestSentTo: 'Solicitud enviada a',
		viewRequestHistory: 'Ver historial de solicitudes',
		healthConcern: 'Preocupación por la salud',
		reassignRequest: 'Solicitud de reasignación',
		welcomeMessage: 'Mensaje de bienvenida',
		visitCost: 'Coste de la visita',
		languagesSpoken: 'Idiomas hablados',
		professionalEducation: 'Educación profesional',
		yearsOfExperience: 'Años de experiencia',
		fellowship: 'Compañerismo',
		requestHistory: 'Solicitar historial',
		youHaveAcceptedRequest: 'Ha aceptado la solicitud de {value}',
		acceptedYourRequest: 'aceptado su solicitud',
		assignedTheRequestTo: 'asignó la solicitud a',
		rejectedYourRequest: 'rechazó su solicitud',
		sentRequestToAllDoctors: 'envió esta solicitud a todos los médicos.',
		general: 'General',
		profileInformation: 'Información del perfil',
		passwordProtection: 'Protección de contraseña',
		notifications: 'Notificaciones',
		measurementUnits: 'Unidades de medida',
		integratedApps: 'Aplicaciones integradas',
		dependents: 'dependientes',
		insurance: 'Seguro',
		paymentInformation: 'Información del pago',
		activityHistory: 'Historial de actividades',
		noPageFound: 'No se encontró ninguna página',
		saveChanges: 'Guardar cambios',
		phoneNumber: 'Número de teléfono',
		emailAddress: 'Dirección de correo electrónico',
		language: 'Idioma',
		cameraNames: 'Nombres de Cámaras',
		customRolesDisplayName: 'Nombre de Visualización de Roles Personalizados',
		enterCustomRoleNameHere: 'Ingrese el nombre del rol personalizado aquí',
		customRoleNameRequired: 'Se requiere el nombre del rol personalizado',
		informationMessageIsRequired: 'Se requiere mensaje informativo',
		informationMessageForMonitoring: 'Mensaje informativo para monitoreo',
		enterAMessageHere: 'Ingrese un mensaje aquí',
		displayAs: 'Mostrar como',
		externalCamera: 'Cámara externa',
		edit: 'Editar',
		timeTrackerValue: '{value} Rastreador de tiempo',
		enable: 'Permitir',
		disable: 'Desactivar',
		disabled: 'Desactivada',
		recent: 'Reciente',
		myDevices: 'Mis dispositivos',
		online: 'En línea',
		offline: 'Desconectado',
		send: 'Enviar',
		healthCategories: 'Categorías de salud',
		addTab: 'Agregar una pestaña',
		addFavTabQuickerAccess: 'Agregue sus aplicaciones o archivos favoritos como pestañas para un acceso más rápido',
		searchAppsFiles: 'Buscar aplicaciones o archivos',
		myApps: 'Mis aplicaciones',
		otherApps: 'Otras aplicaciones',
		addNewHealthSystem: 'Agregar Nuevo Sistema de Salud',
		resendInvite: 'Reenviar invitación',
		cancelInvite: 'Cancelar invitación',
		addNewField: 'Agregar nuevo campo',
		viewYourBaby: 'Ver a tu bebé',
		enablePrivacyButtons:
			'Los botones de privacidad están habilitados en el dispositivo {value}. Puedes ver al bebé cuando los botones de privacidad están desactivados.',
		somethingWentWrong: '¡Algo salió mal! Inténtalo de nuevo.',
		nurseAdded: 'Enfermera fue agregada exitosamente al sistema de salud.',
		doctorAdded: 'Doctor fue agregado exitosamente al sistema de salud.',
		successfullySaved: 'Guardado con éxito.',
		usersAddedOnHelloHealth: '¡Los usuarios fueron invitados con éxito a HELLO/HOLAcare!',
		patientAssignedAsDeviceOwner: 'El paciente ha sido asignado correctamente como propietario del dispositivo',
		manageHealthSystems: 'Gestionar Sistemas de Salud',
		selectHealthSystemNurse: 'Seleccione el sistema de salud al que desea asignar esta enfermera.',
		enterHealthSystem: 'Ingrese al Sistema de Salud',
		healthSystem: 'Sistema de salud',
		assignation: 'Asignación',
		unAssignableLevel: 'Nivel no asignable',
		selectAssignationLevel: 'Seleccionar niveles de asignación',
		downloadCSV: 'Descargar CSV',
		watchRecording: 'Ver Grabación',
		recordedBy: 'Grabado por',
		assignLevel: 'Asignar nivel',
		signsSymptoms: 'Signos y síntomas',
		selectSignsSymptomsAssociatedWithHealthConcerns: 'Seleccione los signos y síntomas asociados con sus problemas de salud.',
		mostRecentMeasurementByDate: 'Esta vista muestra la medición más reciente ordenada por fecha y hora.',
		searchForSymptoms: 'Buscar Síntomas',
		noSymptomsFound: 'No se encontraron síntomas',
		mySymptoms: 'Mis Síntomas',
		searchDoctors: 'Buscar Doctores',
		pleaseWriteYourHealthConcerns: '¡Por favor escriba sus preocupaciones de salud!',
		errorOccurred: 'Ocurrió un error',
		pleaseSelectTheRequiredFields: '¡Seleccione los campos obligatorios!',
		symptomsAssociatedWithGeneralDoctors: 'Sus síntomas están asociados con la categoría de médicos generales',
		sendRequest: 'Enviar petición',
		scheduleAppointment: 'Programar una cita',
		writeYourFirstName: 'Por favor escribe tu nombre aquí...',
		writeYourLastName: 'Por favor escribe tu apellido aquí...',
		writeYourEmail: 'ejemplo@gmail.com',
		confirmEmail: 'Por favor, confirme su correo electrónico aquí...',
		writePassword: 'Por favor escribe tu contraseña aquí...',
		search: 'Búsqueda',
		selectSerialNumber: 'Seleccionar número de serie',
		writeCurrentLocation: 'Por favor escribe tu ubicación actual aquí...',
		writeHeight: 'Por favor escribe tu altura aquí...',
		writeHeightUnit: 'unidad de altura',
		writeWeight: 'Escriba su peso aquí...',
		writeWeightUnit: 'Unidad de peso',
		healthSystemName: 'Nombre del sistema de salud',
		regionName: 'Nombre de la región',
		confirmPassword: 'Confirmar contraseña',
		firstName: 'Primer nombre',
		lastName: 'Apellido',
		password: 'Clave',
		email: 'Correo electrónico',
		enterYourInfo: 'Tu información',
		verifyCellPhone: 'Teléfono celular',
		licenseNumber: 'Número de licencia',
		medicalSchool: 'Escuela de Medicina',
		chooseADate: 'Elige una fecha',
		companyName: 'Nombre de empresa',
		positionTitle: 'Título del Puesto',
		specify: 'Especificar',
		suffix: 'Sufijo',
		maidenName: 'Nombre de soltera',
		npiNumber: 'Número NPI',
		internshipInstitutionName: 'Nombre de la institución de prácticas',
		internshipSpecialty: 'Especialidad de prácticas',
		residencyInstitutionName: 'Nombre de la institución de residencia',
		residencySpecialty: 'Especialidad de Residencia',
		fellowshipInstitutionName: 'Nombre de la institución de la beca',
		fellowshipSpecialty: 'Beca de Especialidad',
		searchForBoard: 'Escriba para buscar Tablero aquí..',
		medicaidProviderNumber: 'Número de proveedor de Medicaid',
		medicareProviderNumber: 'Número de proveedor de Medicare',
		birthDate: 'Fecha de nacimiento',
		socialSecurityNumber: 'Número de seguridad social',
		yourAnswer: 'Tu respuesta',
		noResultsFound: '¡No se han encontrado resultados!',
		moreThanCharacters: 'Necesitas poner al menos {value} letras para hacer una búsqueda.',
		patientNotFound: '¡El paciente que buscabas no existe!',
		showAllResults: 'Mostrar todos los resultados',
		advancedSearch: 'Búsqueda Avanzada',
		filterType: 'Tipo de filtro',
		all: 'Todos',
		patients: 'pacientes',
		lowercaseToday: 'hoy',
		lowercaseTomorrow: 'mañana',
		myHealthConcerns: 'Mis problemas de salud',
		describeYourHealthConcerns: 'Describa sus problemas de salud',
		thereAreAppointmentsFor: 'Hay {value} citas disponibles para',
		typeAppointmentTopic: 'Escriba el tema de la cita',
		whereWouldYouLikeMeetingTakePlace: '¿Dónde le gustaría que se llevara a cabo esta reunión?',
		firstNameCannotBeEmpty: 'El nombre no puede estar vacío',
		parentNameCannotBeEmpty: 'El nombre principal no puede estar vacío',
		idNumberCannotBeEmpty: 'El número de identificación no puede estar vacío',
		patientHealthCardNumberCannotBeEmpty: 'El número de tarjeta de salud del paciente no puede estar vacío',
		lastNameCannotBeEmpty: 'El apellido no puede estar vacío',
		professionCannotBeEmpty: 'La profesión no puede estar vacía',
		emailCannotBeEmpty: 'La dirección de correo electrónico no puede estar vacía',
		rpmCannotBeEmpty: 'El programa RPM no puede estar vacío',
		enterFirstName: 'rellenar el nombre',
		enterLastName: 'rellenar el apellido',
		virtualNurse: 'enfermera virtual',
		virtualCaseManager: 'mánager de casos virtuales',
		virtualIntensivist: 'Intensivista Virtual',
		virtualPatient: 'Paciente Virtual',
		manualPatient: 'Paciente Manual',
		ehrPatient: 'Paciente EHR',
		memberSuccessfullyModified: '¡El miembro fue modificado con éxito!',
		somethingWentWrongTryAgain: '¡Algo salió mal! Por favor inténtelo de nuevo.',
		modifyVirtualCareProviderFirstName: 'Modificar el nombre del proveedor de atención virtual',
		modifyVirtualCareProviderLastName: 'Modificar el apellido del proveedor de atención virtual',
		assignRoleVirtualCareProvider: 'Asigne una función para el proveedor de atención virtual.',
		errorWhileSavingThePhotos: 'Error al guardar las fotos.',
		fileReadingWasAborted: 'La lectura del archivo fue cancelada',
		fileReadingHasFailed: 'La lectura del archivo ha fallado',
		errorDeletingPhoto: 'Error al eliminar la foto.',
		dragAndDropOrSelectPhotos: 'Arrastre y suelte algunas fotos aquí, o haga clic para seleccionar fotos',
		dragAndDropOrSelectAttachments: 'Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos',
		photos: 'Fotos',
		attachments: 'Adjuntos',
		fiveAttachmentsMaximumUpload: 'Puedes subir hasta 5 archivos como máximo.',
		fileUploadMaximumSize: 'El tamaño máximo de archivo debe ser de 5 MB',
		noPhotosForThisUser: 'No hay fotos para este usuario.',
		uploadPhotos: 'Subir fotos',
		dateSent: 'Fecha de envío',
		invitedBy: 'Invitado por',
		role: 'Role',
		addVirtualCareProvider: 'Agregar proveedor de atención virtual',
		addAdministrator: 'Agregar administrador',
		addAdminUsers: 'Agregar usuarios administradores',
		addSuperUsers: 'Añadir superusuarios',
		addSuperAdminUsers: 'Agregar usuarios superadministradores',
		deleteAlertSubscription: 'Eliminar Suscripción a Alertas',
		areYouSureYouWantToDeleteSubscription: '¿Está seguro de que desea eliminar la suscripción para {value}?',
		setupDeviceAlerts: 'Configurar Alertas de Dispositivo',
		deviceAlerts: 'Alertas del Dispositivo',
		receiveAlertsFrom: 'Recibir Alertas De',
		addAnotherEmailAddress: 'Agregar otra dirección de correo electrónico',
		setupAlertsFor: 'Configurar Alertas Para',
		recipients: 'Destinatarios',
		assignationLevel: 'Nivel de Asignación',
		alertType: 'Tipo de Alerta',
		invitationDate: 'Fecha de Invitación',
		deviceIsOffline: 'El dispositivo está fuera de línea',
		anAlertMustBeSelected: 'Debe seleccionarse una alerta',
		couldNotFindThisEmail: 'No se pudo encontrar este correo electrónico',
		alreadyAddedThisEmail: 'Ya has agregado este correo electrónico',
		formShouldContainOneEmail:
			'El formulario debe contener al menos una dirección de correo electrónico válida. Si el campo de entrada está lleno, presione Enter para agregar la dirección de correo electrónico.',
		confirmation: 'Confirmación',
		areYouSureCancelInvitation:
			'¿Está seguro de que desea cancelar esta invitación? El enlace enviado a este usuario invitado se desactivará y no podrá utilizarse más.',
		overwrite: 'Sobrescribir',
		warning: 'Advertencia',
		featureOverwrite: 'Sobrescritura de características',
		exclude: 'Excluir',
		applyToAll: 'Aplicar a todo',
		endedTime: 'Hora de Finalización',
		startTime: 'Hora de Inicio',
		roomsWithDifferentConfigs: 'Habitaciones que tienen un conjunto diferente de indicadores de características',
		waringOverwriteDescription:
			'Está intentando realizar cambios en un sector que ya tiene dispositivos con un conjunto de funciones diferente al que está intentando configurar.',
		bySavingThisDescription:
			'Si desea anular las marcas de funciones en las habitaciones anteriores, seleccione Aplicar a todas.Si desea excluir esas habitaciones, seleccione Excluir.',
		areYouSureResendInvitation:
			'¿Estás seguro de que quieres volver a enviar esta invitación? El usuario que está invitando recibirá otro correo electrónico pidiéndole que se una a hellocare.',
		areYouSureTwoFaReset:
			'¿Está seguro de que desea restablecer la autenticación de dos factores? El usuario recibirá un correo electrónico solicitando restablecer la autenticación de dos factores.',
		userName: 'Nombre de usuario',
		dateInvited: 'fecha invitada',
		dateAdded: 'Fecha Agregada',
		healthSystems: 'Sistemas de Salud',
		areYouSureDeleteMember: '¿estás seguro de eliminar miembro?',
		userInfo: 'Información de usuario',
		userPhotos: 'Fotos de usuario',
		enterEmail: 'rellenar correo electrónico',
		writeEmailOfVcpToInvite:
			'Por favor, escriba los correos electrónicos de los proveedores de atención virtual que desea invitar.',
		writeEmailOfVSToInvite: 'Por favor, escribe los correos electrónicos de los cuidadores virtuales que deseas invitar.',
		writeEmailOfDCToInvite: 'Por favor, escriba los correos electrónicos de los médicos digitales que desea invitar.',
		writeEmailOfDoctorsToInvite: 'Por favor escriba los correos electrónicos de los doctores que desea invitar.',
		writeEmailOfAdminToInvite: 'Por favor, escriba los correos electrónicos de los usuarios administradores que desea invitar.',
		inviteMax5PeopleAtOnce: '¡Solo puedes invitar a un máximo de 5 personas a la vez!',
		selectAtLeastOneHSToAddRole: 'Seleccione al menos un sistema de salud para agregar un {value}',
		writeEmailsYouWishToInvite: 'Escriba los correos electrónicos de {value} que desea invitar.',
		virtualCareProviders: 'proveedores de atención virtual',
		addRole: 'Añadir {value}',
		userManagement: 'mánager de usuarios',
		fleetManagement: 'Gestión de Flotas',
		fleetDashboardDescription:
			'Cuando esto está habilitado, el usuario podrá ver los estados en tiempo real de los dispositivos en el sistema de salud seleccionado.',
		fleetDashboard: 'Gestión de Flotas',
		setupAlerts: 'Configurar Alertas',
		ofTheRooms: 'de las habitaciones',
		deviceAssignation: 'Asignación de Dispositivo',
		connection: 'Conexión',
		clearFilter: 'Limpiar Filtro',
		searchByRoomOrSN: 'Buscar por nombre de habitación o SN',
		ethernet: 'Ethernet',
		wireless: 'Inalámbrico',
		devicesAssigned: 'Dispositivos Asignados',
		members: 'miembros',
		invitedMembers: 'Miembros invitados',
		invitedMember: 'Miembro invitado',
		active: 'Activo',
		notActive: 'No activo',
		patientPosition: 'El paciente ha estado inactivo durante 10 segundos.',
		railDownMessage: 'El riel de la cama está abajo. Por favor revise al paciente.',
		alertCheckThePatient: '¡Alerta! Revisa al paciente.',
		fallRiskCheckThePatient: 'Riesgo de caida. Por favor revise al paciente.',
		pleaseCheckPatient: 'Por favor revise al paciente.',
		concerningMeasurements: 'El paciente tiene medidas preocupantes.',
		alertCheckTheBaby: '¡Alerta! Revisa al bebé.',
		alertBabyExitingCrib: '¡Alerta! Bebé saliendo de la cuna.',
		missingTools: 'Herramientas faltantes',
		surgicalToolsDetection: 'Detección de herramientas quirúrgicas',
		objectDetection: 'Detección de objetos',
		babyDetection: 'Detección de bebés',
		patientPositionDetection: 'Detección de posición del paciente',
		hospitalTimeZone: 'Zona Horaria del Hospital',
		localTimeZone: 'Zona Horaria Local',
		bedRailDetection: 'Detección de cama y rieles',
		actionRecognition: 'Reconocimiento de acción',
		skeletonFallDetection: 'Detección de caída de esqueleto',
		privacyOnSkeletonDetection: 'Privacidad en la detección de esqueletos',
		otoscopeConnected: 'Se ha conectado el otoscopio',
		switchView: 'Cambiar vista',
		areYouSureRebootHuddleCam:
			'¿Estás segura de que quieres reiniciar la cámara {value}? En 30 segundos, la vista volverá a la perspectiva {value}.',
		chooseHealthSystemYouWantToManage: 'Elige el sistema de salud que quieres gestionar.',
		chooseRegionFromHSManageHospitals:
			'Seleccione una región del sistema de salud de arriba para administrar los hospitales en ella.',
		togglePatientTVPowerOn: 'Encender el televisor del paciente',
		volume: 'Volumen',
		switchPatientHDMI: 'Cambiar el HDMI del paciente',
		heartRateTooltip:
			'La frecuencia cardíaca es la velocidad de los latidos del corazón medida por el número de contracciones (latidos) del corazón por minuto (lpm). La Asociación Estadounidense del Corazón establece que la frecuencia cardíaca humana adulta en reposo normal es de 60 a 100 lpm.',
		bloodPressureTooltip:
			'La presión arterial es la presión de la sangre circulante sobre las paredes de los vasos sanguíneos. La mayor parte de esta presión se debe al trabajo realizado por el corazón al bombear sangre a través del sistema circulatorio. Usado sin más especificaciones, "presión arterial" generalmente se refiere a la presión en las arterias grandes de la circulación sistémica.',
		perfusionIndexTooltip:
			'El índice de perfusión o PI es la relación entre el flujo sanguíneo pulsátil y el flujo sanguíneo estático no pulsátil en el tejido periférico de un paciente, como la punta de un dedo de la mano o del pie o el lóbulo de la oreja. El índice de perfusión es una indicación de la fuerza del pulso en el sitio del sensor.',
		stepsTooltip:
			'Una subida de escaleras se calcula como aproximadamente 10 pies (3 metros) de aumento de altura (aproximadamente 16 escalones).',
		flightsClimbedTooltip:
			'Un tramo de escaleras se cuenta como aproximadamente 10 pies (3 metros) de desnivel (aproximadamente 16 escalones).',
		bodyFatPercentageTooltip:
			'El porcentaje de grasa corporal es el peso de la grasa en su cuerpo, en comparación con su peso corporal total, que incluye músculos, huesos, agua, etc.',
		bodyMassIndexTooltip:
			'El IMC es un cálculo de su tamaño que tiene en cuenta su altura y peso. Puede decirle si tiene bajo peso, normal, sobrepeso u obesidad. También puede medir su riesgo de enfermedades que pueden ocurrir con más grasa corporal.',
		bodyTemperatureTooltip:
			'La temperatura corporal normal varía según la persona, la edad, la actividad y la hora del día. La temperatura corporal normal promedio generalmente se acepta como 98.6°F (37°C). Una temperatura superior a 100,4 °F (38 °C) generalmente significa que tiene fiebre causada por una infección o enfermedad. La temperatura corporal normalmente cambia a lo largo del día.',
		oxygenSaturationTooltip:
			'La saturación de oxígeno es una medida de la cantidad de oxígeno en la proteína (hemoglobina) de los glóbulos rojos. Para funcionar correctamente, su cuerpo necesita un cierto nivel de oxígeno circulando en la sangre. Sus glóbulos rojos están cargados (saturados) con oxígeno en los pulmones y lo transportan por todo el cuerpo.',
		sleepAnalysisTooltip:
			'El análisis del sueño proporciona información sobre sus hábitos de sueño. Los rastreadores y monitores de sueño pueden ayudarlo a determinar la cantidad de tiempo que está en la cama y dormido. Estos dispositivos calculan el tiempo que pasas en la cama y el tiempo que duermes analizando los cambios en la actividad física, incluido el movimiento durante la noche. También puede realizar un seguimiento de su sueño ingresando su propia estimación de la hora de acostarse y el tiempo de sueño manualmente. \n El período "En la cama" refleja el período de tiempo que está acostado en la cama con la intención de dormir. Para la mayoría de las personas, comienza cuando apaga las luces y termina cuando se levanta de la cama. El período "Dormido" refleja los períodos en los que está dormido.',
		bloodGlucoseTooltip:
			'La glucosa en la sangre, también llamada azúcar en la sangre, es el azúcar principal que se encuentra en la sangre. Proviene de los alimentos que come y es la principal fuente de energía de su cuerpo.',
		stethoscopeTooltip:
			'El estetoscopio es un dispositivo médico acústico para auscultar o escuchar los sonidos internos de un animal o cuerpo humano. Se puede usar un estetoscopio para escuchar los sonidos producidos por el corazón, los pulmones o los intestinos, así como el flujo sanguíneo en las arterias y las venas.',
		respiratoryRateTooltip:
			'La Frecuencia Respiratoria es su frecuencia de respiración (inhalación y exhalación) dentro de un cierto tiempo (típicamente 60 segundos).',
		noDataFound: 'No hay datos disponibles para este intervalo de fechas',
		noDataAvailable: 'No hay datos disponibles',
		tryOtherDateRange: '¡Prueba seleccionando un intervalo de fechas diferente!',
		healthSystemDoesNotHaveCreatedHospitals:
			'Este sistema de salud no tiene hospitales creados. ¡Asegúrese de crearlos antes de asignar enfermeras!',
		requestAccepted: 'Petición aceptada',
		requestRejected: 'Solicitud rechazada',
		clickAllowToMakeCall: 'Haga clic en �Permitir� para hacer una llamada',
		allowPermissionToMakeCall:
			'Para realizar una llamada en HELLO/HOLAcare, debe otorgar permisos para el micrófono y la cámara.',
		participantHearAndSeeYou: 'Esto permitirá que los demás participantes te oigan y te vean.',
		canTurnOffMicCameraLater: 'Siempre puedes apagar el micrófono o la cámara más tarde.',
		turnOnMicCamera: 'Enciende Micrófono y Cámara',
		deviceNotFound: 'Dispositivo no encontrado',
		videoCallTurnOnCamera: 'Para hacer una videollamada en HELLO/HOLAcare, debes encender tu cámara.',
		audioCallTurnOnMicRefreshPage:
			'Para una llamada de audio, debe encender su micrófono. Vuelva a intentarlo actualizando la página.',
		deviceMayBeInUse: 'El dispositivo puede estar en uso',
		camOrMicAnotherApp: 'Es posible que otra aplicación esté utilizando la cámara o el micrófono.',
		closeOtherAppRefresh: 'Cierra la otra aplicación y actualiza la página.',
		noData: 'Sin datos',
		pleaseReachAdmin: 'Por favor, comuníquese con el administrador para tener esas configuraciones disponibles.',
		overviewDescription: 'Cuando esto está habilitado, se mostrarán datos generales para monitoreo, redondeo y basados en roles.',
		providerCentricReportDescription:
			'Cuando esto está habilitado, se mostrarán datos del proveedor sobre el número de sesiones, pacientes y sesiones',
		patientCentricReportDescription:
			'Cuando esto está habilitado, se mostrarán datos del paciente como el número de sesiones, intervenciones y acciones tomadas.',
		plsReachAdministratorToHaveThisFeatureAvailable:
			'Por favor, comuníquese con su administrador para tener esta función disponible',
		selectOneOfConnectedDoctors: '¡Seleccione uno de los médicos conectados!',
		noConnectedDoctor: '¡No tienes médicos conectados!',
		noActionsFound: 'No se encontraron acciones',
		noHelloIsSelected: '¡No tienes ningún dispositivo {value} conectado!',
		tryBySelectingOne: '¡Prueba seleccionando uno!',
		optionInitiateAudioCall:
			'Esta opción iniciará una llamada de audio al paciente, podrá escucharlo y usted podrá escucharlo. Durante la llamada, puede encender su cámara o la de su paciente, o incluso compartir su pantalla.',
		myPatients: 'Mis pacientes',
		myHellos: 'Mis {value}es',
		noConnectedPatientsYet: '¡Aún no tienes pacientes conectados!',
		noConnectedDoctorsYet: '¡Aún no tienes médicos conectados!',
		editDepartmentDetails: 'Editar detalles del departamento',
		deleteDepartment: 'Eliminar Departamento',
		floorsInThisDepartment: 'Pisos en este departamento',
		roomsInThisDepartment: 'Habitaciones en este departamento',
		enrolledDevices: 'Dispositivos inscritos',
		enrolledCompanionDevices: 'Dispositivos complementarios',
		devices: 'Dispositivos',
		rooms: 'Habitaciones',
		floors: 'Pisos',
		deviceSerialNumber: 'Número de serie del dispositivo',
		tvSerialNumber: 'número de serie de televisión',
		deviceEnrolled: 'Dispositivo inscrito',
		deviceEnrollment: 'Inscripción de dispositivos',
		enrollment: 'Inscripción',
		companyDeviceEnrollment: 'Inscripción de dispositivos a nivel de empresa',
		actions: 'Comportamiento',
		roomNames: 'Nombres de las habitaciones',
		roomNumber: 'Número de habitación',
		writeRoomNumber: 'Escribe el número de habitación',
		floorNames: 'Nombres de pisos',
		unassign: 'Desasignar',
		unassignDevice: 'Desasignar dispositivo',
		unassignPatient: 'Paciente no asignado',
		sureToUnassignDevice:
			'¿Está seguro de que desea anular la asignación del dispositivo {value}? Ni usted ni el paciente podrán realizar ninguna llamada.',
		sureToUnassignPatient:
			'¿Está seguro de que desea anular la asignación de {value} de este dispositivo? Ni usted ni el paciente podrán realizar ninguna llamada.',
		sureToDeleteOrg:
			'¿Está seguro de que desea eliminar {value}? Cualquier dispositivo relacionado con él se eliminará y ni los proveedores de atención virtual ni los pacientes podrán realizar llamadas.',
		editFloorDetails: 'Editar detalles del piso',
		deleteFloor: 'Eliminar piso',
		roomsInThisFloor: 'Las habitaciones de este piso',
		signInToYourAccount: 'Iniciar sesión en su cuenta',
		editHospitalDetails: 'Editar detalles del hospital',
		deleteHospital: 'Eliminar Hospital',
		departmentsInThisHospital: 'Departamentos en este hospital',
		floorsInThisHospital: 'Pisos en este hospital',
		roomsInThisHospital: 'Habitaciones en este hospital',
		departmentNames: 'Nombres de departamentos',
		departments: 'Departamentos',
		editRoomDetails: 'Editar detalles de la habitación',
		familyMember: 'Miembro de la familia',
		familyMembers: 'Miembros de la familia',
		assignFamilyMember: 'Asignar miembro de la familia',
		babyRoom: 'Cuarto del bebé',
		roomDetails: 'Detalles de la habitación',
		roomType: 'Tipo de habitación',
		deleteRoom: 'Eliminar habitación',
		addDevice: 'Añadir dispositivo',
		addCompanionDevice: 'Agregar dispositivo complementario',
		roomDevice: 'Dispositivo de sala',
		assignDevice: 'Asignar dispositivo',
		assignCompanionDevice: 'Asignar dispositivo complementario',
		selectSerialNumberAssociateWithRoom: 'Seleccione un número de serie para asociarlo con esta sala.',
		unitPhoneNumberMust10: `El número de teléfono de la unidad debe tener 10 dígitos`,
		selectCompanionMode: 'Seleccionar modo complementario',
		selectCompanionDevice: 'Seleccionar dispositivo complementario',
		theSectorTypeRoomLocatedAt: 'Esta sala está ubicada en {value}.',
		download: 'Descargar',
		document: 'Documento',
		errorWhileFetchingPatients: 'Error al buscar pacientes',
		selectPatientHelloDevice: 'Seleccione uno de los Patients/{value} Dispositivos!',
		noConnectedPatients: 'No hay pacientes conectados',
		joinHELLOHealth: 'Únete a HELLO/HOLAcare.',
		haveVisitInMinutes: 'Ten una visita en minutos.',
		createUserAccount: 'Crear cuenta de usuario',
		createProviderAccount: 'Crear cuenta de proveedor',
		createHealthSystemProfile: 'Crear un perfil del sistema de salud',
		addRegionsForThisHealthSystem: 'Agregar regiones para este sistema de salud',
		addNew: 'Añadir nuevo',
		healthSystemCreatedSuccessfully: 'Su perfil del sistema de salud ha sido creado con éxito.',
		accountBeingCreatedMayTakeMinutes: 'Su cuenta se está creando. ¡Esto puede tomar unos pocos minutos!',
		voiceCommandForEmergencyAssistance: 'Asistencia de Emergencia',
		vCEADesc:
			'Cuando esto está habilitado, las enfermeras al lado de la cama y los pacientes podrán activarlo diciendo "Hellocare Código Azul"',
		voiceCommandForCareEvents: 'Eventos de Cuidado',
		vCCEDesc:
			'Cuando esto está habilitado, las enfermeras al lado de la cama y los pacientes podrán activarlo diciendo "Hellocare abrir Eventos de Cuidado"',
		voiceCommandForPrivacyEnabling: 'Privacidad',
		vCPDesc:
			'Cuando esto está habilitado, las enfermeras al lado de la cama y los pacientes podrán activarlo diciendo "Hellocare Activar privacidad"',
		voiceCommandForCallNurse: 'Llamar a la Enfermera',
		vCCNDesc: `Cuando esto está habilitado, los pacientes pueden llamar a las enfermeras diciendo "Hellocare Call Nurse" o "Hellocare Call the Nurse"`,
		voiceCommands: 'Comandos de Voz',
		invalidInvitation: 'Esta es una invitación inválida',
		inviteAlreadyAccepted: 'La invitación ya ha sido aceptada',
		inviteCancelled: 'La invitación ha sido cancelada por el administrador del sistema',
		inviteExpired: 'La invitación expiró',
		checkInvitationLinkValidity:
			'Vuelva a su correo electrónico y asegúrese de que el enlace en el que ha hecho clic sea válido. Además, comuníquese con su administrador para asegurarse de que aún no haya aceptado esta invitación.',
		anotherNursePickedUpCall: 'Otra enfermera atendió esta llamada',
		waitAttemptingReconnect: 'Por favor espere... intentando reconectarse a la red.',
		manageHealthSystem: 'Gestionar Sistema de Salud',
		changesWillBeAppliedToThisHS:
			'Cualquier cambio que realice se aplicará a este sistema. Puede cambiar el nombre o eliminar este sistema.',
		pressEnterToSave: 'Presiona enter para guardar',
		regions: 'Regiones',
		availableRegionsInThisHS:
			'Estas son las regiones disponibles en este sistema de salud. Crea más, cambia el nombre o elimina una región.',
		addNewRegion: 'Añadir nueva región',
		areYouSureToDeleteRegion: '¿Está seguro de que desea eliminar esta región?',
		areYouSureToDeleteHS: '¿Estás seguro de que quieres eliminar este Sistema de Salud?',
		sectorIsLocatedAt: 'Este {value2} está ubicado en {value}',
		boardType: 'Tipo de Tablero',
		selectBoard: 'Seleccionar tablero',
		rowsPerPage: 'Filas por página',
		minttiStethoscope: 'estetoscopio mintti',
		littmannStethoscope: 'estetoscopio littmann',
		manuallyAdded: 'Agregado manualmente',
		sipEndpoint: 'Punto de Finalización SIP',
		addData: 'Agregar datos',
		systolic: 'sistólica',
		diastolic: 'diastólica',
		selectStartDate: 'Seleccionar fecha de inicio',
		selectEndDate: 'Seleccionar fecha de finalización',
		allRecordedData: 'Todos los datos registrados',
		deleteAllDataRecords: 'Eliminar todos los registros de datos',
		add: 'Agregar',
		addDataManually: 'Agregar datos manualmente',
		dateAndTime: 'Fecha y hora',
		inBed: 'En cama',
		inChair: 'En silla',
		asleep: 'Dormido',
		selectState: 'Seleccione estado',
		done: 'Hecho',
		deleteThisDataRecord: 'Eliminar este registro de datos',
		dataDetails: 'Detalles de datos',
		dateOfMeasurement: 'Fecha de medición',
		source: 'Fuente',
		manufacturer: 'Fabricante',
		model: 'Modelo',
		areYouSureDeleteThisRecord: '¿Está seguro de que desea eliminar este registro?',
		areYouSureDeleteAllRecords: '¿Está seguro de que desea eliminar todos los registros de datos?',
		documentName: 'Nombre del documento',
		creationDate: 'Fecha de creación',
		attachment: 'Archivo adjunto',
		reboot: 'Reiniciar',
		rebootCamera: 'Reiniciar cámara',
		patientsTV: 'TV del paciente',
		togglePatientTVtoHDMI: 'Cambiar la TV del paciente a HDMI',
		maxCharacterLengthExceeded: 'Número máximo de caracteres excedido',
		firstNameMustStartWithCapitalLetter: 'El nombre debe comenzar con una letra mayúscula',
		pleaseWriteFirstName: 'Por favor escribe tu nombre',
		lastNameMustStartWithCapitalLetter: 'El apellido debe comenzar con una letra mayúscula',
		pleaseWriteLastName: 'Por favor escribe tu apellido',
		emailAlreadyRegistered: 'La dirección de correo electrónico proporcionada ya está registrada.',
		newEmailDifferent: 'El nuevo correo electrónico debe ser diferente al antiguo.',
		invalidEmail: 'El correo electrónico proporcionado no es válido',
		pleaseWriteEmail: 'Por favor escriba su correo electrónico',
		emailsMustMatch: 'Los correos electrónicos deben coincidir',
		passwordsMustMatch: 'Las contraseñas deben coincidir',
		pleaseConfirmYourEmail: 'Por favor, confirme su correo electrónico',
		pleaseWriteDateOfBirth: 'Por favor, escriba su fecha de nacimiento',
		passwordCannotBeLessThan8Chart:
			'Su contraseña debe tener al menos 8 caracteres y contener al menos un carácter en mayúscula, uno en minúscula, un número y un símbolo especial',
		pleaseWritePassword: 'Por favor escribe tu contraseña',
		pleaseWriteConfirmPassword: 'Por favor, confirme su contraseña',
		haveToAgreeToTermsAndConditions: '¡Tienes que aceptar nuestros Términos y Condiciones!',
		haveToAgreePrivacyPolicy: '¡Tienes que aceptar nuestra Política de Privacidad!',
		locationMustStartWithCapitalLetter: 'La ubicación debe comenzar con una letra mayúscula',
		pleaseWriteCurrentLocation: 'Por favor escribe tu ubicación actual',
		pleaseSelectDateOfBirth: 'Por favor, seleccione su fecha de nacimiento',
		pleaseSelectSex: 'Seleccione un sexo',
		pleaseEnterHeight: 'Por favor ingrese su altura',
		pleaseEnterWeight: 'Por favor ingrese su peso',
		pleaseSelectOne: 'Por favor, seleccione uno',
		pleaseSelectHealthInsurancePlan: 'Seleccione su plan de seguro de salud',
		pleaseWriteSubscriberId: 'Por favor escriba su ID de suscriptor',
		pleaseWritePrimarySubscriberFirstName: 'Escriba el nombre de su suscriptor principal',
		pleaseWritePrimarySubscriberLastName: 'Escriba el apellido de su suscriptor principal',
		pleaseWritePrimarySubscriberBirthDate: 'Escriba la fecha de nacimiento de su suscriptor principal',
		serviceKeyLearnMore:
			'Puede recibir una clave de servicio de su empleador, plan de salud o proveedor de atención médica para activar un nuevo servicio. Es opcional y no obligatorio.',
		numberTimesFallen: 'Número de veces caído',
		lowest: 'más bajo',
		average: 'Promedio',
		highest: 'más alto',
		changePresence: 'Cambiar presencia',
		height: 'Altura',
		width: 'Ancho',
		bodyMassIndex: 'Índice de masa corporal',
		primaryDiagnosis: 'Diagnóstico primario',
		headInjury: 'Lesión en la cabeza',
		basicInformation: 'Información básica',
		fillUpFormOnRightSide: '¡Rellena el formulario de la derecha con tus datos reales!',
		currentLocation: 'Localización actual',
		back: 'Atrás',
		continue: 'Continuar',
		signUp: 'Inscribirse',
		alreadyHaveAnAccount: 'Ya tienes una cuenta',
		clickHereToSignIn: 'Clic aquí para ingresar',
		personalInformation: 'Informacion personal',
		agreeToThe: 'Estoy de acuerdo con la',
		termsAndConditions: 'Términos y condiciones',
		privacyPolicy: 'Política de privacidad',
		healthInsurance: 'Seguro de salud',
		doYouHaveHealthInsurance: '¿Tiene seguro de salud?',
		yes: 'Sí',
		no: 'No',
		serviceKey: 'Clave de servicio',
		enterReceivedKey: 'Si recibió una clave de su empleador u otro grupo, introdúzcala aquí.',
		learnMore: 'Aprende más',
		finish: 'Finalizar',
		yourAccountHasBeenSuccessfullyCreated: '¡Tu cuenta ha sido creada satisfactoriamente!',
		checkEmailToActivateAccount: 'Revisa tu correo electrónico para activar tu cuenta.',
		yourAccountActivated: '¡Tu cuenta ha sido activada!',
		continueToSignIn: 'Continuar para iniciar sesión',
		accountActivationFailedTryAgain: '¡Error en la activación de la cuenta! Inténtalo de nuevo',
		nurse: 'Enfermero',
		virtualCareProvider: 'Proveedor de atención virtual',
		outgoing: 'Saliente',
		incoming: 'Entrante',
		viewAll: 'Ver todo',
		callInitiator: 'Iniciador de llamada',
		room: 'Habitación',
		dateSlashTime: 'Fecha y hora',
		callOrigin: 'Llamar Origen',
		callStatus: 'Estado de la llamada',
		callDuration: 'Duración de la llamada',
		successful: 'Exitoso',
		successfulDescription: 'Todos los participantes pudieron unirse.',
		partiallySuccessful: 'Parcialmente exitoso',
		partiallySuccessfulDescription: 'Algunos de los participantes no pudieron unirse debido a errores.',
		failed: 'Fallido',
		failedDescription: 'Ninguno de los participantes pudo conectarse debido a errores.',
		disrupted: 'Interrumpido',
		disruptedDescription:
			'La llamada se estableció con éxito, pero durante la llamada, las acciones de alguien causaron que todos perdieran la capacidad de escucharse y verse.',
		partiallyDisrupted: 'Parcialmente interrumpido',
		partiallyDisruptedDescription:
			'La llamada se estableció con éxito, pero durante la llamada, las acciones de alguien causaron que algunos de los participantes perdieran la capacidad de escucharse y verse.',
		missed: 'Perdida',
		missedDescription: 'La llamada se realizó con éxito, pero el participante invitado se perdió la llamada.',
		aborted: 'Abortada',
		abortedDescription:
			'La llamada ha terminado, mientras otros todavía estaban conectándose a la llamada o la llamada aún no se había establecido.',
		dropped: 'Interrumpida',
		droppedDescription:
			'La conferencia se marca como interrumpida, si la llamada se establece pero el participante se desconecta causando que toda la conferencia termine.',
		excludeDurationValidation: '¡La duración de la exclusión debe estar entre 1 y 60 segundos!',
		excludeStatusValidation: '¡No puedes excluir todos los estados de llamadas!',
		excludeDurationInfo:
			'Cuando se aplica el filtro de duración, las llamadas perdidas, canceladas y fallidas se excluyen del informe.',
		excludeCalls: 'Excluir llamadas',
		under: 'bajo',
		withStatus: 'con estado',
		audioUsed: 'Audio usado',
		videoUsed: 'Vídeo usado',
		contentSharing: 'Compartir contenido',
		queue: 'Cola',
		export: 'Exportar',
		region: 'Región',
		hospital: 'Hospital',
		department: 'Departamento',
		floor: 'Piso',
		queueOfNursesThatWereCalled: 'Cola de enfermeras que llama',
		generalSettings: 'Configuración general',
		closeSession: 'Cerrar la sesión',
		selectMemberToInvite: 'Seleccione un miembro para invitar',
		selectMeetingPlaceDevice: 'Seleccione un lugar de encuentro o dispositivo',
		deviceOnCall: 'Dispositivo en otra llamada.',
		deviceOnCallWithNurse: 'Este dispositivo está siendo monitoreado por',
		deviceOffline: 'Dispositivo fuera de línea',
		initiatingMonitoring: 'Iniciando monitoreo...',
		notAnswering: 'La conexión falló! Cierre y vuelva a abrir la ventana para continuar monitoreando.',
		leftCall: 'No se pudo volver a conectar a la red. Vuelva a agregar este feed.',
		disconnected: 'El paciente está desconectado de la llamada.',
		connected: 'Conectado',
		reconnecting: 'Espere... intentando volver a conectarse a la red.',
		participantRemoved: 'Esta sesión fue finalizada por un administrador.',
		addToMonitoringView: 'Agregar a vista de monitoreo',
		organization: 'Organización',
		callLogs: 'Registros de llamadas',
		collapse: 'Colapso',
		recentLowerCase: 'Reciente',
		hospitals: 'hospitales',
		rounding: 'Rondas',
		patientRequests: 'Solicitudes de pacientes',
		solutions: 'Soluciones',
		providers: 'Proveedores',
		monitoring: 'Monitoreo',
		close: 'Cerrar',
		addHospital: 'Añadir hospital',
		create: 'Crear',
		createMeeting: 'Crear reunión',
		monday: 'Lunes',
		tuesday: 'Martes',
		wednesday: 'Miércoles',
		thursday: 'Jueves',
		friday: 'Viernes',
		saturday: 'Sábado',
		sunday: 'Domingo',
		bloating: 'Hinchazón',
		cough: 'Tos',
		diarrhea: 'Diarrea',
		dizziness: 'Mareo',
		fatigue: 'Fatiga',
		fever: 'Fiebre',
		headache: 'Dolor de cabeza',
		muscleCramp: 'Calambre muscular',
		nausea: 'Náuseas',
		throatIrritation: 'Irritación de garganta',
		runnyNose: 'Nariz que moquea',
		throwingUpVomiting: 'Vomitar (vomitar)',
		soreThroat: 'Dolor de garganta',
		headPressure: 'Presión en la cabeza',
		lowBodyTemperature: 'Baja temperatura corporal',
		highBodyTemperature: 'Alta temperatura corporal',
		backPain: 'Dolor de espalda',
		appetiteChanges: 'Cambios en el apetito',
		earache: 'Dolor de oidos',
		sweating: 'Transpiración',
		sneezing: 'estornudos',
		fainting: 'Desmayo',
		skinRash: 'Erupción cutanea',
		insomnia: 'Insomnio',
		itchySkin: 'Picazón en la piel',
		jointPainAndSwelling: 'Dolor e hinchazón en las articulaciones',
		kneePain: 'Dolor de rodilla',
		legPain: 'Dolor de pierna',
		limbNumbness: 'Entumecimiento de las extremidades',
		muscleAchesAndPains: 'Dolores y dolores musculares',
		nosebleeds: 'hemorragias nasales',
		shoulderPain: 'Dolor de hombro',
		stomachPain: 'Dolor de estómago',
		swellingInJoints: 'Hinchazón (en las articulaciones)',
		tummyPain: 'Dolor de barriga',
		addDepartment: 'Agregar Departamento',
		support: 'Soporte',
		sla: 'SLA',
		na: 'N/D',
		addFloor: 'Añadir Piso',
		addRoom: 'Añadir habitación',
		goBackToHealthApp: 'Volver a la aplicación HELLO/HOLAcare',
		fullScreen: 'Pantalla completa',
		exitFullScreen: 'Salir de pantalla completa',
		fitToScreen: 'Ajustar a la pantalla',
		screenshare: 'Compartir pantalla',
		sound: 'Sonido',
		turnPatientTVOff: 'Apague la TV del paciente',
		turnPatientTVOn: 'Encienda la TV del paciente',
		changeEmail: 'Cambiar correo electrónico',
		changePassword: 'Cambia la contraseña',
		currentPassword: 'Contraseña actual',
		enterCurrentPassword: 'Introducir la contraseña actual',
		enterYourCurrentPassword: 'Introduce tu contraseña actual',
		enterNewPassword: 'Introduzca nueva contraseña',
		reEnterNewPassword: 'Re-ingrese nueva contraseña',
		updatePassword: 'Actualiza contraseña',
		editChangeAccountInfo: 'Edite o cambie la información de su cuenta',
		statusCurrentlyOnline: 'Su estado es actualmente en línea',
		statusCurrentlyOffline: 'Tu estado es actualmente desconectado',
		passwordChangedSuccessfully: 'Su contraseña ha sido cambiada con éxito',
		emailChangedSuccessfully: 'Su correo electrónico ha sido cambiado con éxito',
		oldPasswordIncorrect: 'Tu contraseña actual es incorrecta',
		errorDuringRequestProcessing: 'Ha ocurrido un error al procesar su solicitud',
		passwordRequired: 'La contraseña es requerida',
		atLeastCharacters: 'La contraseña debe ser al menos',
		characters: 'caracteres',
		notMatchCurrentPassword: 'Su nueva contraseña debe ser diferente a su contraseña anterior',
		mustMatchPassword: 'Las contraseñas deben coincidir',
		passwordConfirmationRequired: 'Se requiere confirmación de contraseña',
		participantCameraOff: '{value} ha desactivado la cámara.',
		modifyUserInformation: 'Modificar información de usuario',
		zipCode: 'Código postal',
		country: 'País',
		city: 'Ciudad',
		state: 'Estado',
		visitCostCurrency: 'Moneda de costo de visita',
		dateOfBirthRequired: 'Se requiere la fecha de nacimiento',
		countryRequired: 'El país es obligatorio',
		stateRequired: 'El estado es requerido',
		cityRequired: 'Ciudad es requerida',
		zipCodeRequired: 'Se requiere el código postal',
		addressRequired: 'La dirección es necesaria',
		enterCity: 'Ingresa tu ciudad',
		enterZipCode: 'Ingrese su codigo postal',
		enterAddress: 'Introduce tu dirección',
		enterVisitCost: 'Introduce el coste de tu visita',
		writeWelcomeMessage: 'Escribe tu mensaje de bienvenida',
		profileEditedSuccessfully: 'Tu perfil ha sido editado con éxito',
		profilePicture: 'Foto de perfil',
		updatePicture: 'Actualizar foto',
		shouldUploadProfilePicture: 'Deberías subir una foto de perfil',
		acceptedFilesTypesAre: 'Los tipos de archivos aceptados son',
		select: 'Seleccione',
		visitCostRequired: 'El costo de la visita es obligatorio',
		visitCostCurrencyRequired: 'Se requiere la moneda del costo de la visita',
		welcomeMessageRequired: 'Se requiere un mensaje de bienvenida',
		from: 'desde',
		to: 'a',
		requestDoctor: 'Solicitar un médico',
		thankYou: 'Gracias',
		requestSentSuccessfully: 'Su solicitud ha sido enviada con éxito',
		goToThe: 'Ve a la',
		toGetStarted: 'Para empezar',
		requests: 'Solicitudes',
		noConversation: 'No tienes conversación con {value}',
		startConvo: 'Inicia la conversación escribiendo un mensaje o adjuntando un archivo',
		noRequestsFound: '¡No se encontraron solicitudes!',
		noMatchesFound: 'No se encontraron coincidencias',
		noDoctorsBasedOnSymptoms: 'No se encuentran médicos que coincidan con sus síntomas y problemas de salud',
		noHealthDataFound: '¡No se encontraron datos de salud!',
		noHealthDataCurrently: 'Actualmente no tiene ningún dato de salud agregado',
		newConnection: 'Nueva conexión',
		isNowConnection: 'ahora es una conexión.',
		removeConnection: 'Eliminar conexión',
		callViewMessage: 'Puede llamar, enviar mensajes o ver los datos de salud de {value} a través del menú de pacientes',
		goToPatient: 'Ir a Paciente',
		newConfiguration: 'Nueva configuración',
		addPrivacyConfiguration: 'Agregar configuración de privacidad',
		editPrivacyConfiguration: 'Editar configuración de privacidad',
		selectPrivacy: 'Seleccione Privacidad',
		createdBy: 'Creado por',
		dateCreated: 'Fecha de creacion',
		isHealthSystemActive: '¿Está HealthSystem activo',
		isPrivateModeOn: '¿Está activado el modo de privacidad?',
		healthCareId: 'Identificación de atención médica',
		privacyModeIsOn: 'El modo de privacidad está activado',
		configurations: 'Configuraciones',
		turnOffSurgicalTools: 'Desactivar modo quirúrgico',
		turnOnSurgicalTools: 'Activar modo quirúrgico',
		turnOffBabyDetection: 'Desactivar modo bebé',
		turnOnBabyDetection: 'Activar modo bebé',
		turnOffPatientPosition: 'Desactivar posición del paciente',
		turnOnPatientPosition: 'Activar posición del paciente',
		turnOffBedAndRails: 'Apague la cama y los rieles ',
		turnOffSkeletonAndPrivacy: 'Desactiva el modo esqueleto',
		turnOnBedAndRails: 'Prende cama y barandillas',
		turnOnSkeletonAndPrivacy: 'Activa el Modo Esqueleto',
		turnOnObjectDetection: 'Activar detección de objetos',
		turnOffObjectDetection: 'Desactivar la detección de objetos',
		surgicalToolsMissing: 'Faltan herramientas quirúrgicas',
		railDown: 'Riel abajo',
		breathsPerMin: 'respiraciones/min',
		unknownDevice: 'Dispositivo desconocido',
		activities: 'Actividades',
		inputValue: 'Valor',
		privateMode: 'Modo privado',
		doctorOnList: 'Médico',
		superUserRoleValidation: 'La función de superusuario no se puede asignar a varios sistemas de salud',
		superUserHealthSystemValidation: 'El Superusuario debe estar asignado a un Sistema de Salud',
		superUserOnList: 'Superusuario',
		superUser: 'Superusuario',
		superAdminOnList: 'superadministrador',
		ownerOnList: 'Propietario',
		nurseOnList: 'Enfermero',
		patientOnList: 'Paciente',
		adminOnList: 'Administrador',
		sentOnList: 'Enviado',
		registeredOnList: 'Registrado',
		pendingOnList: 'pendiente',
		cancelledOnList: 'Cancelado',
		failedOnList: 'Fallido',
		virtualSitter: 'Niñera virtual',
		undefinedOnList: 'Indefinido',
		successfulOnList: 'Exitoso',
		partiallySuccessfulOnList: 'Parcialmente exitoso',
		disruptedOnList: 'Interrumpido',
		partiallyDisruptedOnList: 'Parcialmente interrumpido',
		missedOnList: 'perdido/a',
		abortedOnList: 'Abortado',
		droppedOnList: 'descender',
		on: 'Enc.',
		off: 'Ap.',
		fitnessTracker: 'Rastreador de actividad física',
		bodyWeightScale: 'Báscula de peso corporal',
		weightScale: 'Escala de peso',
		weightScaleDevice: 'Escala de peso',
		noEvents: 'No hay eventos.',
		glucometer: 'glucómetro',
		infraredEarThermometer: 'Termómetro de oído infrarrojo',
		thermometer: 'Termómetro',
		iphone: 'iPhone',
		pulseOximeter: 'Oxímetro de pulso',
		hours: 'horas',
		timeOfMeasurement: 'Tiempo de medición',
		fallDetected: 'Caída detectada',
		custom: 'Personalizado',
		auto: 'Automático',
		audioSettings: 'Configuración de Audio',
		audioTuning: 'Ajuste de Audio',
		selectValue: 'Seleccionar Valor',
		selectOneOfTheOptions: 'Seleccione una de las opciones',
		selectCustomValue: 'Seleccionar Valor Personalizado',
		hdmiSyncDelay: 'Retraso del Sistema HDMI',
		detected: 'detectado',
		hasMeasured: 'ha medido',
		callPatient: 'Llamar al paciente',
		callPatientDescription: 'Cuando esto está habilitado, los usuarios podrán llamar al paciente mientras lo monitorean.',
		patientCommunication: 'Mensaje de Monitoreo',
		patientCommunicationDescription:
			'Cuando esto está habilitado, los pacientes podrán ver un mensaje en la pantalla de {helloName} que indica que están siendo monitoreados.',
		monitoringPrecautionsLegend: 'Monitoreo Precauciones Leyenda',
		acknowledge: 'Reconocer',
		hasAcceptedYourRequest: 'ha aceptado su solicitud para la categoría {value}',
		hasRejectedYourRequest: 'ha rechazado su solicitud para la categoría {value}',
		hasCanceledRequest: 'ha cancelado la solicitud de la categoría {value}',
		hasSentRequest: 'le ha enviado una solicitud para la categoría {value}',
		unread: 'No leído',
		earlier: 'Antes',
		alerts: 'Alertas',
		upload: 'Subir',
		zipCodeDigitsMax: 'El código postal debe ser menor o igual a 10 dígitos',
		quickOptions: 'Opciones rápidas',
		addDependents: 'Agregar dependientes',
		measureYourVitalSigns: 'Medir Signos Vitales',
		updateProfile: 'Actualización del perfil',
		upcomingAppointments: 'próximas citas',
		goToMyAppointments: 'Ve a mis citas',
		goToMyRequests: 'Ver todas las solicitudes',
		vitalSigns: 'Signos vitales',
		ewsAiVitalSigns: 'Signos vitales de IA EWS',
		inputterFirstName: 'Nombre del Ingresador',
		inputterLastName: 'Apellido del Ingresador',
		goToMyHealthData: 'Ir a mis Datos de Salud',
		limitedSelectedSymptoms: 'No puede seleccionar más de 5 síntomas.',
		comingSoon: 'Próximamente',
		pleaseTryAgainLater: 'Por favor, inténtelo de nuevo más tarde',
		currentlyNoHealthDataMainAnalysis: 'Actualmente no hay datos de salud Análisis principal',
		currentlyNoVitalSigns: `{value} actualmente no tiene ningún dato de salud agregado del análisis principal. Haga clic en 'Ir a los signos vitales' para ver todos los datos.`,
		extremelyHighTemperature: '¡El paciente tiene una temperatura extremadamente alta!',
		highTemperature: '¡El paciente tiene temperatura alta!',
		lowTemperature: '¡El paciente tiene temperatura baja!',
		highBloodPressure: '¡El paciente tiene presión arterial alta!',
		extremelyHighPressure: '¡El paciente tiene una presión extremadamente alta!',
		extremelyLowBloodPressure: '¡El paciente tiene una presión extremadamente baja!',
		lowBloodPressure: '¡El paciente tiene la presión arterial baja!',
		lowHeartRate: '¡El paciente tiene un ritmo cardíaco muy bajo!',
		highHeartRate: '¡El paciente tiene un ritmo cardíaco muy alto!',
		highBloodGlucose: '¡El paciente tiene la glucosa en sangre muy alta!',
		overweight: '¡El paciente tiene sobrepeso!',
		obese: '¡El paciente es obeso!',
		underweight: '¡El paciente tiene bajo peso!',
		sleepDeficient: '¡El paciente tiene deficiencia de sueño!',
		privacyMode: 'Modo privado',
		microphoneMuted: 'Micrófono silenciado',
		microphoneOn: 'Micrófono encendido',
		cameraOn: 'Cámara encendida',
		cameraOff: 'cámara apagada',
		privacyModeOff: 'Modo de privacidad desactivado',
		privacyModeOn: 'Modo de privacidad activado',
		checkDateOfBirth: 'Debes tener al menos 18 años para inscribirte',
		greaterThanEighteen: 'Debes tener al menos 18 años',
		homePage: 'Casa',
		newPatientRequests: 'Solicitudes de nuevos pacientes',
		noNewRequests: 'No tienes nuevas Solicitudes',
		about: 'Como',
		termsOfUse: 'Términos de Uso',
		goTo: 'Ir a',
		domain: 'Dominio',
		domainRequired: 'Se requiere dominio',
		validGroupName: 'Nombre de grupo válido',
		group: 'Grupo',
		allCaughtUp: 'Estáis todos atrapados',
		noNewAppointmentsOrRequests: '¡No tiene nuevas citas próximas ni nuevas solicitudes!',
		age: 'Años',
		failedToLoad: 'Falló al cargar',
		hvrIndex: 'Índice HVR',
		stressLevel: 'Nivel de estrés',
		mildlyCalm: 'Ligeramente tranquilo',
		highlyTense: 'muy tenso',
		slightlyTense: 'Ligeramente TENSO',
		quietlyRelaxed: 'Tranquilamente Relajado',
		deeplyRelaxed: 'profundamente relajado',
		vitalsCamera: 'cámara de vitales',
		cameraInUseHeader: 'La cámara ya está en uso: cierre otras aplicaciones',
		cameraInUseParagraph1: 'Para participar en la reunión con una cámara, deshabilite la cámara de otras aplicaciones',
		cameraInUseParagraph2: 'Actualizar la página para inicializarla',
		noAppointmentRequests: '¡No tienes solicitudes de cita!',
		noAcceptedAppointments: '¡No tienes solicitudes aceptadas!',
		scheduleAvailability: 'Horario disponibilidad',
		schedulingAvailability: 'Programación basada en su disponibilidad',
		addTopic: 'Añadir tema',
		setupAccount: 'Configurar la cuenta',
		noSetupAccount: 'Parece que aún no ha configurado una cuenta de calendario, haga clic en el botón de abajo para continuar',
		setupCalendarAccount: 'Configura tu cuenta de calendario',
		viewSomeonesProfile: 'Ver el perfil de {value}',
		reSchedule: 'Reprogramar',
		chooseAvailableTime: 'Elija su tiempo disponible para ',
		letAvailbleSchedule: 'Informe a los pacientes cuando esté disponible para aceptar reuniones',
		setup: 'Configuración',
		availability: 'disponibilidad',
		noUpcomingAppointments: '¡No tienes citas próximas!',
		roomNoDeviceAssigned: 'Esta sala no tiene ningún dispositivo asignado',
		roomsNeedDevice: 'Las habitaciones necesitan tener un dispositivo asignado',
		inOrderToCall: 'para llamar',
		createCompanyProfile: 'Crear un perfil de empresa',
		companyWebsite: 'Página Web de la compañía',
		createCompany: 'Crear Empresa',
		owner: 'Propietario',
		companyType: 'Tipo de compañía',
		website: 'Sitio web',
		companyId: 'ID de la compañía',
		goBack: 'Volver',
		companySuccessfullyCreated: 'Empresa creada con éxito',
		meetingTime: 'Tiempo de reunión',
		meetingDate: 'Fecha de la reunión',
		noPendingAppointments: '¡No tienes solicitudes pendientes!',
		noRejectedAppointments: '¡No tienes solicitudes rechazadas!',
		patientEmail: 'Correo electrónico del paciente',
		familyMemberEmail: 'Correo electrónico del miembro de la familia',
		patientFirstName: 'Nombre del paciente',
		familyMemberFirstName: 'Nombre del miembro de la familia',
		patientLastName: 'Apellido del paciente',
		familyMemberLastName: 'Apellido del miembro de la familia',
		assignPatient: 'Asignar paciente',
		assignmentMethod: 'Método de asignación',
		modalLogsTitle: 'Registros de dispositivos',
		modalLogsFailure: 'No se pudieron obtener los registros del dispositivo. El dispositivo no está conectado.',
		modalLogsWentWrong: 'Algo salió mal. Por favor, vuelva a intentarlo',
		modalLogsSuccess: 'Registros del dispositivo registrados en el sistema Sollaborate. Puedes cerrar este mensaje.',
		modalLogsButton: 'Obtener registros del dispositivo',
		rebootExportLogsButton: 'Exportar registros de reinicio',
		rebootExportLogsNoLogsYet: 'Este dispositivo aún no tiene registros de reinicio.',
		exportAsCSV: 'Exportar como CSV',
		endpointWhitelisting: 'Lista blanca de terminales',
		csv: 'CSV',
		reportedOn: 'Reportado En',
		id: 'Identificación',
		allowed: 'Permitida',
		denied: 'Denegada',
		protocol: 'Protocolo',
		response: 'Respuesta',
		searchPatient: 'Buscar Paciente',
		newPatient: 'Paciente nuevo',
		existingPatient: 'Paciente existente',
		requiredField: 'Campo requerido',
		selectedPatient: 'Paciente Seleccionado',
		nextDays: 'dias',
		nextTime: 'En el proximo',
		nextHours: 'horas',
		nextHour: 'hora',
		nextMinutes: 'minutos',
		availableHours: 'Horas Disponibles',
		availableDays: 'Días Disponibles',
		break: 'Pausa',
		checkSelectedBreaks: 'Por favor, compruebe los tiempos de descanso seleccionados',
		sundays: 'domingos',
		mondays: 'los lunes',
		tuesdays: 'los martes',
		wednesdays: 'miércoles',
		thursdays: 'jueves',
		fridays: 'viernes',
		saturdays: 'Sábados',
		month: 'Mes',
		week: 'Semana',
		day: 'Día',
		remote: 'Remoto',
		clearData: 'Borrar datos',
		resetDevice: 'Reiniciar el dispositivo',
		th: 'el',
		fileUploadError: 'El archivo {value} ya existe',
		conversationHistory: 'Historial de conversación',
		noDevices: 'Sin dispositivos',
		oneDevice: '{value} Dispositivo',
		moreThanOneDevice: '{value} Dispositivos',
		healthSystemRequired: 'Se requiere sistema de salud',
		hospitalRequired: 'Se requiere hospital',
		emailRequired: 'Correo electronico es requerido',
		userAlreadyExists: 'Este usuario ya existe',
		privacy: 'Privacidad',
		enablePrivacyMode: 'Habilitar el modo de privacidad',
		privacyDescription:
			'Cuando está habilitado, cualquier persona que llame a este dispositivo verá una vista borrosa de la cámara.',
		futureConfigurations: 'Configuraciones futuras',
		false: 'Falso',
		true: 'Verdadero',
		forwarded: 'Reenviado',
		acknowledged: 'Reconocido',
		rotate: 'Rotar',
		filter: 'Filtrar',
		appointment: 'cita',
		editMeeting: 'Editar reunión',
		companyDetails: 'Detalles de la compañía',
		companySettings: 'Configuración de la empresa',
		companyAddress: 'Dirección de la empresa',
		remotePatientMonitoring: 'Monitoreo Remoto de Pacientes',
		continuousPatientMonitoring: 'Monitoreo Continuo del Paciente',
		numberOfPatients: 'Número de pacientes',
		highRisk: 'Alto riesgo',
		memberStatus: 'Estado de miembro',
		disableAccess: 'Deshabilitar acceso',
		moderateRisk: 'Riesgo moderado',
		searchByNameOrHello: 'Buscar pacientes por nombre o {value}',
		optional: 'Opcional',
		uploadPicture: 'Subir foto',
		profileImageDescription:
			'Esta foto se usará como su imagen de perfil y los pacientes podrán verla en nuestro sitio web y nuestra aplicación móvil.',
		pleaseUploadPicture: 'Por favor suba una foto de perfil',
		maxSizePic: 'El tamaño de la imagen no puede exceder los 600 KB.',
		commandNotSupported: 'Este comando no es compatible con el televisor de este paciente.',
		userIdleConfigurations: 'Configuraciones inactivas del usuario',
		minutes: 'Minutos',
		editedBy: 'Editado por',
		dateEdited: 'Fecha de edición',
		historyFor: 'Historia para',
		backToConfigs: 'Volver a Configuraciones',
		setTimeOut: 'Establecer tiempo de espera',
		viewHistory: 'Ver historial',
		minutesGreaterThanZero: 'Los minutos deben ser mayores que 0',
		minutesRequired: 'Se requieren minutos',
		userIdleFormTitle: 'El usuario debe volver a iniciar sesión después de un período de inactividad',
		selectHealthSystemOrAll: 'Seleccione el sistema de salud o elija Todos para aplicar el período de inactividad',
		setInActivityPeriod: 'Establecer periodo de inactividad',
		userIdleNotifedPeriod:
			'El usuario será notificado durante 30 segundos, si no confirma su presencia, se desconectará automáticamente',
		configurationSuccessfullyAdded: 'Configuración añadida con éxito',
		imHere: 'Estoy aquí',
		idleMessage: 'Has estado inactivo durante {value} minutos.',
		idleMessageDescription: 'Se cerrará la sesión en caso de que no confirme su presencia en los próximos {value} segundos',
		seeDoctorNow: 'Ver al médico ahora',
		waitingRoomDoctor: 'Se espera que su llamada en vivo con {value1} comience en {value2} o menos',
		doctorAvailableNow: 'está disponible ahora',
		doctorUnAvailableNow: 'no está disponible para una llamada ahora',
		hasPatientsInWaitingList: ' tiene {value} otros pacientes en lista de espera',
		goToWaitingRoom: 'Ir a la sala de espera',
		pleaseSpecifySymptoms: 'Por favor, especifique sus síntomas para encontrar el médico que más le convenga.',
		pleaseSelectAvailableDoctor: 'Por favor, seleccione uno de los médicos disponibles para proceder con su cita.',
		seeTheAvailableDoctors: 'Consultar a los médicos disponibles',
		pleaseWaitUntilDoctorCalls: 'Por favor, espere hasta que el médico le llame',
		liveCallExpectedToStart: 'Se espera que su llamada en vivo con el Dr. {value} comience en',
		orLess: 'o menos',
		searchForDoctors: 'Buscar y seleccionar un médico',
		additionalHealthData: 'Datos adicionales de salud',
		additionalNotes: 'Notas adicionales',
		clickHere: 'Haga clic aquí',
		orDragAndDrop: 'o arrastrar y soltar para cargar un archivo',
		leaveWaitingRoom: 'Sal de Sala de Espera',
		liveCallRequest: 'Solicitud de llamada en vivo',
		requestTime: 'Tiempo requerido',
		notNow: 'Ahora no',
		hasRequestedAliveCall: 'ha solicitado una llamada en vivo con usted',
		haveRequestedAliveCall: 'ha solicitado una llamada en vivo con usted',
		andNumberOthers: 'y {value} de los demás',
		typeHere: 'Escriba aquí',
		notSpecified: 'No especificado',
		changeDoctor: 'Cambiar de médico',
		changedYourMind: 'Si ha cambiado de opinión acerca de su médico, puede elegir otro',
		diagnoses: 'Diagnósticos',
		procedures: 'Procedimientos',
		medicalInfoProcedures: 'Procedimientos',
		notes: 'Notas',
		medicalInfoNotes: 'Notas',
		notesDescription: 'Cuando está habilitado, los usuarios podrán escribir notas mientras monitorean a un paciente.',
		showNotesOnExpand: 'Mostrar notas solo al expandir',
		showNotesUnderTile: 'Mostrar notas debajo de cada mosaico',
		defaultVideoMonitoring: 'Monitoreo de Video Predeterminado',
		defaultAmbientMonitoring: 'Monitoreo Ambiental Predeterminado',
		customize: 'Personalizar',
		prescriptions: 'recetas',
		medicalInfoPrescriptions: 'recetas',
		configurationExists: '¡Esta configuración ya existe!',
		deviceSettings: 'Configuración de dispositivo',
		languages: 'Idiomas',
		aiModels: 'modelos de IA',
		selectMode: 'Seleccionar modo',
		mainDisplay: 'Pantalla principal',
		secondDisplay: 'Segunda pantalla',
		secondaryDisplay: 'Pantalla secundaria',
		approval: 'Aprobación',
		approvalNeeded: 'Para alternar con éxito esta función, necesita la aprobación del paciente.',
		changeSettingsRequestAccepted: 'El paciente {paciente} ha {aceptado o rechazado} su solicitud',
		updateRequested: 'Actualizar solicitado',
		updateNow: 'Actualizar ahora',
		softwareUpdate: 'Actualización de programa',
		markAllAsRead: 'Marcar todo como leido',
		selectDoctor: 'Seleccionar médico',
		tvMode: 'Modo TV',
		gamingMode: 'Modo de juego',
		cleanMode: 'Modo de limpieza',
		widgetMode: 'Modo widget',
		autoAnswer: 'Respuesta automática',
		appInstaller: 'Instalador de aplicaciones',
		audioLevel: 'Nivel de audio',
		portraitMode: 'Modo retrato',
		landscape: 'Paisaje',
		portrait: 'Retrato',
		portraitReverse: 'Retrato Invertido',
		deviceOrientation: 'Orientación de la Pantalla',
		connectPatientsHelloToWifi: "Conectar el Patient's {value} a Wifi",
		and: 'y',
		maxSymptomsNo: 'El número máximo de síntomas es 10',
		haveCompleted: 'Han completado',
		haveCanceled: 'han cancelado',
		yourRequestWith: 'su solicitud con',
		yourLiveCallWith: 'tu llamada en vivo con',
		doctorRequestResponse: 'Doctor tiene {value} su solicitud',
		goBackToDoctors: 'Puede volver atrás y solicitar una llamada con otro médico',
		deleteHealthSystemMember: '¿Está seguro de que desea eliminar este sistema de salud de este miembro?',
		dateValidation: 'Seleccione una fecha de inicio anterior a la fecha de finalización.',
		twentyHrsBetween: 'Por favor seleccione 23',
		notAvailableNow:
			'Desafortunadamente, el proveedor no está disponible en este momento. Por favor, seleccione uno de los horarios de disponibilidad a continuación:',
		notAvailableToday: 'Desafortunadamente, el proveedor no está disponible hoy. Por favor, seleccione una fecha diferente.',
		showForm: 'Mostrar formulario',
		priority: 'Prioridad',
		selectPriority: 'Seleccionar prioridad',
		changesSaved: 'Cambios guardados.',
		poolRoleRequired: 'Se requiere el rol de grupo.',
		requestSentToAllDoctors: 'Solicitud enviada a todos los médicos',
		firstNameMinLength: 'El nombre debe tener al menos 2 caracteres',
		lastNameMinLength: 'El apellido debe tener al menos 2 caracteres',
		pleaseWritePhoneNumber: 'Por favor escribe tu número de celular',
		pleaseSelectPrimarySpecialty: 'Por favor seleccione una especialidad primaria',
		pleaseWriteLicenseNumber: 'Por favor escriba un número de licencia',
		pleaseWriteNPINumber: 'Por favor escriba su número de NPI',
		npiMaxLength: 'El número NPI debe tener 10 caracteres o menos',
		atLeastOneState: 'Al menos un estado debe ser seleccionado',
		selectedItems: 'Artículos seleccionados',
		valueItemSelected: '{value} seleccionado',
		roles: 'Roles',
		activeDirectoryInvite: 'Invitación de Active Directory',
		doctor: 'Médico',
		superadmin: 'Superadministrador',
		superuser: 'Superusuario',
		noAdded: 'No has añadido {value}',
		addAnother: 'Añade otro {value}',
		cancelVisit: 'Cancelar visita',
		submitWrapUp: 'Enviar resumen',
		addNote: 'Añadir la nota',
		addDiagnose: 'Agregar diagnóstico',
		addProcedure: 'Agregar Procedimiento',
		addedNotes: 'Notas añadidas',
		title: 'Título',
		selectPharmacy: 'Seleccione Farmacia',
		pharmacy: 'Farmacia',
		noMoreHealthSystems: 'No hay otros Sistemas de Salud.',
		whereToSendPrescriptions: '¿Adónde le gustaría que le enviaran sus recetas?',
		findPharmacy: 'Encuentra Farmacia',
		miles: 'millas',
		homeDelivery: 'Entrega a domicilio',
		pickUp: 'Levantar',
		errorLoading: 'Error al cargar',
		searchPharmacies: 'Buscar Farmacias',
		startCall: 'Iniciar llamada',
		changePharmacy: 'Cambiar de Farmacia',
		enterDescription: 'Ingrese la descripción aquí',
		enterTopic: 'Introduce el tema aquí',
		editAppointment: 'Editar cita',
		discardChanges: '¿Descartar los cambios?',
		discard: 'Desechar',
		discardDescription: 'Al cerrar el modal, descartará cualquier dato que haya ingresado.',
		dosage: 'Dosis',
		frequency: 'Frecuencia',
		duration: 'Duración',
		dispense: 'Dispensar',
		refills: 'recargas',
		isRequired: 'se requiere',
		maxLengthIs: 'La longitud máxima es ',
		medicineSelection: 'Selección de medicamentos',
		dispensedAsWritten: 'Dispensado como está escrito si es médicamente necesario',
		noteToPharmacist: 'Nota para el farmacéutico',
		patientInstructions: 'Instrucciones del paciente',
		quantityToDispense: 'Cantidad a dispensar',
		timesAday: 'veces al día',
		capsule: 'cápsula',
		addedPrescriptions: 'Recetas añadidas',
		typeOfDosage: 'Tipo de dosificación',
		orUntilDiretedToStop: '(o) hasta que se le indique que se detenga',
		onlyAsNeeded: 'Solo según sea necesario',
		default: 'Defecto',
		numberOfDays: 'Número de días',
		appointmentCreated: 'Cita creada con éxito',
		appointmentUpdated: 'Cita actualizada con éxito',
		appointmentDetails: 'Los detalles de su cita son',
		change: 'Cambiar',
		confirm: 'Confirmar',
		successfulSignIn: 'Inicio de sesión con éxito',
		successfulSignInDescription: 'Su {value} se ha conectado con éxito a HELLO/HOLAcare.',
		errorSignIn: 'Error al iniciar sesión',
		errorSignInDescription: 'No se pudo conectar a su {value}, intente nuevamente.',
		invalidFileType: 'Tipo de archivo invalido.',
		hideForm: 'Ocultar formulario',
		addSuperUser: 'Añadir Superusuario',
		myWorkflow: 'Mi Flujo de Trabajo',
		directWorkflow: 'Flujo de trabajo directo',
		throughNurses: 'A través de las enfermeras',
		selectMyWorkflow: 'Seleccionar mi flujo de trabajo',
		nurses: 'enfermeras',
		clickHereAddNurse: 'Haga clic aquí para agregar una nueva enfermera',
		addNurse: 'Añadir enfermera',
		inviteNurses: 'Invitar a enfermeras',
		sectorIsRequired: 'El nombre del sector no puede estar vacío',
		timeZoneMustBeSet: 'La zona horaria debe ser establecida',
		sectorNameMinLength: 'El nombre del sector debe tener al menos 2 caracteres',
		writeSectorName: 'Escribir nombre del sector',
		writeDeviceSerialNumber: 'Escriba el número de serie del dispositivo',
		writeTvSerialNumber: 'Escribir número de serie de TV',
		healthSystemMinLength: 'El nombre del sistema de salud debe tener al menos 2 caracteres',
		nextStep: 'Próximo paso',
		minLengthIs: 'La longitud mínima es',
		areYouSureDeleteItem: '¿Está seguro de que desea eliminar este {value}?',
		addPrescription: 'Añadir prescripción',
		medicalInfo: 'Información médica',
		addMedicalInfo: 'Añadir valor}',
		diagnose: 'diagnosticar',
		note: 'Nota',
		prescription: 'prescripción',
		titleIsRequired: 'Se requiere título',
		appointmentSlotIsRequired: 'Por favor, seleccione un horario de cita',
		personIsRequired: 'Se requiere persona',
		meetingOptionIsRequired: 'Se requiere la opción de reunión',
		descriptionIsRequired: 'Se requiere descripción',
		descriptionMustHaveLetter: 'La descripción no puede tener solo espacios en blanco, números o símbolos. Debe contener letras.',
		rebootDevice: 'Reiniciar dispositivo',
		factoryReset: 'Restablecimiento de fábrica',
		rebootDeviceQuestion: '¿Por qué quieres reiniciar el dispositivo?',
		resetDeviceQuestion: '¿Por qué quieres restablecer el dispositivo?',
		rebootDeviceReason: 'Describe el motivo del reinicio...',
		resetDeviceReason: 'Describa el motivo para iniciar el restablecimiento de fábrica...',
		resetDeviceUndone:
			'Esta acción no se puede deshacer. ¿Está seguro de que desea iniciar un restablecimiento de fábrica de este dispositivo con',
		clearDataQuestion: '¿Por qué quieres borrar los datos del dispositivo?',
		clearDataReason: 'Describa el motivo de los datos claros',
		editDetails: 'Editar detalles',
		otherDevices: 'otros dispositivos',
		areYouSureDeletePrescription: '¿Está seguro de que desea eliminar esta receta?',
		inviteDoctor: 'invitar al doctor',
		inactiveConference: 'El paciente se ha ido',
		inactiveConferenceDesc: 'El paciente ya salió de la habitación virtual',
		joinCall: 'Unirse a la llamada',
		expandLatestHealthData: 'Mostrar los últimos datos de salud',
		collapseLatestHealthData: 'Ocultar datos de salud más recientes',
		minutesInWaitingRoom: 'minutos en la sala de espera',
		rejectedRequest: 'rechazó su solicitud',
		selectHelloDevice: 'Seleccionar Dispositivo {value}',
		otherMedicalInfo: 'Otra información médica',
		mobile: 'Móvil',
		laptop: 'portátil',
		youHaveLengthPatients: 'Tiene {longitud} {pacientes} en la sala de espera',
		bodyFat: 'Grasa corporal',
		muscleMass: 'Masa muscular',
		bodyWater: 'Agua corporal',
		symptoms: 'Síntomas',
		transferToDoctor: 'Transferir al médico',
		transferToDoctorWarning:
			'Transferir la llamada al Dr. {doctor} dará como resultado que finalice la llamada con {paciente}. ¿Estas seguro que deseas continuar?',
		aiPrivacyMode: 'Modo de privacidad AI',
		noMoreThanTwoAI: 'No puedes agregar más de dos modelos de IA',
		soundOn: 'Sonido encendido',
		soundOff: 'Sonido apagado',
		nightVisionOn: 'visión nocturna encendida',
		nightVisionOff: 'Visión nocturna apagada',
		reportAiDetection: 'Informar de la detección de IA',
		detectionReported: 'La detección de IA se informó con éxito',
		visitWith: 'Visita con',
		mainDetails: 'Detalles principales',
		lengthOfVisit: 'Duración de la visita',
		viewDetails: 'Ver detalles',
		newAppointmentRequest: 'Tienes una nueva solicitud de cita con',
		onDate: 'sobre',
		has: 'posee',
		yourAppointmentInvitation: 'su invitación de cita',
		youHaveAppointmentWith: 'Tienes una cita con',
		inMinutesOn: 'en {value} minutos después',
		hasAcceptedAppointment: 'ha aceptado su invitación de cita',
		hasRejectedAppointment: 'ha rechazado su invitación de cita',
		hasCanceledAppointment: 'ha cancelado su invitación a la cita',
		atDate: 'en',
		youHaveAppointmentRequest: 'Tienes una solicitud de cita con',
		updated: 'actualizado',
		rescheduled: 'reprogramado',
		expand: 'Expandir',
		reportAsFalse: 'Reportar como falso',
		callEmergency: 'Llamar a emergencias',
		callNurse: 'llamar a la enfermera',
		markFalseAlert: 'Marcar como falsa alerta',
		markAsSeen: 'Marcar como visto',
		resultsFor: 'Resultados para {value}',
		patientExists: '¡Este paciente ya existe!',
		dataAcquisition: 'Adquisición de datos',
		allRequests: 'todas las solicitudes',
		opened: 'Abierto',
		cancelAppointmentTitle: '¿Cancelar cita?',
		cancelAppointment: 'Cancelar cita',
		cancelAppointmentQuestion: '¿Está seguro de que desea cancelar esta cita?',
		alertHistory: 'Historial de alertas',
		healthData: 'datos de salud',
		roundingHealthData: 'datos de salud',
		healthDataAlerts: 'Alertas de datos de salud',
		artificialIntelligence: 'Inteligencia artificial',
		wasDetected: 'fue detectado en',
		timeline: 'línea de tiempo',
		youNeedAuthorizedNurses:
			'`Necesita autorizar al menos a 1 enfermera para cambiar el flujo de trabajo a "A través de enfermeras"',
		timeSlotExists: 'Ya tienes una cita en esa franja horaria.',
		hideEmergencyButtons: 'Ocultar botones de emergencia',
		showEmergencyButtons: 'Mostrar botones de emergencia',
		appointmentDetail: 'Detalles de la cita',
		invitee: 'invitado',
		selectOneOption: 'Selecciona una de las siguientes opciones',
		noCanceledAppointments: '¡No tienes ninguna solicitud cancelada!',
		editOrder: 'Editar orden',
		reorderSessions: 'Reordenar sesiones',
		rearrangeMonitoringSessions: 'Arrastre y suelte para reorganizar las sesiones de monitoreo',
		code: 'Código',
		alreadyAddedBreakTime: 'Ya añadiste ese tiempo de descanso',
		completeAppointmentTitle: '¿Completar cita?',
		completeAppointmentQuestion: '¿Está seguro de que desea completar esta cita?',
		markAsCompleted: 'Marcar como completado',
		contactNurses: 'Póngase en contacto con las enfermeras',
		contactNurse: 'Póngase en contacto con las enfermeras',
		contactNursesDescription:
			'Cuando esto esté habilitado, el usuario podrá contactar a las enfermeras eligiendo 3 opciones: Enviar un mensaje de grupo, Llamar a una enfermera de guardia o contactar a una enfermera específica.',
		falseAlert: 'falsa alerta',
		forwardToNurses: 'Reenviar a las enfermeras de primera línea',
		forwardToFrontlineNurses: 'Reenviar a las enfermeras de primera línea',
		forwardToNursesDescription:
			'Flujo de trabajo de comunicación para que las alertas sean enviadas por la enfermera virtual o eSitter a las enfermeras de cabecera.',
		sendGroupMessage: 'Enviar mensaje de grupo',
		callNursesOnDuty: 'Llame a las enfermeras de turno',
		specificNurse: 'Enfermera Específica',
		stopTalking: 'Click para dejar de hablar',
		checkBreakTime: 'Agregue tiempo de descanso dentro de las horas disponibles',
		teams: 'Equipos',
		sendMessage: 'Enviar mensaje',
		message: 'Mensaje',
		chat: 'Charlar',
		sort: 'Ordenar',
		viewLatestAlerts: 'Ver las últimas alertas',
		sharedAttachments: 'Adjuntos compartidos',
		links: 'linkear',
		sharedLinks: 'linkear compartidos',
		images: 'Imágenes',
		sharedImages: 'imágenes compartidas',
		videos: 'Vídeos',
		sharedVideos: 'Vídeos compartidos',
		noTeamMembers: 'Sin miembros del equipo',
		noTeamsYet: 'Aún no hay equipos',
		noCheckedInNurse: '¡No hay enfermeras registradas disponibles!',
		nursesUnavailable: 'No hay enfermeras disponibles. Vuelve a intentarlo más tarde.',
		receivedAlert: 'recibió una alerta',
		sentAlert: 'envió una alerta',
		addedComment: 'agregó un comentario',
		markedThe: 'marcado el',
		alertAcknowledged: 'alerta como reconocida',
		wasAddedAs: 'fue añadido como un ',
		hospitalCareTaker: 'cuidador en el hospital',
		alertDetails: 'Detalles de alerta',
		measured: 'Medido',
		forwardedToNurses: 'envió la alerta a las enfermeras',
		raiseAlert: 'Levantar Alerta',
		raiseAnAlertManually: 'Levantar una alerta manualmente',
		raiseAlertDescription:
			'A través de esta opción, la enfermera podrá generar una alerta manualmente para ese paciente. Esta alerta se enviará a las enfermeras de cabecera o de primera línea.',
		typeOfAlert: 'Tipo de Alerta',
		isAtRisk: 'está en riesgo debido a',
		bloodType: 'Grupo sanguíneo',
		latestVitalSigns: 'Últimos signos vitales',
		latestDiagnosis: 'Último diagnóstico',
		chronicDiseases: 'Enfermedades crónicas',
		allergies: 'alergias',
		goToAllScripts: 'Vaya a Allscripts EHR',
		alertAsFalse: 'alerta como falso',
		manualAlerts: 'Alertas manuales',
		forwardToMSNurses: 'Reenviar a MS Nurses',
		raisedAnAlert: 'lanzó una alerta',
		badURL: 'URL incorrecta. El formato de URL debe ser como https',
		symbolAndSpacesNotAllowed: 'No se permiten símbolos ni espacios',
		symbolsNotAllowed: '¡No se permiten símbolos!',
		call911: 'Llama al 911',
		call911Description: 'Cuando esto esté habilitado, los usuarios podrán llamar al 911 con un solo clic.',
		addComment: 'Agregar comentario',
		saveComment: 'Guardar comentario',
		forwardToNoraApp: 'Reenviar a la aplicación Nora',
		mainMedicalFamilyCenter: 'Centro Médico Familiar Principal',
		medicalFamilyCenter: 'Centro Médico Familiar',
		digitalClinic: 'Clínica Digital',
		checkIn: 'Registrarse',
		checkOut: 'Revisa',
		checkInPage: 'Chequear',
		noCheckInAllowed: 'No se permite el check in',
		userIsCheckedIn: 'Usuario con el correo electrónico',
		hangUp: 'Colgar',
		calling: 'Llamado ',
		emergencyCall: 'Llamada de emergencia',
		callEnded: 'Llamada terminada.',
		typeNameForPool: 'Escriba el nombre de este grupo',
		selectRoles: 'Seleccionar roles',
		selectRoleAppy: 'Seleccionar rol para aplicar el directorio activo',
		checkInPatient: 'Registrar a un paciente',
		selectPatient: 'Seleccione un paciente',
		patientHealthDetails: 'Síntomas y datos de salud adicionales',
		measureVitalSigns: 'Medir Signos Vitales del paciente',
		searchForPatients: 'Buscar pacientes',
		addNewPatient: 'Agregar nuevo paciente',
		addPatient: 'Añadir paciente',
		results: 'Resultados',
		showConsent: 'Consentimiento del paciente',
		idNumber: 'Número ID',
		selectThePatient: 'Seleccione el paciente',
		typeSymptoms: 'Escriba los síntomas y signos aquí',
		selectedSymptoms: 'Después de seleccionar los síntomas que aparecerán aquí',
		howLongSymptoms: '¿Por cuánto tiempo tiene los síntomas?',
		preExistingCondition: '¿Tiene alguna condición preexistente? En caso afirmativo, escríbalos a continuación',
		anyAllergies: '¿Tienes alguna alergia?',
		dragAndDropOrUpload: 'Haga clic aquí o arrastre y suelte para cargar un archivo',
		measurePatientVitalSigns: '`Por favor mida los signos vitales del paciente`',
		saveAndSelectPatient: 'Guardar y seleccionar paciente',
		addManually: 'Agregar manualmente',
		userExists: '¡Este usuario ya existe!',
		viewDevices: 'Ver dispositivos',
		assignDevicesList: 'Lista de Dispositivos Asignados',
		monitoringSettings: 'Configuración de monitoreo',
		editMonitoringSettings: 'Editar configuración de monitoreo',
		nightVision: 'Vision nocturna',
		nightVisionDescription: 'Cuando esto está habilitado, los usuarios podrán tener visión cuando la habitación esté oscura.',
		EHRDescription: 'Acceso a los datos del EHR del paciente.',
		microsoftTeams: 'Equipos de Microsoft',
		microsoftTeamsDescription: 'Flujo de trabajo de comunicación con la aplicación Microsoft Team del hospital',
		walkieTalkie: 'walkie-talkie',
		walkieTalkieDescription:
			'Transmisión de audio a todas las enfermeras de cabecera y enfermeras virtuales registradas en el piso específico.',
		addDigitalClinician: 'Añadir Clínico Digital',
		digitalClinicianOnList: 'Clínico Digital',
		maxSizeExceeded: 'Prueba a subir un archivo de menos de 2 MB',
		maxCompanySizeExceeded: 'Prueba a subir un archivo de menos de 600 KB',
		appointmentDateRequired: 'Se requiere una fecha de cita',
		timeSlotRequired: 'El intervalo de tiempo es obligatorio',
		editSafeRanges: 'Editar gama seguros',
		thresholdAlertDescription:
			'Ingrese valores mínimos y máximos para cada dato de salud según la condición del paciente. Si alguno de los pacientes',
		doctorPooling: 'grupo de doctores',
		doctorList: 'Lista de Doctores',
		addDoctor: 'Añadir médico',
		searchDoctor: 'Buscar médico',
		selectedDoctors: 'Doctores Seleccionados',
		sureToDeleteDoctor: '¿Está seguro de que desea eliminar al médico {value}?',
		deleteDoctor: 'Borrar Doctor',
		checkedIn: 'Registrado en ',
		checkedOut: 'sin registrar',
		placement: 'Colocación',
		seeDoctorInDC: 'Ver médico en clínica digital',
		patientIsInVisit: '¡El paciente ya está en visita!',
		doctorAvailableForCall: 'está disponible para una llamada ahora',
		healthDataSafeRanges: '`gama seguros de datos de salud`',
		medicalReport: 'Informe médico',
		doctorsStampSignature: 'Firma del sello del médico',
		doctorFullName: 'Nombre completo del médico',
		printReports: 'Imprimir informes',
		printReport: 'Imprimir reporte',
		capsulesDosage: '{value} cápsulas',
		oneCapsule: '{value} cápsula',
		specialty: 'Especialidad',
		refillsLowercase: 'recargas',
		cityMustHaveLetter: 'La ciudad debe contener letras',
		ok: 'ESTÁ BIEN',
		measuredIn: 'medido en',
		completeRegistration: 'Le recomendamos que complete su información personal y profesional en el siguiente formulario.',
		dragAndDropOr: 'Arrastrar y soltar o',
		click: 'haga clic aquí',
		uploadProfilePicture: 'para subir tu foto de perfil',
		addLicense: 'Agregar licencia',
		licenseNo: 'número de licencia',
		selectDate: 'Seleccione fecha',
		selectCountry: 'Seleccionar país',
		selectSpecialty: 'Seleccione Especialidad',
		saveAndContinue: 'Guardar Continuar',
		position: 'Posición',
		addPosition: 'Agregar Posición',
		company: 'Compañía',
		educationHistory: 'Historia de la Educación',
		school: 'Escuela',
		degree: 'Grado',
		startYear: 'Año de inicio',
		endYear: 'Año final',
		employmentHistory: 'Historial de empleo',
		addEducation: 'Agregar educación',
		licenses: 'licencias',
		reset: 'Reiniciar',
		companyNameRequired: 'El nombre de la empresa es obligatorio',
		doctorsStamp: 'Sello de médicos',
		doctorsSignature: 'Firma del Doctor',
		companyWebsiteRequired: 'El sitio web de la empresa es obligatorio',
		companyAddressRequired: 'La dirección de la empresa es obligatoria',
		somethingWrongWithConference: '¡Algo salió mal al crear la conferencia!',
		patientIsOnWaitingRoom: '¡El paciente está en la sala de espera ahora!',
		deviceOfflineNow: 'El dispositivo está fuera de línea en este momento. ¡Por favor inténtelo de nuevo!',
		personalNumber: 'Número personal',
		pleaseWriteIdNumber: 'Por favor escriba su número de identificación',
		idNumberLength: 'El número de identificación debe constar de 10 caracteres y no debe comenzar con cero.',
		patientHealthCardNumberLength:
			'El número de tarjeta de salud del paciente debe constar de 10 a 20 caracteres y no debe comenzar con cero.',
		diabetes: 'Diabetes',
		oxygen: 'Oxígeno',
		distanceWalkingRunning: 'Caminar + Correr Distancia',
		temperature: 'Temperatura',
		pi: 'índice de perfusión',
		numberOfTimesFallen: 'Número de veces caído',
		pleaseUploadProfilePicture: 'Sube una foto de perfil',
		beforeContinue: 'Antes de continuar',
		currentlyWorkingHere: 'Actualmente trabajando aquí',
		currentlyStudyingHere: 'Actualmente estudiando aquí',
		high: 'Alto ',
		low: 'Bajo',
		criticalHigh: 'Alto crítico',
		criticalLow: 'Bajo crítico',
		french: 'Francés',
		german: 'Alemán',
		dutch: 'Holandés',
		turkish: 'Turco',
		spanish: 'Español',
		englishUnitedStates: 'Inglés Estados Unidos)',
		serbian: 'Serbio',
		graduateSchool: 'Escuela de Graduados',
		nursePractitioner: 'Practicante de enfermería',
		emergencyMedicinePhysician: 'Médico de Urgencias',
		hospitalist: 'hospitalista',
		internist: 'internista',
		pediatrician: 'Pediatra',
		psychiatrist: 'Psiquiatra',
		childPsychologist: 'Psicóloga Infantil',
		counselor: 'Consejero',
		psychologist: 'Psicólogo',
		psychotherapist: 'psicoterapeuta',
		other: 'Otro',
		newVersionAvailable: 'La nueva versión de la aplicación está disponible',
		checkInOtherPatient:
			'Si continúa registrando a otro paciente, se perderán todos los cambios actuales que haya realizado. ¿Está seguro de que desea descartar los cambios y comenzar de nuevo con el proceso de registro?',
		discardAndContinue: 'Descartar cambios y continuar',
		currentSignature: 'Firma actual',
		cancelCall: 'Cancelar llamada',
		deviceOfflineTryLater: 'Este dispositivo está fuera de línea. Vuelve a intentarlo más tarde.',
		deviceOnCallTryLater: 'Este dispositivo está actualmente en una llamada. Vuelve a intentarlo más tarde.',
		deviceOfflineTryLaterExtended: '{value1} está desconectado. Vuelve a intentarlo más tarde.',
		deviceOnCallTryLaterExtended: '{value1} está actualmente en una llamada. Vuelve a intentarlo más tarde.',
		cannotConnect: 'No se puede conectar. Por favor inténtelo de nuevo.',
		callDeclined: 'La llamada ha sido rechazada',
		callEndedTryAgain: 'La llamada ha terminado. Inténtalo de nuevo',
		failedReconnect: 'No se pudo volver a conectar a la red. Inténtalo de nuevo.',
		idleCallEnded: 'Has estado inactivo durante demasiado tiempo. La llamada finalizó.',
		riskOfFall: 'Riesgo de caída',
		patientNeedsHelp: 'El paciente necesita ayuda',
		patientRequiresHelp: 'Paciente requiere ayuda',
		otherActivity: 'otra actividad',
		searchPatientByName: 'Por favor, busque al paciente por nombre y apellido para ver la información de sus visitas',
		switchToHelloSource: 'Cambiar a HOLA Fuente',
		powerOnTV: 'Encender TV',
		powerOffTV: 'Apagar TV',
		time: 'Hora',
		macAddress: 'Dirección MAC',
		macUpperAddress: 'Dirección MAC',
		appVersion: 'Version de aplicacion',
		androidVersion: 'Versión de Android',
		osVersion: 'Versión del OS',
		ipAddress: 'Dirección IP',
		stethoscopeAudioRecordings: 'Grabaciones de audio del estetoscopio',
		selectPatientAndContinue: 'Seleccione uno de los pacientes para continuar con otros procedimientos',
		unit: 'Unidad',
		pleaseWriteHeight: 'Por favor escribe tu altura',
		pleaseWriteWeight: 'Por favor escribe tu peso',
		pleaseWriteTotalCholesterol: 'Por favor escriba su colesterol total',
		pleaseWriteHdlCholesterol: 'Por favor escriba su colesterol HDL',
		pleaseWriteWaistCircumference: 'Por favor escribe la circunferencia de tu cintura',
		centimeters: 'centimetros',
		kilograms: 'kilogramos',
		pleaseSelectCountry: 'Por favor seleccione un país',
		pleaseWriteCity: 'Por favor escribe una ciudad',
		pleaseWriteZipCode: 'Por favor escriba un código postal',
		pleaseWriteAddress: 'Por favor escriba una dirección',
		pleaseWriteLicense: 'Por favor escribe una licencia',
		pleaseSelectDegree: 'Seleccione un grado',
		pleaseWriteStartDate: 'Escriba la fecha de inicio',
		startDateCannotBeHigher: 'La fecha de inicio no puede ser posterior a hoy',
		startDateCannotBeThatLower: 'La fecha de inicio no puede ser inferior a 120 años a partir de ahora',
		endDateCannotBeSameAsStartDate: 'La fecha de finalización debe ser al menos 1 año posterior a la fecha de inicio.',
		pleaseWriteEndDate: 'Escriba la fecha de finalización',
		pleaseWriteCompany: 'Por favor escriba el nombre de la empresa',
		pleaseWritePosition: 'Por favor escriba posición',
		clear: 'Claro',
		noDiagnosesFound: 'No se encontraron diagnósticos',
		noPrescriptionsFound: 'No se encontraron recetas',
		noNotesFound: 'No se encontraron notas',
		noProcedureFound: 'No se encontró ningún procedimiento',
		setupAvailability: 'Disponibilidad de configuración',
		noVisitsFound: 'No se encontraron visitas',
		connectionType: 'Tipo de conección',
		physicalExercises: 'Ejercicios fisicos',
		physicalTherapy: 'Terapia física',
		medicalInfoPhysicalTherapy: 'Terapia física',
		physicalTherapist: 'Fisioterapeuta',
		startingDate: 'Fecha de inicio',
		targetedDays: 'Días objetivo',
		pTPrescriptionReport: 'Informe de prescripción PT',
		dataEditedSuccessfully: 'Los datos se han editado con éxito',
		exercise: 'Ejercicio',
		exerciseDate: 'Fecha de ejercicio',
		editExercise: 'Editar ejercicio',
		noPhysicalExercisesFound: 'No se encontraron terapias físicas',
		treeView: 'Vista de árbol',
		listView: 'Vista de la lista',
		currentlyLoggedInAs: 'Estás conectado actualmente como',
		logOutHere: 'Cerrar sesión aquí',
		pleaseLogOutFromThisAccount: 'Cierra la sesión de esta cuenta y vuelve a tu correo electrónico para aceptar esta invitación.',
		thisDeviceIsOnACall: 'Este dispositivo está en una llamada.',
		onePatient: 'Paciente',
		multiplePatients: 'pacientes',
		noOptions: 'Sin opciones',
		anotherProcedure: 'Procedimiento',
		addExistingPatient: 'Agregar paciente existente',
		addOrUpdate: 'Agrega o actualiza tu',
		locationAndAddress: 'Dirección del sitio',
		signatureAndStamp: 'Sello de firma',
		employment: 'Empleo',
		education: 'Educación',
		signatureDescription: 'Cuando guardes los cambios, todo lo que escribas en el lienzo se convertirá en tu firma.',
		stampDescription: 'Se recomienda que el sello sea transparente y en formato PNG.',
		visceralFatGrade: 'Grado de grasa visceral',
		daysNo: 'dias',
		emailExists: 'El correo electrónico existe',
		gridView: 'Vista en cuadrícula',
		singleView: 'Vista única',
		unknown: 'Desconocido',
		measuredAt: 'medido en',
		patientName: 'Nombre del paciente',
		patientMRN: 'MRN del paciente',
		of: 'de',
		firstNameNonNumber: 'El primer nombre solo debe contener caracteres.',
		lastNameNonNumber: 'El apellido solo debe contener caracteres.',
		parentNameNonNumber: 'El nombre principal debe contener solo caracteres.',
		cityNameNonNumber: 'El nombre de la ciudad debe contener solo caracteres.',
		dateOfBirthNotValid: 'La fecha de nacimiento no es válida',
		minHeightIs: 'La altura mínima es',
		maxHeightIs: 'La altura máxima es',
		minWeightIs: 'El peso mínimo es',
		maxWeightIs: 'El peso máximo es',
		minTotalCholesterolIs: 'El colesterol total mínimo es',
		maxTotalCholesterolIs: 'El colesterol total máximo es ',
		minHdlCholesterolIs: 'El colesterol HDL mínimo es ',
		maxHdlCholesterolIs: 'El colesterol HDL máximo es ',
		minWaistCircumferenceIs: 'La circunferencia mínima de la cintura es ',
		maxWaistCircumferenceIs: 'La circunferencia máxima de la cintura es ',
		dateFormat: 'Formato de fecha',
		timeFormat: 'Formato de tiempo',
		companyRegion: 'Región de la empresa',
		companyOwner: 'El dueño de la compañía',
		invite: 'Invitar',
		defaultMeasurementUnits: 'Unidades de medida por defecto',
		userCanChangeMeasurement:
			'Las unidades seleccionadas serán las predeterminadas para cada usuario que esté registrado en su empresa, pero cada usuario podrá cambiarlo a su preferencia (solo para ellos).',
		walkingRunningDistanceRequired: 'Se requiere distancia caminando + corriendo',
		weightRequired: 'Se requiere peso',
		bodyTemperatureRequired: 'Se requiere temperatura',
		heightRequired: 'Se requiere altura',
		bloodGlucoseRequired: 'Se requiere glucosa en sangre',
		dateFormatRequired: 'Se requiere formato de fecha',
		timeFormatRequired: 'Se requiere formato de hora',
		cameraNameRequired: 'Se requiere el nombre de la cámara',
		enterCameraName: 'Ingrese el nombre de la cámara aquí',
		uniqueEmail: 'Los emails deben ser únicos',
		companies: 'Compañías',
		verificationEmailFollowInstructions: 'Ha recibido un correo electrónico de verificación, siga las instrucciones.',
		success: 'Éxito',
		requiredCompanyName: 'Se requiere el nombre de la empresa',
		requiredWebsiteName: 'Se requiere la URL del sitio web',
		milesUnit: 'mi (Milla)',
		kmUnit: 'km (Kilómetro)',
		lbsUnit: 'libras (libras)',
		kgUnit: 'kg (Kilogramo)',
		fahrenheitUnit: '°F (Fahrenheit)',
		celsiusUnit: '°C (Celsius)',
		ftUnit: 'pies (pies)',
		cmUnit: 'cm (centímetros)',
		mgUnit: 'mg/dL (miligramos por decilitro)',
		mmolUnit: 'mmol/L (milimoles por litro)',
		hourFormat12: '12 horas',
		hourFormat24: '24 horas',
		continueAs: 'Continuar como',
		rememberAsDefaultChoice: 'Recuerda esto como mi elección predeterminada',
		myUserRoles: 'Mis roles de usuario',
		switch: 'Conmutador',
		addPatientRole: 'Agregar rol de paciente',
		addDoctorRole: 'Agregar rol de médico',
		primarySpecialty: 'Especialidad Primaria',
		selectStates: 'Seleccionar estado(s)',
		statesLicensed: 'Estados con licencia en',
		addARole: 'Añadir un rol',
		resetTwoFa: 'Restablecer autenticación de dos factores',
		selectAuth: 'Seleccione la autenticación de dos factores',
		pleaseSelectRole: 'Seleccione el rol que desea agregar a este miembro',
		switchRole: 'Cambiar rol',
		providerOrPatient: 'Cambiar a una cuenta de proveedor o paciente',
		admin: 'Administración',
		selectRole: 'Selecciona un rol',
		digitalClinician: 'Clínico Digital',
		digitalclinician: 'Clínico Digital',
		areYouSureDeleteRole: '¿Está seguro de que desea eliminar este rol de este miembro?',
		deleteRole: '¿Borrar rol?',
		memberRole: 'role',
		hrs: 'horas',
		physicalTherapyExercises: 'Ejercicios de fisioterapia',
		addNewExercise: 'Añadir un nuevo ejercicio',
		selectTypeOfExercise: 'Seleccionar tipo de ejercicio',
		exerciseSettings: 'Ajustes de ejercicio',
		addExerciseSettings: 'Agregar configuración de ejercicio',
		noAddedExercise: '  ¡no tiene ejercicios añadidos!',
		selectCategory: 'Seleccionar categoría',
		regulateExerciseIntensity: 'Regular la intensidad de los ejercicios',
		selectDatesForExercise: 'Seleccionar fechas para hacer ejercicio',
		containOnlyNumbers: 'Debería contener sólo números',
		rangeExceeded: 'El número debe estar entre {value1} y {value2}',
		downloadReport: 'Descargar Informe',
		downloadPatientReport: 'Descargar informe del paciente',
		participantProfile: 'Perfil del participante',
		bridge: 'Puente',
		togglePatientsTv: 'Alternar el apagado del paciente de la TV',
		toggleGridView: 'Alternar vista de cuadrícula',
		toggleLayoutView: 'Alternar vista de diseño',
		invitePeople: 'Invitar a la gente',
		moreOptions: 'Mas opciones',
		phoneNumberExists: 'El número de teléfono ya existe.',
		pleaseEnterValidNumber: 'Por favor ingrese un número de teléfono válido.',
		directLink: 'Enlace directo',
		sendLinkToPeople: 'Envía este enlace a las personas a las que quieras invitar',
		addEmail: 'Agregar correo electrónico',
		inviteViaEmail: 'Invitar por correo electrónico',
		pleaseEnterToAddEmail: 'Presiona enter para agregar correo electrónico.',
		inviteViaSms: 'Invitar por SMS',
		inviteSms: 'Invitar por SMS',
		joinViaPhone: 'Unirse por teléfono',
		accessCode: 'Agregar código de acceso',
		pressEnterToAddPhoneNumber: 'Presiona enter para agregar el número de teléfono.',
		sendInvite: 'Enviar invitación',
		addOneOrMorePplEmailSmsLink: 'Agregue una o más personas por correo electrónico, SMS, enlace',
		pleaseEnterValidEmail: 'Por favor, ingrese un correo electrónico válido.',
		emailAlreadyExists: 'Ya existe Este email.',
		conferenceLoading: 'La conferencia se está cargando',
		cameraIsOff: 'La cámara está apagada',
		joinNow: 'Únete ahora',
		youAreLoggedInAs: 'Has iniciado sesión como',
		youAreAboutToParticipate: 'Vas a participar en una cita de telemedicina.',
		pleaseIntroduceYourself: 'Por favor presentate',
		yourName: 'Tu nombre',
		askToJoin: 'Pide unirte',
		conferenceNotActive: '¡La conferencia no está activa!',
		joiningConference: 'Unirse a la conferencia',
		firstNameRequired: 'Se requiere el primer nombre',
		lastNameRequired: 'El apellido es obligatorio',
		seeingOrHearingPatient:
			'No estás {value5} al {value2} porque se ha activado el botón de privacidad física en {value}. Este botón solo se puede desactivar manualmente. Por favor, contacta a tu administrador. {value4}',
		alsoTvIsMuted: 'Además, el televisor está silenciado o APAGADO. Asegúrate de que {value} pueda oírte.',
		tvIsMuted: 'El televisor está silenciado o APAGADO. Asegúrate de que {value} pueda oírte.',
		thePatient: 'paciente',
		theBaby: 'bebé',
		connectingToHello: 'Conectando a {value}',
		loadingCamera: 'Cargando cámara',
		cameraAndMicBlocked: 'Tu Cámara y Micrófono están bloqueados',
		micBlocked: 'Tu Micrófono está bloqueado',
		cameraBlocked: 'Tu cámara está bloqueada',
		clickMicOrCamera: 'Haga clic en el icono {value} bloqueado en la parte superior derecha de su pantalla',
		camera: 'Cámara',
		microphone: 'Micrófono',
		maximizeFeed: 'Entrar en modo pantalla completa',
		minimizeFeed: 'Salir del modo de pantalla completa',
		allowAccessRefreshPage: 'Permitir el acceso y actualizar la página',
		youHaveLeftMeeting: 'Has salido de la reunión',
		meetingEndedFamilyMember:
			'La sesión ha sido finalizada por el hospital. Los botones de privacidad están activados en el Dispositivo {value}. Puedes ver al bebé cuando los botones de privacidad estén desactivados.',
		babyOutsideVisitingHoursMessage: 'No puedes ver a tu bebé fuera del horario de visitas.',
		unSupportedTV: 'Televisión no compatible',
		commandIsNotSupportedPatientTV: 'Este comando no se admite en el televisor de este paciente.',
		areUsureUwantToLeaveSession: '`¿Estás seguro de que quieres salir de la sesión? ',
		copy: 'Copiar',
		copied: 'Copiado',
		failedToCopyLink: '¡No se pudo copiar el enlace!',
		copyCorrelationId: 'Copiar id de correlación',
		thereIsActiveConference: 'Ya hay una conferencia activa',
		callTransferredToAnotherClient: 'Llamada transferida a otro cliente',
		callAnsweredElsewhere: 'Llamada respondida en otro dispositivo.',
		rejoin: 'Reincorporarse a',
		submitPhysicalTherapy: 'Presentar fisioterapia',
		universalSafetyInterventions: 'Intervenciones Universales de Seguridad',
		fallRisk: 'Riesgo de caida',
		dailyWeight: 'Peso Diario',
		fallHarmRisk: 'Caer con Riesgo de Daño',
		silentHeartAttack: 'Ataque cardíaco silencioso',
		addSuperAdmin: 'Agregar superadministrador',
		privacyOn: 'Privacidad activada',
		privacyOff: 'Privacidad desactivada',
		settings: 'Configuración',
		safeRanges: 'rangos seguros',
		pressureInjury: 'Lesiones por presión',
		getOutOfBed: 'Saliendo de la cama',
		screenMode: 'Modo de pantalla',
		speakerAudioVolume: 'Volumen de audio del altavoz',
		displayOrientationExtended: 'Orientación de visualización extendida',
		roundingDefaultCamera: 'Redondeo de cámara predeterminada',
		monitoringDefaultCamera: 'Monitoreo de cámara predeterminada',
		faceDown: 'Boca abajo',
		rails: 'Rieles',
		left: 'Izquierda',
		right: 'Derecha',
		top: 'Arriba',
		bottom: 'Abajo',
		skeletonMode: 'Modo esqueleto',
		physicalTherapies: 'Terapias Físicas',
		addExercise: 'Añadir ejercicio',
		seconds: 'segundos',
		hour: 'horas',
		noRooms: 'No hay habitaciones en este momento.',
		callType: 'Tipo de llamada',
		details: 'Detalles',
		exportCSV: 'Exportar registros CSV',
		exportToCSV: 'Exportar a CSV',
		cannotBeNDaysDifference: 'La diferencia de fecha no puede ser superior a {value} días',
		reportSentSuccessfully: 'El informe ha sido enviado con éxito.',
		toAccessCheckEmail: 'Para acceder y descargar el informe, continúe en su dirección de correo electrónico.',
		reportIsUnderway: 'El informe está en proceso.',
		reportUnderwayDescription: 'Su informe está en proceso y puede tardar un tiempo dependiendo del tamaño de los datos.',
		thankYouForPatience: '¡Gracias por su paciencia!',
		listOfRooms: 'Lista de Habitaciones',
		exportCSVInBackground: 'Exportar CSV en segundo plano',
		talkToPatient: 'Hable con el paciente',
		talkToNurse: 'Habla con la enfermera',
		patientView: 'Vista del paciente',
		ongoing: 'En marcha',
		past: 'Pasado',
		screenUsed: 'Pantalla utilizada',
		callDetails: 'Detalles de la llamada',
		endCallForRoom: 'Fin de la llamada para la habitación',
		retry: 'Reintentar',
		callDurationSoFar: 'Duración de la llamada (hasta ahora)',
		noOngoingConferences: 'No hay conferencias en curso en este momento.',
		refresh: 'actualizar',
		areYouSureToTerminate: '¿Estás seguro de terminar?',
		terminateRequestTimeOut: 'Terminar el tiempo de espera de la solicitud',
		retryRequest: '¿Quieres volver a intentarlo? Esto finalizará la llamada entre el médico y el paciente.',
		sessionTerminatedSuccessfully: 'La sesión se ha terminado con éxito.',
		terminateRoomSession: 'Terminar sesión de sala',
		roomSessionTerminated: 'La sesión de la sala se ha terminado correctamente.',
		sessionAlreadyEnded: 'Esta sesión ya ha finalizado.',
		terminationInitiated: 'Otro administrador inicia la finalización de esta sesión.',
		noPermissionToTerminate: 'No tienes permiso para terminar esta sesión.',
		assignedDevices: 'Dispositivos asignados',
		assignedDevice: 'Dispositivo asignado',
		availabilitySetup: ', Datos',
		data: 'Disponibilidad',
		participants: 'Participantes',
		nursesCurrentlyDuty: 'Las enfermeras están actualmente de servicio',
		noCategory: 'Ninguna Categoria',
		required: 'Requerido',
		fillRequiredFields: 'Rellene los campos obligatorios',
		ai: 'IA',
		noMoreThanOneAiType: 'No puedes habilitar más de una configuración de IA al mismo tiempo.',
		maxBirthdayLimit: 'Debes tener como máximo 120 años',
		year: 'Año',
		video: 'Video',
		file: 'Archivo',
		invalidLink: 'Enlace no válido. ¡Por favor inténtelo de nuevo!',
		measureCompleted: 'Medida completa',
		couldNotGetPatientData: 'No se pudieron obtener los datos del paciente',
		tryAgainWithoutMoving: 'Inténtalo de nuevo sin mover',
		pressurizationSlow: '¡La presurización es lenta! ¡Por favor revise el brazalete!',
		wait5minutes: 'Espera 5 minutos e inténtalo de nuevo',
		doToRetakeMeasure: '¡Puedes {value} para volver a tomar la medición!',
		toRemeasureTemperature: 'Para volver a tomar la medida, presione el botón de medida del termómetro.',
		doToReconnect: '¡Puedes {value} para volver a conectarte!',
		measureHasFinished: '{value} ha terminado de medir',
		deviceIsConnected: '{value} - El dispositivo está conectado',
		deviceIsConnecting: '{value} - El dispositivo se está conectando',
		deviceDisconnected: '{value} - El dispositivo se ha desconectado',
		pleaseWaitConnection: 'Espere hasta que se establezca la conexión.',
		pleaseStandOnScale: 'Párese en la báscula para comenzar a medir.',
		valueIsDisconnected: '{value} está desconectado',
		valueIsConnecting: '{value} se está conectando',
		valueIsConnected: '{value} está conectado',
		deviceCouldNotConnect: '{value}: no se pudo conectar al dispositivo. Comprobar bluetooth',
		deviceMeasuring: '{value} - El dispositivo está midiendo',
		deviceConnectFail: '{value} - Fallo en la conexión del dispositivo',
		deviceMeasureStopped: '{value} - Medida detenida',
		deviceMeasureCompleted: '{value} - Medida completada',
		deviceReconnecting: '{value} - El dispositivo se está volviendo a conectar',
		problemWithReader: 'Problema con el lector. ¡Realice una nueva prueba con una nueva tira reactiva!',
		testStripRemoved: 'Se retiró la tira reactiva durante la medición.',
		problemStrip:
			'Problema con la tira reactiva (tira mojada, retirada demasiado rápido, demasiada sangre aplicada a la tira). ¡Realice una nueva prueba con una nueva tira reactiva!',
		lowRoomTemperature: 'La temperatura ambiente es demasiado baja. La temperatura óptima es 10°C - 40°C / 50°F - 104°F',
		highRoomTemperature: 'La temperatura ambiente es demasiado alta. La temperatura óptima es 10°C - 40°C / 50°F - 104°F',
		communicationError:
			'{value} - Error de comunicación. Mantenga presionado el botón M (Memoria) durante 7 segundos para restaurar el dispositivo',
		newTestNewStrip: '¡Realice una nueva prueba con una nueva tira reactiva!',
		getBlood: '¡Obtén sangre!',
		measureStarted: 'Medida comenzada',
		stripIn: 'Desnúdate',
		stripOut: 'Quítate',
		totalCholesterol: 'Colesterol total',
		hdlCholesterol: 'Colesterol HDL',
		waistCircumference: 'Circunferencia de cintura',
		healthInformation: 'Información de salud',
		currentEverydaySmoker: 'Fumador actual todos los días',
		currentSomedaySmoker: 'Fumador actual algún día',
		formerSmoker: 'Ex fumador',
		neverSmoker: 'Nunca fumo',
		sufferFromHypertension: '¿Sufres de hipertensión?',
		medicalStatus: '¿Tiene un estado de medicación?',
		areYouSmoker: '¿Eres fumador?',
		haveDiabeticStatus: '¿Tienes un estado diabético?',
		patientIsBeingRegistered: 'El paciente está siendo registrado',
		patientAlreadyExists: 'El paciente ya existe',
		pleaseSelectOneOfTheOptionsBelow: 'Seleccione una de las opciones a continuación',
		diabetesMellitusI: 'Diabetes Mellitus Tipo I',
		diabetesMellitusII: 'Diabetes Mellitus Tipo II',
		submit: 'Someter',
		dateTimeFormats: 'Formatos de fecha y hora',
		seeingAndHearing: 'viendo y escuchando',
		seeing: 'viendo',
		hearing: 'audiencia',
		patientRequestedHelp: 'Paciente pidió ayuda',
		patientSitting: 'El paciente fue detectado inactivo en una posición de silla.',
		patientStanding: 'El paciente fue detectado inactivo en posición de pie.',
		patientLyingSide: 'El paciente fue detectado inactivo en posición lateral.',
		patientLyingRightSide: 'Se detectó al paciente inactivo en posición lateral derecha.',
		patientLyingLeftSide: 'Se detectó al paciente inactivo en posición lateral izquierda.',
		patientLyingFront: 'Se detectó al paciente inactivo en posición frontal acostada.',
		patientFaceDown: 'El paciente fue detectado inactivo con la posición boca abajo.',
		patientInactive: 'El paciente fue detectado inactivo.',
		closed: 'cerrado/a',
		or: 'u',
		history: 'Historia',
		availableDoctors: 'Doctores disponibles',
		alreadySelected: 'Ya seleccionado',
		alreadyInPool: 'Ya en grupo',
		cantAddMoreDoctors: '¡No se pueden agregar más de 5 médicos a la vez!',
		cam: 'cámara',
		mic: 'micrófono',
		camAndMic: 'cámara y micrófono',
		hellocareMode: 'Modo HELLO/HOLACARE',
		aiWidgetMode: 'Modo de widget de IA',
		telemedicineMode: 'Modo de telemedicina',
		hospitalMode: 'Modo Hospital',
		signOut: 'Salir',
		primaryHealthcare: 'Atención Primaria de Salud',
		healthSystemType: 'Tipo de sistema de salud',
		selectHealthSystemType: 'Seleccionar tipo de sistema de salud',
		patientGettingOutOfBed: 'El paciente se está levantando de la cama',
		clickToLoginAnotherAcc: 'Haga clic aquí para iniciar sesión con otra cuenta.',
		notAssignedHs: 'Actualmente no estás afiliado a ningún sistema de salud',
		memberNoHs: `Este miembro actualmente no está asignado a ningún sistema de salud`,
		digitalClinicMode: 'Modo Clínica Digital',
		impulsiveConfused: 'Impulsivo/Confundido',
		pullingO2: 'tirando de O2',
		nonRedirectable: 'No redireccionable',
		precautions: 'Precauciones',
		precautionsDescription:
			'Habilitar esta opción permite a las enfermeras establecer precauciones, como el riesgo de caídas para el paciente, de modo que puedan visualizar fácilmente y clasificar a los pacientes según la lista de precauciones.',
		terminateSession: '¿Finalizar sesión?',
		terminateRoomCall: '¿Terminar sesión en la habitación?',
		selectRoom: 'Seleccionar habitación',
		selectLocationReassin: 'Seleccione una ubicación del sistema de salud de arriba para administrar los hospitales en él.',
		selectLocation: 'Seleccionar ubicación',
		selectHsToManage: 'Elige el sistema de salud que quieres gestionar.',
		reAssignDevice: 'Reasignar dispositivo',
		regionSaved: 'Región guardada',
		somethingWrong: '¡Algo salió mal!',
		selectLocationReassign: 'Elija una ubicación del sistema de salud anterior para administrar los hospitales en él.',
		hi: 'Hola',
		selectOptionsBelow: 'Seleccione las opciones a continuación',
		willBeReceivingCalls: 'Estaré recibiendo llamadas de pacientes',
		willNotBeReceivingCalls: 'No estaré recibiendo llamadas de pacientes',
		selectHealthSystems: 'Seleccione Sistemas de Salud',
		selectReceivingCalls: 'Selecciona si recibirás llamadas de pacientes.',
		selectHealthSystemsReceiveCalls: 'Seleccione los sistemas de salud de los que recibirá llamadas.',
		selectLevelReceiveCalls: 'Seleccione el nivel desde el que recibirá llamadas.',
		neckExercises: 'Ejercicios de cuello',
		legExercises: 'ejercicios de piernas',
		shoulderExercises: 'Ejercicios de hombro',
		hipExercises: 'Ejercicios de cadera',
		extendedLegExercise: 'Ejercicio de piernas extendidas',
		singleLegGluteKicksExercise: 'Ejercicio de patadas de glúteos de una sola pierna',
		standingMarchExercise: 'Ejercicio de marcha de pie',
		hamstringStretchExercise: 'Ejercicio de estiramiento de isquiotibiales',
		standingQuadStretchExercise: 'Ejercicio de estiramiento cuádruple de pie',
		neckExercise: 'Ejercicio de cuello',
		neckStretchExercise: 'Ejercicio de estiramiento de cuello',
		liftingHeadSidewaysExercise: 'Ejercicio de elevación de la cabeza hacia los lados',
		shoulderExercise: 'Ejercicio de hombro',
		armRaisesExercise: 'Ejercicio de levantamiento de brazos',
		rotatorCuffExercise: 'Ejercicio del manguito de los rotadores',
		shoulderExtensionExercise: 'Ejercicio de extensión de hombro',
		seatedShoulderRaiseExercise: 'Ejercicio de elevación de hombros sentado',
		hipExercise: 'ejercicio de la cadera',
		hipMarchingExercise: 'Ejercicio de marcha de la cadera',
		sideLyingLegLiftExercise: 'Ejercicio de elevación de piernas acostado de lado',
		fireHydrantExercise: 'Ejercicio de boca de incendios',
		maximumRangeOfMotion: 'Máximo rango de movimiento',
		leftSideNumberOfRepetition: 'Número de repetición del lado izquierdo',
		rightSideNumberOfRepetition: 'Lado derecho número de repetición',
		numberOfDaysToAchieveTheTarget: 'Número de días para lograr la meta',
		degreeIncrementInTargetROM: 'Incremento de grado en la ROM objetivo',
		holdingTimeInSeconds: 'Tiempo de espera en segundos',
		numberOfRepetition: 'Número de repetición',
		numberOfRepetitionForRightNeck: 'Lado derecho número de repetición',
		addPhysicalTherapy: 'Añadir una terapia física',
		remotePairedWithDevice: 'Hay un control remoto vinculado con este dispositivo. ¿Quieres desemparejarlo?',
		remoteNotPairedWithDevice:
			'Este dispositivo no tiene ningún control remoto emparejado. Si desea vincular un control remoto, mantenga presionado el botón Aceptar y Atrás hasta que vea una luz roja intermitente en el control remoto y luego haga clic en Vincular en la web.',
		remotePairedSuccessfully: 'Remoto emparejado con éxito.',
		remoteUnpairedSuccessfully: 'Remoto desemparejado exitosamente.',
		pairingTimeout: 'Tiempo de espera de emparejamiento. Inténtalo de nuevo.',
		pair: 'Par',
		unpair: 'Desemparejar',
		dismiss: 'Descartar',
		tryingToPairRemote: 'Intentando vincular el control remoto...',
		tryingToUnpairRemote: 'Intentando desemparejar el control remoto...',
		remoteControl: 'Remoto',
		patientStandingWalking: 'Pérdida de equilibrio detectada',
		personIsDetectedWalking: 'Pérdida de equilibrio detectada. Por favor revise al paciente!',
		analyticPositionDescription: 'La paciente fue detectada {value} para {value1}',
		lyingFront: 'acostada',
		lyingSide: 'lado acostado',
		lyingDown: 'acostada',
		sitting: 'sesión',
		walking: 'caminando',
		inviteUsers: 'Invitar a los usuarios',
		cannotEnableDataAcquisition:
			'No puedes habilitar la adquisición de datos ya que este dispositivo actualmente tiene la privacidad activada.',
		cannotEnablePrivacyMode:
			'No puedes habilitar la privacidad ya que este dispositivo actualmente tiene activada la adquisición de datos.',
		careEventsForNurses: 'Eventos de Cuidado para Enfermeras',
		roundingCareEventsForNurses: 'Eventos de Cuidado para Enfermeras',
		careEventsForNursesDescription:
			'Se registran los eventos de atención al paciente como Admisión, Consulta, Tratamiento y Alta realizados por los VCP.',
		careEventsForSafetyCompanions: 'Intervenciones y alertas para Compañeros de Seguridad y Enfermeras',
		careEvents: 'Cuidado de Eventos',
		careEvent: 'evento de cuidado',
		recordings: 'Grabaciones',
		savedCareEvent: 'Evento de cuidado guardado',
		eventDetails: 'Detalles del evento',
		homeMedListCompleted: 'Lista de HomeMed completada',
		admissionDataCompleted: 'Datos de admisión completados',
		safetyCompanions: 'Compañeros de seguridad',
		companionModeName: 'Modo compañero',
		frontlineNurses: 'Enfermeras de primera línea',
		ipoCompleted: 'IPOC completado',
		dischargeInstructionsCreated: 'Instrucciones de descarga creadas',
		dischargeTeachingCompleted: 'Enseñanza de alta completada',
		performed2ndRNMedVerification: 'Realizó la 2da Verificación RNMed',
		notifiedMD: 'MD notificado',
		performedMentorship: 'Mentoría Realizada',
		performedSepsisScreenNotification: 'Notificación de pantalla de septicemia realizada',
		completed24HourChartCheck: 'Comprobación de gráfico de 24 horas completada',
		performedClinicalRounding: 'Redondeo clínico realizado',
		providedUpdateToFamily: 'Proporcionó actualización a la familia ',
		assistedWithCodeBlue: 'asistido con código azul',
		otherCareEventForNurse: 'Otro evento de cuidado para enfermera',
		redirectedPatientToPreventFall: 'Paciente redirigido para prevenir la caída ',
		notifiedBedsideStaffToAssistPatientToPreventFall:
			'Personal de cabecera notificado para ayudar al paciente a prevenir una caída',
		notifiedBedsideStaffToAssistPatient: 'Personal de cabecera notificado para ayudar al paciente',
		calledRapidResponse: 'Llamado Respuesta Rápida',
		preventedHarmToStaffMember: 'Prevención de daños a miembros del personal',
		typeOfCareEvent: 'Agregar un Evento de Cuidado',
		stopRecording: 'Para de grabar',
		record: 'Registro',
		startRecording: 'Iniciar la grabación',
		videoRecordingSaving: 'Video Feed Recording está guardando',
		chooseValidDate: 'La fecha de finalización no puede estar en el futuro',
		patientHistory: 'Historia del paciente',
		additionalHealthDataSymptoms: 'Datos de salud adicionales y síntomas',
		patientRegistration: 'Registro de pacientes',
		parentName: 'Nombre del padre',
		patientHealthCardNumber: 'Número de Tarjeta Sanitaria del Paciente',
		maritalStatus: 'Estado civil',
		single: 'Soltero',
		married: 'Casado',
		divorced: 'Divorciado',
		widowWidower: 'Viuda/Viudo',
		registerNewPatient: 'Registrar Nuevo Paciente',
		getPatientData: 'Obtener datos del paciente',
		menuSettings: 'Ajustes de menú',
		editMenuSettings: 'Editar configuración del menú',
		alreadyWaitingForRequest: 'Ya estás esperando un pedido',
		whatReasonOfVisit: '¿Cuál es el motivo de tu visita hoy?',
		reasonOfVisit: 'Motivo de visita',
		cannotMeasureVS:
			'Los signos vitales no se pueden medir desde la web si no está en la llamada con el médico. Vaya o descargue la aplicación hellocare en el móvil para medir los signos vitales.',
		takeBreak: 'Tomar un descanso',
		resumeWorking: 'Reanudar trabajo',
		symptomsDuration: 'Síntomas Duración',
		frontDeskCheckIn: 'Registro de entrada en la recepción',
		startVisit: 'Empezar Visita',
		minutesWaitingRoom: 'Minutos en la sala de espera',
		markVisitAsCompleted: 'Marcar visita como finalizada',
		inPerson: 'En persona',
		virtualCall: 'Llamada Virtual',
		instructionsForConsultation: 'Instrucciones para la consulta',
		instructionsForDiagnosticResearch: 'Instrucciones para la investigación de diagnóstico',
		healthInstitutionWherePatientInstructed: 'Institución de Salud donde se instruye al paciente',
		consultant: 'Consultor',
		purposeOfInstruction: 'Finalidad de la Instrucción',
		medicalHistory: 'Historial médico',
		examinations: 'Exámenes',
		icd9Code: 'Código CIE-9',
		currentTreatment: 'Tratamiento actual',
		codeMedicines: 'Código Medicamentos',
		workingDiagnosis: 'Diagnóstico de Trabajo',
		icd10Code: 'Código CIE-10',
		organizationalUnit: 'Unidad organizacional',
		organizationalUnitCode: 'Código de unidad organizativa',
		addressOfInstitution: 'Dirección de la Institución',
		consultantDoctor: 'Médico consultor',
		procedureCode: 'Código de procedimiento',
		icpc: 'CIPC',
		addForms: 'Añadir formularios',
		visitDetails: 'Detalles de la visita',
		forms: 'Formularios',
		numberOfVisits: 'Número de visitas',
		lastVisit: 'Última visita',
		showVisitHistory: 'Mostrar historial de visitas',
		remove: 'Eliminar',
		nextVisit: 'Siguiente visita',
		waitingList: 'Lista de espera',
		nextTest: 'Próxima Prueba',
		startTesting: 'Empezar a probar',
		instructions: 'Instrucciones',
		test: 'Prueba',
		sample: 'Muestra',
		enterResults: 'Introducir resultados',
		submitResults: 'Enviar resultados',
		checkInPatients: 'Registrar pacientes',
		doYouHaveAllergies: '¿Tienes alguna alergia?',
		waitingResults: 'Esperando resultados',
		healthInstitution: 'Institución de Salud',
		codeHealthInstitution: 'Código de la Institución de Salud',
		addedForms: 'Formularios añadidos',
		addAnotherTest: 'Agregar otra prueba',
		doesPatientHaveAllergies: '¿Tiene el paciente alguna alergia?',
		noResponseFromPatient: 'Sin respuesta del paciente',
		whatTypeOfAllergies: '¿Qué tipo de alergias tiene el paciente?',
		food: 'Comida',
		environmental: 'Ambiental',
		biological: 'Biológico',
		foodAllergies: 'Alergias a los alimentos',
		medicationAllergies: 'Alergias a medicamentos',
		addAllergies: 'Añadir alergias',
		registerPatient: 'Registrar Paciente',
		helloIsDisconnected: '{value} está desconectado!',
		connectionCannotEstablish:
			'No se pudo establecer la conexión con {value}. Por favor, comprueba la conexión a internet en el dispositivo e inténtalo de nuevo.',
		cannotChangeWorkflow: 'No se puede cambiar el flujo de trabajo',
		noCompletedAppointments: '¡No tienes solicitudes completadas!',
		cholesterol: 'Colesterol',
		totalCholesterolTooltip:
			'El colesterol total en sangre es una medida del colesterol LDL, el colesterol HDL y otros componentes lipídicos. Su médico usará su nivel de colesterol total para determinar su riesgo de enfermedad cardíaca y la mejor manera de controlarlo.',
		hdlCholesterolToolip:
			'HDL (lipoproteína de alta densidad), o colesterol “bueno”, absorbe el colesterol y lo lleva de regreso al hígado. Luego, el hígado lo elimina del cuerpo. Los niveles altos de colesterol HDL pueden reducir el riesgo de enfermedad cardíaca y accidente cerebrovascular.',
		waistCircumferenceTooltip: 'Ingrese la nota aquí',
		meetingWithDoctor: 'Reunión con el Dr.',
		meetingWithPatient: 'Reunión con el paciente',
		enterNoteHere: 'Ingrese la nota aquí',
		accountIsDeactivated: 'Hola {value}',
		helloUser: 'Hola {value}',
		reachAdministrator: 'Número de muestras',
		numberOfSamples: 'Número de pruebas',
		numberOfTests: 'Casos de emergencia',
		emergencyCases: 'Iniciar caso de emergencia',
		startEmergencyCase: 'Esperando Resultados',
		waitingForResults: 'Busque el nombre del paciente o el número de tarjeta de identificación',
		searchPatientNameOrNumber: 'Añadir resultados',
		addResults: 'Agregar resultados',
		network: 'La red',
		profileName: 'Nombre de perfil',
		cidr: 'CIDR',
		wiFiBand: 'Banda WiFi',
		minBandwidth: 'Ancho de banda mínimo en kbps',
		maxBandwidth: 'Ancho de banda máximo en kbps',
		addConfiguration: 'Añadir configuración',
		bandwidth: 'Banda ancha',
		wifiFrequency: 'Frecuencia WiFi',
		minShouldBeLessThanMax: 'El mínimo debe ser menor que el máximo.',
		minBandwidthRequired: 'Se requiere un ancho de banda mínimo.',
		maxBandwidthRequired: 'Se requiere el ancho de banda máximo.',
		minBandwidthLessThan: 'El ancho de banda mínimo no puede ser inferior a 500.',
		maxBandwithMoreThan: 'El ancho de banda máximo no puede ser más de 5000.',
		wiFiBandRequired: 'Se requiere banda WiFi.',
		chooseUnit: 'Elegir unidad',
		applyConfig: 'Aplicar configuración.',
		selectHospital: 'Seleccione Hospital',
		selectHospitalApply: 'Seleccione Hospital para aplicar el directorio activo',
		selectFloor: 'Seleccionar piso.',
		setMinBandwidth: 'Establecer ancho de banda mínimo',
		setMaxBandwidth: 'Establecer ancho de banda máximo',
		chooseWiFiBand: 'Seleccionar Banda WiFi',
		roleIsRequired: 'El rol es obligatorio.',
		invalidIpAddress: 'Dirección IP o intervalo CIDR no válido.',
		webSiteAccess: 'Acceso al sitio web',
		setIpAddress: 'Establecer dirección IP',
		maximumIpAddresses: 'No se pueden agregar más de {value} direcciones IP a la vez',
		provideCIDRRange:
			'Proporcione un rango de direcciones utilizando la notación CIDR (p. ej., 192.168.99.0/24) o una dirección IP (p. ej., 192.168.99.0). También puede proporcionar una lista de direcciones IP o rangos de direcciones usando enter.',
		setIpAddressOrRange: 'Establecer dirección IP o rango',
		deleteNetworkConfigConfirmation: '¿Seguro que quieres eliminar esta configuración de red?',
		deleteAdConfirmation: '¿Seguro que desea eliminar este directorio activo?',
		deleteGroupRoleConfirmation: '¿Está seguro de que desea eliminar este rol de grupo del directorio activo? ',
		representsRangeOfIps: `Representa el rango de IP's`,
		historyForConfig: 'Historial para la configuración',
		notAuthorizedFromThisIP: 'No tiene autorización para acceder a esta página desde la dirección IP',
		notAuthorizedToAccessThisPage: 'No tiene permisos para acceder a esta página.',
		ifYouThinkThereAreMistakes: 'Si cree que ha habido un error, comuníquese con su administrador.',
		activeDirectory: 'Directorio Activo',
		addAd: 'Agregar anuncio',
		addGroupRoles: 'Agregar roles de grupo',
		groupRoles: 'Roles de grupo',
		groupRolesDescription: 'Grupo de funciones del directorio activo',
		groupRolesRequired: 'Se requieren roles de grupo',
		azureAdUrl: 'Establecer URL de Azure AD',
		setValidGroupName: 'Establecer un nombre de grupo válido',
		setValidGroupNameRequired: 'Se necesita un nombre de grupo válido',
		setValidGroupNameDescription: 'Nombre de grupo válido de Active Directory',
		clientId: 'ID de cliente',
		clientIdRequired: 'Se requiere ID de cliente',
		clientIdDescription: 'Establecer ID de cliente de {value}',
		clientSecret: 'secreto del cliente',
		clientSecretRequired: 'Se requiere el secreto del cliente',
		clientSecretDescription: 'Establecer el secreto del cliente del {value}',
		radiology: 'Radiología',
		numberOfRepetitionForLeftNeck: 'Número de repeticiones para el cuello',
		inviteToLaboratory: 'Invitar al Laboratorio',
		startTests: 'Iniciar pruebas',
		patientNoShowUp: 'El paciente no se presentó',
		markSamplesAsTaken: 'Marcar muestras como tomadas',
		markProcessAsCompleted: 'Marcar proceso como completado',
		editPatient: 'Editar Paciente',
		searchPatientNameId: 'Puede comenzar a buscar pacientes a través de su nombre o número de identificación',
		medicalForms: 'Formularios Médicos',
		formsDisplayedHere: 'Aquí se mostrarán los formularios que agregues.',
		totalNumberOfSessions: 'Número Total de Sesiones',
		completedVisits: 'Visitas completadas',
		sessionDuration: 'Duración de la Sesión',
		completedConferences: 'Conferencias completadas',
		notShownUp: 'No aparece',
		ongoingVisits: 'Visitas continuas',
		registryNumber: 'Número de registro',
		visitStart: 'Comienzo de la visita',
		visitEnd: 'Fin de la visita',
		inviteToRoom: 'Invitar a la Sala',
		currentVisit: 'Visita actual',
		consultationReport: 'Informe de consulta',
		instructionsForInjection: 'Instrucciones para la administración de terapia parenteral (inyección)',
		instructionsForInfusion: 'Instrucciones para dar terapia parenteral (Perfusión)',
		laboratory: 'Laboratorio',
		searchToAdd: 'Buscar para agregar {value} para {value2}',
		tests: 'pruebas',
		xRayScans: 'escaneos de rayos x',
		medicationNameHere: 'nombre del medicamento aqui',
		medicationType: 'Tipo de medicamento',
		addAnotherMedication: 'Añadir otro medicamento',
		selectData: 'Seleccionar datos',
		addOtherData: 'Añadir otros datos',
		returnReportFromLab: 'Informe de devolución del laboratorio',
		returnReportFromRadiology: 'Informe de vuelta de la radiología',
		addOtherNote: 'Añadir otra nota',
		addMedicalForms: 'Agregar Formularios Médicos',
		searchPatientNameIdVisitNumber: 'Busque por nombre del paciente, DNI o número de registro de visita',
		nextDay: 'día',
		frontDesk: 'Mostrador',
		injectionRoom: 'Sala de inyección',
		infusionRoom: 'Sala de infusiones',
		capacity: 'Capacidad',
		writeCapacity: 'Capacidad de escritura',
		writePriority: 'Prioridad de escritura',
		startBreak: 'Iniciar descanso',
		endBreak: 'Fin de la ruptura ',
		startSchedule: 'Programar inicio',
		endSchedule: 'Finalizar horario',
		personalInformationTitle: 'Informacion personal',
		locationAndAddressTitle: 'Ubicación & Dirección',
		licensesTitle: 'licencias',
		signatureAndStampTitle: 'Firma y Sello',
		employmentTitle: 'Empleo',
		educationTitle: 'Educación',
		visitHistoryFor: 'Visite Historial para',
		patientNoVisits: 'El paciente no tiene ninguna visita.',
		openCases: 'Casos abiertos',
		closedCases: 'Casos Cerrados',
		instruction: 'Instrucción',
		therapyDescription: 'Descripción de la terapia',
		checkUp: 'Chequeo',
		reCheckUp: 'Re chequeo',
		referral: 'referencia',
		referToOneOfTheDoctors: 'Derivar al paciente a uno de los médicos',
		patientQueue: 'Cola de pacientes',
		referToThisRoom: 'Remitir al paciente a esta habitación',
		waitingTime: 'Tiempo de espera',
		referralDetails: 'Detalles de referencia',
		queueNumber: 'número de cola',
		patientAddedDoctorWaitingList: 'El paciente ha sido añadido a la Lista de Espera del Doctor',
		itinerary: 'Itinerario',
		additionalNote: 'Nota adicional',
		visitIn: 'Visita en',
		doctorCheckUp: 'Chequeo médico',
		laboratoryTesting: 'Prueba de laboratorio',
		invited: 'Convidado',
		notShowedUp: 'No se presentó',
		inProgress: 'En curso',
		cancelled: 'Cancelado',
		frontDeskPatientCheckinPending: 'Recepción - Registro de pacientes - Pendiente',
		frontDeskPatientCheckinInvited: 'Recepción - Registro de pacientes - Invitados',
		frontDeskPatientCheckinInprogress: 'Recepción - Registro de pacientes - En progreso',
		frontDeskPatientNotShowedUp: 'Recepción - Paciente no mostrado ',
		frontDeskPatientCheckinCompleted: 'Recepción - Registro de paciente completado',
		frontDeskPatientCheckinCancelled: 'Recepción - Registro de paciente cancelado',
		waitingForDoctor: 'Esperando al doctor',
		doctorInvitedPatient: 'Paciente invitado por Doctor',
		doctorInvitedPatientNotShowedUp: 'Paciente invitado por el Doctor - No se muestra',
		inDoctorVisit: 'En visita con doctor',
		doctorVisitCompleted: 'Visita al doctor - Completada',
		doctorVisitCancelled: 'Visita al médico - Cancelada',
		patientWaitingForLaboratoryTest: 'Prueba de laboratorio - Paciente esperando',
		laborantInvitedPatient: 'Paciente invitado a Lab',
		laborantInvitedPatientNotShowedUp: 'Paciente invitado al laboratorio: no se presentó',
		laboratoryTestInProgress: 'Prueba de laboratorio - En progreso',
		laboratoryVisitCompleted: 'Visita al laboratorio - Completada',
		laboratoryVisitCancelled: 'Visita al laboratorio - Cancelada',
		otherInformation: 'Otra información',
		preExistingDescription: 'Condiciones preexistentes',
		typeOfAllergies: '¿Qué tipo de alergias tienes?',
		searchOrAddAllergies: 'Buscar o añadir nuevas alergias',
		typeAllergyAndPressEnter: 'Escribe tu alergia y presiona enter',
		pleaseSelectOneOfTheOptionsAbove: 'Por favor seleccione una de las opciones de arriba',
		environmentalAllergies: 'Alergias ambientales',
		biologicalAllergies: 'Alergias biológicas',
		writePreExistingCondition: 'Escribir condiciones preexistentes',
		pleaseWritePreExistingCondition: 'Por favor escriba condiciones preexistentes',
		patientToBeTestedFor: 'Paciente para ser examinado para ',
		creatinine: 'Creatinina',
		lucose: 'lucosa',
		hemogram: 'hemograma',
		sedimentation: 'Sedimentación',
		triglycerides: 'triglicéridos',
		urea: 'Urea',
		urine: 'Orina',
		setToPending: 'Establecer como pendiente',
		numberOfAnalysis: 'Número de análisis',
		glucose: 'Glucosa',
		assignNewDoctor: 'Asignar nuevo doctor ',
		reassignToQueue: 'Reasignar a {value} cola',
		pleaseWriteCorrectValues: 'Por favor escriba los valores correctos',
		caseId: 'ID de caso',
		checkInNumber: 'Número de registro',
		addNecessaryVitalSigns: 'Agrega cualquier signo vital necesario haciendo clic en el botón "Agregar otros datos".',
		sediment: 'Sedimento',
		erythrocyteSedimentationRate: 'Velocidad de sedimentación globular',
		erythrocyteCount: 'Recuento de eritrocitos',
		hemoglobin: 'Hemoglobina',
		femtolitre: 'femtolitro',
		picograms: 'picogramos',
		hematocrit: 'hematocrito',
		ldlCholesterol: 'Colesterol LDL',
		vldCholesterol: 'Colesterol VLD',
		view: 'Vista',
		color: 'Color',
		reaction: 'Reacción',
		specificWeight: 'Peso específico',
		proteins: 'Proteínas',
		acetone: 'Acetona',
		bilirubin: 'Bilirrubin',
		urobilinogen: 'urobilinógeno',
		nitrites: 'nitritos',
		babyCrying: 'Bebe llorando',
		babyCryingDetected: 'Bebé llorando detectado',
		availableLaboratorians: 'Laboratoristas disponibles',
		referToOneOfTheLaboratorians: 'Refiérase a uno de los laboratorians',
		referToThisLaboratorian: 'Refiérase a este laboratorio ',
		leukocyteCount: 'Conteo de leucocitos',
		patientAddedNurseWaitingList: 'Paciente agregado a la lista de espera de {value}',
		auditLogs: 'Registros de auditoría',
		client: 'Cliente',
		logType: 'Tipo de registro',
		inputter: 'Entrada',
		inputterId: 'Id de entrada',
		inputterType: 'Tipo de entrada',
		subjectType: 'Tipo de sujeto',
		subjectName: 'Nombre del sujeto',
		subjectId: 'Identification del sujeto',
		tenantId: 'Identificación del inquilino',
		objectType: 'Tipo de objeto',
		objectId: 'Id. de objeto',
		sourceOfInput: 'Fuente de entrada',
		actioneeName: 'Nombre del actor',
		actioneeObjectId: 'Id. del objeto de la acción',
		actioneeObjectType: 'Tipo de objeto de acción',
		noAuditLogs: 'Sin registros de auditoría',
		filterLogs: 'Filtrar registros',
		user: 'Usuario',
		resetFilter: 'Restablecer filtro',
		waistUnit: 'unidad de cintura',
		totalCholesterolUnit: 'Unidad de colesterol total',
		hdlCholesterolUnit: 'unidad de colesterol HDL',
		contactInformation: 'Información del contacto',
		updateYour: 'Actualiza tus',
		update: 'Actualizar',
		profilePictureTitle: 'Foto de perfil',
		addressTitle: 'Dirección',
		contactInformationTitle: 'Información del contacto',
		healthInformationTitle: 'Información de salud',
		manageProfileInformation: 'Gestiona la información de tu perfil',
		keepAccountSecure: 'Mantén tu cuenta segura',
		changePharmacyLocation: 'Cambiar la ubicación de su farmacia',
		manageApps: 'Gestiona tus aplicaciones',
		changeAppLanguages: '`Cambiar el idioma de la aplicación',
		changeMeasurementUnits: 'cambiar las unidades de medida',
		helpDescription: 'Acerca de, chat de soporte en vivo, invitar a personas',
		appLanguage: 'Idioma de la aplicación',
		help: 'Ayuda',
		uploadNewPicture: 'Subir nueva foto',
		postalCode: 'Código postal',
		otherData: 'Otros datos',
		selectOption: 'Seleccionar opción',
		selectRails: 'Seleccionar Rails Para que Rails envíe una alerta',
		showFullProfile: 'Mostrar perfil completo',
		fillMandatoryFields: 'Los cambios no se pueden guardar a menos que complete los campos obligatorios',
		endDateLaterThanStartDate: 'La fecha de finalización debe ser posterior a la fecha de inicio',
		endDateLaterThanToday: 'La fecha de finalización no debe ser posterior a la fecha de hoy',
		rtgPulmonary: 'Escáner pulmonar',
		rtgSkeleton: 'Escáner de esqueleto',
		rtgChest: 'Escáner de tórax',
		rtgUpperExtremities: 'Escáner de extremidades superiores',
		rtgLowerExtremities: 'Escáner de extremidades inferiores',
		rtgSpine: 'Escáner de columna',
		ultrasonography: 'Eco - Ultrasonografía',
		injection: 'Inyección',
		infusion: 'Infusión',
		recording: 'Grabación',
		recordTheSession: 'Grabar la sesión',
		recordingDescription:
			'Cuando esto esté habilitado, los usuarios podrán grabar la sesión de monitoreo que se guardará en el perfil del paciente.',
		pleaseChooseAnotherDate: 'Por favor elige otra fecha.',
		availableNursesIn: 'Enfermeros disponibles en {value}',
		referToOneOfNurses: 'Refiérase a uno de los enfermeros',
		infusionTherapy: 'Terapia de infusión',
		injectionTherapy: 'Terapia de inyección',
		deleteRegion: 'Borrar Región?',
		atLeastOneRegion: 'Los sistemas de salud deben tener al menos una región!',
		deleteHS: '¿Eliminar el sistema de salud?',
		regionCannotBeEmpty: 'La región no puede estar vacía',
		regionExists: 'La región con este nombre ya existe',
		healthSystemExists: 'Ya existe un sistema de salud con este nombre',
		positive: 'Positivo',
		negative: 'Negativo',
		inTraces: 'En huellas',
		clearUrine: 'nítido/a',
		turbid: 'Difuminar',
		reddish: 'rojo',
		yellow: 'amarillo',
		acidic: 'ácido',
		noOngoingVisit: 'Actualmente no hay ninguna visita en curso.',
		noShowUpVisit: 'Actualmente no hay ningún caso en el que los pacientes no se presenten',
		noCompletedVisits: 'Actualmente no hay visitas realizadas.',
		radiologyScans: 'escáneres de radiología',
		comment: 'Comentario',
		enterComment: 'Introducir comentario',
		radiologyRoom: 'Sala de radiología',
		newPassword: 'Nueva contraseña',
		readTermsAndConditions: 'Lee nuestros términos y condiciones',
		readPrivacyPolicy: 'Lea nuestra política de privacidad',
		chemicals: 'químicos',
		distance: 'Distancia',
		weightAndLeanBodyMass: 'Relacionado con el peso y la masa corporal magra',
		relatedBodyTemperature: 'Relacionado con la temperatura corporal',
		heightAndWaistCircumference: 'Relacionado con la altura y la circunferencia de la cintura',
		walkingAndRunning: 'Relacionado con la distancia de caminar + correr',
		bloodGlucoseAndCholesterol: 'Relacionado con Glucosa en Sangre, Colesterol Total y Colesterol HDL',
		selectUnit: 'Seleccionar unidad',
		poolingFlow: 'Flujo de agrupación',
		editGeneralSettings: 'Editar configuración general',
		durationDays: 'días',
		timesADay: 'veces al día',
		parenteralTherapyReport: 'Informe de terapia parenteral',
		returnReportFromDiagnostics: 'Devolver informe de diagnóstico',
		searchToAddDiagnosis: 'Buscar para agregar Diagnóstico',
		addDiagnoses: 'Agregar Diagnósticos',
		previousDiagnoses: 'Diagnósticos Previos',
		presets: 'Preajustes',
		createPreset: 'Crear ajuste preestablecido',
		createNewPreset: 'Crear nuevo Preset',
		typePresetDescription: 'Nombre del preset',
		addNewPreset: 'Añadir nuevo preset',
		deletePreset: 'Eliminar este preset',
		areYouSureToDeletePreset: '¿Estás seguro de que deseas eliminar la configuración preestablecida {value}?',
		goToPreset: 'Ir a este preset',
		noPresets: 'No hay presets disponibles, intenta agregar uno.',
		presetChangeDescription: 'Puedes cambiarlo en cualquier momento',
		presetName: 'Nombre del preset',
		activePreset: 'Preset activo:',
		noActivePreset: 'Sin preset activo',
		resetCamera: 'Restablecer la cámara',
		updateToCurrentPosition: 'Actualizar a la posición actual',
		pleaseWriteValidDate:
			'Por favor agregue experiencias más recientes. Desafortunadamente, actualmente no admitimos experiencias que hayan pasado 60 años.',
		returnReportFromParentalTherapy: 'Informe de devolución de la terapia parental',
		number: 'Número',
		refer: 'Referir',
		sectorExistsMessage: '{value} con este nombre ya existe',
		youAreAlreadyOnCall: 'Ya estás en una llamada.',
		deviceNotAvailableForCall: 'El dispositivo no está disponible para llamar ahora.',
		couldNotGetDeviceState: 'No se pudo obtener el estado de la llamada del dispositivo.',
		visitingHours: 'Horario de visitas',
		addVisitingHours: 'Agregar horario de visita',
		selectAll: 'Seleccionar todo',
		deSelectAll: 'Deseleccionar todo',
		conferenceId: 'ID de la Conferencia',
		initiator: 'Iniciador',
		roomCount: 'Cantidad de Habitaciones',
		origin: 'Origen',
		usedAudio: 'Audio Utilizado',
		usedVideo: 'Video Utilizado',
		usedScreen: 'Pantalla Utilizada',
		selectContentToDownload: 'Selecciona el contenido que deseas descargar',
		applyGlobalSettings: 'Aplicar configuración global',
		areYouSureConfigurations: '¿Estás seguro de que quieres guardar las nuevas configuraciones? ',
		enforceConfiguration: 'Hacer cumplir la configuración',
		overrideLocalConfiguration:
			'Al habilitar esta opción, anula la configuración local establecida en la jerarquía a continuación.',
		confirmationModal: 'Confirmación Modal',
		activeQueueError: 'Tienes pacientes en tu cola de espera. Por favor completa la cola.',
		chooseRole: 'Elegir un papel',
		selectRoleOrAll: 'Seleccione un rol o elija Todos para aplicar el período de inactividad',
		addToQueue: 'Añadir a la cola en {value}',
		reject: 'Rechazar',
		rejectSessionReason: 'Motivo del rechazo (Opcional)',
		rejectSession: 'Rechazar sesión',
		confirmRejectSession: '¿Estás segura de que quieres rechazar esta sesión?',
		patientsInQueue: '{value} pacientes en cola',
		availableNurses: 'Enfermeras disponibles',
		virtualSitterOnList: 'Niñera virtual',
		addVirtualSitter: 'Agregar niñera virtual',
		virtualsitter: 'Niñera virtual',
		nextVisitIn: 'La próxima visita es en {value}',
		pleaseGoToFrontDesk: 'Por favor, visite la recepción para que le asignen una cola',
		hdmiPort: 'Puerto HDMI',
		defaultSource: 'Fuente predeterminada',
		ringtoneVolume: 'Volumen del tono de llamada',
		'port/siteId': 'ID de puerto/sitio',
		port: 'Puerto',
		siteId: 'Identificación del sitio',
		apiKey: 'Clave API',
		pleaseSelectHealthSystem: 'Seleccione un sistema de salud.',
		cannotSelectNrMoreThanValue: 'No se puede seleccionar más de {value} {item},',
		pleaseSelectHospital: 'Por favor, seleccione un hospital.',
		pleaseSelectDepartment: 'Por favor, seleccione un departamento.',
		pleaseSelectSector: 'Por favor seleccione un valor}.',
		pleaseSelectHDMIPort: 'Por favor, seleccione un puerto HDMI.',
		pleaseSelectTV: 'Por favor, seleccione un televisor.',
		ringtoneVolumeValidation: 'El volumen del timbre debe estar entre 0 y 100.',
		pleaseSelectEndCallSource: 'Por favor, seleccione una fuente de llamada final de TV.',
		pleaseSelectProfileName: 'Por favor, establezca un nombre de perfil.',
		pleaseSelectCompanionDevice: 'Seleccione un dispositivo complementario si está disponible',
		pleaseSetURL: 'Por favor, establezca una URL.',
		pleaseSetSecret: 'Por favor establece un secreto.',
		pleaseSetApiKey: 'Establezca una clave de API.',
		pleaseSetSiteId: 'Establezca una ID de sitio.',
		pleaseSetClientId: 'Por favor establezca un ID de cliente',
		pleaseSetUserName: 'Establezca un nombre de usuario',
		integrationType: 'Por favor elige un tipo de integración.',
		portValidation: 'El puerto debe ser un número superior a 0',
		addHSConfiguration: 'Añadir Configuración del Sistema de Salud',
		addTVConfiguration: 'Agregar configuración de TV',
		selectHSForConfiguration: 'Selecciona Sistema de Salud para que puedas elegir el hospital para aplicar las configuraciones.',
		chooseHospitalForChanges: 'Elija el hospital al que desea aplicar los cambios',
		chooseSectorForChanges: 'Elija el {value} al que desea aplicar los cambios',
		selectDepartment: 'Seleccione Departamento',
		chooseTVSetup: 'Elija Configuración de TV',
		chooseTVSetupType: 'Elige el tipo de configuración de TV.',
		selectHelloCareHDMI: 'Seleccione puerto HDMI HELLO/HOLAcare',
		selectHDMIPort: 'Seleccione el puerto HDMI donde se conectará HELLO/HOLAcare.',
		tvManagement: 'Gestión de TV',
		selectTVOption: 'Selecciona la opción en la que la TV volverá después de que finalice la llamada.',
		selectTeleHealthProfile: 'Seleccionar perfil de telesalud',
		selectPreConfiguredProfile: 'Seleccione el perfil de telesalud preconfigurado. Dejar en blanco si no hay.',
		helloCareRingtone: 'tono de llamada de HELLO/HOLAcare',
		applyToAllDevices: 'Esta configuración se aplicará a todos los dispositivos de este hospital.',
		setProfileName: 'Establecer nombre de perfil',
		setURL: 'Establecer URL',
		setPort: 'Establecer puerto',
		setSiteId: 'Establecer ID del sitio',
		setAPIKey: 'Establecer clave API',
		secret: 'Secreto',
		setSecret: 'Establecer secreto',
		setScope: 'Establecer alcance',
		pleaseSetScope: 'Por favor establezca el alcance.',
		pleaseSetTenantId: 'Establezca la ID del inquilino.',
		facilityAccessKey: 'Clave de acceso a las instalaciones',
		pleaseSetFacilityAccessKey: 'Configure la clave de acceso a las instalaciones.',
		setTheme: 'Establecer tema',
		pleaseSetTheme: 'Por favor configure el tema.',
		tokenUrl: 'URL del token',
		pleaseSetTokenUrl: 'Por favor configure la URL del token',
		deleteConfiguration: '¿Eliminar configuración?',
		areYouSureToDeleteConfig: '¿Está seguro de que desea eliminar esta configuración?',
		liveExaminations: 'Exámenes en vivo',
		selectOneOfExaminations: 'Por favor, seleccione uno de los exámenes para empezar a medir',
		ear: 'Oído',
		lungs: 'Pulmones',
		throat: 'Garganta',
		skin: 'Piel',
		ecg: 'electrocardiograma',
		eCG: 'electrocardiograma',
		spirometer: 'espirómetro',
		abdomen: 'Abdomen',
		lungsAir: 'espirómetro',
		connectDevice: '{value} {value1}',
		confirmIotProperlyPlaced: 'Confirme con el paciente que el dispositivo médico esté colocado correctamente.',
		toCheckConnectToDevice: 'Para {value} {value2}, el paciente necesita {value3}.',
		toStartMeasureTemperature:
			'Para comenzar a medir la temperatura, el paciente debe presionar el botón de medición en el dispositivo.',
		check: 'controlar',
		toMeasure: 'medida',
		otoscope: 'otoscopio',
		dermatoscope: 'dermatoscopio',
		ultrasound: 'Ultrasonido',
		deviceIsConnectedStartMeasurement: '{value} está conectado, ¡puede comenzar la medición!',
		startMeasuring: 'Iniciar medición',
		previousMeasurements: 'Mediciones Previas',
		showMore: 'Mostrar más',
		checkMePro: 'Chequeme Pro',
		initiateDevice: 'Iniciar {value}',
		assignVisitor: 'Asignar visitante',
		visitors: 'visitantes',
		disableVisits: 'Deshabilitar Visitas',
		disableVisitsDescription:
			'Cuando esto está habilitado, los usuarios establecerán el tiempo máximo que las llamadas al paciente estarán deshabilitadas para familiares y amigos.',
		enableVisits: 'Habilitar visitas',
		selectTime: 'Seleccionar tiempo',
		areYouSureDisableVisits:
			'Al habilitar esta opción, establece el tiempo máximo que las llamadas al paciente estarán deshabilitadas para Familiares y Amigos. Una vez transcurrido el tiempo seleccionado, se permitirán las llamadas según el rol del usuario y el programa de visitas al hospital.',
		idNumberExists: 'Este número de ID ya existe. ¡Por favor inténtelo de nuevo!',
		infectiousDisease: 'Enfermedad Infecciosa',
		cardiologist: 'Cardiólogo',
		pulmonologist: 'Neumólogo',
		nephrologist: 'Nefrólogo',
		callDuringVisitingHours: 'Puedes llamar al paciente solo durante el horario de visitas.',
		retakeMeasurement: 'Retomar la medida',
		deviceIsMeasuring: '{value} está midiendo',
		waitForCompletionToEnsure: '¡Espere a que finalice para garantizar resultados precisos!',
		removeDeviceToCompleteMeasure: 'Retire el dispositivo del dedo para completar la medición.',
		completeProfileTerms: 'Marque aquí para indicar que ha leído y está de acuerdo con el ',
		terms: 'términos ',
		disabledFor: 'Deshabilitado para',
		familyFriends: 'Familia y amigos',
		otherPeople: 'Otras personas',
		hierarchy: 'Jerarquía',
		roomOnList: 'Habitación',
		selectHSHierarchy: 'Seleccionar jerarquía del sistema de salud',
		applyVisitingHoursAllDays: 'Aplicar horarios de visita a todos los días de la semana',
		addedConfigsRecurring: 'Las configuraciones agregadas son recurrentes en función de un día específico de la semana.',
		deleteMember: '¿Eliminar miembro?',
		stopMeasuring: 'Deja de medir',
		showHistory: 'Mostrar historial',
		error: 'Error',
		takingCareOfbaby: 'Cuidando a este bebé',
		takingCareOfbabyConfirm:
			'Al habilitar esta opción, se le asignará al grupo de chat con los familiares de este bebé. ¿Confirmar?',
		takingCareOfbabyConfirmUnAssigned:
			'Al habilitar esta opción, se le anulará la asignación al grupo de chat con los familiares de este bebé. ¿Confirmar?',
		nicuChat: 'NICU chatear',
		nicuCheckIn: 'Registro en la NICU',
		nicuCheckOut: 'salida de la NICU',
		kiosk: 'Quiosco',
		triage: 'triaje',
		includeRoles: 'Incluir roles',
		isSingleSignOutEnabled: 'Habilitar cierre de sesión único',
		deviceIsBusy: 'El dispositivo está ocupado',
		ntpConfigurations: 'Configuraciones de NTP',
		ntpUrl: 'Punto final NTP',
		ntpPort: 'Puerto NTP',
		setNtpUrl: 'Establecer punto final NTP',
		setNtpPort: 'Establecer puerto NTP',
		setNtpUrlPort: 'Establecer punto final y puerto NTP',
		pleaseEnterNtpUrl: 'Ingrese el punto final de NTP',
		pleaseEnterNtpPort: 'Por favor, introduzca el puerto NTP',
		atLeastOneNtpUrlIsRequired: 'Se requiere al menos 1 NTP Enpoint',
		enterValidUrl: ' introduzca una URL válida',
		portIsRequired: 'puerto requerido',
		urlPortCannotBeSame: 'Los campos de punto final + puerto deben ser únicos',
		turnOnGettingOutOfBed: 'Levantarse de la cama',
		turnOffGettingOutOfBed: 'Apaga salir de la cama',
		turnOnInactiveTime: 'Habilitar el tiempo de inactividad del paciente',
		turnOffInactiveTime: 'Deshabilitar el tiempo de inactividad del paciente',
		patientInQueue: 'Tiene pacientes en su cola de espera. ¡Completa la cola!',
		medicalVisitInitiated: 'Visita médica ya iniciada. ¡Completa la visita!',
		disableAi: 'Deshabilitar IA',
		disableAiTemporarily: 'Deshabilitar la IA temporalmente',
		disableAiDescription:
			'Cuando esto está habilitado, los usuarios podrán deshabilitar un caso de uso de IA que esté encendido durante un período de tiempo deseado y, una vez que finalice ese tiempo, la IA continuará con su actividad.',
		enableAi: 'Habilitar IA',
		sendRailsAlert: 'Para que Rails envíe una alerta',
		areYouSureDisableAi:
			'Al habilitar esta opción, establece la hora en que las alertas de IA se desactivarán para usted y el paciente. Una vez transcurrido el tiempo seleccionado, la función de IA se activará y recibirá alertas.',
		defaultCamera: 'cámara predeterminada',
		selectDefaultCamera: 'Seleccionar cámara predeterminada',
		selectedDefaultCameraDescription: 'La cámara seleccionada se mostrará por defecto para cada tipo de llamada.',
		defaultCameraDescription:
			'Antes de seleccionar la Cámara {value} como cámara predeterminada, asegúrate de que esté conectada al dispositivo.',
		scheduleDataAcquisition: 'Programar Adquisición de Datos',
		scheduleTimeForDataAcquisition: 'Programe el tiempo para la adquisición de datos',
		dataAcquisitionSchedule: 'Calendario de adquisición de datos',
		snoozeFalseAlert: 'Esta alerta se pospondrá durante 5 minutos.',
		snoozeAcknowledgeAlert: 'Esta alerta se desactivará durante 15 minutos mientras el paciente recibe ayuda.',
		configureFeatureFlags: 'Configurar banderas de características',
		generalAndMenuSettings: 'Ajustes generales y de menú',
		featureFlags: 'Banderas de características',
		featureFlagsDescription:
			'Los indicadores de funciones proporcionan una configuración de control centralizada para habilitar o deshabilitar funciones específicas en todos los niveles de una empresa.',
		selectSector: 'Seleccione {value}',
		sector: 'Sector',
		pleaseSelectFloor: 'Por favor, seleccione un piso',
		adminPreviousConfigs: 'Al seleccionar esta opción, anula las configuraciones anteriores de los sectores a continuación.',
		maxPortNumber: 'El número de puerto máximo es ',
		portCannotBeNegative: 'Port no puede ser un número negativo',
		wearablesDescription:
			'Cuando esto esté habilitado, los dispositivos portátiles estarán disponibles para usar durante el examen en vivo.',
		bodyComposition: 'Composición corporal',
		snoreDetection: 'Detección de ronquidos',
		caloriesBurned: 'Calorías quemadas',
		makeSureDeviceIsOn: 'Asegúrese de que el dispositivo del paciente esté ENCENDIDO.',
		samsungWatch: 'Samsung Watch',
		vitalKit: 'Vital Kit',
		showPreviousMeasurements: 'Mostrar medidas anteriores',
		patientHasMeasured: '{value1} ha medido {value2}',
		ecgDoesNotShowSigns: 'Este ECG no muestra signos de fibrilación auricular',
		cannotCheckForSignsOfHeartAttack:
			'No podemos buscar signos de un ataque al corazón. Si cree que tiene una emergencia médica,',
		callEmergencyServices: 'llamar a los servicios de emergencia',
		theear: 'oído',
		thelungs: 'pulmones',
		theheart: 'corazón',
		thethroat: 'garganta',
		theheartRate: 'ritmo cardiaco',
		theoxygenSaturation: 'saturación de oxígeno',
		thetemperature: 'La temperatura',
		theskin: 'piel',
		thebloodPressure: 'Presión arterial',
		theecg: 'electrocardiograma',
		thebloodGlucose: 'glucosa en sangre',
		theweight: 'Peso',
		theabdomen: 'Abdomen',
		theotoscope: 'otoscopio',
		thestethoscope: 'Estetoscopio',
		thepulseOximeter: 'Oxímetro de pulso',
		thethermometer: 'Termómetro',
		thedermatoscope: 'dermatoscopio',
		theglucometer: 'glucómetro',
		thescale: 'Escala',
		thespirometer: 'espirómetro',
		theultrasound: 'Ultrasonido',
		selectAvailableDevices: 'Seleccione uno de los dispositivos disponibles para iniciar el examen.',
		medicalDevices: 'Dispositivos médicos',
		medicalDevicesDescription:
			'Cuando esto está habilitado, todos los dispositivos IoT estarán disponibles para que el usuario los use durante el examen en vivo',
		aiUsingCamera: 'IA usando cámara',
		guidePatientOtoscope:
			'Guíe al paciente para que inserte el espéculo ovalado en la cámara cilíndrica del dispositivo y colóquelo correctamente en el oído.',
		guidePatientHeartLungs:
			'Guíe al paciente para que coloque correctamente el dispositivo en el cuerpo usando la ilustración guiada en la pantalla.',
		guidePatientThroat:
			'Guíe al paciente para que inserte el bajalenguas en el dispositivo y colóquelo correctamente en la boca.',
		guidePatientHeartRate: 'Guíe al paciente para que coloque el pulgar derecho en el electrodo derecho inferior.',
		guidePatientForMeasurement: 'Orientar al paciente sobre cómo realizar la medición.',
		guidePatientDiabetes: 'Asegúrese de que los dispositivos del paciente estén encendidos y conectados mediante bluetooth',
		guidePatientECG:
			'Guíe al paciente para que gire el dispositivo horizontalmente a la derecha. Presione el pulgar derecho sobre el electrodo inferior derecho. Presione el pulgar izquierdo sobre el electrodo inferior izquierdo. Presione el dedo índice izquierdo sobre el electrodo superior izquierdo.',
		startDateLaterThanToday: 'La fecha de inicio no debe ser posterior a la fecha de hoy',
		pleaseWriteAllergy: '¡Escriba al menos una alergia y haga clic en Entrar!',
		examKit: 'Kit de examen todo en uno',
		examKitDescription:
			'Cuando está habilitado, el dispositivo del kit de examen todo en uno estará disponible para usar durante el examen en vivo',
		aiMeasurements: 'Medidas de IA',
		AIMeasurementsDescription:
			'Cuando está habilitado, la medición de signos vitales estará disponible a través de IA durante el examen en vivo',
		biobeatPatch: 'Parche Biobeat',
		biobeatPatchDescription:
			'Cuando está habilitado, el dispositivo Biobeat Patch estará disponible para su uso durante el examen en vivo',
		lifesignalsPatch: 'Parche de señales de vida',
		lifeSignalsPatchDescription:
			'Cuando está habilitado, el dispositivo Lifesignal Patch estará disponible para su uso durante el examen en vivo',
		healthDataDescription: 'Cuando está habilitado, se mostrarán todos los datos de salud del paciente en la llamada.',
		summaryDescription: 'Cuando esto está habilitado, el resumen de la información médica se mostrará en un solo lugar',
		visitsDescription: 'Cuando está habilitado, se mostrará todo el historial de visitas del paciente en la llamada.',
		alertHistoryDescription:
			'Cuando esto esté habilitado, se mostrará la opción de actualizar y ver el historial del riesgo para los signos vitales.',
		medicalInfoPatientDiagnosis: 'Diagnóstico del paciente',
		patientDiagnosesDescription:
			'Cuando esto esté habilitado, los médicos tendrán la opción de agregar un diagnóstico para el paciente durante la llamada.',
		proceduresDescription:
			'Cuando esto esté habilitado, los médicos tendrán la opción de agregar un procedimiento para el paciente durante la llamada.',
		roundingNotesDescription:
			'Cuando esto esté habilitado, los médicos tendrán la opción de agregar notas para el paciente durante la llamada.',
		prescriptionsDescription:
			'Cuando esto esté habilitado, los médicos tendrán la opción de agregar una receta para el paciente durante la llamada.',
		physicalTherapyDescription:
			'Cuando esto esté habilitado, los médicos tendrán la opción de agregar nuevos ejercicios para el paciente durante la llamada.',
		wrapUpPage: 'Resumen de la Sesión',
		sessionWrapUpDescription:
			'Cuando esto esté habilitado, a los médicos se les presentará una página donde podrán revisar e ingresar información médica.',
		previous: 'Previa',
		ehr: 'EHR',
		eHR: 'EHR',
		companyImage: 'Imagen de empresa',
		togglePatientsTvON: `está encendido`,
		audioFromMonitoringFeeds: 'Audio de fuentes de monitoreo',
		talkToPatients: 'Hablar con los pacientes',
		tvChannels: 'Canales de televisión',
		previousState: 'Estado Anterior',
		visitor: 'Visitante',
		nicuMode: 'Modo UCIN',
		virtualExaminationMode: 'Modo de examen virtual',
		simplifiedMode: 'Modo simplificado',
		eVideonMode: 'Modo eVideon',
		hellocareEngagement: 'Participación en hellocare',
		patientMonitoring: 'Monitoreo de pacientes',
		bedDefinition: 'Definición de cama EHR',
		bed: 'Cama',
		tablet: 'Tablet',
		locked: 'Bloqueado',
		unlocked: 'Desbloqueado',
		callSettingStatus: '{value} {value2} permitido',
		is: 'es',
		isNot: 'no es',
		audioRoutingOptions: 'Opciones de fuente de audio',
		selectAudioRoutingOptions: 'Seleccione la opción de fuente de audio para cada tipo de llamada',
		customConfigurations: 'Configuraciones personalizadas',
		basedOnDeviceType: 'Basado en el tipo de dispositivo',
		selectOneOfRoutingOptions: 'Seleccione una de las opciones de fuente de sonido',
		audioRoutingDeviceType: 'Elegir configuraciones específicas basadas en el dispositivo',
		notAuthorizedHealthSystem: 'No está autorizado para usar este Sistema de Salud.',
		switchHsFeature: 'Cambie el sistema de salud para usar esta función.',
		alwaysFromHello: 'Siempre desde HELLO',
		routesToTvPatientNurse: 'Enruta a la TV cuando el paciente ve a la enfermera',
		routesToTvSourceAvailable: 'Rutas a TV cuando fuente/estado disponible',
		alwaysPillowSpeaker: 'Siempre desde altavoz',
		alwaysFromPhone: 'Siempre desde el teléfono',
		alwaysFromTablet: 'Siempre desde la tableta',
		routeExternalDetected: 'Enrutar al altavoz externo cuando se detecta',
		viewPatient: 'Ver paciente',
		callStartAudioOnly: 'La llamada comienza solo con audio',
		callStartAudioVideo: 'La llamada comienza con audio y video',
		audioOnly: 'Empezar solo con audio',
		audioVideo: 'Empezar con audio y video',
		callSettings: 'Configuración de llamadas',
		roomSignCallSettings: 'Configuración de Llamadas para Señal de Habitación',
		providerAndPatientCallSettings: 'Configuración de Llamadas para Proveedor y Paciente',
		wallpaper: 'Fondo de pantalla y fondo',
		selectCallOptions: 'Seleccione entre opciones dadas para cada tipo de llamada',
		others: 'Otros',
		callTypes: 'Tipos de llamadas',
		notAuthorizedMonitoring: 'No está autorizado para usar el Monitoreo',
		areYouSureToRemoveMember: 'Está seguro de que desea eliminar a este miembro de la sala?',
		returnReport: 'Informe de devolución',
		medicalAssessment: 'Evaluación médica',
		therapy: 'Terapia',
		diagnosticResearch: 'Investigación diagnóstica',
		menuOptions: 'Opciones de menú',
		mainConcerns: 'Preocupaciones principales',
		anamnesis: 'Anamnesia',
		clickToSave: 'Haga clic para guardar',
		addMoreAllergies: 'Agregar más alergias',
		patientAcknowledgedAllergies: 'El paciente reconoció tener las siguientes alergias',
		doesHaveAllergies: '¿Esta/e paciente tiene alergias?',
		writeAllergyAndEnter: 'Escribe alergia y presiona Enter para agregar',
		noAddedAllergies: 'Sin alergias añadidas',
		searchForDiagnoses: 'Busque el código o el nombre y presione enter para agregar el diagnóstico',
		addedDiagnoses: 'Diagnósticos agregados',
		recommendations: 'Recomendaciones',
		prescribeDrugs: 'Medicamentos Recetados',
		useToPrescribe: 'Usar para prescribir {value}',
		drugs: 'Drogas',
		prescribeInfusions: 'Prescribir Infusiones',
		prescribeInjection: 'Prescribir Inyecciones',
		addedTests: 'Pruebas añadidas',
		useToReferPatientTo: 'Úselo para derivar al paciente al {value}',
		searchforTheCodeAndEnter: 'Busque el código y presione enter para agregar el procedimiento',
		commentForTechnicians: 'Comentarios para los técnicos',
		visitSummaries: 'Resúmenes de visitas',
		isCompanyLevelConfiguration: 'Configuración a nivel de empresa',
		autoSynchronizeLogin: 'Sincronización automática al iniciar sesión',
		youAlreadyHaveAddedThisDomain: 'Ya has agregado este Dominio',
		theProvidedDomainIsInvalid: 'El Dominio proporcionado no es válido (por ejemplo, hellocare.ai)',
		formShouldContainOneDomainEmail:
			'Se requiere al menos un dominio, por favor escriba y presione Enter para agregar un correo electrónico de dominio.',
		maximumEmailDomainsAllowedAre5: 'El máximo permitido de dominios de correo electrónico es 5',
		accountCreationFailed: `La creación de la cuenta falló. Si el problema persiste, comuníquese con nuestro equipo de soporte para obtener ayuda.`,
		accountSynchronizationFailed: `La sincronización de la cuenta falló. Si el problema persiste, comuníquese con nuestro equipo de soporte para obtener ayuda.`,
		accountBeingSynchronizedMayTakeMinutes: `Su cuenta está en proceso de sincronización. ¡Esto puede tardar unos minutos!`,
		autoAdd: 'Añadir automáticamente',
		autoUpdate: 'Auto actualización',
		autoDelete: 'Eliminación automática',
		adUserDoesNotExist:
			'Este usuario ya existe o la cuenta de usuario no existe en la lista de usuarios de proveedores externos de identidad y no puede acceder a la aplicación. Por favor, compruebe si la cuenta es correcta.',
		additionalDataMissing:
			'No se pueden obtener datos adicionales de la báscula debido a la ausencia de información de altura, peso y sexo en el perfil del paciente',
		missingWeight: 'No se pueden obtener datos de báscula adicionales debido a la ausencia de peso',
		missingHeight: 'No se pueden obtener datos de escala adicionales debido a la ausencia de altura',
		missingGender: 'No se pueden obtener datos de escala adicionales debido a la ausencia de Género',
		unrealisticWeight: 'El peso no se corresponde con la altura del paciente',
		shoesOn:
			'La báscula no puede detectar la impedancia del cuerpo. Asegúrese de que sus pies descalzos estén colocados correctamente en los 4 electrodos',
		emailNotValid: 'El correo no es válido',
		emailDuplicated: 'Correo electrónico duplicado',
		taskCompleted: 'Tarea terminada',
		noEmailsExelFile: 'No hay correos electrónicos en su archivo de Excel',
		errorType: 'Tipo de error',
		bulkUpload: 'Carga masiva',
		emailList: 'Lista de correo',
		medium: 'Medio',
		sensitivity: 'Sensibilidad',
		highSensitivity:
			'Este modo está diseñado para activar una alerta incluso con el más mínimo movimiento de una persona alejándose de su cama.',
		mediumSensitivity:
			'Este modo está configurado para enviar una alerta cuando una persona se ha alejado moderadamente de su cama.',
		lowSensitivity:
			'Este modo está programado para activar una alerta solo cuando una persona se ha levantado completamente de su cama.',
		standOnScale: 'Párese en la escala',
		deviceDisconnect: 'Desconectada',
		lowBodyTemperatureDescription: 'Baja temperatura corporal',
		highBodyTemperatureDescription: 'temperatura corporal alta',
		lowHeartRateDescription: 'La paciente tiene una frecuencia cardíaca muy baja!',
		highHeartRateDescription: 'La paciente tiene una frecuencia cardíaca muy alta!',
		criticalHighBodyTemperature: 'Temperatura corporal alta crítica',
		lowBMIUnderweight: 'Bajo IMC Bajo peso',
		highBMI: 'IMC alto',
		criticalHighBMI: 'IMC alto crítico',
		systolicLowBloodPressure: 'Presión arterial baja sistólica',
		diastolicLowBloodPressure: 'Presión arterial baja diastólica',
		systolicCriticalLowBloodPressure: 'Presión arterial baja crítica sistólica',
		diastolicCriticalLowBloodPressure: 'Presión arterial baja crítica diastólica',
		systolicHighBloodPressure: 'Presión arterial alta sistólica',
		diastolicHighBloodPressure: 'Presión arterial alta diastólica',
		systolicCriticalHighBloodPressure: 'Presión arterial alta crítica sistólica',
		diastolicCriticalHighBloodPressure: 'Presión arterial alta crítica diastólica',
		lowOxygenSaturation: 'Baja saturación de oxígeno',
		criticalLowOxygenSaturation: 'Saturación de oxígeno baja crítica',
		thehighHeartRate: 'Ritmo cardíaco alto',
		thelowHeartRate: 'Frecuencia cardíaca baja',
		thelowBodyTemperature: 'Baja Temperatura Corporal',
		thehighBodyTemperature: 'Temperatura corporal alta',
		thecriticalHighBodyTemperature: 'Temperatura corporal alta crítica',
		thelowBMIUnderweight: 'Bajo IMC Bajo peso',
		thehighBMI: 'IMC alto',
		thecriticalHighBMI: 'IMC alto crítico',
		thesystolicLowBloodPressure: 'Presión arterial baja sistólica',
		thediastolicLowBloodPressure: 'Presión arterial baja diastólica',
		thesystolicCriticalLowBloodPressure: 'Presión arterial baja crítica sistólica',
		thediastolicCriticalLowBloodPressure: 'Presión arterial baja crítica diastólica',
		thesystolicHighBloodPressure: 'Presión arterial alta sistólica',
		thediastolicHighBloodPressure: 'Presión arterial alta diastólica',
		thesystolicCriticalHighBloodPressure: 'Presión arterial alta crítica sistólica',
		thediastolicCriticalHighBloodPressure: 'Presión arterial alta crítica diastólica',
		thelowOxygenSaturation: 'Baja saturación de oxígeno',
		thecriticalLowOxygenSaturation: 'Saturación de oxígeno baja crítica',
		thehighBloodGlucose: 'glucosa en sangre alta',
		thelungsAir: 'FEV1/PEF',
		stethoscopeMeasurements: 'Mediciones del {value} del estetoscopio',
		heartStetho: 'Corazón',
		lungsStetho: 'Pulmones',
		clickAnyPoint:
			'Haga clic en cualquiera de las grabaciones en la parte inferior para comenzar a reproducir el archivo y ver el gráfico de medición',
		availableRecordings: 'Grabaciones disponibles',
		measurement: 'Medición',
		pause: 'Pausa',
		more: 'Más',
		switchToFrontBack: 'Cambiar a la vista {value}',
		frontView: 'parte delantera',
		backView: 'regresa',
		totalMeasurements: '{value} Medicion/es',
		allRoleCustomizationsDisabled: 'La función de personalización de roles podría estar desactivada para su rol.',
		point: 'Punto',
		selectOneOfThePoints: 'Seleccione uno de los puntos para comenzar a medir!',
		selectAnotherPoint: 'Seleccione otro punto',
		front: 'Delantera',
		bulkView: 'Vista masiva',
		defaultView: 'Vista predeterminada',
		maxEmail: 'El máximo de correos electrónicos que puede cargar es 100',
		missingPatientInfo: 'falta información del paciente',
		conversationFiles: 'Archivos de conversación',
		conversationPhotos: 'Fotos de conversación',
		noPermission: '¡No tienes permiso!',
		sendRequestFailed: 'Enviar solicitud fallida. ¡Inténtalo de nuevo!',
		conferenceFailed: 'Conferencia fallida. ¡Inténtalo de nuevo!',
		requestHasBeenSent: '¡La solicitud ha sido enviada!',
		manageBedside: 'Administrar junto a la cama',
		serialNumber: 'Número de serie',
		roomSignage: 'Señalización de habitaciones',
		respiration: 'Respiración',
		facialSkinAge: 'Edad de la piel del rostro',
		stressIndex: 'Índice de estrés',
		cardiacWorkload: 'Carga de trabajo cardiaca',
		heartAttackRisk: 'Riesgo de ataque al corazón',
		strokeRisk: 'El riesgo de apoplejía',
		extremelyLowOxygen: 'La paciente tiene una saturación de oxígeno extremadamente baja!',
		lowOxygen: 'La paciente tiene una baja saturación de oxígeno!',
		appleWatch: 'Apple Watch',
		selectTimer: 'Seleccionar temporizador',
		snoozeAlert: 'Esta alerta se desactivará durante {value} mientras el paciente recibe ayuda.',
		translationServices: 'Servicios de traducción',
		cardiacOutput: 'Salida cardíaca',
		cardiacIndex: 'Indice cardíaco',
		reloadLatestFeatures: 'Por favor {value} la página para acceder a las últimas funciones y mejoras',
		minuto: 'minuto',
		generateQRCode: 'Generar código QR',
		clickGenerateQRCode: 'Haga clic en Generar código QR para autorizar Vital Kit',
		scanGeneratedQRCode:
			'Código QR generado. Pídale a la enfermera de cabecera que escanee el código QR con el All-in-one Exam Kit.',
		removedAek: 'El All-in-on Exam Kit ha sido retirado de la sesión.',
		spirometerMeasurements: 'Mediciones del espirómetro',
		spirometerTooltip: `Un espirómetro es un dispositivo de diagnóstico que mide la cantidad de aire que puede inhalar y exhalar. También realiza un seguimiento del tiempo que tarda en exhalar por completo después de respirar profundamente. Una prueba de espirometría requiere que respire en un tubo conectado a una máquina llamada espirómetro.`,
		guidePatientSpirometer: 'Guiar al paciente para que coloque el dispositivo correctamente',
		selectedTranslator: 'Traductor seleccionado',
		areYouSureRemoveTranslation: '¿Está seguro de que desea eliminar el servicio de traducción?',
		providerName: 'Nombre del proveedor',
		otoscopeMeasurements: 'Otoscopio {value} Medidas',
		dermatoscopeMeasurements: 'Medidas de la piel con un dermatoscopio',
		darkMode: 'Modo oscuro',
		handWashing: 'Lavarse las manos',
		handsDisinfected: 'manos desinfectadas',
		handsDisinfectedSuccessfully: 'Manos desinfectadas con éxito!',
		lastUpdate: 'Última actualización',
		interpreter: 'Ipanterpreter',
		resourceAssigned: 'Recurso asignado',
		e2ee: 'Llamadas E2EE',
		dialIn: 'Marcar',
		dialOut: 'Marcar Hacia Afuera',
		ptzCam: 'Controles PTZ',
		pTZControlsDescription:
			'Cuando esto está habilitado, los usuarios podrán controlar el PTZ del dispositivo {helloName} y la cámara USB {huddleName} y crear marcadores para posiciones de cámara específicas.',
		faceDownDescription:
			'Cuando esto está activado, los usuarios podrán ser alertados a través de la IA cuando un paciente se gira hacia abajo.',
		aIPrivacyModeDescription:
			'Cuando esto está activado, los usuarios podrán ocultar al paciente y dibujar una figura de palitos encima de él para proteger su privacidad.',
		handWashingDescription:
			'Cuando esto está activado, los administradores del hospital podrán monitorear/rastrear a los proveedores de atención médica a través de la IA para verificar si cumplen con la higiene del hospital.',
		fallDetectionDescription:
			'Cuando esto está activado, los usuarios podrán ser alertados a través de la IA cuando un paciente cae al suelo.',
		patientMobility: 'Movilidad del paciente',
		patientWalkingDescription:
			'Cuando esto está activado, los usuarios podrán ser alertados a través de la IA cuando un paciente tenga riesgo de caer mientras camina.',
		railsDescription:
			'Cuando esto esté habilitado, los usuarios podrán recibir alertas a través de IA cuando un paciente baje una baranda de su cama.',
		inactiveTimeDescription:
			'Cuando esto está habilitado, los usuarios podrán recibir alertas a través de IA cuando un paciente ha estado inactivo durante un período de tiempo especificado.',
		gettingOutOfBedDescription:
			'Cuando esto esté habilitado, los usuarios podrán recibir alertas a través de IA cuando un paciente intente levantarse de la cama.',
		aIVitalSignsDescription:
			'Cuando esto esté habilitado, los usuarios podrán monitorear los signos vitales de los pacientes utilizando Inteligencia Artificial.',
		biobeatVitalSigns: 'Signos vitales biobeat',
		biobeatVitalSignsDescription:
			'A través de esta opción, los usuarios podrán habilitar el monitoreo de signos vitales en tiempo real de Biobeat.',
		smartWatchVitalSigns: 'Signos vitales del reloj inteligente',
		smartWatchVitalSignsDescription:
			'A través de esta opción, los usuarios podrán habilitar el monitoreo de signos vitales en vivo de smartwatch.',
		dialInDescription:
			'Cuando esto esté habilitado, se mostrará la opción para invitar a personas a la llamada a través del número de teléfono.',
		dialOutDescription:
			'Cuando esto esté habilitado, se mostrará la opción para llamar a las personas a través del número de teléfono.',
		inviteViaEmailDescription:
			'Cuando esto esté habilitado, se mostrará la opción de invitar a personas a la llamada por correo electrónico.',
		translationServiceDescription: 'Cuando esto esté habilitado, se mostrarán los servicios de traducción en diferentes idiomas.',
		inviteViaSmsDescription:
			'Cuando esto esté habilitado, se mostrará la opción de invitar a personas a la llamada a través de SMS.',
		meetingUrl: 'URL de la reunión',
		meetingUrlDescription:
			'Cuando esto esté habilitado, se mostrará la opción para invitar a personas a la llamada a través del enlace.',
		roundingNightVisionDescription:
			'Cuando esto está habilitado, aparecerá la opción de ver en el modo de visión nocturna durante la llamada.',
		gridViewDescription:
			'Cuando esto esté habilitado, aparecerá la opción para mostrar a todos los participantes de la llamada como una vista de cuadrícula.',
		fitToScreenDescription:
			'Cuando esto está habilitado, se mostrará la opción para hacer pantalla completa o ajustar a la pantalla durante la llamada.',
		screenSharing: 'Compartir pantalla',
		screenSharingDescription: 'Cuando esto esté habilitado, se mostrará la opción para compartir la pantalla durante la llamada.',
		invitingPeople: 'Invitando a la gente',
		generalFeatures: 'Características generales',
		communicatingWithThePatient: 'Comunicación con el paciente',
		communicatingWithNurses: 'Comunicación con enfermeras',
		automaticAlerts: 'Alertas automáticas',
		cannotBeEmpty: 'No puede estar vacía',
		viewPatientTooltip:
			'Esta opción le permitirá ver y escuchar al paciente; el paciente no podrá verte ni escucharte a menos que lo habilites durante la transmisión.',
		ttpTooltip:
			'Esta opción iniciará una llamada de audio al paciente, ellos podrán escucharte y tú podrás escucharlos. Durante la llamada, puedes encender tu cámara o la del paciente, o incluso compartir tu pantalla.',
		reload: 'Recargar',
		rpmProgram: 'Programa RPM',
		rpmPrograms: 'Programas RPM',
		addRpmProgram: 'Agregar programa RPM',
		babyRoomDeviceBusy: 'No puedes ver al bebé porque {value} está de guardia',
		pleaseWriteProgramName: 'Por favor escriba el programa',
		careTeam: 'Equipo de atención',
		pleaseSelectCareTeam: 'Seleccione los miembros del equipo de atención',
		primaryCarePhysician: 'Medica de atencion primaria',
		specialist: 'Especialista',
		pharmacist: 'Farmacéutica',
		careGiver: 'Cuidadora',
		selectMember: 'Seleccione un miembro',
		survey: 'Encuesta',
		viewSurvey: 'Ver encuesta',
		viewCareTeam: 'Ver equipo de atención',
		score: 'Puntuación',
		heartRateSpo2: 'Ritmo cardíaco y SpO2',
		mod: 'Medio',
		searchByName: 'Buscar pacientes por nombre',
		pleaseSelectRpmProgram: 'Seleccione un programa RPM',
		selectProgram: 'Seleccione un programa',
		pleaseSelectMember: 'Por favor seleccione un {value}',
		pleaseSelectDoctor: 'Por favor seleccione una doctora',
		program: 'Programa',
		createProgram: 'Crear programa',
		deleteProgram: 'Eliminar programa?',
		areYouSureDeleteProgram: '¿Está seguro de que desea eliminar este programa?',
		heartLungsAuscultation: 'Auscultación de corazón y pulmones',
		creatingProgram: 'Crear programa',
		enterDifferentName: 'Ingrese un nombre diferente de los programas predefinidos.',
		programName: 'Nombre del programa',
		measurementsSelection: 'Selección de medidas',
		pickMeasurements: 'Elija las medidas y los dispositivos que desea habilitar para este programa.',
		selectedMeasurements: 'Medidas seleccionadas',
		measurements: 'Mediciones',
		pleaseSelectMeasurement: 'Seleccione al menos una medida.',
		pleaseSelectDevice: 'Seleccione al menos un dispositivo por cada medida seleccionada.',
		editingProgram: 'Programa de edición',
		editProgram: 'Editar programa',
		programExists: '¡Este programa ya existe!',
		maximumDevices: '¡La medición debe contener hasta dos dispositivos!',
		wearables: 'Dispositivos portátiles',
		provider: 'Proveedor',
		wearableId: 'ID del dispositivo',
		wearableType: 'Tipo',
		patchWearableType: 'Parche',
		watchWearableType: 'Reloj',
		inactive: 'Inactivo',
		viewFullData: 'Ver datos completos',
		showEcg: 'Mostrar ECG',
		monitor: 'Monitorear',
		aspDescription:
			'Alexa Smart Properties para el cuidado de la salud hace que sea fácil y rentable para hospitales y proveedores cuidar a sus pacientes. Alexa Smart Properties simplifica la implementación y administración de dispositivos habilitados para Alexa a escala y permite que las propiedades habiliten las habilidades de Alexa elegibles para HIPAA, conectando a los proveedores de atención con los pacientes a través de experiencias de voz.',
		alexaSmartProperties: 'Propiedades inteligentes de Alexa',
		alexaSmartPropertiesActivated: 'Propiedades inteligentes de Alexa activadas',
		aspFailed: 'No se pudo activar ASP, inténtelo de nuevo más tarde',
		nutritionist: 'Nutricionista',
		providerError: 'Por favor, selecciona un proveedor.',
		wearableTypeError: 'Por favor, selecciona el tipo de dispositivo.',
		wearableIdEmpty: 'Por favor ingrese el ID del dispositivo.',
		invalidWearableId: 'Por favor ingrese una identificación válida.',
		patchIdEmpty: 'El ID del parche no puede estar vacío.',
		patchIdAlreadyExists: 'El ID del parche ya existe',
		baseURL: 'URL base',
		addBaseUrl: 'Agregar URL base',
		addApiKey: 'Agregar clave API',
		eCareManager: 'Gerente de atención electrónica',
		secretKey: 'Llave secreta',
		nodeId: 'Identificación del nodo',
		nodeLevel: 'Nivel de nodo',
		carelogs: 'Registros de atención',
		carelogDetails: 'Detalles del registro de atención',
		visitInfo: 'Información de la visita',
		patientInfo: 'Información del paciente',
		endVisit: 'Visita final',
		careMemberType: 'Tipo de miembro de atención',
		liveMeasurements: 'Mediciones en vivo',
		medicalRecordNumber: 'Numero de historia CLINICA',
		patientsCareLogs: `Registros de cuidados de {value}`,
		possessiveSessions: 'Sesiones de {value}',
		at: 'en {value}',
		removePosition: 'Quitar Posición',
		removeLicense: 'Eliminar licencia',
		removeEducation: 'Quitar Educación',
		ewsScore: 'EWS Puntuación',
		lowRisk: 'Bajo riesgo',
		ewsSettings: 'Configuración de EWS',
		removeOption: 'Quitar opción',
		removeQuestion: 'Quitar pregunta',
		display: 'Mostrar',
		theme: 'Tema',
		defaultTheme: 'Tema Predeterminado',
		light: 'Claro',
		dark: 'Oscuro',
		question: 'Pregunta',
		option: 'Opción',
		addNewSurveyDescription: 'Aún no se han añadido encuestas.',
		addNewSurvey: 'Agregar nueva encuesta',
		addNewQuestion: 'Agregar pregunta',
		addMoreOptions: 'Agregar más opciones',
		atLeastTwoOptions: 'Seleccione al menos dos opciones por pregunta',
		valuesCannotBeEmpty: 'Los valores no pueden estar vacíos',
		options: 'Opciones',
		patchNotExist: 'Este parche no existe',
		telemetry: 'Telemetría',
		deleteFileForMyself: 'Eliminar archivo para mí',
		deleteFileWarning: '¿Estás segura de que quieres eliminar este archivo?',
		conferenceEndedParticipantInviteDenied:
			'No tienes suficientes permisos para llamar a este participante. Póngase en contacto con su administrador.',
		participantInviteDenied: 'No tienes suficientes permisos para llamar a {value}. Póngase en contacto con su administrador.',
		switchingToOtherTab:
			'Esta sesión de monitoreo finalizará y las ventanas se cerrarán al abandonar esta página. Estás seguro de que quieres salir?',
		key: 'Clave',
		selectConfiguration: 'Seleccionar Configuración',
		selectConfigurationDesc: 'Selecciona la configuración que deseas aplicar',
		nodeName: 'Nombre del Nodo',
		scope: 'Ámbito',
		issuer: 'Emisor',
		returnUrl: 'URL de retorno',
		oauthScopes: 'Ámbitos de OAuth',
		dictate: 'Dictar',
		write: 'Escribir',
		statAlarmInCSV: 'Alarma de urgencia',
		statAlarm: 'Alarma Estatutaria',
		statAlarmDescription:
			'Cuando está habilitado, los usuarios verán un ícono adicional dentro de cada fuente de monitoreo y, al alternar, se reproducirá una alarma en el lado del paciente.',
		statAlarms: 'Alarmas de Estadísticas',
		activationTime: 'Tiempo de Activación',
		deactivationTime: 'Tiempo de Desactivación',
		editRoomNameRounding: 'Editar nombre de la habitación',
		editRoomNameMonitoring: 'Editar nombre de la habitación',
		editRoomNameRoundingDescription:
			'Cuando esto esté habilitado, los usuarios podrán editar los nombres de las habitaciones para redondear',
		editRoomNameMonitoringDescription:
			'Cuando esto está habilitado, los usuarios podrán editar los nombres de las habitaciones para el Monitoreo',
		enableStatAlarm: 'Habilitar Alarma Estática',
		disableStatAlarm: 'Deshabilitar Alarma Estática',
		alexaEnabled: 'Alexa',
		enableAlexa: 'Habilitar Alexa para esta empresa',
		enableAlexaConfiguration: 'Al habilitar esta función, los hospitales podrán usar Alexa en sus dispositivos {helloName}.',
		pleaseSetPassword: 'Establezca una contraseña.',
		errorStatAlarm: 'Se produjo un error al alternar la alarma estática de {value}. ¡Por favor, inténtelo de nuevo!',
		shippingAddress: 'Dirección de envío',
		requestedBy: 'Solicitado por',
		shipped: 'Enviada',
		logistics: 'Logística',
		patientProfile: 'Perfil del paciente',
		dashboard: 'Panel de control',
		totalNumberOfCalls: 'Número total de llamadas',
		sessionByRole: '{value} Sesiones por Rol',
		totalNumberSessions: 'Número total de sesiones',
		averageSessionDuration: 'Duración promedio de la sesión',
		totalDuration: 'Duración Total',
		durationInSeconds: 'Duración en segundos',
		sessionsByHour: 'Sesiones por Hora',
		session: 'Sesión',
		sessions: 'Sesiones',
		ambient: 'Ambiente',
		monitoringSessions: 'Sesiones de seguimiento',
		totalSessionsDuration: 'Duración total de las sesiones',
		outgoingSessionsDescription: 'Sesiones de Hablar con el Paciente Iniciadas por el Equipo de Atención',
		incomingSessionsDescription: 'Sesiones de Hablar con el Paciente Iniciadas por los Pacientes',
		viewPatientSessionsDescription: 'Ver sesiones de pacientes iniciadas por el equipo de atención',
		familyVisits: 'Visitas familiares',
		avgSessions: 'Sesiones promedio por hora',
		highestVolumeOfSessions: 'Mayor volumen de sesiones',
		totalSessions: 'Sesiones Totales',
		sessionByType: 'Sesiones por tipo',
		externalIdentityProviders: 'Proveedores de Identidad Externos',
		externalIdentityProviderMembers: 'Miembros del Proveedor de Identidad Externo',
		selectNurse: 'Seleccionar enfermera',
		noMembersAdded:
			'Aún no se ha agregado ningún {value}. Para comenzar a agregar {value}, simplemente haga clic en el botón de arriba. Puedes sumar hasta {value2} {value}.',
		doctors: 'Doctores',
		atLeastOneMember: 'Por favor seleccione al menos una miembro!',
		addAdditionalMember:
			'Puede agregar {value} adicionales además de los que ya están incluidos en el programa. Para comenzar a agregar {value}, simplemente haga clic en el botón de arriba. Puedes sumar hasta {value2} {value}.',
		editRpmProgram: 'Editar programa RPM',
		levelOfConsciousness: 'Nivel de consciencia',
		supplementOxygen: 'Suplemento de oxígeno',
		phoneNotReachable: 'El número de teléfono no es accesible',
		sipNotAvailable: 'La marcación telefónica no está disponible en este momento.',
		integrations: 'Integraciones',
		eCareApiKey: 'Clave de API de e-care',
		eCareBaseUrl: 'URL Base de e-care',
		deviceWiFiTryingToConnect: 'Intentando conectar {value}',
		deviceWiFiScanFailed: 'El escaneo {value} falló. Espera un poco y vuelve a intentarlo.',
		diviceWiFiScanNotFound: '{value} no encontrado, inténtelo de nuevo.',
		deviceWiFiConnectionFailed: '{value} conexión fallida. Inténtalo de nuevo.',
		deviceWiFiDisconnected: 'Desconectado {value}. Inténtalo de nuevo.',
		length: 'Longitud',
		viewAllProviders: 'Ver todos los proveedores',
		totalNumberOfProviders: 'Número total de proveedores',
		lastSessionDate: 'Fecha de la última Sesión',
		noProvidersFound: 'No hay proveedores con sesiones completadas en la lista.',
		sessionStart: 'Inicio de sesión',
		sessionEnd: 'Final de sesión',
		sessionStatus: 'Estado de la sesión',
		sessionDetails: 'Detalles de la sesión',
		callStart: 'Inicio de llamada',
		callEnd: 'Fin de llamada',
		allowMicPermissions:
			'hellocare necesita acceso a su micrófono para que los pacientes puedan escucharlo. Permita el acceso al micrófono en la configuración del navegador web.',
		turnOnDictation: 'Activar dictado',
		turnOffDictation: 'Desactivar dictado',
		somethingWentWrongSpecific: '{value}. ¡Algo salió mal!',
		unitCannotBeEmpty: 'La unidad no puede estar vacía',
		bedCannotBeEmpty: 'La cama no puede estar vacía',
		total: 'Total',
		reason: 'Razón',
		subjectIdentification: 'Identificación del sujeto',
		employeeDirection: 'Dirección del empleado',
		handHygieneAudits: 'Auditorías de higiene de manos',
		handHygieneViewed: 'Higiene de manos visto',
		exportExcel: 'Exportar Excel',
		exportExcelBackground: 'Exportar Excel en segundo plano',
		complianceReport: 'Informe de Cumplimiento de Higiene',
		screenShareNotAllowed: 'Alguien más está compartiendo su pantalla.',
		screenSharingNotAllowed: 'No puedes compartir tu pantalla mientras otra persona la está compartiendo.',
		ptzCamSettingsTitle: '20x PTZ Configuración de la Cámara',
		reverseCamera: 'Cámara trasera',
		allowPermissions: 'Permita los permisos para la cámara y el micrófono para llamar al paciente.',
		guest: 'Invitado',
		selectSurvey: 'Seleccionar tipo de encuesta',
		generic: 'General',
		safetyRange: 'Rango de seguridad',
		createSurvey: 'Crear encuesta',
		addQuestionsDescription: 'Puede comenzar a agregar preguntas haciendo clic en el botón de arriba.',
		typeOfSurvey: '{value} encuesta',
		editSurvey: 'Editar encuesta',
		surveyType: 'Tipo de encuesta',
		surveys: 'Encuestas',
		scheduledDate: 'Cita agendada',
		questions: 'Preguntas',
		rapidRequestType: 'Rápida',
		admissionRequestType: 'Admisión',
		regularRequestType: 'Regular',
		failedToGetParticipantInfo: 'No se pudo obtener la información del participante. Inténtalo de nuevo.',
		failedToGetYourInfo: 'No se pudo obtener su información. Inténtalo de nuevo.',
		failedToGetparticipantInfoName: 'No se pudo obtener la información de {value}. Inténtalo de nuevo.',
		failedToGetInitiatorInfo: 'No se pudo obtener la información del iniciador. Inténtalo de nuevo.',
		ascAlphaOrder: '(A-Z)',
		descAlphaOrder: '(Z-A)',
		ascNumOrder: '(0-9)',
		descNumOrder: '(9-0)',
		queueManagement: 'Gestión de colas',
		rapidRequestTypeCalls: 'Llamadas rápidas',
		admissionRequestTypeCalls: 'Llamadas de Admisión',
		regularRequestTypeCalls: 'Llamadas Regulares',
		manageQueue: 'Administrar cola',
		viewQueue: 'Ver cola',
		doctorsQueue: ` cola`,
		transfer: 'Transferir',
		transferPatientsToDoctor: 'Transferir pacientes al doctor',
		noAvailableDoctor: '¡No hay médico disponible en este hospital!',
		successfullyTransferPatient: 'Transferencia exitosa',
		requestSuccessfullyTransferPatient: '¡Su transferencia de paciente ha sido enviada con éxito!',
		incomingPatientRequest: '¡Solicitud de paciente entrante!',
		changeRpmProgram: 'Cambiar RPM',
		reasonToConnect: 'Motivo para Conectar',
		acuity: 'Agudeza',
		clinicalConcern: 'Preocupación Clínica',
		nurseInfo: 'Información de la Enfermera',
		clinicalContact: 'Contacto Clínico',
		isNurseClinicalContact: '¿Es un Contacto Clínico de Enfermería?',
		rapidResponse: 'Respuesta Rápida',
		edAdmitDiscussion: 'Discusión de Ingreso en Urgencias',
		drugOrderOrClarification: 'Pedido de Medicamento / Aclaración',
		abnormalVital: 'Signos Vitales Anormales',
		abnormalLab: 'Laboratorio Anormal',
		criticalLab: 'Laboratorio Crítico',
		patientDeclining: 'Paciente en Declive',
		otherEmergent: 'Otro Emergente',
		reviewOutsideFacilityTransfer: 'Revisión de Transferencia Fuera de la Instalación',
		drnOrDniClarification: 'Aclaración de DRN o DNI',
		otherNonEmergent: 'Otro No Emergente',
		admitIcu: 'Ingreso a la UCI',
		admitNonIcu: 'Ingreso fuera de la UCI',
		admitBackup: 'Ingreso de Respaldo',
		immediate: 'Inmediato',
		ext: 'Extensión',
		callPhoneNumber: 'Llamar al Número de Teléfono',
		lifeSignals: 'Señales de vida',
		lifeSignalDescription:
			'Cuando esto está habilitado, el dispositivo Lifesignal Patch estará disponible para usar durante el monitoreo',
		unableToGetInfo: 'No se pudo obtener la información de {value}.',
		unableToGetInfoGeneralMessage: 'No se puede obtener la información del participante.',
		callNumber: 'Llamar',
		assignWearable: 'Asignar dispositivo',
		assignWearableTooltip: 'Asignar dispositivo',
		wearableHistoryTooltip: 'Historial',
		unassignWearable: '¿Está seguro de que desea desasignar este dispositivo a este paciente?',
		unassignWearableTooltip: 'Desasignar dispositivo',
		roleCustomization: 'Personalización de roles',
		monitoringPrecautionsDescription:
			'Cuando esto está habilitado, {value} podrá ver la leyenda de precauciones durante el monitoreo.',
		poolingFlowDescription: 'Cuando esto está habilitado, el {value} podrá cambiar el flujo de agrupación.',
		conversationHistoryDescription: 'Cuando esto está habilitado, {value} podrá ver el historial de conversaciones.',
		notificationsDescription: 'Cuando esto esté habilitado, {value} podrá ver las notificaciones.',
		savedSessionsDescription: 'Cuando esto está habilitado, el {value} podrá guardar la vista de las sesiones.',
		conversationFilesDescription:
			'Cuando esto está habilitado, {value} podrá compartir archivos durante el chat con otros usuarios.',
		conversationPhotosDescription:
			'Cuando esto está habilitado, {value} podrá compartir fotos durante el chat con otros usuarios.',
		homePageDescription:
			'Cuando esto está habilitado, {value} verá una lista de los pacientes que están esperando para unirse a la llamada.',
		waitingRoomDescription:
			'Cuando esto está habilitado, {value} verá una lista de los pacientes que están esperando para unirse a la llamada.',
		aiNotificationsDescription:
			'Cuando esto esté habilitado, el usuario podrá ver las Notificaciones de Alerta de AI de Rounding.',
		patientsDescription: 'Cuando esto está habilitado, {value} verá una lista de los pacientes que le han sido asignados.',
		apppointmentsDescription: `Cuando esto esté habilitado, {value} tendrá la opción de establecer la disponibilidad y crear citas para los pacientes.`,
		doctorRegistrationForm: 'Formulario de Registro',
		registrationFormPhotosDescription:
			'Cuando se habilita la bandera de función, se requerirá que los médicos completen un formulario de registro para continuar con su rol.',
		roundingDescription: `Cuando esto está habilitado, {value} podrá iniciar rondas con pacientes.`,
		rpmDescription: 'Cuando esto está habilitado, {value} podrá iniciar programas de RPM para pacientes.',
		chatDescription: 'Cuando esto esté habilitado, {value} podrá iniciar el chat con otros usuarios.',
		teamsDescription: 'Cuando esto esté habilitado, {value} podrá iniciar el chat con otros usuarios.',
		monitoringDescription: 'Cuando esto está habilitado, {value} podrá iniciar sesiones de monitoreo.',
		visitorsDescription: 'Cuando esto está habilitado, {value} podrá ver una lista de los visitantes de la sala específica.',
		dashboardDescription:
			'Cuando esto está habilitado, {value} podrá ver datos en tiempo real para el Sistema de Salud asignado.',
		auditLogsDescription: 'Cuando esto está habilitado, el usuario podrá ver los registros para un sistema de salud específico.',
		queueManagementDescription: 'Cuando esto está habilitado, {value} podrá gestionar la cola de los médicos.',
		organizationDescription:
			'Cuando esto está habilitado, {value} podrá agregar nuevos hospitales, departamentos, plantas y habitaciones.',
		callLogsDescription: 'Cuando esto está habilitado, {value} podrá ver llamadas pasadas y en curso.',
		userManagementDescription: 'Cuando esto está habilitado, {value} podrá ver miembros y miembros invitados.',
		configurationsDescription: 'Cuando esto está habilitado, {value} podrá configurar dispositivos, redes y eventos de atención.',
		noDataToDownload: 'No hay datos para descargar',
		noAssignedWearable:
			'No hay ningún dispositivo asignado a este paciente. Para asignar un dispositivo, haga clic en el botón "+" a la derecha.',
		enableMultipleFeedAudioDescription:
			'Cuando esto está habilitado, los usuarios podrán habilitar el audio al monitorear múltiples fuentes.',
		patientInformation: 'Información del Paciente',
		voiceOver: 'Voz en off',
		telehealthDescription:
			'Una solución de TV integrada permite a las personas hospitalizadas disfrutar de películas y contenidos de entretenimiento, mejorando su estancia sin necesidad de movilidad física.',
		evideoDescription:
			'eVideon es una plataforma integral de experiencia del paciente diseñada para mejorar la participación y la comunicación en los centros de atención médica. Ofrece soluciones multimedia interactivas que brindan educación personalizada, entretenimiento y herramientas de comunicación para los pacientes, mejorando su satisfacción y la experiencia hospitalaria general.',
		pcareDescription: `Con la plataforma de participación del paciente de pCare, los pacientes tienen acceso a herramientas interactivas de educación, entretenimiento y comunicación. Empodera a los pacientes brindándoles información y recursos de salud personalizados, reduciendo la ansiedad y fomentando una mayor participación en su propia atención.`,
		getwellDescription: `La plataforma de comunicación y participación del paciente de Getwell facilita una conexión perfecta entre los pacientes y sus proveedores de atención médica. Ofrece una gama de herramientas para la educación, la comunicación y la coordinación de la atención, lo que permite a los pacientes acceder a información de salud personalizada, interactuar con su equipo de atención y participar activamente en sus planes de tratamiento.`,
		sonifiDescription:
			'Mejore la experiencia del paciente con la solución integrada de SONIFI Health, fusionando comunicación, educación y entretenimiento a la perfección. Mejore la prestación de atención permitiendo la participación sin esfuerzo del paciente dentro de las habitaciones del hospital.',
		patientExperience: 'Experiencia del paciente',
		rtls: 'RTLS',
		rtlsDescription: 'Cuando esto esté habilitado, los pacientes verán que la enfermera está en la habitación.',
		roomModes: 'Modos de Habitación',
		helloCareDescription: `Cuando esto está habilitado, la pantalla mostrará información como la fecha, el nombre de la habitación y el nombre del paciente.`,
		nicuDescription: 'Cuando esto esté habilitado, la pantalla mostrará una vista de la habitación del bebé.',
		backgroundAI: 'Fondo de IA',
		customizeRoom: 'Personalizar Habitación',
		roomTypes: 'Tipos de Habitaciones',
		babyRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Bebés como tipo de habitación a nivel de habitación.',
		frontDeskRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Recepción como tipo de habitación a nivel de habitación.',
		doctorRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación del Doctor como tipo de habitación a nivel de habitación.',
		laboratoryRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Laboratorio como tipo de habitación a nivel de habitación.',
		injectionRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Inyecciones como tipo de habitación a nivel de habitación.',
		infusionRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Infusiones como tipo de habitación a nivel de habitación.',
		digitalClinicRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Clínica Digital como tipo de habitación a nivel de habitación.',
		pharmacyRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Farmacia como tipo de habitación a nivel de habitación.',
		radiologyRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Radiología como tipo de habitación a nivel de habitación.',
		kioskRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Quiosco como tipo de habitación a nivel de habitación.',
		triageRoomTypeDescription:
			'Cuando esto esté habilitado, podrás establecer la Habitación de Triaje como tipo de habitación a nivel de habitación.',
		intakeForm: 'Formulario de Admisión',
		intakeFormDescription: 'Cuando esto esté habilitado, los proveedores podrán recopilar información del paciente.',
		doctorPoolingDescription:
			'Cuando esto esté habilitado, la pantalla mostrará la lista de los médicos asignados a ese hospital.',
		scpHealthForm: 'Formulario de salud de SCP',
		baycareForm: 'Formulario de cuidado de la bahía',
		aiWidgetDescription:
			'Cuando esto está habilitado, la pantalla mostrará widgets como signos vitales, próximas citas, llamar a un cuidador, signos vitales de IA y fisioterapia.',
		widgetDescription:
			'Cuando esto está habilitado, la pantalla mostrará widgets como signos vitales, próximas citas, Llamar a un cuidador, Educación, Recetas de hoy.',
		digitalClinicDescription: `Cuando esto está habilitado, la pantalla mostrará información como la fecha, el nombre de la habitación, el nombre del paciente y las últimas mediciones del paciente.`,
		telemedicineDescription:
			'Cuando esto esté habilitado, la pantalla mostrará la lista de médicos disponibles y el tipo de llamadas que los usuarios pueden iniciar.',
		hospitalDescription:
			'Cuando esto está habilitado, la pantalla mostrará widgets como lista de familiares y amigos, aplicaciones, llamar a un cuidador, educación y recetas de hoy.',
		virtualExaminationDescription:
			'Cuando esto está habilitado, la pantalla mostrará la lista de médicos disponibles a los que el usuario puede iniciar llamadas.',
		simplifiedDescription: `Cuando esto está habilitado, la pantalla mostrará información como la fecha, el nombre de la habitación y el nombre del paciente.`,
		eVideonDescription:
			'Cuando esto está habilitado, la pantalla mostrará soluciones interactivas de atención al paciente que atraen, educan y entretienen a los pacientes y visitantes, al tiempo que optimizan los flujos de trabajo para el personal.',
		patientExperienceDescription:
			'Cuando esto está habilitado, la pantalla mostrará soluciones interactivas de atención al paciente que atraen, educan y entretienen a los pacientes y visitantes, al tiempo que optimizan los flujos de trabajo para el personal.',
		mdmDescription:
			'La plataforma de experiencia del paciente MDM eleva la participación del paciente durante todo el proceso de atención.',
		azureAd: 'Azure AD',
		duo: 'Duo',
		pingFederate: 'Ping Federate',
		okta: 'OKTA',
		hillRom: 'Hill-Rom',
		epic: 'Epic',
		cerner: 'Cerner',
		telehealth: 'Telehealth',
		evideon: 'eVideon',
		pcare: 'pCare',
		getwell: 'GetWell',
		sonifi: 'Sonifi',
		mdm: 'MDM',
		globo: 'Globo',
		amn: 'AMN',
		addUnitPhoneNumber: 'Agregar número de teléfono de la unidad',
		unitPhoneNumber: 'Número de teléfono de la unidad',
		noIntegrationTypesAvailable: 'No hay tipos de integración disponibles',
		pleaseSetupIntegrationType:
			'Primero configure un tipo de integración. Comuníquese con su proveedor de servicios para obtener ayuda.',
		errorVoiceOver: 'Hubo un error al cambiar la alerta de "Mantenerse quieto" de {value}. ¡Por favor, inténtalo nuevamente!',
		enableStayStill: 'Habilitar alerta "Mantenerse quieto"',
		disableStayStill: 'Deshabilitar alerta "Mantenerse quieto"',
		stoppedAlarmPopup: 'La alarma se detuvo debido a la respuesta de la enfermera {value}',
		activeAlarmTriggered: 'Alarma activa activada por',
		statAlarmStarted: 'Alarma estática iniciada',
		statAlarmStopped: 'Alarma estática detenida',
		complete: 'Completa',
		hasStoppedStatAlarm: 'detenido la alarma',
		voiceOverAlarmStopped: 'Alerta manual desactivada',
		voiceOverAlarmStarted: 'Alerta manual activada',
		addTranslatorInCall: 'Agregar un traductor a la llamada',
		whiteboard: 'Pizarra Blanca',
		whiteboardType: 'Tipo de pizarra',
		stoppedAlertPopup: 'Alerta manual detenida debido a respuesta de enfermera {value}',
		selectAlarm: 'Seleccionar alarma',
		availableAlarms: 'Alarmas disponibles',
		selectOtherAlarm: 'Seleccione otra alarma',
		alarm: 'Alarma',
		alarmCount: 'Alarma {value}',
		scheduled: 'Programada',
		repeat: 'Repetir',
		weekly: 'Semanalmente',
		monthly: 'Mensual',
		selectSchedule: 'Seleccionar tipo de horario',
		ivBagFluidLevel: 'Nivel de Fluido en la Bolsa de IV',
		admissions: 'Admisiones',
		discharges: 'Altas y Bajas',
		admissionsAndDischarges: 'Admisiones y Altas y Bajas',
		avgAdmissionsPerHour: 'Admisiones Promedio por Hora',
		avgDischargesPerHour: 'Altas Promedio por Hora',
		highestAdmissionsPerHour: 'Máximo de Admisiones por Hora',
		highestDischargesPerHour: 'Máximo de Altas por Hora',
		ivBagDescription:
			'Cuando esto esté habilitado, los usuarios podrán ver actualizaciones en tiempo real del nivel de líquido de la bolsa IV y recibirán una alerta cuando el nivel sea bajo.',
		monitoringHealthDataDescription:
			'Cuando esto está habilitado, la pestaña "Datos de Salud" bajo Alertas en el monitoreo debe mostrarse.',
		ivBagCheck: 'El nivel de fluido en la bolsa IV está bajo. Por favor, revise la IV.',
		warningAlertAt: 'Alerta de advertencia al',
		enableIvBagWarning: 'Para habilitar esta opción, primero debes proporcionar un valor de advertencia.',
		patientEngagement: 'Compromiso del paciente',
		nurseCallsSystems: 'Sistemas de Llamadas de Enfermería',
		configurationTypeRequired: 'Se requiere el tipo de configuración',
		externalTypeDescription:
			'When this is enabled, will be able as a global authentication authority that allows customers, employees, and partners to securely access all the applications they need from any device.',
		ehrDescription:
			'Cuando esto esté habilitado, los usuarios podrán acceder a un registro de paciente unificado para una mejor toma de decisiones clínicas y una mejor atención al paciente.',
		translationTypeDescription:
			'Cuando esto esté habilitado, los usuarios podrán traducir sin problemas el contenido y la comunicación a varios idiomas.',
		doctorQueue: 'Cola del Doctor',
		lowIvBagFluidLevel: 'Nivel Bajo de Líquido en la Bolsa de IV',
		bedIdentifier: 'Identificador de Cama',
		enterEmailAddress: 'Introducir la dirección de electrónico',
		addDomain: 'Agregar Dominio',
		enterEmailDomain: 'Ingrese el Dominio de Correo Electrónico',
		familyMemberNotAssigned: 'Aún no estás asignado a ningún paciente!',
		dependants: 'Dependientes',
		cannotBeFamilyMember: 'El paciente no puede ser un miembro de su familia por sí mismo.',
		patientGettingOutOfChair: 'Detección de levantarse de la silla',
		personGettingOutOfChairMessage: 'El paciente se está levantando de la silla',
		sessionsQueue: 'Cola de Sesiones',
		sessionsQueueDescription:
			'Cuando esto está habilitado, el médico podrá ver una cola de sesiones a la que puede unirse o rechazar.',
		patientIsInactiveToastMessage:
			'Es hora de girar al paciente hacia el otro lado. Por favor, notifique a la enfermera de cabecera.',
		forwardToNursesBtn: 'Reenviar a las enfermeras',
		patientInactiveInformation: 'Es hora de girar al paciente hacia el otro lado.',
		careEventsForNursesDesc:
			'Las intervenciones del paciente, como Admisión, Consulta, Tratamiento, Alta, Alarma Estadística y Modo de Privacidad realizadas por los VCPs están registradas.',
		measuringIv: 'Medición del nivel de líquido IV...',
		digitalBackground: 'Fondo digital',
		selectPreferences: 'Seleccionar preferencias',
		backgroundImage: 'Imagen de fondo',
		backgroundImagesAllowed:
			'Proporcione opciones de imagen para que los usuarios seleccionen efectos de fondo durante las videollamadas.',
		presentImage: 'Presentar imágenes',
		addImage: 'Agregar imagen',
		blurryBackgroundDefault: 'Establecer el fondo borroso como predeterminado',
		blurryBackground: 'Fondo Borroso',
		enabled: 'Habilitado',
		notEnabled: 'No habilitado',
		viewMedications: 'Ver medicamentos',
		todaysMedication: 'La medicación de hoy',
		patientSkippedMedication: 'La paciente se ha saltado la medicación',
		notifyPatientForMedication: 'Notificar al paciente que tome su medicación.',
		featureChanged: 'Característica Cambiada',
		variant: 'Variante',
		changedForRole: 'Cambiado para Rol',
		skipped: 'Saltada',
		taken: 'Tomada',
		displayCaregiverName: 'Mostrar Nombre del Cuidador',
		displayCaregiversNameDescription:
			'Cuando esta función está habilitada, el paciente podrá ver el nombre completo (por ejemplo, Linda Andreson) o el primer nombre y la primera letra del apellido (por ejemplo, Linda A.) del cuidador que se muestra durante la llamada entre el paciente y el cuidador. En casos en que esta función esté deshabilitada, el paciente no podrá ver ni el nombre ni ninguna letra de los cuidadores.',
		sessionMaintenance: 'Esta sesión fue finalizada por mantenimiento.',
		exceededCharactersLimit:
			'Ha excedido el número máximo de caracteres permitidos. Por favor, ajústelos o elimine las notas en exceso.',
		helloCareEngagement: 'Compromiso de Hellocare',
		homeWidget: 'Inicio',
		whiteboardURL: 'Whiteboard URL',
		engagementWhiteboardURLDescription:
			'Ingrese la URL de la pizarra para acceder a la funcionalidad de la pizarra digital hellocare',
		home: 'Inicio',
		engagementHomeDescription:
			'Centro de Comunicación e Información para Pacientes: Un centro central que facilita la comunicación con los pacientes y proporciona actualizaciones esenciales. Incluye una pizarra interactiva de uso versátil.',
		educationWidget: 'Educación',
		engagementEducationDescription:
			'Recursos Educativos de Salud: Un repositorio de videos educativos relacionados con la atención médica que mejoran el conocimiento y la participación del paciente.',
		liveTvWidget: 'Televisión en Vivo',
		engagementLiveTVDescription:
			'Canales de Entretenimiento e Información: Una selección de diversos canales de televisión de diferentes géneros, asegurando que los pacientes tengan acceso a opciones de entretenimiento e información.',
		moviesWidget: 'Películas',
		engagementMoviesDescription:
			'Biblioteca de Películas: Un catálogo de películas que cubren varios géneros, ofreciendo entretenimiento bajo demanda.',
		musicWidget: 'Música',
		engagementMusicDescription:
			'Estaciones de Radio de Música: Acceso a estaciones de radio de música categorizadas por género, para la relajación y disfrute de los pacientes.',
		moodsWidget: 'Mood (Estado de Ánimo)',
		engagementMoodsDescription:
			'Videos de Relajación: Una colección de videos que mejoran el estado de ánimo, incluyendo escenas como chimeneas y playas, para promover la comodidad y el bienestar de los pacientes.',
		appsWidget: 'Aplicaciones',
		engagementAppsDescription:
			'Aplicaciones de Entretenimiento: Integración con aplicaciones de entretenimiento populares como Netflix, Amazon Prime y YouTube Music, ampliando las opciones de entretenimiento para los pacientes.',
		familyAndFriendsWidget: 'Familia y Amigos',
		engagementFamilyFriendsDescription:
			'Conectividad con Familia y Amigos: Una función que permite a los pacientes mantenerse conectados con sus seres queridos a través de videollamadas, promoviendo el apoyo emocional.',
		orderMealsWidget: 'Ordenar Comidas',
		engagementOrderMealDescription:
			'Comodidad al Ordenar Comidas: Una opción conveniente para que los pacientes ordenen comidas según sus preferencias, mejorando su experiencia de comer.',
		whiteBoardWidget: 'Pizarra Interactiva',
		engagementWhiteboardDescription:
			'Centro de Información Interactivo: Una pizarra multifuncional que sirve como centro de información interactivo, permitiendo notas, visualización de contenido e integración de video.',
		careTeamWidget: 'Equipo de Cuidados',
		engagementCareTeamDescription:
			'Acceso al Equipo de Cuidados: Los pacientes pueden acceder fácilmente y comunicarse con los miembros de su equipo de cuidados dedicado, simplificando la coordinación de la atención.',
		healthDataWidget: 'Datos de Salud',
		engagementHealthDataDescription:
			'Monitoreo de Salud e Información: Acceso a datos de salud de varios dispositivos de monitoreo e IoT, proporcionando una vista completa de la salud del paciente.',
		sessionAborted: 'La sesión ha sido abortada. Por favor, inténtelo de nuevo.',
		sessionDisrupted: 'La sesión ha sido interrumpida. Por favor, inténtelo de nuevo.',
		perfect: 'Perfecto',
		good: 'Bueno',
		bad: 'Malo',
		mediocre: 'Mediocre',
		noCareEvents: 'No hay eventos de cuidado disponibles.',
		askAdministratorForCareEvents: 'Por favor, pida a su administrador que agregue eventos de cuidado.',
		providerCamera: 'Desactivar Video',
		providerMic: 'Micrófono del proveedor',
		providerMicDesc: 'Al habilitar esto, el micrófono del proveedor estará encendido al comenzar la llamada.',
		patientCamera: 'Cámara del Paciente',
		patientMicrophone: 'Micrófono del Paciente',
		patientMicrophoneDesc: 'Al habilitar esto, el micrófono del paciente estará encendido al comenzar la llamada.',
		disableProviderCameraDesc: 'Al habilitar esto, la cámara del proveedor estará encendida al comenzar la llamada.',
		patientMicDesc: 'Al habilitar esto, el micrófono del paciente estará encendido al comenzar la llamada.',
		patientCameraDesc: 'Al habilitar esto, la cámara del paciente estará encendida al comenzar la llamada.',
		enableLiveCaptions: 'Activar subtítulos en vivo',
		disableLiveCaptions: 'Desactivar subtítulos en vivo',
		closedCaptions: 'Subtítulos',
		liveCaptionsDescription:
			'Cuando esto esté habilitado, la {value} podrá ver los subtítulos en vivo del participante que está hablando.',
		participantRemovedByAdministrator: 'Un administrador lo ha eliminado de esta conferencia.',
		wantToTurnOnFallPrevention: '¿Desea activar la prevención de caídas con IA en el monitoreo?',
		continueNoAi: 'No, continuar sin IA',
		aiMonitoringOn: 'Activar el monitoreo con IA',
		aiFallPrevention: 'Prevención de caídas con IA',
		helloBackground: 'Fondo de Saludo',
		setHelloBackground: 'Seleccione una imagen que aparecerá en la vista del paciente antes de que comience la sesión.',
		helloWallpaper: 'Fondo de Pantalla Hello',
		setHelloWallpaper: 'Seleccione una imagen que aparecerá detrás de los íconos en la pantalla de la aplicación.',
		addCareEvent: 'Agregar evento de cuidado',
		nameOrEmail: 'Nombre o Correo Electrónico',
		careEventHistory: 'Historial de eventos de cuidado',
		talkToPatientSettings: 'Configuración de Hablar con el Paciente',
		talkToPatientSettingsDesc:
			'Utilice las opciones a continuación para especificar lo que verá y escuchará el proveedor y el paciente al realizar una llamada hacia o desde el dispositivo hello.',
		callSettingsForRoomSignDesc:
			'Use las opciones a continuación para especificar lo que el proveedor y el paciente verán y escucharán al hacer o recibir una llamada desde la Señal de Habitación.',
		viewPatientSettings: 'Ver Configuración del Paciente',
		viewPatientSettingsDesc: 'Utilice esta opción para mostrar u ocultar la opción Ver Paciente en el lado del proveedor.',
		videoMonitoring: 'Monitoreo de video',
		ambientMonitoring: 'Monitoreo ambiental',
		ambientAndVideoMonitoring: 'Monitoreo ambiental y de video',
		ambientMonitoringDescription: 'Cuando esto esté habilitado, el proveedor podrá recibir alertas.',
		unoccupied: 'Desocupada',
		ambientMonitoringNotActive: 'Monitoreo Ambiental No Activo',
		receiveAlerts: 'Para recibir alertas,',
		inOrderToReceiveAlerts: 'para recibir alertas.',
		alert: 'Alerta',
		manageAlert: 'Administrar Alerta',
		closeAlert: 'Cerrar Alerta',
		selectViewOrAddRooms: 'Seleccione una de las Vistas guardadas o comience agregando habitaciones a la Vista actual.',
		maxAmbientMonitoringProviders: 'Este dispositivo ha alcanzado el máximo de proveedores de monitoreo ambiental permitidos.',
		exportRebootDeviceLogs: 'Este dispositivo aún no tiene registros de reinicio.',
		logs: 'Registros',
		removeParticipant: 'Quitar participante',
		confirmParticipantRemoval: 'Confirmar eliminación de participante',
		confirmationOfRemovalParticipant: '¿Estás seguro de que deseas eliminar a {value} de la llamada?',
		youAreRemovedFromConference: 'Has sido eliminado de esta conferencia.',
		helloSpeakerSelected: 'Se ha seleccionado el altavoz interno del dispositivo Hello',
		tvSpeakerSelected: 'Zgjedhja e zërit të televizorit është bërë',
		switchToTv: 'Cambiar al altavoz del televisor',
		switchToHello: 'Cambiar al altavoz del dispositivo Hello',
		viewing: 'Visualización',
		fetchingPrecautionsFailed: 'La obtención de precauciones ha fallado.',
		selectCareEvent: 'Para habilitar las notas, selecciona un evento de cuidado',
		supportChat: 'Chat de Soporte',
		supportChatDescription:
			'Cuando esto está habilitado, los usuarios tendrán el chat de soporte disponible dentro de la aplicación.',
		prefix: 'Prefijo',
		displayFullName: 'Mostrando el nombre completo',
		displayCredentialsName: 'Mostrando el primer nombre, la primera letra del apellido y las credenciales',
		deleteConfirmation: 'Confirmación de eliminación',
		failedToGetCaptions: 'No se pudieron obtener los subtítulos. Por favor, inténtalo de nuevo en un momento.',
		created: 'Creada',
		departmentFloorRoom: 'Departamento > Piso > Habitación',
		careEventType: 'Tipo de evento de atención',
		admission: 'Admisión',
		discharge: 'Descargar',
		allEvents: 'Todos los eventos',
		myCareEvents: 'Mis eventos de cuidado',
		selectCareTeam: 'Seleccionar equipo de atención',
		assignCareTeam: 'Asignar equipo de atención',
		newCareEvent: 'Nuevo evento de atención',
		roomCannotBeEmpty: 'La habitación no puede estar vacía.',
		searchRoomName: 'Buscar nombre de habitación',
		careEventsDescription: 'Cuando esto esté habilitado, el {value} podrá ver los eventos de atención.',
		statusAlreadyExists: '¡Este estado ya existe!',
		patientCannotBeEmpty: 'La paciente no puede estar vacía',
		nurseCannotBeEmpty: 'La enfermera no puede estar vacía',
		addPatientWithEvent: 'Asigne un paciente nuevo o existente a una sala para vincular el evento de atención con su registro.',
		selectExistingPatientWithEvent:
			'Seleccione un paciente para agregar un evento de atención y asigne un miembro del equipo de atención para que lo administre como una tarea.',
		searchPatientByMrn: 'Buscar paciente por nombre o MRN',
		aIDetectionDrawings: 'Dibujos de Detección de IA',
		removeRoomMonitoring: 'Desea eliminar la habitación {value}?',
		sureRemoveRoomMonitoring: 'Por favor, confirme que desea eliminar esta habitación de la sesión de monitoreo actual.',
		removeSessionMonitoring: 'Desea cerrar la sesión actual?',
		sureRemoveSessionMonitoring: 'Por favor, confirme que desea cerrar la sesión actual.',
		interventions: 'Intervenciones',
		addInterventions: 'Agregar Intervenciones',
		interventionsHistory: 'Historial de Intervenciones',
		newInterventions: 'Nuevas intervenciones',
		noInterventions: 'No hay intervenciones disponibles',
		askAdministratorForInterventions: 'Por favor, pida a su administrador que cree una lista de intervenciones para elegir.',
		selectInterventions: 'Seleccione una opción para agregar notas adicionales.',
		aditionalNotes: 'Notas Adicionales',
		addAdditionalNotes: 'Agregar Notas Adicionales',
		allRooms: 'Todas las Habitaciones',
		eventHistory: 'Historial de eventos',
		eventCompleted: '{value} completada',
		eventCreated: 'Evento de {value} creado',
		statusUpdated: 'Estado actualizado',
		youUpdatedStatus: 'Has actualizado el estado de un evento',
		describeChange: 'Describe por qué hiciste este cambio.',
		yourInsightsHelp:
			'Sus conocimientos nos ayudan a mantener registros precisos y garantizar la mejor atención para todos los involucrados.',
		youReassignedCareMember: 'Ha reasignado un equipo de atención en un evento',
		reassigned: 'Reasignado',
		assignedCareTeam: 'Equipo de atención asignado',
		patientIsAtRoom: 'La paciente esta en la habitacion',
		sameExerciseSameDay: '¡El mismo Ejercicio, en el mismo día, ya está creado!',
		youUpdatedPriority: 'Ha actualizado la prioridad de un evento',
		priorityUpdated: 'Prioridad actualizada',
		selectStatus: 'Seleccionar estado',
		clearAll: 'Limpiar todo',
		filters: 'Filtros',
		dateRange: 'Rango de fechas',
		selectWithinDateRange: 'El intervalo de fechas no puede ser superior a {value} días',
		selectValidDateRange: '¡Seleccione un rango de fechas válido!',
		selectValidEndTime: '¡Elija una hora de finalización válida!',
		patientPlacement: 'Colocación del paciente',
		skipSslVerification: 'Saltar verificación SSL',
		mrnNoMatch: 'El Número de Registro Médico (MRN) no coincide con nuestros registros.',
		healthAlerts: 'Alertas de salud',
		aiAlerts: 'Alertas de AI',
		activeAlerts: 'Alertas activas',
		showAll: 'Mostrar todo',
		showLess: 'Muestra menos',
		allHospitals: 'Todos los hospitales',
		allDepartments: 'Todos los departamentos',
		allFloors: 'Todos los pisos',
		isIntervening: 'esta interviniendo',
		aiSettings: 'Configuración de IA',
		dataCollectionAlerts: 'Permitir la adquisición de datos para alertas falsas',
		dataCollectionAlertsDescription:
			'Esta opción permite que nuestro sistema obtenga imágenes en caso de que se informe una alerta falsa.',
		savingImage: 'Guardar imagen',
		imageSaved: 'Imagen guardada!',
		savingImageFailed: '¡Error al guardar la imagen!',
		helloDevice: 'Hello dispositivo',
		systemExecutedActions: 'Acciones Ejecutadas por el Sistema',
		digitalSign: 'Señal Digital',
		aiConfigurations: 'Configuraciones de IA',
		railsDetection: 'Detección de rieles',
		statAlarmActivation: 'Activación de alarma estadística en alertas de IA',
		toastMessage: 'Mensaje de brindis',
		alertOnMonitoringFeed: 'Alerta sobre el feed de seguimiento',
		soundOnAiAlert: 'Sonido en alerta de IA',
		patientAudio: 'Audio del paciente',
		allowPatientAudio:
			'Los navegadores requieren la interacción del usuario antes de reproducir audio. Simplemente haga clic en Aceptar para continuar.',
		allow: 'Permitir',
		transferOwnership: 'Transferir propiedad',
		transferOwnershipDescription: 'Abandonar la llamada transferirá la propiedad a otro participante y no finalizará la llamada.',
		leaveCall: 'Dejar llamada',
		directCallEcm: 'Llamada directa desde eCM',
		activeCareEvent: '¡Hay un evento de atención activo para el paciente actual de la habitación seleccionada!',
		setTimeZone: 'Configurar zona horaria',
		selectedTimeZone: 'Zona horaria seleccionada',
		searchPlaceTimeZone: 'Buscar Lugar o Zona Horaria',
		updateCareEvents:
			'Hay nuevas actualizaciones disponibles para los eventos de atención. Haga clic en el botón de la derecha para actualizar',
		updateList: 'Lista de actualizacion',
		host: 'Anfitrión',
		path: 'Ruta',
		isSubscribed: 'Está suscrito',
		multipleBedRoom: 'Habitación con varias camas',
		multipleBedRoomDesc: 'Esto limitará la vista desde el lado del visitante. Debe agregarse encima del Tipo de habitación.',
		careTeamReport: 'Informe del Equipo de Atención',
		patientReport: 'Informe del Paciente',
		head: 'Cabeza',
		rail: 'Riel',
		foot: 'Pie',
		admittedDate: 'Fecha de admisión',
		dischargedDate: 'Fecha de alta',
		viewSessions: 'Ver Sesiones',
		endTime: 'Hora de Finalización',
		intervention: 'Intervención',
		actionTaken: 'Acción Tomada',
		interventedBy: 'Intervenido Por',
		hidePatientProfile: 'Ocultar perfil del paciente',
		showPatientProfile: 'Mostrar perfil del paciente',
		nameNumber: 'Nombre/número',
		primaryPatientCondition: 'Condición Primaria del Paciente',
		primaryAdverseEvent: 'Evento Adverso Primario',
		additionalTitleBarNotes: 'Notas Adicionales en la Barra de Título',
		fullySilentMode: 'Modo Silencioso',
		cameraSettings: 'Ajustes de la cámara',
		cameraUse: 'Uso de la cámara',
		selectOneOfCameraSettings: 'Seleccione una de las configuraciones de la cámara',
		singleCameraUse: 'Uso de una sola cámara',
		multiCameraUse: 'Uso de varias cámaras',
		genericDescription:
			'Una encuesta genérica está diseñada para que los pacientes la completen cuando les convenga o cuando lo consideren necesario. Los pacientes podrán acceder a esta encuesta en su página de inicio.',
		safetyRangeDescription:
			'La Encuesta de Rangos de Seguridad está creada para que los pacientes la completen cada vez que sus mediciones superen los límites de seguridad establecidos para un Signo Vital específico.',
		scheduledDescription:
			'Las encuestas programadas están estructuradas para servir como chequeos mensuales, semanales o diarios para el paciente.',
		patientNotes: 'Notas del Paciente',
		addNotes: 'Agregar notas sobre el paciente',
		enterPatientName: 'Ingresar Nombre del Paciente',
		patientAge: 'Edad del Paciente',
		enterPatientAge: 'Ingresar Edad del Paciente',
		nurseNameNumber: 'EN - Nombre/Número',
		enterNurseNameNnumber: 'Ingresar EN - Nombre/Número',
		nurseAssistantNameNumber: 'ATP - Nombre/Número',
		enterNurseAssistantNameNumber: 'Ingresar ATP - Nombre/Número',
		tlNameNumber: 'TL - Nombre/Número',
		enterTlNameNumber: 'Ingresar TL - Nombre/Número',
		enterPrimaryAdverseEvent: 'Ingresar Evento Adverso Primario',
		enterPrimaryPatientCondition: 'Ingresar Condición Primaria del Paciente',
		primaryPatientRiskFactor: 'Factor de Riesgo Primario del Paciente',
		enterPrimaryPatientRiskFactor: 'Ingresar Factor de Riesgo Primario del Paciente',
		titleBarNotes: 'Notas Adicionales de la Barra de Título',
		enterTitleBarNotes: 'Ingresar Notas Adicionales de la Barra de Título',
		additionalPatientNotes: 'Notas Adicionales del Paciente',
		enterAdditionalPatientNotes: 'Ingresar Notas Adicionales del Paciente',
		enterPatientInformation: 'Ingresar Información del Paciente',
		voiceAnnouncements: 'Redirección Verbal',
		patientPrivacy: 'Privacidad del Paciente',
		patientOutOfRoom: 'Paciente fuera de la habitación',
		pleaseDoNotGetUp: '¡Atención: Por favor, no se levante!',
		pleaseDoNotTouchThat: 'Por favor, no toque eso.',
		pleaseStopWhatYouAreDoing: '¡Atención: Por favor, detenga lo que está haciendo!',
		stopPlease: '¡Deténgase, por favor!',
		someoneIsOnTheWay: 'Hablé con su enfermera, alguien está en camino a su habitación.',
		useYourCallLight: 'Por favor, use su luz de llamada.',
		doYouNeedYourNurse: '¿Necesita a su enfermera?',
		staffInjury: 'Lesión del Personal',
		isolationExposure: 'Exposición a Aislamiento',
		medicalDeviceInterference: 'Interferencia de Dispositivos Médicos',
		elopement: 'Elopement',
		intentionalSelfHarm: 'Autolesión Intencional',
		suicide: 'Suicidio',
		delirium: 'Delirio',
		dementia: 'Demencia',
		substanceWithdrawal: 'Abstinencia de Sustancias',
		brianInjury: 'Lesión Cerebral',
		psychiatricDisorder: 'Trastorno Psiquiátrico',
		stroke: 'Derrame Cerebral',
		infectiousDiseaseIsolation: 'Aislamiento por Enfermedad Infecciosa',
		poorShortTermMemory: 'Memoria a Corto Plazo Deficiente',
		poorComprehension: 'Comprensión Deficiente',
		impulsivity: 'Impulsividad',
		agitation: 'Agitación',
		anxiety: 'Ansiedad',
		distortedPerceptionOfReality: 'Percepción Distorsionada de la Realidad',
		angerFrustration: 'Ira/Frustración',
		none: 'Ninguno',
		patientIsOutOfTheRoom: 'El paciente está fuera de la habitación',
		surgery: 'Cirugía',
		patientisGoingDownForACTScan: 'El paciente está bajando para un escaneo de TC',
		patientIsGoingDownForAnMRI: 'El paciente está bajando para una resonancia magnética',
		ambulating: 'Caminando',
		paracentesis: 'Paracentesis',
		sunshinePrivileges: 'Privilegios de Luz Solar',
		transferring: 'Transferencia',
		erCp: 'ER CP',
		dialysis: 'Diálisis',
		mandarinChinese: 'Chino mandarín',
		roomWithoutPatientData: 'Esta habitación no tiene datos del paciente.',
		patientReturnedToRoom: 'Paciente regresó a la habitación.',
		fitToScreenVideoFeeds: 'Ajustar a pantalla las transmisiones de video',
		drafts: 'Borradores',
		forward: 'Forward',
		interventionsAndAlerts: 'Interventions and Alerts',
		monitorPatient: 'Vigilar al Paciente',
		removeFeed: 'Quitar feed',
		turnVideo: 'Mostrar video',
		turnVideoOn: 'Encender el video',
		sessionType: 'Tipo de sesión',
		voiceAnnouncementsDescription:
			'Habilitar la Redirección Verbal agregará lo mismo como una característica dentro del monitoreo desde donde los proveedores pueden usarlo para comunicarse con el paciente simplemente reproduciéndolo como un sonido pregrabado.',
		patientOutOfRoomDescription:
			'Habilitar Paciente Fuera de Habitación agregará lo mismo como una característica dentro del monitoreo desde donde los proveedores pueden documentar casos cuando el paciente por ciertas razones (divulgadas en un desplegable) está fuera de la habitación.',
		snoozeTimerForAlert: 'Temporizador de siesta para la alerta {value}',
		snoozeTimer: 'Temporizador de repetición',
		primaryPatientRisk: 'Riesgo Principal del Paciente',
		sessionByTime: 'Sesión Por Tiempo',
		totalHours: 'Total de Horas',
		transferPatient: 'Transferir al Paciente',
		openLifeSignals: 'Abierto LifeSignals',
		isOldUserExperience: 'Es Experiencia de Usuario Antigua',
		isEnabledForHs: 'Está Habilitado para Sistemas de Salud',
		isEnabledForHsDescription:
			'Cuando esto esté habilitado, los administradores de sistemas de salud podrán modificar la bandera de características "¿Es una experiencia de usuario antigua?".',
		isOldUserExperienceDescription:
			'Cuando esto está habilitado, la experiencia del usuario de la empresa o del sistema de salud será la antigua.',
		userExperience: 'Experiencia de Usuario',
		mixedMonitoringView: 'Vista de monitoreo mixta (Video y ambiente)',
		mixedMonitoringViewDescription:
			"Cuando esto esté habilitado, los proveedores verán 'Monitoreo Ambiental' como una capacidad/pestaña adicional dentro de la vista de árbol y de esta manera habilitarán la vista de monitoreo mixto (transmisión de video + monitoreo ambiental).",
		invitationNote: 'Nota de invitación',
		invitationNotePlaceholder: 'Agregue una nota de invitación para que la vea el invitado.',
		invitationNoteDescription: 'Todos los participantes verán esta nota. Máximo 160 caracteres.',
		pinLock: 'Bloqueo de PIN',
		disablePIN: 'Al desactivar el bloqueo de PIN, no será necesario para acceder a la configuración.',
		pinMinValue: 'El PIN debe ser al menos 1000',
		changePin: 'Cambiar PIN',
		disablePinBtn: 'Desactivar PIN',
		disablePinLock: 'Desactivar bloqueo de PIN',
		newPinLock: 'Nuevo bloqueo de PIN',
		searchByPatientNameMrn: 'Buscar por nombre del paciente o MRN',
		talkToPatientDescription: 'Cuando esto esté habilitado, el usuario podrá hablar con el paciente.',
		viewPatientDescription: 'Cuando esto esté habilitado, el usuario podrá ver al paciente',
		checkFeatureAvailability: 'Comuníquese con su administrador para tener esta función disponible',
		badgeId: 'ID de Insignia',
		hillRomId: 'ID de Hill Rom',
		userPicture: 'Foto de Usuario',
		badgeAuthorization: 'Autorización de Insignia',
		hl7v2: 'HL7 v2',
		hl7v2Description: 'Cuando esto esté habilitado, se mostrará la definición de la cama con las secciones necesarias.',
		enablingPrivacyMode:
			'Activar esta función desenfocará la vista de su paciente durante el tiempo seleccionado. Por favor, introduzca el tiempo durante el cual le gustaría desenfocar su pantalla o seleccione de las opciones predefinidas.',
		enterSelectTimer: 'Ingrese o seleccione el temporizador en minutos.',
		writeWholeNumber: 'Por favor, introduzca un número entero entre 1 y 60.',
		isActive: 'Está Activo',
		badgeStatus: 'Estado de la Insignia',
		badgeDeleteMsg: '¿Está seguro de que desea desactivar a este empleado?',
		aspectRatio: 'Relación de aspecto',
		badgingAccess: 'Acceso con Insignia',
		centrakBadge: 'Insignia Centrak',
		proxBadge: 'Insignia Prox',
		overviewTab: 'Cuando esto esté habilitado, la pestaña Descripción general estará disponible.',
		converationTab: 'Cuando esto esté habilitado, la pestaña Conversación estará disponible.',
		filesTab: 'Cuando esto esté habilitado, la pestaña Archivos estará disponible.',
		healthTab: 'Cuando esto esté habilitado, la pestaña Salud estará disponible.',
		physicalTherapyTab: 'Cuando esto esté habilitado, la pestaña Fisioterapia estará disponible.',
		visitTab: 'Cuando esto esté habilitado, la pestaña Visitas estará disponible.',
		alertHistoryTab: 'Cuando esto esté habilitado, la pestaña Historial de alertas estará disponible.',
		activate: 'Activar',
		moreParticipants: 'Más participantes',
		clickToViewParticipants: 'Haga clic para ver a los participantes',
		currentlyInThisCall: 'Actualmente en esta llamada ',
		regeneratePin: 'Regenerar PIN',
		hide: 'Ocultar',
		pca: 'PCA',
		roomInfo: 'Información de la Habitación',
		pain: 'Dolor',
		painLevel: 'Nivel de Dolor',
		manageablePainLevel: 'Nivel de Dolor Manejable',
		roomServiceNumber: 'Número de Servicio de Habitaciones',
		isolations: 'Aislamientos',
		patientInfoDescription:
			'Cuando esto esté habilitado, las enfermeras verán "Información del Paciente" como un cuadro dentro de la vista de la habitación.',
		monitoringWhiteboardDescription:
			'Cuando esto esté habilitado, los proveedores verán la "Pizarra" como un cuadro dentro de la vista de la sala y podrán actualizar la Pizarra del paciente desde Monitoreo o Monitoreo ambiental.',
		listOfParticipants: 'Lista de Participantes',
		participant: 'Partícipe',
		userAutoGeneratedPin: 'PIN Generado Automáticamente',
		autoGeneratedPinDesc:
			'Wenn dies aktiviert ist, wird eine vom System automatisch generierte PIN unter Benutzerverwaltung verfügbar sein, um mit dem Whiteboard zu interagieren.',
		podcastsWidget: 'Podcasts',
		podcastsWidgetDescription: 'Biblioteca de Podcasts: Un catálogo de podcasts que cubren varios temas.',
		gamesWidget: 'Juegos',
		gamesWidgetDescription: 'Una lista de diferentes aplicaciones de juegos.',
		hdmiInWidget: 'HDMI-IN',
		hdmiInWidgetDescription: 'HDMI-IN: Reproduce contenido del decodificador o de otros dispositivos de streaming.',
		roomControlsWidget: 'Controles de la Habitación',
		roomControlsWidgetDescription: 'Control de la Habitación: Permite al paciente usar los controles de la habitación.',
		settingsWidget: 'Ajustes',
		settingsWidgetDescription: 'Ajustes: Opciones para personalizar tu experiencia en Whiteboard.',
		whiteboardPiPWidget: 'Whiteboard PiP',
		whiteboardPiPWidgetDescription:
			'Whiteboard PiP: Reproducción de una selección de diversos canales de televisión en diferentes géneros en un formato de imagen en imagen.',
		transferOwnershipMessage: 'Ahora eres el dueño de esta llamada.',
		lying: 'Acostada',
		goingHome: 'Volviendo a Casa',
		rideConfirmed: '¿Está Confirmado el Viaje?',
		healthSummary: 'Resumen de salud',
		diet: 'Dieta',
		bradenScore: 'Braden Score',
		sensoryPerceptions: 'Percepciones Sensoriales',
		skinMoisture: 'Humedad de la Piel',
		mobility: 'Movilidad',
		nutrition: 'Nutrición',
		frictionShear: 'Fricción & Cizallamiento',
		rideIsConfirmed: 'El viaje está confirmado',
		rideIsNotConfirmed: 'El viaje no está confirmado',
		completelyLimitedLabel: 'Completamente limitado',
		completelyLimited:
			'Completamente limitado: no responde (no gime, se estremece o agarra) a la presión. Capacidad limitada para sentir dolor en la mayor parte del cuerpo',
		veryLimitedLabel: 'Muy limitado',
		veryLimited: 'Muy limitado: responde solo a estímulos dolorosos. No puede comunicar el malestar adecuadamente',
		slightlyLimitedLabel: 'Ligeramente limitado',
		slightlyLimited: 'Ligeramente limitado: responde a comandos verbales pero siente malestar en 1 o 2 extremidades',
		noImpairmentLabel: 'Sin impedimentos',
		noImpairment: 'Sin impedimentos: responde a comandos verbales, no tiene déficit sensorial',
		constantlyMoistDesc: '1 - Constantemente húmedo: la piel se mantiene casi constantemente húmeda',
		constantlyMoist: 'Constantemente húmedo',
		veryMoistDesc: '2 - Muy húmedo: la piel suele estar húmeda',
		veryMoist: 'Muy húmedo',
		occasionallyMoistDesc:
			'3 - Ocasionalmente húmedo: la piel está ocasionalmente húmeda y requiere un cambio de ropa una vez al día',
		occasionallyMoist: 'Ocasionalmente húmedo',
		rarelyMoistDesc: '4 - Rara vez húmedo: la piel está seca',
		rarelyMoist: 'Rara vez húmedo',
		bedfastDesc: '1 - Bedfast: confinado en cama',
		bedfast: 'Confinado en cama',
		chairfastDesc: '2 - Chairfast: el paciente tiene la capacidad de caminar muy limitada pero no puede soportar su propio peso',
		chairfast: 'Limitado a la silla',
		walksOccasionallyDesc: '3 - Camina ocasionalmente: puede caminar distancias muy cortas',
		walksOccasionally: 'Camina ocasionalmente',
		walksFrequentlyDesc: '4 - Camina con frecuencia: sale de la habitación al menos dos veces al día y puede caminar sin ayuda',
		walksFrequently: 'Camina con frecuencia',
		completelyImmobileDesc: '1 - Completamente inmóvil: no puede hacer ni siquiera movimientos leves',
		completelyImmobile: 'Completamente inmóvil',
		veryLimitedDesc:
			'2 - Muy limitado: puede cambiar la posición del cuerpo ocasionalmente pero no puede hacer cambios significativos de forma independiente',
		veryLimitedTitle: 'Muy limitado',
		slightlyLimitedDesc:
			'3 - Ligeramente limitado: hace cambios corporales de forma independiente pero no tiene la fuerza para hacerlo con frecuencia',
		slightlyLimitedTitle: 'Ligeramente limitado',
		noLimitationDesc: '4 - Sin limitación: el paciente puede cambiar de posición sin ayuda',
		noLimitation: 'Sin limitación',
		veryPoorDesc:
			'1 - Muy pobre: el paciente nunca come una comida completa. Toma poco líquido y no puede comer más de ⅓ de la comida',
		probablyInadequateDesc: '2 - Probablemente inadecuado: el paciente come sólo alrededor de la mitad de la comida ofrecida',
		probablyInadequate: 'Probablemente inadecuado',
		adequateDesc: '3 - Adecuado: no puede comer toda la comida',
		adequate: 'Adecuado',
		excellentDesc: '4 - Excelente: el paciente suele comer un total de 4 o más porciones',
		excellent: 'Excelente',
		problemDesc: '1 - Problema: Levantar sin deslizarse contra las hojas es imposible',
		problem: 'Problema',
		potentialProblemDesc: '2 - Posible problema: El paciente requiere asistencia mínima para minimizar la fricción y el corte',
		potentialProblem: 'Problema potencial',
		noApparentProblemDesc:
			'3 - Ningún problema aparente: la capacidad de moverse en la cama y en la silla de forma independiente. Capacidad para mantener una buena posición en la cama y en la silla.',
		noApparentProblem: 'Sin problema aparente',
		ateCallQuality: 'Calificar la Calidad de la Llamada',
		rateCallQualityDesc: 'Esto permite a los participantes proporcionar comentarios al final de la llamada.',
		veryPoor: 'Muy Malo',
		poor: 'Malo',
		veryGood: 'Muy Bueno',
		howWasQuality: '¿Cómo fue la calidad de la llamada?',
		rateCall: 'Calificar la Llamada',
		roundingCareEvents: 'Rondas de Eventos de Cuidado',
		roundingCareEventsDesc:
			'Al habilitar esta opción, puede establecer el motivo de la llamada antes o durante la llamada de rondas.',
		excludeCpmPatients: 'Excluir pacientes de CPM',
		removeFromRpm: 'Quitar de RPM',
		removePatient: 'Quitar paciente',
		areYouSureRemovePatient: '¿Está seguro de que desea eliminar al paciente del programa RPM?',
		makeHost: 'Hacer anfitrion/a',
		noPinGenerate: 'No tienes un PIN existente. Por favor, genera uno.',
		missedCallsNotifications: 'Notificaciones de Llamadas Perdidas',
		missedCallFrom: 'Llamada Perdida de',
		questionProvider: 'Pregunta para su proveedor',
		safetyPatientNeeds: 'Alertas de Seguridad/Necesidades de los Pacientes',
		newMedicationAndSideEffects: 'Nueva Medicación y Efectos Secundarios',
		whatMattersToMe: 'Lo que me Importa',
		planForToday: 'Plan para Hoy',
		painMedAvailable: 'Medicamento para el dolor disponible',
		lastDoseGiven: 'Última dosis administrada',
		nextDose: 'Próxima dosis',
		generatePin: 'Generar PIN',
		aiDetectionDrawingsDescription:
			'Si esto está habilitado, el cuidador verá dibujos alrededor de las personas y objetos detectados por el modelo de AI.',
		soundOnAiAlertDescription:
			'Si esto está habilitado, el cuidador escuchará un sonido de alerta cuando se active una alerta de IA. El sonido se reproducirá en el dispositivo que están utilizando para monitorear a los pacientes.',
		caseManager: 'Gestor de Casos',
		caseManagerPhone: 'Teléfono del Gestor de Casos',
		showStats: 'Mostrar estadísticas',
		mrn: 'MRN',
		sharedView: 'Vista Compartida',
		sharedBy: 'Compartido por {value1} {value2}',
		logisticsDescription: 'Cuando esto esté habilitado, {value} podrá ver y usar la pestaña de logística.',
		thisMonth: 'Este mes',
		thisWeek: 'Esta semana',
		sharedInChat: 'Compartido en el chat',
		handoverTo: 'Entregar a',
		selectSitterNurse: 'Selecciona la enfermera/cuidadora virtual',
		handoverSession: 'Entrega de sesión',
		mobileApp: 'Aplicación móvil',
		noSnooze: 'Sin posponer',
		triggerAlertsMoreThanOnePerson: 'Disparar alertas si hay más de 1 persona en la habitación',
		thresholdValues: 'Umbrales',
		thresholdsForRailDown: 'Umbrales para bajar el riel',
		thresholdsForRailUp: 'Umbrales para subir el riel',
		aiModelCheckTimer: 'Temporizador de verificación del modelo de IA',
		triggerAlertsRailsCovered: 'Activar alertas si las vías están cubiertas',
		setAsDefault: 'Establecer como predeterminado',
		enterNumber: 'Introducir número',
		writeAndClickEnter: 'Escribe y haz clic en Enter',
		selectOneCheckbox: 'Por favor, seleccione al menos una opción, con solo una como predeterminada',
		provideThreshold: 'Por favor, proporciona un valor para cada uno de los valores umbral',
		pleaseSelectOption: 'Por favor selecciona una opción',
		fillRequiredFieldsAi: 'Por favor, complete los campos requeridos en la pestaña de configuración de IA',
		preferredLanguage: 'Idioma Preferido',
		updateRpmList: 'Hay nuevas actualizaciones disponibles para la lista de pacientes de RPM',
		roundingWhiteboardDescription:
			'Cuando esto esté habilitado, se mostrará la opción para ver y actualizar la pizarra del paciente durante la llamada.',
		sleepScheduleTitle: 'Hora del horario de sueño',
		sleepScheduleDescription:
			'El Horario de Sueño apaga automáticamente la pantalla según la configuración siguiente, y durante este periodo se puede encender tocando la pantalla. La pantalla volverá a apagarse según el tiempo de espera de la pantalla.',
		addSleepSchedule: 'Agregar horario de sueño',
		bodyPositionToSendAlert: 'Posición del cuerpo para enviar alertas {value}',
		side: 'Lado',
		down: 'Abajo',
		inactiveTime: 'Temporizador de aviso de tiempo inactivo',
		aiAlertsDescription:
			'Cuando esto esté habilitado, se mostrarán datos generales de las alertas de IA basadas en casos de uso.',
		downloadButtonAtPatientReport: 'Botón de Descarga en el Informe del Paciente',
		downloadBtnPatientReportDesc:
			'Cuando se habilita la bandera de función, el administrador / superusuario puede descargar el informe de un paciente específico.',
		dashboardHandHygiene: 'Higiene de Manos',
		hHDashboardDesc:
			'Cuando esta característica está habilitada, el Administrador/Usuario Principal podrá ver datos sobre la Higiene de Manos.',
		liveCaptions: 'Subtítulos en vivo',
		liveCaptionsEmptyMessage: 'Las/Los participantes necesitan hablar para generar subtítulos en vivo.',
		scheduleNightVision: 'Programar visión nocturna',
		removeSchedule: 'Eliminar horario',
		nightVisionError: '¡Por favor complete la hora de inicio y finalización para continuar!',
		nightVisionInfo: 'Las horas mostradas reflejan la zona horaria local de la habitación',
		assignedSessionFrom: 'Tienes una sesión asignada de',
		disregardOpenHospital: 'Si desea ignorarlo y monitorear otras habitaciones, por favor abra un hospital,',
		chooseDepartmentFloorRoom: 'elija el departamento, haga clic en un piso y seleccione una habitación.',
		whiteboardTooltip: 'Esta opción le permitirá ver y editar la pizarra del paciente.',
		callsNotifications: 'Notificaciones de Llamadas',
		answeredCalls: 'Llamadas Contestadas',
		missedCalls: 'Llamadas Perdidas',
		callBack: 'Devolver Llamada',
		openSession: 'Abrir sesión',
		silentModeDescription:
			'Cuando esto está habilitado, podemos activar la IA sin monitorear al paciente y recibir alertas en modo silencioso para fines de entrenamiento e informes.',
		whiteboardDisplayControl: 'Control de visualización de pizarra',
		selectPatientPosition: 'Seleccione la posición del paciente para enviar una alerta',
		patientPositions: 'Posición del paciente',
		sendPatientPositionAlert: 'Para qué posición del paciente se debe activar una alerta',
		upInAChair: 'Sentado en una silla',
		supine: 'En decúbito supino',
		prone: 'En decúbito prono',
		lateral: 'Lateral',
		screenTimeout: 'Tiempo de espera de la pantalla',
		useRestroom: 'Necesitas usar el baño?',
		needDrink: 'Necesitas una bebida?',
		sorryDidNotHear: 'Lo siento, no te escuché. Por favor, repite tu respuesta.',
		needMeToRepeat: 'Necesitas que repita lo que dije?',
		allowPatientSignUp: 'Permitir registro de pacientes',
		auditLog: 'Registro de auditoría',
		waitingForResult: 'Esperando resultados',
		galaxyWatch: 'Galaxy Watch',
		monitoringFeature: 'Función de monitoreo',
		clientThemeColor: 'Color del tema del cliente',
		twoFactorProviderType: 'Tipo de proveedor de dos factores',
		lengthUnit: 'Unidad de longitud',
		patientPrivateMode: 'Modo privado del paciente',
		nICUChat: 'Chat de NICU',
		rpm: 'RPM',
		bloodGlucoseUnit: 'Unidad de glucosa en sangre',
		heightUnit: 'Unidad de altura',
		temperatureUnit: 'Unidad de temperatura',
		weightUnit: 'Unidad de peso',
		talkToPatientRoute: 'Ruta de comunicación con el paciente',
		monitoringRoute: 'Ruta de monitoreo',
		viewPatientRoute: 'Ruta de visualización del paciente',
		switchE2EE: 'Cambiar a E2EE',
		pTZCam: 'Cámara PTZ',
		helloCameraName: 'Nombre de la cámara Hello',
		externalCameraName: 'Nombre de la cámara externa',
		multipartyCalls: 'Llamadas multiparte',
		roundingSummary: 'Resumen de redondeo',
		screensharing: 'Compartir pantalla',
		roundingNightVision: 'Visión nocturna de redondeo',
		digitalClinicianMode: 'Modo clínico digital',
		liveTVWidget: 'Widget de TV en vivo',
		roundingCareEventsForSafetyCompanions: 'Eventos de cuidado de redondeo para acompañantes de seguridad',
		healthSystemDefaultBackground: 'Fondo predeterminado del sistema de salud',
		callBackground: 'Fondo de llamada',
		providerMicrophone: 'Micrófono del proveedor',

		helloDefaultBackground: 'Fondo predeterminado de Hello',
		patientAdtDashboard: 'Panel de control ADT del paciente',
		virtualPatientAdmission: 'Admisión virtual del paciente',
		manualPatientAdmission: 'Admisión manual del paciente',
		ehrPatientAdmission: 'Admisión del paciente en EHR',
		isAudioCall: '¿Es una llamada de audio?',
		alertsCenterDashboard: 'Panel de control del centro de alertas',
		aiDetectionDrawings: 'Dibujos de detección de IA',
		messageDoctor: 'Mensaje al doctor',
		staffDuress: 'Situación de estrés del personal',
		allowDataCollection: 'Permitir la recopilación de datos',
		verbalRedirection: 'Redirección verbal',
		hillRoom: 'Sala de Hill',
		patientsOverview: 'Visión general de los pacientes',
		patientsFiles: 'Archivos de pacientes',
		patientsHealth: 'Salud de los pacientes',
		patientsPhysicalTherapy: 'Terapia física de los pacientes',
		patientsVisits: 'Visitas de los pacientes',
		patientsAlertHistory: 'Historial de alertas de los pacientes',
		isNewUserExperience: '¿Es una nueva experiencia de usuario?',
		isExperienceHealthSystemChangeable: '¿Es modificable el sistema de salud de la experiencia?',
		providerCentricReport: 'Informe centrado en el proveedor',
		patientCentricReport: 'Informe centrado en el paciente',
		userAutoGeneratedPIN: 'PIN generado automáticamente por el usuario',
		vcpRoleDisplayName: 'Nombre de visualización del rol de VCP',
		patientInfoBox: 'Cuadro de información del paciente',
		whiteboardBox: 'Cuadro de pizarra blanca',
		aiDetectionDrawing: 'Dibujo de detección de IA',
		soundOnAIAlert: 'Sonido en alerta de IA',
		patientAdmission: 'Admisión del paciente',
		whiteboardWidgetSettings: 'Configuraciones del widget de pizarra blanca',
		monitoringMessage: 'Mensaje de monitoreo',
		whiteboardPiP: 'Pizarra blanca PiP',
		whiteboardBoxRounding: 'Redondeo de cuadro de pizarra blanca',
		careNotifications: 'Notificaciones de Atención',
		ir: 'IR',
		ct: 'TC',
		xray: 'Radiografía',
		orAcronym: 'Quirófano',
		swallowTest: 'Prueba de Deglución',
		mri: 'RM',
		inHallway: 'En el Pasillo',
		cathLab: 'Laboratorio de Cateterismo',
		restroom: 'Baño',
		restroomByRn: 'Baño cerca de RN',
		s529: 'S529',
		j516: 'J516',
		dc: 'Alta',
		nuclearMed: 'Medicina Nuclear',
		epLab: 'Laboratorio EP',
		liveSitterUntilAm: 'Acompañante hasta AM',
		s732: 'S732',
		giLab: 'Laboratorio GI',
		tee: 'ETE',
		pegTubePlacement: 'Colocación de Sonda PEG',
		moveTo527: 'Mover a 527',
		walkingWithNurse: 'Caminando con Enfermera',
		eeg: 'EEG',
		additionalDiet: 'Dieta Adicional',
		discontinueMonitoring: 'Suspender la supervisión',
		behaviorsImproved: 'Comportamiento mejorado',
		requiresOneOnOneObservation: 'Requiere observación individual',
		leftAma: 'Alta contra consejo médico',
		familyPatientRefuses: 'Familia/Paciente se niega',
		shiftChange: 'Cambio de turno',
		stopVideoMonitoring: '¿Por qué desea detener la monitorización de este paciente.',
		closingMonitoring: 'Cerrar la monitorización',
		discontinuePatientMonitoring: 'Interrumpir la monitorización del paciente',
		discontinuingMonitoringReason: 'Motivo de la interrupción del seguimiento',
		transferToAnotherUnit: 'Transferencia a otra unidad',
		selectedIncorrectRoom: 'Paciente incorrecto; cambiando al paciente correcto',
		technicalIssue: 'Sala desconectada debido a problemas técnicos',
		patientDischargedDiscontinue: 'Paciente dado de alta. Por favor, suspenda el monitoreo.',
		patientTransferredDiscontinue: 'Paciente transferido. Por favor, deje de monitorear.',
		patientDischarged: 'Paciente dado de alta',
		workflow: 'Flujo de trabajo',
		aiNotifications: 'Notificaciones de IA',
		whiteboardControls: 'Controles del Pizarra Blanca',
		whiteboardControlsDesc: 'Cuando esto está habilitado, los proveedores podrán controlar la pizarra desde la interfaz web',
		discontinueMonitoringDescription:
			'When this is enabled, providers will have to select a reason for discontinuing monitoring.',
		roomSign: 'Letrero de habitación',
		roundingRoomSignDescription:
			'Cuando esto está habilitado, se mostrará la opción de ver y actualizar el Letrero de Habitación del paciente durante y fuera de la llamada.',
		careConsiderations: 'Consideraciones de cuidado',
		selectLevelofBradenScore: 'Seleccione el nivel de la puntuación Braden',
		hearingImpaired: '¿El paciente tiene problemas de audición?',
		interpreterNeeded: '¿Se necesita un intérprete?',
		whatLanguageIsNeeded: '¿Qué idioma se necesita?',
		noWhiteboardAssigned: 'No se asignó ningún dispositivo compañero a la sala',
		invitedParticipants: 'Participantes invitados',
		cancelAllInvites: 'Cancelar todas las invitaciones',
		cancelInviteDescription:
			'Cuando esto esté habilitado, el usuario podrá cancelar una invitación única o todas las invitaciones.',
		invalidConferenceInvitation: 'Esta invitación ya no es válida.',
		invalidConferenceLink: 'Este enlace ya no es válido.',
		invalidConferenceParameters: 'La información de la conferencia es incorrecta.',
		addPeople: 'Añadir Personas',
		sentInvites: 'Invitaciones Nnviadas',
		multipartyCalling: 'Llamada de múltiples participantes',
		hospitalAtHome: 'Hospital en casa',
		patientNameDescription:
			"Cuando esté habilitado, los enfermeros verán 'Nombre del Paciente' como una entrada en la sección de Información del Paciente.",
		patientAgeDescription:
			"Cuando esté habilitado, los enfermeros verán 'Edad del Paciente' como una entrada en la sección de Información del Paciente.",
		nurseNameNumberDescription:
			"Cuando esté habilitado, los enfermeros verán 'Nombre/Número del Enfermero' como una entrada en la sección de Información del Paciente.",
		nurseAssistantNameNumberDescription:
			"Cuando esté habilitado, los enfermeros verán 'Nombre/Número del Asistente de Enfermería' como una entrada en la sección de Información del Paciente.",
		tlNameNumberDescription:
			"Cuando esté habilitado, los enfermeros verán 'Nombre/Número del Líder del Equipo' como una entrada en la sección de Información del Paciente.",
		primaryAdverseEventDescription:
			"Cuando esté habilitado, los enfermeros verán 'Evento Adverso Primario' como una entrada en la sección de Información del Paciente.",
		primaryPatientConditionDescription:
			"Cuando esté habilitado, los enfermeros verán 'Condición Primaria del Paciente' como una entrada en la sección de Información del Paciente.",
		primaryPatientRiskFactorDescription:
			"Cuando esté habilitado, los enfermeros verán 'Factor de Riesgo Primario del Paciente' como una entrada en la sección de Información del Paciente.",
		preferredLanguageDescription:
			"Cuando esté habilitado, los enfermeros verán 'Idioma Preferido' como una entrada en la sección de Información del Paciente.",
		titleBarNotesDescription:
			"Cuando esté habilitado, los enfermeros verán 'Notas de la Barra de Título' como una entrada en la sección de Información del Paciente.",
		additionalPatientNotesDescription:
			"Cuando esté habilitado, los enfermeros verán 'Notas Adicionales del Paciente' como una entrada en la sección de Información del Paciente.",
		patientInfoWidget: 'Widget de información del paciente',
		patientInfoConfigsTitle:
			'Los siguientes campos se mostrarán dentro del Cuadro de Información del Paciente al activarlos. Por favor, active los campos que desea mostrar.',
		imbalance: 'Desequilibrio',
		imbalanceType: 'Tipo de Desequilibrio',
		recentMetrics: 'Métricas recientes',
		orders: 'Pedidos',
		schedule: 'Cronograma',
		painScale: 'Escala de dolor',
		assignedNurse: 'Enfermera asignada',
		product: 'Producto',
		sponsor: 'Patrocinadora',
		payer: 'Pagadora',
		phaseOfCare: 'Fase de atención',
		admitDate: 'Fecha de admisión',
		appointmentWith: 'Cita con',
		timeADay: 'hora al día',
		dateOfBirthAge: 'Fecha de nacimiento (edad)',
		timeAday: 'hora al día',
		hospitalAtHomeDescription:
			'Habilitar esta función permitirá a los cuidadores ver a sus pacientes en casa, proporcionando una interfaz personalizable para administrar la información del paciente, los planes de tratamiento y la comunicación de manera efectiva.',
		currentPosition: 'Posición actual',
		configuredFor: 'Configurado para',
		configuredPositions: 'Posiciones configuradas',
		patientPositionMonitoring: 'Posición del paciente',
		pressureInjuryStats: 'Estadísticas de Lesiones por Presión',
		standing: 'De pie',
		rightLateral: 'Lateral derecho',
		leftLateral: 'Lateral izquierdo',
		pressureInjuryHistoryDescription: 'Persona detectada inactiva durante {value1} en posición {value2}.',
		pressureInjuryHistoryTime: 'Desde {value1} hasta {value2}',
		noHistoryFound: 'No se registró historial para este paciente.',
		writeNewVerbalRedirection: 'Escribir nueva redirección verbal...',
		atLeastOne: 'Al menos 1 debe ser seleccionado.',
		onState: 'Encendido',
		offState: 'Apagado',
		isEnabledMessage: '{value1} convertido {value2} {value3}',
		sensitivityOptionsMessage: '{value1} aktualisiert die Empfindlichkeit von {value2} zu {value3} für {value4}',
		snoozeTimeMessage: '{value1} aktualisiert den Schlummertimer von {value2} zu {value3} für {value4}',
		notificationOptionsMessage: '{value1} actualizado alertas de {value2} a {value3} para {value4}',
		statAlarmMessage: '{value1} actualizado alarma inmediata de {value2} a {value3} para {value4}',
		railsOptionsMessage: '{value1} actualizado {value2} de {value3} a {value4}',
		pressureInjuryPositionsMessage: '{value1} actualizado posiciones de los pacientes de {value2} a {value3}',
		ivBagValueMessage: '{value1} actualizado valor de advertencia de la bolsa de IV de {value2} a {value3}',
		pressureInjuryTimerMessage: '{value1} temporizador de lesiones por presión actualizado desde {value2} a {value3}',
		atLeastOneMustBeSet: 'Debe establecerse al menos uno.',
		sleepScheduleTimeError: 'La hora de finalización debe ser al menos 1 minuto después de la hora de inicio.',
		allHealthSystems: 'Todos los sistemas de salud',
		tomorrow: 'Mañana',
		never: 'Nunca',
		gobConfigurationMessage: '{value1} configuración: Sensibilidad {value2}',
		alertConfigurationMessage: '{value1} configuración: {value2}',
		compliant: 'Cumplidor / Conforme',
		nonCompliant: 'No Cumplidor / No Conforme',
		totalIdentifications: 'Identificaciones Totales / Totales Identificados',
		method: 'Método',
		evidence: 'Evidencia',
		handHygiene: 'Higiene de Manos',
		rn: 'RN',
		primaryDisplayLogo: 'Logo Principal de Pantalla',
		primaryDisplayLogoDescription:
			'Cargue el logotipo principal de su hospital. Este se mostrará de manera destacada en toda la plataforma.',
		secondaryDisplayLogo: 'Logo Secundario de Pantalla',
		secondaryDisplayLogoDescription: 'Cargue un logotipo secundario para ser utilizado en áreas o pantallas específicas.',
		compliance: 'Cumplimiento',
		subjectIdentifications: 'Identificaciones de Sujetos',
		addTranslationService: 'Agregar Servicio de Traducción',
		healthSystemMustBeSet: 'El sistema de salud debe establecerse',
		evidenceReport: 'Informe de Evidencia',
		stats: 'Estadísticas',
		skinTemperature: 'Temperatura de la piel',
		missingInformation: 'No pude unirme a la llamada debido a la falta de información.',
		selectReasonPlease: 'Por favor seleccione un motivo!',
		ecm: 'ECM',
		smartFHIR: 'SMART en FHIR',
		nurseCalling: 'Llamada a la Enfermera',
		allPatients: 'Todos los pacientes',
		aiVoiceAnalysis: 'Análisis de Voz con IA',
		aiVoiceDescription:
			'Cuando esto esté habilitado, los usuarios podrán recibir alertas a través de IA cuando se detecten voces de audio del hospital.',
		sendAiVoiceAlert: 'Para qué eventos de voz recibir alertas',
		snoring: 'Ronquidos',
		coughing: 'Tos',
		cryingBaby: 'Bebé Llorando',
		screaming: 'Gritos',
		lowPriorityAlarm: 'Alarma de Baja Prioridad',
		mediumPriorityAlarm: 'Alarma de Prioridad Media',
		highPriorityAlarm: 'Alarma de Alta Prioridad',
		alarmOff: 'Alarma Apagada',
		aiAudioAnalysisEvents: '{value1} Eventos de voz de IA actualizados {value2} a {value3}',
		sneezingDetected: 'Se detectó estornudo.',
		snoringDetected: 'Se detectó ronquido.',
		coughingDetected: 'Se detectó tos.',
		cryingBabyDetected: 'Se detectó llanto de bebé.',
		screamingDetected: 'Se detectó grito.',
		helpDetected: 'Se detectó ayuda al paciente.',
		lowPriorityAlarmDetected: 'Se detectó alarma de baja prioridad.',
		mediumPriorityAlarmDetected: 'Se detectó alarma de prioridad media.',
		highPriorityAlarmDetected: 'Se detectó alarma de alta prioridad.',
		alarmOffDetected: 'Se detectó apagado de alarma.',
		alertDetected: '{value} fue detectado.',
		alertsEvidence: 'Evidencia de Alertas',
		alertsEvidenceDescription:
			'Cuando esto está habilitado, los usuarios pueden ver pruebas de fotogramas relacionadas con una alerta de IA activada.',
		otherOptions: 'Otras opciones',
		end: 'Terminar',
		audio: 'Audio',
		stop: 'Detener',
		liveExam: 'Examen en Vivo',
		handsNotDisinfected: 'Manos no desinfectadas',
	},
};

export default keys;
