import { useEffect, useState, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { enums } from '@solaborate/calls';
import { Mic } from '@solaborate/calls/webrtc';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { ParticipantAudio, Avatar, DragToZoomVideo } from 'calls/components/index.js';
import { useConference, useConferenceConfigurations, useControllerTracks, useLocalParticipant } from 'calls/hooks/index.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

const StyledPictureInPictureCamera = styled.main.attrs(props => ({
	style: {
		top: `${props.$top || 0}px`,
		right: `${props.$right || 0}px`,
	},
}))`
	position: absolute;
	width: 200px;
	height: auto;
	z-index: 99;
	&:not(:has(> video)) {
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};
		padding: var(--spacing-sl) 0;
		text-align: -webkit-center;
	}
`;

const PictureInPictureCameraView = forwardRef(
	/**
	 * @param {object} props
	 * @param {LocalParticipant | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */ ({ participant, activeTrackType }, ref) => {
		const conference = useConference();
		const conferenceConfigs = useConferenceConfigurations();
		const localParticipant = useLocalParticipant();
		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
		);
		const mainRef = useRef(null);
		const audioRef = useRef(null);
		const [loadingConference, setLoadingConference] = useState(true);
		const [participantDisplayedName, setParticipantDisplayedName] = useState(participant.alias || participant.name);
		const [isDragging, setIsDragging] = useState(false);
		const [offset, setOffset] = useState({ x: 0, y: 0 });
		const [position, setPosition] = useState({ top: 20, right: 20 });
		const videoTrack = tracks[activeTrackType];

		useEffect(() => {
			const displayName = participant.alias || participant.name;
			setParticipantDisplayedName(displayName);
		}, [participant]);

		useEffect(() => {
			setLoadingConference(false);
		}, [tracks]);

		useEffect(() => {
			const onLoaded = ({ target }) => {
				if (target) {
					const { videoHeight, videoWidth } = target;
					conferenceConfigs.setIsMainParticipantPortraitVideo(videoWidth <= videoHeight);
				}
			};

			if (ref.current) {
				ref.current.addEventListener('loadedmetadata', onLoaded);
			}

			return () => {
				if (ref.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					ref.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [conferenceConfigs, ref]);

		const startDragging = (clientX, clientY) => {
			setIsDragging(true);
			const element = mainRef.current;
			setOffset({
				x: clientX - element.getBoundingClientRect().left,
				y: clientY - element.getBoundingClientRect().top,
			});
			element.style.cursor = 'grabbing';
		};

		const handleTouchStart = e => {
			const touch = e.touches[0];
			startDragging(touch.clientX, touch.clientY);
		};

		const moveElement = (clientX, clientY) => {
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;
			const elementWidth = mainRef.current.offsetWidth;
			const elementHeight = mainRef.current.offsetHeight;

			let newRight = viewportWidth - (clientX + (elementWidth - offset.x));
			let newTop = clientY - offset.y;

			if (newTop < 0) newTop = 0;
			if (newTop + elementHeight > viewportHeight) newTop = viewportHeight - elementHeight;
			if (newRight < 0) newRight = 0;
			if (newRight + elementWidth > viewportWidth) newRight = viewportWidth - elementWidth;

			setPosition({ top: newTop, right: newRight });
		};

		const handleMouseMove = e => {
			if (isDragging) {
				moveElement(e.clientX, e.clientY);
			}
		};

		const handleTouchMove = e => {
			if (isDragging) {
				const touch = e.touches[0];
				moveElement(touch.clientX, touch.clientY);
			}
		};

		const stopDragging = () => {
			setIsDragging(false);
			mainRef.current.style.cursor = 'grab';
		};

		useEffect(() => {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', stopDragging);
			document.addEventListener('touchmove', handleTouchMove, { passive: false });
			document.addEventListener('touchend', stopDragging);

			return () => {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('mouseup', stopDragging);
				document.removeEventListener('touchmove', handleTouchMove);
				document.removeEventListener('touchend', stopDragging);
			};
		}, [isDragging, offset]);

		return (
			<StyledPictureInPictureCamera
				ref={mainRef}
				onMouseDown={e => startDragging(e.clientX, e.clientY)}
				onTouchStart={handleTouchStart}
				$top={position.top}
				$right={position.right}
				$isDarkMode={conferenceConfigs.isDarkMode}>
				{videoTrack && (
					<DragToZoomVideo
						ref={ref}
						mainRefElement={mainRef.current}
						participant={participant}
						activeTrackType={activeTrackType}
					/>
				)}
				{!videoTrack && !loadingConference && (
					<Avatar
						size={48}
						src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
						name={participantDisplayedName}
					/>
				)}
				{conference.callType === enums.CallTypes.SECURITYCAM && participant instanceof RemoteHelloParticipant && (
					<ParticipantAudio track={tracks[Mic]} ref={audioRef} autoPlay={false} />
				)}
			</StyledPictureInPictureCamera>
		);
	}
);

export default PictureInPictureCameraView;
