import { LOCALES } from 'i18n-translations/locales.js';

const keys = {
	[LOCALES.ALBANIAN]: {
		conversation: 'Biseda',
		files: 'Dosjet',
		health: 'Shëndeti',
		visits: 'Vizitat',
		roundingVisits: 'Vizitat',
		healthRecords: 'Të dhënat',
		hello: 'Tung',
		hideProfile: 'Fsheh profilin e {value}',
		showProfile: 'Shfaq profilin e {value}',
		familyPhysician: 'Mjeku familjar',
		assignedToMe: 'E përcaktuar tek unë',
		otherRequests: 'Kërkesa të tjera',
		requestSentFrom: 'Kërkesa e dërguar nga',
		healthConcernsDescription: 'Përshkrimi i brengave shëndetësore',
		symptomsSigns: 'Simptomat dhe shenjat',
		viewProfile: 'Shiko profilin',
		viewAlerts: 'Shiko njoftimet',
		requestedAppointment: 'Termini i kërkuar',
		meetingPlace: 'Vendi i takimit',
		location: 'Lokacioni',
		dateOfBirth: 'Data e lindjes',
		sex: 'Seksi',
		accepted: 'E pranuar',
		acceptRequest: 'Prano',
		wrapUp: 'Përmbledh',
		rejected: 'E refuzuar',
		canceled: 'E anuluar',
		completed: 'E përfunduar',
		unassigned: 'E papërcaktuar',
		rejectRequest: 'Refuzo kërkesën',
		male: 'Mashkull',
		female: 'Femër',
		nonBinary: 'Tjetër',
		gender: 'Gjinia',
		helloHealthAppointmentRequest: 'Kërkesë për termin në hellocare',
		appointmentConfirmation: 'Konfirmimi i terminit',
		areYouSureAcceptFollowingAppointment: 'Jeni të sigurt se doni ta pranoni terminin?',
		addToGoogleCalendar: 'Shto në Google Calendar',
		addToOutlookCalendar: 'Shto në Outlook Calendar',
		clientNotAuthenticated: 'Klienti nuk është i autentifikuar',
		appointments: 'Terminet',
		calendarOverview: 'Përmbledhje e kalendarit',
		appointmentRequests: 'Kërkesat për termine',
		appointmentsHistory: 'Historiku i termineve',
		nextAppointmentAt: 'Termini i ardhshëm',
		noUpcomingAppointment: 'Nuk keni termine të ardhshme',
		yesterday: 'Dje',
		today: 'Sot',
		lastAmountOfDays: '{value} ditët e fundit',
		lastMonth: 'Muajin e kaluar',
		lastYear: 'Vitin e kaluar',
		lastOnline: 'Online e fundit',
		monthToDate: 'Muaj deri më sot',
		quarterToDate: 'Tremujori deri më sot',
		yearToDate: 'Viti deri më sot',
		firstQuarterOf: 'Tremujori i parë i {value}',
		secondQuarterOf: 'Tremujori i dytë i {value}',
		thirdQuarterOf: 'Tremujori i tretë i {value}',
		fourthQuarterOf: 'Tremujori i 4-të i {value}',
		customRange: 'Gama e personalizuar',
		selectRange: 'Zgjidhni Gama',
		jul: 'Jul',
		unauthorize: 'Deautorizo',
		authorize: 'Autorizo',
		connectGoogleAccount: 'Lidh llogarinë Google',
		connectOutlookAccount: 'Lidh llogarinë Outlook',
		googleAccount: 'Llogaria Google',
		outlookAccount: 'Llogaria Outlook',
		calendarAccounts: 'Llogaritë e kalendarit',
		waitingRoom: 'Dhoma e pritjes',
		primaryCare: 'Kujdesi primar',
		healthcareProgressAndDiagnosisChecking: 'Duke u kontrolluar progresi dhe diagnoza e kujdesit të shëndetit',
		yourMobileDevice: 'Pajisja juaj mobile',
		clinic: 'Klinika',
		days: 'Ditët',
		anErrorOccurred: 'Ndodhi një gabim.',
		assignCompanionDeviceError: 'Për çdo modalitet shoqërues mund të zgjidhet vetëm një pajisje në të njëjtën kohë.',
		recaptchaLoadError: 'Gabim gjatë ngarkimit të skriptës për recaptcha.',
		noAppointmentsToShowClickAuth:
			'Nuk ka termine për tu shfaqur. Kliko "Autorizo" për të marrë termine të lidhura me llogarinë tuaj.',
		noAppointmentsToShowClickCreateAppointment: 'Nuk keni termine. Ju lutem klikoni në "Krijo termin" për ta krijuar terminin.',
		createAppointment: 'Krijo termin',
		inAMoment: 'Në një moment',
		personsProfile: 'Profili i {value}',
		topic: 'Tema',
		inviteMember: 'Dërgo ftesë te një anëtar',
		fullName: 'Emri i plotë',
		facility: 'Objekti',
		profession: 'Profesioni',
		familyDoctor: 'Mjeku familjar',
		birthday: 'Ditëlindja',
		address: 'Adresa',
		phone: 'Telefoni',
		audioCall: 'Audio thirrje',
		videoCall: 'Video thirrje',
		cameraFeed: 'Kamera vëzhguesi',
		sendNotification: 'Dërgo lajmërimin',
		writeMessage: 'Shkruaj mesazhin',
		rapidCall: 'Thirrje e Shpejtë',
		rapidCallDescription:
			'Kur kjo është e aktivizuar, opsioni për të nisur thirrjen e shpejtë do të jetë i disponueshëm në anën e karrocës.',
		regularCall: 'Thirrje e rregullt',
		regularCallDescription:
			'Kur kjo është e aktivizuar, opsioni për të nisur thirrjen e rregullt do të jetë i disponueshëm në anën e Shportës.',
		admissionCall: 'Thirrja e pranimit',
		admissionCallDescription:
			'Kur kjo është e aktivizuar, opsioni për të filluar thirrjen e pranimit do të jetë i disponueshëm në anën e Shportës.',
		submitNewCase: 'Dërgo një Rast të Ri',
		submitNewCaseDescription: 'Kur kjo është e aktivizuar, në ekran do të shfaqet vetëm një opsion për të filluar një sesion.',
		sharedQueue: 'Rrjedha e Shpërndarë',
		sharedQueueDescription:
			'Kur ky opsion është i aktivizuar, thirrjet e filluara do të dërgohen te të gjithë mjekët në dispozicion brenda një spitali.',
		sessionAlreadyReceived: 'Kjo sesion është pranuar tashmë nga një ofrues tjetër.',
		join: 'Bashkohu',
		type: 'Lloji',
		name: 'Emri',
		dateTime: 'Data/Koha',
		sentBy: 'Dërguar nga',
		noFilesFound: 'Nuk keni shpërndarë ndonjë dosje me {value}',
		noFilesParagraph: 'Provoni të dërgoni një fotografi, video apo dokument!',
		deleteMsgTitle: 'Fshij mesazhin për mua',
		deleteMsgWarning: 'Jeni të sigurtë që dëshironi ta fshini këtë mesazh?',
		deleteMediaTitle: 'Fshij media për mua',
		deleteMediaWarning: 'Jeni të sigurt që dëshironi ta fshini këtë media?',
		actionUndone: 'Ky veprim nuk ka kthim prapa.',
		summary: 'Përmbledhje',
		heart: 'Zemra',
		heartRate: 'Të rrahurat e zemrës',
		beatsPerMinute: 'rrahje në minutë',
		mmOfMercury: 'milimetra të merkurit',
		percentage: 'përqindje',
		bloodPressure: 'Presioni i gjakut',
		perfusionIndex: 'Indeksi i perfusionit',
		activity: 'Aktiviteti',
		steps: 'Hapa',
		flightsClimbed: 'Shkallët e ngjitura',
		walkingRunningDistance: 'Distanca e ecjes dhe vrapimit',
		bodyMeasurements: 'Përmasat e trupit',
		scale: 'Peshorja',
		weight: 'Pesha',
		pinFeed: 'Fikso në pamjen kryesore',
		unpinFeed: 'Largo nga pamja kryesore',
		bodyTemperature: 'Temperatura e trupit',
		leanBodyMass: 'Masa trupore pa dhjam',
		bodyFatPercentage: 'Përqindja e masës dhjamore',
		bmi: 'Indeksi i masës trupore',
		bMI: 'IMT',
		respiratory: 'Frymëmarrja',
		oxygenSaturation: 'Saturimi i oksigjenit',
		respiratoryRate: 'Ritmi i frymëmarrjes',
		sleep: 'Gjumi',
		sleepAnalysis: 'Analiza e gjumit',
		bloodGlucose: 'Glukoza e gjakut',
		stethoscope: 'Stetoskop',
		noVisits: 'Nuk ka vizita për këtë dhomë.',
		hasVisited: '{value1} ka vizituar {value2}',
		selectVisitsDate: 'Zgjedhni datat e vizitave',
		enterDetailsOfVisitReason: 'Shkruani arsyen pse pacienti është këtu.',
		allergy: 'Alergji',
		medication: 'Medikamenti',
		condition: 'Gjendja',
		observation: 'Observimi',
		immunization: 'Imunitizimi',
		procedure: 'Procedura',
		device: 'Pajisja',
		carePlan: 'Plani i kujdesit',
		manifestation: 'Manifestimi',
		criticality: 'Gjendja kritike',
		substance: 'Substanca',
		status: 'Statusi',
		medicationName: 'Emri i medikamentit',
		dateWritten: 'Data e shkruar',
		startDate: 'Data e fillimit',
		endDate: 'Data e përfundimit',
		prescriber: 'Udhëzimi',
		diagnosis: 'Diagnoza',
		dateRecorded: 'Data e inçizuar',
		severity: 'Shkalla e seriozitetit',
		category: 'Kategoria',
		value: 'Vlera',
		report: 'Raporti',
		performer: 'Performuesi',
		date: 'Data',
		vaccineType: 'Lloji i vakcinës',
		procedureName: 'Emri i procedurës',
		deviceType: 'Lloji i pajisjes',
		deviceModel: 'Modeli i pajisjes',
		deviceUDI: 'Pajisja UDI',
		deviceStatus: 'Statusi i pajisjes',
		expiryDate: 'Data e ekspirimit',
		diseases: 'Sëmundjet',
		categories: 'Kategoritë',
		goals: 'Qëllimet',
		appointmentDate: 'Data e terminit',
		noAppointmentsAvailableSelectDifferentDate: 'Nuk ka takime në dispozicion, ju lutem zgjedhni një datë tjetër.',
		noAppointmentsAvailableSelectDifferentDoctor: 'Nuk ka takime në dispozicion, ju lutem zgjedhni një doktor tjetër.',
		whereMeetingPlace: 'Ku dëshironi të zhvillohet ky takim?',
		setUpAvailabilityHours: 'Ju lutem shtoni disponueshmërinë tuaj para se të caktoni takimin.',
		recurringMeeting: 'Takim i përsëritur',
		recurrenceEndDate: 'Data e përfundimit të takimit të përsëritur',
		description: 'Përshkrimi',
		unavailable: 'Jo në dispozicion',
		selectAnotherColor: 'Zgjedhni një ngjyrë tjetër',
		interfaceThemeColor: 'Ngjyra e faqës',
		enableMultipleFeedAudio: 'Aktivizo audion për monitorues të shumtë',
		invalidCode: 'Kodi i pavlefshëm. Ju lutemi provoni përsëri!',
		emailChanged: 'Email-i juaj hellocare u ndryshua me sukses. Ju lutemi hyni përsëri duke përdorur emailin tuaj të ri.',
		goToLoginPage: 'Klikoni këtu për të shkuar në faqen e hyrjes',
		currentEmail: 'Email-i aktual',
		newEmail: 'Email-i i ri',
		authNone: 'None',
		authExternalProvider: 'Authenticator',
		authEmail: 'Email',
		available: 'Në dispozicion',
		changeLanguage: 'Ndërroni gjuhën',
		english: 'Anglisht',
		albanian: 'Shqip',
		editProfile: 'Ndrysho profilin',
		accountSettings: 'Aranzhimi i llogarisë',
		logOut: 'Dilni',
		noNotifications: 'Ju nuk keni lajmërim të ri',
		noConversations: 'Nuk keni biseda me Pacientë',
		conversationWithPatient: 'Bisedë me Pacientin',
		toggledPatientCamera: 'Ju e keni fikur kamerën e pacientit.',
		turnCameraOn: 'Ndize kamerën',
		turnCameraOff: 'Ndale kamerën',
		initiatingCall: 'Duke filluar thirrjen',
		endCall: 'Përfundo thirrjen',
		turnOnMic: 'Ndizeni mikrofonin',
		turnOffMic: 'Ndaleni mikrofonin',
		hidePTZ: 'Fsheh PTZ',
		showPTZ: 'Shfaq PTZ',
		showHealth: 'Shfaq shëndetin',
		hideHealth: 'Fsheh shëndetin',
		takeScreenshot: 'Bëj fotografi të ekranit',
		otoscopeCamera: 'Otoskopi',
		selectBackground: 'Zgjedh sfondin',
		streamSettings: 'Cilësimet teknike',
		streamSettingsModalTitle: 'Kontrollo cilësimet e audios dhe videos',
		usingDifferentMic: 'Duke përdorur mikrofon dhe kamerë të ndryshme mund të shkaktoj jehonë.',
		streamSettingsSpeaker: 'Altoparlanti',
		filterNone: 'Asnjë',
		filterBlur: 'Turbullt',
		tvControls: 'TV kontrollet',
		startScreenSharing: 'Fillo të shfaqësh ekranin tuaj',
		stopScreenSharing: 'Ndalo së shfaquri ekranin tuaj',
		presenting: 'Duke prezantuar',
		you: 'Ju',
		patient: 'Pacienti',
		disableNightvision: 'Deaktivizo vizion nate',
		enableNightvision: 'Aktivizo vizion nate',
		aiControls: 'AI Kontrollet',
		connect: 'Lidhuni',
		measure: 'Përmasa',
		connecting: 'Duke u lidhur',
		measuring: 'Duke marrë përmasat',
		overview: 'Pasqyrë',
		seeMoreData: 'Shiko më shumë të dhëna',
		historicalData: 'Të dhënat e historisë',
		first: 'të parin',
		second: 'të dytin',
		third: 'të tretin',
		daily: 'Ditore',
		weeklyOn: 'Javore - çdo ',
		monthlyThe: 'Mujore - me ',
		annuallyOn: 'Vjetore me ',
		everyWorkday: 'Çdo ditë pune - nga e hëna në të premte',
		prev: 'Prapa',
		next: 'Përpara',
		switchHealthSystem: 'Ndërro sistemin shëndetësor',
		createNewHealthSystem: 'Krijo një sistem të ri shëndetësor',
		editHealthSystem: 'Ndrysho sistemin shëndetësor',
		createHospital: 'Krijoni një spital',
		communicateWithARoom: 'Në mënyrë që të komunikoni me një dhomë',
		monitorRoom: 'Në mënyrë që të monitoroni dhomat',
		clickAddIconInTree: 'në pamjen e hierarkisë, klikoni në ikonën shto pranë emrit të dhomës',
		openHospitalChooseDepartment: 'ju lutem hapni një spital, zgjedhni departamentin',
		clickFloorSelectRoom: 'klikoni në një kat dhe zgjedhni një dhomë',
		scheduleMeeting: 'Planifiko një takim',
		makeAppointment: 'Planifiko një takim',
		switchHSorRegion: 'Ndërroni sistemin shëndetësor ose regjionin',
		selectHealthSystem: 'Zgjedhni sistemin shëndetësor',
		selectIntegrationType: 'Zgjedhni tipin e integrimit',
		selectGroupRole: 'Zgjedheni Group Role',
		selectRegion: 'Zgjedhni regjionin',
		newDevice: 'Pajisje e re',
		enterSerialNumber: 'Shëno numrin serik',
		deviceName: 'Emri i pajisjes',
		deviceMode: 'Modaliteti i pajisjes',
		deviceOfflineStatus: 'Disa pajisje mund të jenë jashtë linje.',
		version: 'Verzioni',
		deviceFamilyName: 'Emri i familjes së pajisjes',
		deviceLocation: 'Lokacioni i pajisjes',
		activationCode: 'Kodi i aktivizimit',
		savedSessions: 'Pamjet e Ruajtura',
		updateSession: 'Përditso sesionin',
		saveSession: 'Ruaje këtë pamje',
		currentSession: 'Pamja aktuale',
		loadingSession: 'Sesioni duke u mbushur',
		alreadyMonitoringPatients: 'Ju jeni në një thirrje tjetër apo duke monitoruar pacienta.',
		tryAgainAfterSessionEnds: 'Ju lutem provoni prapë pasi të përfundoni sesionin.',
		disconnectedFromMonitoring: 'Ju sapo jeni shkyçur nga monitorimi',
		youCantSwitchCam: `Nuk mund të ktheheni te {value} sepse butonat e privatësisë fizike janë aktivizuar. Këto butona mund të çaktivizohen vetëm me dorë. Ju lutemi kontaktoni administratorin tuaj.`,
		monitoringEndSoon:
			'Pamjet e monitorimit ose infermieres do të përfundojnë duke dalë nga faqja. Jeni të sigurt që dëshuroni të dilni?',
		talkToPatients: 'Bisedoni me pacientët',
		tvChannels: 'Kanalet televizive',
		previousState: 'Gjendja e mëparshme',
		microphoneIsOn: 'Mikrofoni është i ndezur',
		audioFromMonitoringFeeds: 'Audio nga pajisjet e monitorimit',
		cameraBlockedTitle: 'Lejo hellocare të përdor kamerën tuaj',
		microphoneBlockedTitle: 'Lejo hellocare të përdor mikrofonin tuaj',
		cameraAndMicrophoneBlockedTitle: 'Lejo hellocare të përdor kamerën the mikrofonin tuaj',
		cameraBlockedMessage:
			'hellocare ka nevojë të përdor kamerën që pacientat të mund të ju shohin. Shih majtas lartë ekranit dhe shtyp Allow',
		microphoneBlockedMessage:
			'hellocare ka nevojë të përdor mikrofonin që pacientat të mund të ju dëgjojnë. Shih majtas lartë ekranit dhe shtyp Allow',
		cameraAndMicrophoneBlockedMessage:
			'hellocare ka nevojë të përdor mikrofonin dhe kamerën që pacientat të mund të ju dëgjojnë. Shih majtas lartë ekranit dhe shtyp Allow',
		cantFindMicrophoneTitle: 'Nuk mund të gjindet mikrofoni juaj',
		cantFindMicrophoneMessage:
			'Ju lutemi shikoni nëse ndonjë mikrofon është kyçur dhe keni lejuar të përdoret mikrofoni. Nëse jo, kyçeni ose lejoni përdorimin e mikrofonit dhe rifilloni shfletuesin tuaj. Pacientët nuk do të mund të ju dëgjojnë deri sa të I kryeni këto.',
		cantFindCameraTitle: 'Nuk mund të gjindet kamera juaj',
		cantFindCameraTitleMessage:
			'Ju lutemi shikoni nëse ndonjë kamerë është kyçur dhe keni lejuar të përdoret kamera. Nëse jo, kyçeni ose lejoni përdorimin e kamerës dhe rifilloni shfletuesin tuaj. Pacientët nuk do të mund të ju dëgjojnë deri sa të I kryeni këto.',
		popUpCameraBlockedTitle: 'Kamera juaj është e bllokuar',
		popUpCameraBlockedMessage: 'Shtypni ikonën e kameras djathtë lartë të ekranit tuaj',
		popUpMicrophoneBlockedTitle: 'Mikrofoni juaj është bllokuar',
		popUpMicrophoneBlockedMessage: 'Shtypni ikonën e mikrofonit djathtë lartë të ekranit tuaj',
		popUpCameraAndMicrophoneBlockedTitle: 'Kamera dhe mikrofoni juaj janë të bllokuar',
		popUpCameraAndMicrophoneBlockedMessage: 'Shtypni ikonën e kameras djathtë lartë të ekranit tuaj',
		microphoneBlockedTitleGeneric: 'Mikrofoni është i bllokuar',
		microphoneBlockedMessageGeneric:
			'Hello hellocare kërkon të ketë qasje në mikrofonin tuaj. Shtypni ikonën e mikrofonit afër adresës së shfletuesit dhe pastaj rifreskoni.',
		cameraBlockedTitleGeneric: 'Kamera është e bllokuar',
		cameraBlockedMessageGeneric:
			'Hello hellocare kërkon të ketë qasje në kamerën tuaj. Shtypni ikonën e kameras afër adresës së shfletuesit dhe pastaj rifreskoni.',
		deviceUnavailable: 'Pajisja jo në dispozicion',
		virtualCaseProviderIsInCall:
			"Kujestari virtual për momentin është në një thirrje. Ju do të mund t'i përdorni këto kontrolle pasi të përfundojë thirrja.",
		browserPeerConnectionsLimitReached:
			'Limiti i shfletuesit për numrin maksimal të lidhjeve është arritur. Ju lutemi mbyllni dhe rihapni të gjitha skedat e hellocare.',
		sessionName: 'Emri i pamjes',
		enterSessionName: 'Shëno emrin e pamjes',
		delete: 'Fshij',
		deleteSession: 'Fshije këtë pamje',
		sureDeleteSession: 'A jeni të sigurt se dëshironi ta fshini këtë pamje?',
		failedToReconnect: 'Kyqja në rrjet ka dështuar.',
		sessionEndedByAdmin: 'Sesioni është ndërprerë nga admini.',
		disconnectedByCall: 'Korniza është ndërprerë nga një thirrje sesioni, do të rilidhet sapo të përfundojë thirrja.',
		maximumNumberOfMonitoringSessions: 'Pajisja është nën monitorim nga {value}. Numri maksimal për këtë dhomë është arritur.',
		beingMonitoredBy: 'Ky pacient është duke u monitoruar nga',
		pleaseRefreshThePage: 'Ju lutem rifreskoni faqen.',
		sessionUpdatedSuccessfully: 'Sesioni është përditësuar me sukses',
		save: 'Ruaje',
		cancel: 'Anulo',
		apply: 'Aplikoni',
		suspiciousBehavior: 'Sjellje e dyshimtë',
		suicideWatch: 'Mbikqyrje për vetëvrasje',
		watch: 'Ora',
		aIWithCamera: 'AI me kamerë',
		falls: 'Rrëzime',
		behavioral: 'Sjellje',
		isolation: 'Izolimi',
		seizure: 'Krizë',
		hospice: 'Strehë',
		detox: 'Detoks',
		fallRiskCheckPatient: 'Rrezik rrëzimi. Ju lutem kontrolloni pacientin.',
		multiPersonFallDetection: 'Detektim rrëzimi i shumë personave.',
		bedRailDetection: 'Detektimi i shtratit',
		fallDetection: 'Detektim i rrëzimit',
		turnOffPrivacyMode: 'Ndalni modin privat',
		turnOnPrivacyMode: 'Ndizeni modin privat',
		allowPermission: 'Lejo çasjen',
		mute: 'Fik Zërin',
		unmute: 'Ndiz Zërin',
		muteAudio: 'Ndal audio',
		unMuteAudio: 'Lësho audio',
		reinitiateCallPermissions: 'Për ta inicuar sërish këtë thirrje, ju duhet ta lejoni audion',
		for: 'për',
		myRequests: 'Kërkesat e mia',
		newRequest: 'Kërkesë e re',
		acceptedRequests: 'Kërkesat e pranuara',
		pendingRequests: 'Kërkesat në pritje',
		rejectedRequests: 'Kërkesat e refuzuara',
		pending: 'Në pritje',
		requestSentTo: 'Kërkesa e dërguar tek',
		viewRequestHistory: 'Shiko historinë e kërkesave',
		healthConcern: 'Brengat shëndetësore',
		reassignRequest: 'Ripërcakto kërkesën',
		welcomeMessage: 'Mesazh mirëseardhës',
		visitCost: 'Çmimi i vizitës',
		languagesSpoken: 'Gjuhët e folura',
		professionalEducation: 'Edukimi profesional',
		yearsOfExperience: 'Vitet e përvojës',
		fellowship: 'Anëtarësia',
		requestHistory: 'Historia e kërkesës',
		youHaveAcceptedRequest: 'Ju keni pranuar kërkesën e {value}',
		acceptedYourRequest: 'pranoi kërkesën tuaj',
		assignedTheRequestTo: 'ka përckatuar kërkesën tek',
		rejectedYourRequest: 'refuzoi kërkesën tuaj',
		sentRequestToAllDoctors: 'dërgo këtë kërkesë tek të gjithë mjekët',
		general: 'E përgjithshme',
		profileInformation: 'Informatat e profilit',
		passwordProtection: 'Mbrojtje e fjalëkalimit',
		notifications: 'Lajmërimet',
		measurementUnits: 'Njësitë e përmasave',
		integratedApps: 'Aplikacionet e integruara',
		dependents: 'Vartësi',
		insurance: 'Sigurimi',
		paymentInformation: 'Informatat e pagesave',
		activityHistory: 'Historia e aktivitetit',
		noPageFound: 'Nuk u gjet faqja',
		saveChanges: 'Ruaj ndryshimet',
		phoneNumber: 'Numri i telefonit',
		emailAddress: 'Email adresa',
		language: 'Gjuha',
		cameraNames: 'Emrat e kamerave',
		customRolesDisplayName: 'Emri i Kostumizuar për Rolet',
		enterCustomRoleNameHere: 'Vendosni emrin e rolit të përshtatur këtu',
		customRoleNameRequired: 'Emri i rolit të përshtatur është i domosdoshëm',
		informationMessageIsRequired: 'Mesazhi i informacionit është i detyrueshëm',
		informationMessageForMonitoring: 'Mesazh informacioni për monitorimin',
		enterAMessageHere: 'Shkruani një mesazh këtu',
		displayAs: 'Shfaq si',
		externalCamera: 'Kamera e jashtme',
		edit: 'Ndrysho',
		timeTrackerValue: '{value} Gjurmuesi i kohës',
		enable: 'Aktivizo',
		disable: 'Çaktivizo',
		disabled: 'E Çaktivizuar',
		recent: 'Të fundit',
		myDevices: 'Pajisjet e mia',
		online: 'Në dispozicion',
		offline: 'Jo në dispozicion',
		send: 'Dërgo',
		healthCategories: 'Kategoritë e shëndetit',
		addTab: 'Shto një tab',
		addFavTabQuickerAccess: 'Shto aplikacionin tuaj të dëshiruar ose dosjet si tabs për qasje më të shpejtë',
		searchAppsFiles: 'Kërko aplikacione ose dosje',
		myApps: 'Aplikacionet e mia',
		otherApps: 'Aplikacionet tjera',
		addNewHealthSystem: 'Shto një sistem të ri shëndetësor',
		resendInvite: 'Ri-dërgo ftesën',
		cancelInvite: 'Anulo ftesën',
		addNewField: 'Shto një fushë të re',
		viewYourBaby: 'Shikoni foshnjën tuaj',
		enablePrivacyButtons:
			'Butonat e privatësisë janë aktivizuar në pajisjen {value}. Ju mund ta shihni fëmijën kur butonat e privatësisë janë të çaktivizuar.',
		somethingWentWrong: 'Diçka shkoi gabim! Ju lutem provoni prapë.',
		nurseAdded: 'Infermierja është shtuar me sukses në sistemin shëndetëdor.',
		doctorAdded: 'Doktori është shtuar me sukses në sistemin shëndetësor.',
		successfullySaved: 'Ruajtja është bërë me sukses.',
		usersAddedOnHelloHealth: 'Përdoruesit janë ftuar me sukses në hellocare!',
		patientAssignedAsDeviceOwner: 'Pacienti është shtuar me sukses si pronar i pajisjes',
		manageHealthSystems: 'Menaxho sistemet shëndetësore',
		selectHealthSystemNurse: 'Ju lutem zgjedheni sistemin shëndetësor ku dëshironi ta regjistroni këtë doktor.',
		enterHealthSystem: 'Shëno sistemin shëndetësor',
		healthSystem: 'Sistemi shëndetësor',
		assignation: 'Përcaktim',
		unAssignableLevel: 'Nivel i papërcaktuar',
		selectAssignationLevel: 'Zgjedhni Nivelin/et e Caktimit',
		downloadCSV: 'Shkarko CSV',
		watchRecording: 'Shiko Inqizimin',
		recordedBy: 'Inqizuar nga',
		assignLevel: 'Përcakto nivelin',
		signsSymptoms: 'Shenjat dhe simptomat',
		selectSignsSymptomsAssociatedWithHealthConcerns:
			'Ju lutemi zgjedhni shenjat dhe simptomat e ndërlidhura me shqetësimet tuaja shëndetësore.',
		mostRecentMeasurementByDate: 'Kjo pamje shfaq matjen më të fundit të renditur sipas datës dhe orës.',
		searchForSymptoms: 'Kërko simptoma',
		noSymptomsFound: 'Nuk u gjetën simptomat',
		mySymptoms: 'Simptomat e mia',
		searchDoctors: 'Kërko mjek',
		pleaseWriteYourHealthConcerns: 'Ju lutem shënoni brengat tuaja shëndetësore !',
		errorOccurred: 'Ndodhi një gabim:',
		pleaseSelectTheRequiredFields: 'Ju lutem zgjedhni fushat e kërkuara!',
		symptomsAssociatedWithGeneralDoctors: 'Simptomat tuaja ndërlidhen me kategorinë e përgjithshme të mjekëve!',
		sendRequest: 'Dërgo kërkesën',
		scheduleAppointment: 'Planifiko termin',
		writeYourFirstName: 'Ju lutem shënoni emrin tuaj këtu...',
		writeYourLastName: 'Ju lutem shënoni mbiemrin tuaj këtu...',
		writeYourEmail: 'example@gmail.com',
		confirmEmail: 'Ju lutem konfirmoni e-mail adresën tuaj këtu...',
		writePassword: 'Ju lutem shënoni fjalëkalimin tuaj këtu...',
		search: 'Kërko',
		selectSerialNumber: 'Zgjedheni numrin serik',
		writeCurrentLocation: 'Ju lutem shënoni lokacionin tuaj aktual këtu...',
		writeHeight: 'Ju lutem shënoni gjatësinë tuaj këtu...',
		writeHeightUnit: 'Njësia e gjatësisë',
		writeWeight: 'Ju lutem shënoni peshën tuaj këtu...',
		writeWeightUnit: 'Njësia e peshës',
		healthSystemName: 'Emri i sistemit shëndetësor',
		regionName: 'Emri i regjionit',
		confirmPassword: 'Konfirmoni fjalëkalimin',
		firstName: 'Emri',
		lastName: 'Mbiemri',
		password: 'Fjalëkalimi',
		email: 'Email adresa',
		enterYourInfo: 'Të dhënat tuaja',
		verifyCellPhone: 'Numri i telefonit',
		licenseNumber: 'Numri i licensës',
		medicalSchool: 'Shkolla mjekësore',
		chooseADate: 'Zgjedhni një datë',
		companyName: 'Emri i kompanisë',
		positionTitle: 'Pozita/Titulli',
		specify: 'Specifiko',
		suffix: 'Prapashtesë',
		maidenName: 'Mbiemri i vajzërise',
		npiNumber: 'Numri NPI',
		internshipInstitutionName: 'Internship Institution Name',
		internshipSpecialty: 'Internship Specialty',
		residencyInstitutionName: 'Residency Institution Name',
		fellowshipInstitutionName: 'Fellowship Institution Name',
		fellowshipSpecialty: 'Fellowship Specialty',
		searchForBoard: 'Type to search for Board here..',
		medicaidProviderNumber: 'Medicaid Provider Number',
		medicareProviderNumber: 'Medicare Provider Number',
		birthDate: 'Data e lindjes',
		socialSecurityNumber: 'Social Security Number',
		yourAnswer: 'Përgjigja juaj',
		noResultsFound: 'Nuk u gjetën rezultate!',
		moreThanCharacters: 'Duhet të shenoni të paktën {value} shkronja për te kërkuar.',
		patientNotFound: 'Pacienti që kërkoni nuk ekziston!',
		showAllResults: 'Shfaq të gjitha rezultatet',
		advancedSearch: 'Kërkim i avancuar',
		filterType: 'Lloji i filterit',
		all: 'Të gjitha',
		patients: 'Pacientët',
		lowercaseToday: 'sot',
		lowercaseTomorrow: 'nesër',
		myHealthConcerns: 'Brengat e mia shëndetësore',
		describeYourHealthConcerns: 'Përshkruani brengat tuaja shëndetësore',
		thereAreAppointmentsFor: 'Janë {value} termine në dispozicion për',
		typeAppointmentTopic: 'Shkruani temën e takimit',
		whereWouldYouLikeMeetingTakePlace: 'Ku dëshironi që të zhvillohet ky takim?',
		firstNameCannotBeEmpty: 'Emri nuk mund të jetë i zbrazët',
		parentNameCannotBeEmpty: 'Emri i prindit nuk mund të jetë i zbrazët',
		idNumberCannotBeEmpty: 'Numri personal nuk mund të jetë i zbrazët',
		patientHealthCardNumberCannotBeEmpty: 'Numri i kartës shëndetësore nuk mund të jetë i zbrazët',
		professionCannotBeEmpty: 'Profesioni nuk mund të jetë i zbrazët',
		lastNameCannotBeEmpty: 'Mbiemri nuk mund të jetë i zbrazët',
		emailCannotBeEmpty: 'Email adresa nuk mund të jetë e zbrazët',
		rpmCannotBeEmpty: 'RPM programi nuk mund të jetë i zbrazët',
		enterFirstName: 'Shëno emrin',
		enterLastName: 'Shëno mbiemrin',
		virtualNurse: 'Infermiere virtuale',
		virtualCaseManager: 'Menagjer virtual i rasteve ',
		virtualIntensivist: 'Intensivist virtual',
		virtualPatient: 'Pacient Virtual',
		manualPatient: 'Pacient Manual',
		ehrPatient: 'Pacient EHR',
		memberSuccessfullyModified: 'Anëtari është modifikuar me sukses!',
		somethingWentWrongTryAgain: 'Diçka shkoi gabim! Ju lutem provoni sërish.',
		modifyVirtualCareProviderFirstName: 'Modifikoni emrin e kujdestarit virtual',
		modifyVirtualCareProviderLastName: 'Modifikoni mbiemrin e kujdestarit virtual',
		assignRoleVirtualCareProvider: 'Ju lutem caktoni një rol për kujdestarin virtual.',
		errorWhileSavingThePhotos: 'Gabim gjatë ruajtjes së fotografisë.',
		fileReadingWasAborted: 'Leximi i dosjes është abourtuar',
		fileReadingHasFailed: 'Leximi i dosjes ka dështuar',
		errorDeletingPhoto: 'Gabim duke fshirë fotografinë',
		dragAndDropOrSelectPhotos: 'Tërhiqni dhe vendosni disa fotografi këtu, ose klikoni të zgjedhni fotografitë',
		dragAndDropOrSelectAttachments: 'Tërhiqni dhe vendosni disa skedarë këtu, ose klikoni të zgjedhni skedarët',
		photos: 'Fotografitë',
		attachments: 'Bashkëngjitjet',
		fiveAttachmentsMaximumUpload: 'You mund të ngarkoni maksimumi deri në 5 skedarë',
		fileUploadMaximumSize: 'Madhësia e skedarit duhet të jetë maksimumi 5MB',
		noPhotosForThisUser: 'Nuk ka fotografi për këtë përdorues.',
		uploadPhotos: 'Ngarkoni fotografi',
		dateSent: 'Data e dërguar',
		invitedBy: 'Ftesa është bërë nga',
		role: 'Roli',
		addVirtualCareProvider: 'Shto një ofrues të kujdesit virtual',
		addAdministrator: 'Shto administrator',
		confirmation: 'Konfirmimi',
		areYouSureCancelInvitation:
			'A jeni të sigurt se doni të anuloni këtë ftesë? Linku që është dërguar tek përdoruesi është deaktivizuar dhe nuk do të mund të përdoret më.',
		overwrite: 'Mbishkruani',
		warning: 'Paralajmërim',
		featureOverwrite: 'Mbishkrim i veçorive',
		exclude: 'Përjashtoni',
		applyToAll: 'Aplikoni për të gjitha',
		endedTime: 'Koha e Përfundimit',
		startTime: 'Koha e Fillimit',
		roomsWithDifferentConfigs: 'Dhomat që kanë të vendosura ndryshe veçorit',
		waringOverwriteDescription:
			'Po përpiqeni të bëni ndryshime në një sektor që tashmë ka pajisje me një grup funksionesh të ndryshme nga ai që po përpiqeni të mbishkruani.',
		bySavingThisDescription: `Nëse dëshironi të anashkaloni veçorive në dhomat e mësipërme, atëherë ju lutemi zgjedhni Apliko për të gjithë.
			Nëse dëshironi t'i përjashtoni ato dhoma, ju lutemi zgjedhni Përjashtoni.`,
		areYouSureResendInvitation:
			'A jeni të sigurt se doni ta dërgoni sërish këtë ftesë? Përdoruesi do të pranoj edhe një e-mail duke e ftuar të bashkangjitet në hellocare.',
		areYouSureTwoFaReset:
			'A jeni të sigurt se doni ta resetoni autentifikimin me dy faktorë? Përdoruesi do të pranoj edhe një e-mail duke e ftuar të resetoj autentifikimin me dy faktorë.',
		userName: 'Emri i përdoruesit',
		dateInvited: 'Data e ftesës',
		dateAdded: 'Data e shtuar',
		healthSystems: 'Sistemet shëndetësore',
		areYouSureDeleteMember:
			'A jeni të sigurt se doni të fshini këtë anëtar? Ky anëtar do të largohet nga të gjitha sistemet dhe pajisjet e bashkangjitura me të.',
		userInfo: 'Informata të përdoruesit',
		userPhotos: 'Fotografi të përdoruesit',
		addAdminUsers: 'Shto admin përdorues',
		addSuperAdminUsers: 'Shto super-admin përdorues',
		deleteAlertSubscription: 'Fshij Abonimin e Sinjalizimeve',
		areYouSureYouWantToDeleteSubscription: 'Jeni të sigurt që dëshironi të fshini abonimin për {email}',
		setupDeviceAlerts: 'Rregullo Sinjalizimet e Pajisjes',
		deviceAlerts: 'Sinjalizimet e Pajisjes',
		receiveAlertsFrom: 'Merrni Sinjalizime nga',
		addAnotherEmailAddress: 'Shto një adresë email tjetër',
		setupAlertsFor: 'Rregullo Sinjalizimet për',
		recipients: 'Marësit',
		assignationLevel: 'Niveli i Caktimit',
		alertType: 'Lloji i Sinjalizimit',
		invitationDate: 'Data e Ftesës',
		deviceIsOffline: 'Pajisja është jashtë linje',
		anAlertMustBeSelected: 'Duhet të zgjidhet një sinjalizim',
		couldNotFindThisEmail: 'Nuk mund të gjendet ky email',
		alreadyAddedThisEmail: 'Ju tashmë keni shtuar këtë email',
		formShouldContainOneEmail:
			'Formulari duhet të përmbajë të paktën një adresë emaili të vlefshme. Nëse fusha është e mbushur, ju lutem shtypni Enter për të shtuar emailin.',
		addSuperUsers: 'Shto super përdorues',
		enterEmail: 'Shëno e-mail adresën',
		writeEmailOfVcpToInvite: "Ju lutemi shkruani të gjitha email adresat e kujdestarëve virtual që dëshironi t'i ftoni.",
		writeEmailOfVSToInvite: "Ju lutemi shkruani të gjitha email adresat e kujdestarëve virtual që dëshironi t'i ftoni.",
		writeEmailOfDCToInvite: "Ju lutemi shkruani të gjitha email adresat e klinicistëve digjital që dëshironi t'i ftoni.",
		writeEmailOfDoctorsToInvite: "Ju lutemi shkruani të gjitha email adresat e doktorëve që dëshironi t'i ftoni.",
		writeEmailOfAdminToInvite: "Ju lutemi shkruani të gjitha email adresat e admin përdoruesve që dëshironi t'i ftoni.",
		inviteMax5PeopleAtOnce: 'Ju mund ti ftoni 5 njerëz maksimalisht për një herë!',
		selectAtLeastOneHSToAddRole: "Ju lutem zgjedhni së paku një sistem shëndetësor në mënyrë që t'i shtohet një {value}",
		writeEmailsYouWishToInvite: "Ju lutem shkruani të gjitha email adresat e {value} që dëshironi t'i ftoni.",
		virtualCareProviders: 'kujdestarëve virtualë',
		addRole: 'Shto {value}',
		userManagement: 'Menaxhimi i përdoruesve',
		fleetManagement: 'Menaxhimi i Flotës',
		fleetDashboardDescription:
			'Kur ky është aktivizuar, përdoruesi do të jetë në gjendje të shohë gjendjet e pajisjeve në kohë reale në sistemin e shëndetit të zgjedhur.',
		fleetDashboard: 'Menaxhimi i Flotës',
		setupAlerts: 'Caktoni Sinjalizime',
		ofTheRooms: 'i dhomave',
		deviceAssignation: 'Caktimi i Pajisjes',
		connection: 'Lidhja',
		clearFilter: 'Pastro Filtrin',
		searchByRoomOrSN: 'Kërko sipas emrit të dhomës ose SN',
		ethernet: 'Ethernet',
		wireless: 'Pa tel',
		devicesAssigned: 'Pajisjet e Caktuara',
		members: 'Anëtarët',
		invitedMembers: 'Anëtarët e ftuar',
		invitedMember: 'Anëtari i ftuar',
		active: 'Aktive',
		notActive: 'Pa aktivizuar',
		patientPosition: 'Pacienti ka qenë joaktiv për 10 sekonda.',
		railDownMessage: 'Mbajtësja e shtratit u rrëzua. Ju lutem kontrolloni pacientin.',
		alertCheckThePatient: 'Vëmendje! Kontrolloni pacientin.',
		fallRiskCheckThePatient: 'Rrezik rrëzimi. Ju lutem kontrolloni pacientin.',
		pleaseCheckPatient: 'Ju lutem kontrolloni pacientin.',
		concerningMeasurements: 'Pacienti ka matje shqetësuse.',
		alertCheckTheBaby: 'Vëmendje! Kontrolloni foshnjën.',
		alertBabyExitingCrib: 'Vëmendje! Foshnja po del nga dhomëza.',
		missingTools: 'Veglat që mungojnë: {value}',
		surgicalToolsDetection: 'Detektim i veglave kirurgjike ',
		objectDetection: 'Detektim i objektit',
		babyDetection: 'Detektim i foshnjes',
		patientPositionDetection: 'Detektim i pozitës së pacientit',
		hospitalTimeZone: 'Zona Kohore e Spitalit',
		localTimeZone: 'Zona Kohore Lokale',
		actionRecognition: 'Njohja e veprimit',
		skeletonFallDetection: 'Detektim rrëzimi i skeleturës së njeriut ',
		privacyOnSkeletonDetection: 'Privatësia e ndezur në skeleturën e njerëzve',
		otoscopeConnected: 'Otoskopi është lidhur',
		switchView: 'Ndërro pamje',
		areYouSureRebootHuddleCam:
			'Jeni i sigurt që dëshironi të ristartoni kamerën {value}? Pas 30 sekondash pamja do të kthehet në perspektivën {value}.',
		chooseHealthSystemYouWantToManage: 'Zgjedh sistemin shëndetësor që ju doni ta menaxhoni.',
		chooseRegionFromHSManageHospitals: 'Zgjedh regjionin nga sistemet mbi në mënyrë që të menaxhoni spitalet në të.',
		togglePatientTVPowerOn: 'Ndiz TV e pacientit',
		volume: 'Volumi',
		switchPatientHDMI: 'Ndërro HDMI-në e pacientit',
		heartRateTooltip:
			'Ritmi i zemrës është shpejtësia e rrahjeve të zemrës e matur nga numri i kontraktimeve (rrahjeve) të zemrës në minutë (bpm). American Heart Association thotë se norma normale e zemrës së njeriut gjatë pushimit është 60-100 bpm.',
		bloodPressureTooltip:
			'Presioni i gjakut është presioni i qarkullimit të gjakut në muret e enëve të gjakut. Pjesa më e madhe e këtij presioni është për shkak të punës së bërë nga zemra duke pompuar gjak përmes sistemit të qarkullimit të gjakut. Përdoret pa specifikime të mëtejshme, "presioni i gjakut" zakonisht i referohet presionit në arteriet e mëdha të qarkullimit sistemik.',
		perfusionIndexTooltip:
			'Indeksi i perfuzionit ose PI është raporti i rrjedhës pulsatile të gjakut në rrjedhën statike jo pulsatile të gjakut në indin periferik të një pacienti, siç është në një majë të gishtit, shputës ose lobit të veshit. Indeksi i perfuzionit është një tregues i fuqisë së pulsit në vendin e sensorit.',
		stepsTooltip:
			'Numërimi i hapave është numri i hapave që ndërmerrni gjatë gjithë ditës. Pedometrat dhe gjurmuesit e veprimtarisë dixhitale mund të ju ndihmojnë të përcaktoni numrin tuaj të hapave. Këto pajisje numërojnë hapa për çdo aktivitet që përfshin lëvizje të ngjashme, duke përfshirë ecjen, vrapimin, ngjitjen e shkallëve, skijimin ndër-vend, dhe madje edhe lëvizjen kur shkoni për punët tuaja të përditshme.',
		flightsClimbedTooltip: 'Një ngjitje e shkallëve llogaritet si afro 10 feet(3 metra) fitim lartësie(afërsisht 16 hapa).',
		leanBodyMassTooltip:
			'Masa e dobët e trupit është sasia e peshës që mbani që nuk është yndyrë e trupit. Ajo përfshin muskujt por edhe kockat, ujin dhe indin lidhës, midis substancave të tjera.			',
		bodyFatPercentageTooltip:
			'Përqindja e yndyrës në trup është pesha e yndyrës në trupin tuaj, në krahasim me peshën totale të trupit tuaj, e cila përfshin muskujt, kockat, ujin etj.',
		bodyMassIndexTooltip:
			'BMI është një llogaritje që merr parasysh lartësinë dhe peshën tuaj. Mund t’ju thotë nëse jeni me peshë normale, nën peshë, mbipeshë ose obezë. Ai gjithashtu mund të vlerësojë rrezikun tuaj për sëmundje që mund të ndodhin me më shumë yndyrë të trupit.',
		bodyTemperatureTooltip:
			'Temperatura normale e trupit ndryshon në bazë të personit, moshës, aktivitetit dhe kohës së ditës. Temperatura mesatare normale e trupit pranohet në përgjithësi si 98,6 ° F (37 ° C). Një temperaturë mbi 100.4 ° F (38 ° C) më shpesh nënkupton që keni një ethe të shkaktuar nga një infeksion ose sëmundje. Temperatura e trupit normalisht ndryshon gjatë gjithë ditës.',
		oxygenSaturationTooltip:
			'Ngopja e oksigjenit është një masë e sasisë së oksigjenit në proteinë (hemoglobinë) në qelizat e kuqe të gjakut. Për të funksionuar siç duhet, trupi juaj ka nevojë për një nivel të caktuar oksigjeni që qarkullon në gjak. Qelizat tuaja të kuqe të gjakut janë të ngarkuara (të ngopura) me oksigjen në mushkëri dhe i bartin në të gjithë trupin tuaj.',
		sleepAnalysisTooltip:
			'Analiza e gjumit siguron njohuri për zakonet tuaja të gjumit. Gjurmuesit dhe monitorët e gjumit mund të ju ndihmojnë të përcaktoni sasinë e kohës kur jeni në shtrat dhe në gjumë. Këto pajisje vlerësojnë kohën tuaj në shtrat dhe kohën tuaj në gjumë duke analizuar ndryshimet në aktivitetin fizik, përfshirë lëvizjen gjatë natës. Ju gjithashtu mund të mbani gjurmët e gjumit tuaj duke futur vlerësimin tuaj të gjumit tuaj dhe kohën e gjumit me dorë. Periudha në shtrat ​​pasqyron periudhën kohore në të cilën jeni shtrirë në shtrat me qëllim të gjumit. Për shumicën e njerëzve fillon kur fikni dritat dhe mbaron kur të dilni nga shtrati. Periudha Gjumë pasqyron periudhën(at) në të cilën(cilat) jeni duke fjetur.',
		bloodGlucoseTooltip:
			'Glukoza e gjakut, e quajtur edhe sheqer në gjak, është sheqeri kryesor që gjendet në gjakun tuaj. Ai vjen nga ushqimi që hani, dhe është burimi kryesor i energjisë i trupit tuaj.',
		stethoscopeTooltip:
			'Stetoskopi është një pajisje mjekësore akustike për auskultation, ose duke dëgjuar tingujt e brendshëm të një kafshe ose trupi njerëzor. Një stetoskop mund të përdoret për të dëgjuar tingujt e bërë nga zemra, mushkëritë ose zorrët, si dhe rrjedhja e gjakut në arteriet dhe venat.',
		respiratoryRateTooltip:
			'Shkalla e frymëmarrjes është niveli juaj i frymëmarrjes(marrja dhe nxjerrja) brenda një kohe të caktuar(zakonisht 60 sekonda).',
		noDataFound: 'Nuk u gjetën të dhëna për këto data',
		noDataAvailable: 'Nuk ka të dhëna në dispozicion',
		tryOtherDateRange: 'Provo duke klikuar në një periudhë tjetër të datave!',
		healthSystemDoesNotHaveCreatedHospitals:
			"Ky sistem shëndetësor nuk ka spitale të krijuara. Sigurohuni t'i krijoni ato para se t'i caktoni infermieret!",
		requestAccepted: 'Kërkesa u pranua',
		requestRejected: 'Kërkesa u refuzua',
		clickAllowToMakeCall: 'Shtyp �Allow� për të bërë thirrje',
		allowPermissionToMakeCall: "Për të bërë thirrje në hellocare, ju duhet t'i lejoni permissions për mikrofon dhe kamerë.",
		participantHearAndSeeYou: 'Kjo do të lejojë pjesëmarrësit tjerë të ju degjojnë dhe shikojnë',
		canTurnOffMicCameraLater: 'Ju çdo herë mund ta fikni mikrofonin dhe kamerën më vonë ',
		turnOnMicCamera: 'Ndizeni mikrofonin dhe kamerën',
		deviceNotFound: 'Pajisja nuk u gjet',
		videoCallTurnOnCamera: 'Për të bërë thirrje në hellocare, ju duhet të ndizni kamerën ',
		audioCallTurnOnMicRefreshPage:
			'Për audio thirrje, ju duhet të ndizni mikrofonin tuaj. Provoni sërish duke e rifreskuar faqen.',
		deviceMayBeInUse: 'Pajisja mund të jetë në përdorim',
		camOrMicAnotherApp: 'Kamera apo mikrofoni mund të jetë në përdorim në ndonjë aplikacion tjetër.',
		closeOtherAppRefresh: 'Ju lutem mbylleni aplikacionin tjetër dhe rifreskoni faqen.',
		noData: 'Nuk ka të dhëna',
		pleaseReachAdmin: 'Ju lutemi kontaktoni administratorin që të keni ato konfigurime në dispozicion.',
		overviewDescription:
			'Kur ky është aktivizuar, të dhënat e përgjithshme për monitorimin, rrethimin dhe bazuar në rol do të shfaqen.',
		providerCentricReportDescription:
			'Kur ky është aktivizuar, të dhënat për ofruesit mbi numrin e seancave, pacientëve dhe seancave',
		patientCentricReportDescription:
			'Kur ky është aktivizuar, të dhënat e pacientit si numri i seancave, ndërhyrjet dhe veprimet e marrë do të shfaqen.',
		plsReachAdministratorToHaveThisFeatureAvailable:
			'Ju lutemi kontaktoni administratorin tuaj që të keni këtë veçori në dispozicion.',
		selectOneOfConnectedDoctors: 'Zgjedh një nga mjekët e lidhur!',
		noConnectedDoctor: 'Nuk keni ende doktor të lidhur!!',
		noActionsFound: 'Nuk u gjindën veprime',
		noHelloIsSelected: 'Nuk ke asnjë pajisje të lidhur {value}!',
		tryBySelectingOne: 'Provoni duke zgjedhur një!',
		optionInitiateAudioCall:
			"Ky opcion do të inicoj thirrje drejtuar pacientit, ata do të mund të ju dëgjojnë juve dhe ju do të mund t'i dëgjoni ata Gjatë kësaj thirrje, ju mund ta ndezni kamerën e pacientit, apo edhe ta shfaqni ekranin tuaj.",
		myPatients: 'Pacientët e mi',
		myHellos: '{value}-të e mia ',
		noConnectedPatientsYet: 'Nuk keni ende pacient të lidhur!',
		noConnectedDoctorsYet: 'Nuk keni ende doktor të lidhur!',
		editDepartmentDetails: 'Ndrysho detajet e departamentit',
		deleteDepartment: 'Fshij departamentin',
		floorsInThisDepartment: 'Katet në këtë departament',
		roomsInThisDepartment: 'Dhomat në këtë departament',
		enrolledDevices: 'Pajisjet e kyçura',
		enrolledCompanionDevices: 'Pajisjet shoqëruese',
		devices: 'Pajisjet',
		rooms: 'Dhomat',
		floors: 'Katet',
		deviceSerialNumber: 'Numri serik i pajisjes',
		tvSerialNumber: 'Numri serik i televizorit',
		deviceEnrolled: 'Pajisja e kyçur',
		deviceEnrollment: 'Regjistrimi i pajisjes',
		enrollment: 'Regjistrimi',
		companyDeviceEnrollment: 'Regjistrimi i pajisjes në nivel kompanie',
		actions: 'Veprimet',
		roomNames: 'Emrat e dhomave',
		roomNumber: 'Numri i dhomës',
		writeRoomNumber: 'Shkruani numrin e dhomës',
		floorNames: 'Emrat e kateve',
		unassign: 'Hiq përcaktimin ',
		unassignDevice: 'Hiq përcaktimin e pajisjes',
		unassignPatient: 'Hiq përcaktimin e pacientit',
		sureToUnassignDevice:
			'A jeni i sigurtë se dëshironi ta fshini pajisjen {value}? As ju e as pacienti nuk do të mund të bëni thirrje.',
		sureToUnassignPatient:
			'A jeni i sigurtë se dëshironi ta fshini {value} nga pajisja? As ju e as pacienti nuk do të mund të bëni thirrje.',
		sureToDeleteOrg:
			'A jeni i sigurtë se dëshironi ta fshini këtë {value}? Cilado pajisje e ndërlidhur me të do të fshihet dhe as kujdestari virtual e as pacientët nuk do të mund të bëjnë thirrje',
		editFloorDetails: 'Ndrysho detajet e katit',
		deleteFloor: 'Fshij katin',
		roomsInThisFloor: 'Dhomat në këtë kat.',
		signInToYourAccount: 'Kyçuni në llogarinë tuaj',
		editHospitalDetails: 'Ndrysho detajet e spitalit',
		deleteHospital: 'Fshij spitalin',
		departmentsInThisHospital: 'Departamentet në këtë spital',
		floorsInThisHospital: 'Katet në këtë spital',
		roomsInThisHospital: 'Dhomat në këtë spital',
		departmentNames: 'Emrat e departamenteve',
		departments: 'Departamentet',
		editRoomDetails: 'Ndrysho detajet e dhomës',
		roomDetails: 'Detajet e dhomës',
		roomType: 'Tipi i dhomës',
		familyMember: 'Anëtar i familjes',
		familyMembers: 'Pjesëtarët e familjes',
		babyRoom: 'Dhoma e bebeve',
		assignFamilyMember: 'Cakto një anëtar të familjes',
		deleteRoom: 'Fshij dhomën',
		addDevice: 'Shto pajisje',
		addCompanionDevice: 'Shto pajisjen shoqëruese',
		roomDevice: 'Pajisja e dhomës',
		assignDevice: 'Përcakto pajisjen',
		assignCompanionDevice: 'Përcakto pajisjen shoqëruese',
		selectSerialNumberAssociateWithRoom: 'zgjedhni një numër serik për ta ndërlidhur me këtë dhomë',
		unitPhoneNumberMust10: `Numri i telefonit të njësisë duhet të ketë 10 shifra`,
		selectCompanionMode: 'Zgjidhni modalitetin shoqërues',
		selectCompanionDevice: 'Zgjidhni pajisjen shoqëruese',
		theSectorTypeRoomLocatedAt: 'Kjo dhomë është vendosur në {value}.',
		download: 'Shkarko',
		document: 'Dokument',
		errorWhileFetchingPatients: 'Gabim duke shfaqur pacientat',
		selectPatientHelloDevice: 'Zgjidhni një nga Përdoruesit/{value} Pajisjeve!',
		noConnectedPatients: 'Nuk ka pacient të lidhur',
		joinHELLOHealth: 'Bashkangjitu në hellocare',
		haveVisitInMinutes: 'Bëj një vizitë për disa minuta',
		createUserAccount: 'Krijo një llogari të përdoruesit',
		createProviderAccount: 'Krijo llogari të kujdestarit',
		createHealthSystemProfile: 'Krijo një profil të sistemit shëndetësor',
		addRegionsForThisHealthSystem: 'Shto regjione për këtë sistem shëndetësor',
		addNew: 'Shto të re ',
		healthSystemCreatedSuccessfully: 'Profili juaj i sistemit shëndetësor është krijuar me sukses.',
		accountBeingCreatedMayTakeMinutes: 'Profili juaj është duke u krijuar. Kjo mund të merr disa minuta!',
		voiceCommandForEmergencyAssistance: `Ndihma Emergjente`,
		vCEADesc: `Kur ky opsion është aktivizuar, infermierët në anën e krevatit dhe pacientët do të mund ta aktivizojnë duke thënë "Hellocare Kod Blu"`,
		voiceCommandForCareEvents: `Ngjarjet e Kujdesit`,
		vCCEDesc: `Kur ky opsion është aktivizuar, infermierët në anën e krevatit dhe pacientët do të mund ta aktivizojnë duke thënë "Hellocare hap Ngjarjet e Kujdesit"`,
		voiceCommandForPrivacyEnabling: `Privatësia`,
		vCPDesc: `Kur ky opsion është aktivizuar, infermierët në anën e krevatit dhe pacientët do të mund ta aktivizojnë duke thënë "Hellocare Aktivizo privatësinë"`,
		voiceCommandForCallNurse: `Thirr Infermierin`,
		vCCNDesc: `Kur ky opsion është aktivizuar, pacientët mund të thërrasin infermierët duke thënë "Hellocare Call Nurse" ose "Hellocare Call the Nurse"`,
		voiceCommands: 'Komandat me Zë',
		invalidInvitation: 'Kjo ftesë nuk është valide',
		inviteAlreadyAccepted: 'Kjo ftesë është e pranuar',
		inviteCancelled: 'Kjo ftesë është anuluar nga administratori',
		inviteExpired: 'Kjo ftesë ka skaduar',
		checkInvitationLinkValidity:
			'Kthehuni në e-mailin tuaj dhe sigurohuni se linku që keni klikuar është valid. Gjithashtu, kontaktoni administratorin tuaj që të siguroheni se ju nuk e keni pranuar këtë ftesë më herët.',
		anotherNursePickedUpCall: 'Një infermiere tjetër u përgjigj në këtë telefonatë.',
		waitAttemptingReconnect: 'Ju lutem prisni… duke tentuar të lidhemi prapë në rrjet.',
		manageHealthSystem: 'Menaxho sistemin shëndetësor',
		changesWillBeAppliedToThisHS:
			'Çdo ndryshim që ju bëni do të aplikohet në këtë sistem. Ju mund të ri-emëroni apo të fshini këtë sistem.',
		pressEnterToSave: 'Ju lutem shtypni Enter për ta ruajtur',
		regions: 'Regjionet',
		availableRegionsInThisHS:
			'Këto janë regjionet në dispozicion për këto sisteme shëndetësore. Krijoni më shumë, ri-emëroni, apo fshini regjionet.',
		addNewRegion: 'Shto regjion të ri',
		areYouSureToDeleteRegion: 'A jeni të sigurt se doni ta fshini ketë regjion?',
		areYouSureToDeleteHS: 'A jeni të sigurt se dëshironi ta fshini këtë sistem shëndetësor?',
		sectorIsLocatedAt: 'Kjo {value2} ndodhet në këtë {value}',
		boardType: 'Lloj i Bordit',
		selectBoard: 'Zgjidhni bordin',
		rowsPerPage: 'Rreshta për faqe',
		minttiStethoscope: 'Mintti Stetoskop',
		littmannStethoscope: 'Littmann Stetoskop',
		manuallyAdded: 'E shtuar manualisht',
		sipEndpoint: 'SIP Endpoint-i',
		addData: 'Shto të dhëna',
		systolic: 'Sistolike',
		diastolic: 'Diastolike',
		selectStartDate: 'Zgjedh datën fillestare',
		selectEndDate: 'Zgjedh datën përfundimtare',
		allRecordedData: 'Të gjitha të dhënat e incizuara',
		deleteAllDataRecords: 'Fshini të gjitha incizimet e të dhënave',
		add: 'Shto',
		addDataManually: 'Shto të dhëna manualisht',
		dateAndTime: 'Data dhe koha',
		inBed: 'Në shtrat',
		inChair: 'Në karrige',
		asleep: 'Në gjumë',
		selectState: 'Zgjedh shtetin',
		done: 'Përfundoni',
		deleteThisDataRecord: 'Fshini këtë të dhënë',
		dataDetails: 'Detajet e të dhënave',
		dateOfMeasurement: 'Data e përmasës',
		source: 'Burimi',
		manufacturer: 'Prodhuesi',
		model: 'Modeli',
		areYouSureDeleteThisRecord: 'A jeni të sigurt që doni ta fshini këtë të dhënë?',
		areYouSureDeleteAllRecords: "A jeni të sigurt që doni t'i fshini të gjitha të dhënat?",
		documentName: 'Emri i dokumentit',
		creationDate: 'Data e krijimit',
		attachment: 'E bashkangjitur',
		reboot: 'Ristarto',
		rebootCamera: 'Ristarto kamerën',
		patientsTV: 'TV e pacientit',
		togglePatientTVtoHDMI: 'Lidhe TV e pacientit me HDMI',
		maxCharacterLengthExceeded: 'Keni tejkaluar numrin maksimal të karaktereve',
		firstNameMustStartWithCapitalLetter: 'Emri duhet të filloj me shkronjë të madhe.',
		pleaseWriteFirstName: 'Ju lutem shënoni emrin tuaj',
		lastNameMustStartWithCapitalLetter: 'Mbiemri juaj duhet të filloj me shkronjë të madhe.',
		pleaseWriteLastName: 'Ju lutem shënoni mbiemrin tuaj',
		emailAlreadyRegistered: 'E-mail i dhënë ekziston',
		emailAlreadyExists: 'Ky email tashmë ekziston.',
		invalidEmail: 'E-mail i dhënë është jo valid',
		pleaseWriteEmail: 'Ju lutem shënoni e-mail adresën tuaj',
		newEmailDifferent: 'Email-i juaj i ri duhet të jetë ndryshe nga i vjetri.',
		pleaseWriteDateOfBirth: 'Ju lutem shënoni datën e lindjes',
		emailsMustMatch: 'E-mails adresat duhet të përputhen',
		passwordsMustMatch: 'Fjalëkalimet duhet të përputhen',
		pleaseConfirmYourEmail: 'Ju lutem konfirmoni e-mail adresën tuaj',
		passwordCannotBeLessThan8Chart:
			'Fjalëkalimi juaj duhet të përmbajë së paku 8 karaktere, një shkronjë të madhe, një të vogël, një shenjë speciale dhe një numër',
		pleaseWritePassword: 'Ju lutem shënoni fjalëkalimin tuaj',
		pleaseWriteConfirmPassword: 'Ju lutem konfirmoni fjalëkalimin tuaj',
		haveToAgreeToTermsAndConditions: 'Ju duhet të pajtoheni me kushtet e përdorimit!',
		haveToAgreePrivacyPolicy: 'Ju duhet të pajtoheni me politikat e privatësisë',
		locationMustStartWithCapitalLetter: 'Lokacioni duhet të filloj me shkronjë të madhe',
		pleaseWriteCurrentLocation: 'Ju lutem shënoni lokacionin tuaj aktual',
		pleaseSelectDateOfBirth: 'Ju lutem shënoni datën e lindjes',
		pleaseSelectSex: 'Ju lutem zgjedhni seksin',
		pleaseEnterHeight: 'Ju lutem shënoni gjatësinë tuaj',
		pleaseEnterWeight: 'Ju lutem shënoni peshën tuaj',
		pleaseSelectOne: 'Ju lutem zgjedhni një',
		pleaseSelectHealthInsurancePlan: 'Ju lutem zgjedhni planin tuaj të sigurimit shëndetësor',
		pleaseWriteSubscriberId: 'Ju lutem shënoni Subscriber ID tuaj',
		pleaseWritePrimarySubscriberFirstName: 'Ju lutem shënoni emrit tuaj të abonuesit primar',
		pleaseWritePrimarySubscriberLastName: 'Ju lutem shënoni mbiemrin tuaj të abonuesit primar',
		pleaseWritePrimarySubscriberBirthDate: 'Ju lutem shënoni ditën e lindjes të abonuesit primar',
		serviceKeyLearnMore:
			'Ju mund të pranoni një Service Key nga punëdhënësi juaj, plani i shëndetit, ose nga ofruesi i kujdesit shëndetësor për të aktivizuar një shërbim të ri. Është opsionale dhe jo e domosdoshme.',
		numberTimesFallen: 'Numri i rrëzimeve',
		lowest: 'Më e ulëta',
		average: 'Mesatare',
		highest: 'Më e larta',
		changePresence: 'Ndrysho prezencën',
		height: 'Gjatësia',
		width: 'Gjerësia',
		bodyMassIndex: 'Indeksi i masës trupore',
		primaryDiagnosis: 'Diagnoza primare',
		headInjury: 'Lëndim i kokës',
		basicInformation: 'Informacione bazike',
		fillUpFormOnRightSide: 'Mbushni formën në anën e djathtë duke përdorur të dhënat tuaja reale!',
		currentLocation: 'Lokacioni aktual',
		back: 'Kthehu prapa',
		continue: 'Vazhdoni',
		signUp: 'Regjistrohu',
		alreadyHaveAnAccount: 'Tashmë keni një llogari',
		clickHereToSignIn: 'Klikoni këtu për tu kyqur',
		personalInformation: 'Informatat personale',
		agreeToThe: 'Unë pajtohem me',
		termsAndConditions: 'Termat dhe kushtet',
		privacyPolicy: 'Politikat e privatësisë',
		healthInsurance: 'Sigurim shëndetësor',
		doYouHaveHealthInsurance: 'A keni sigurim shëndetësor?',
		yes: 'Po',
		no: 'Jo',
		serviceKey: 'Çelësi i shërbimit',
		enterReceivedKey: 'Nëse keni pranuar një çelës nga punëdhënësit tuaj ose ndonjë grupi tjetër, shënoni këtu.',
		learnMore: 'Mëso më shumë',
		finish: 'Përfundo',
		yourAccountHasBeenSuccessfullyCreated: 'Llogaria juaj është krijuar me sukses!',
		checkEmailToActivateAccount: 'Shikoni email për të aktivizuar llogarinë tuaj.',
		yourAccountActivated: 'Llogaria juaj është aktivizuar!',
		continueToSignIn: 'Vazhdoni për tu kyqur',
		accountActivationFailedTryAgain: 'Aktivizimi i llogarisë tuaj ka dështuar! Ju lutem provoni përsëri',
		nurse: 'Infermier/e',
		virtualCareProvider: 'Ofruesi i kujdesit virtual',
		outgoing: 'Dalëse',
		incoming: 'Hyrëse',
		viewAll: 'Shikoni të gjitha',
		callInitiator: 'Iniciuesi i thirrjes',
		room: 'Dhomë',
		dateSlashTime: 'Data/Koha',
		callOrigin: 'Origjina e thirrjes',
		callStatus: 'Statusi i thirrjes',
		callDuration: 'Kohëzgjatja e thirrjes',
		successful: 'I suksesshëm',
		successfulDescription: 'Të gjithë pjesëmarrësit mundën të bashkoheshin.',
		partiallySuccessful: 'Pjesërisht i suksesshëm',
		partiallySuccessfulDescription: 'Disa nga pjesëmarrësit nuk mundën të bashkoheshin për shkak të gabimeve.',
		failed: 'Dështoi',
		failedDescription: 'Asnjë nga pjesëmarrësit nuk mundi të lidhej për shkak të gabimeve.',
		disrupted: 'Ndërprerë',
		disruptedDescription:
			'Thirrja u krijua me sukses, por gjatë thirrjes veprimet e dikujt shkaktuan që të gjithë të humbasin aftësinë për të dëgjuar dhe parë njëri-tjetrin.',
		partiallyDisrupted: 'Pjesërisht i ndërprerë',
		partiallyDisruptedDescription:
			'Thirrja u krijua me sukses, por gjatë thirrjes veprimet e dikujt shkaktuan që disa nga pjesëmarrësit të humbasin aftësinë për të dëgjuar dhe parë njëri-tjetrin.',
		missed: 'Humbur',
		missedDescription: 'Thirrja u bë me sukses, por pjesëmarrësi i ftuar e humbi thirrjen.',
		aborted: 'Abortuar',
		abortedDescription: 'Thirrja ka përfunduar, ndërsa të tjerët ishin ende duke u lidhur ose thirrja nuk ishte krijuar ende.',
		dropped: 'Braktisur',
		droppedDescription:
			'Konferenca shënohet si e braktisur, nëse thirrja është krijuar, por pjesëmarrësi shkëputet duke shkaktuar përfundimin e gjithë konferencës.',
		excludeDurationValidation: 'Përjashtimi i kohëzgjatjes duhet të jetë midis 1 dhe 60 sekonda!',
		excludeStatusValidation: 'Nuk mund të përjashtoni të gjitha statuset e thirrjeve!',
		excludeDurationInfo:
			'Kur zbatohet filtri i kohëzgjatjes, telefonatat e humbura, të ndërprera dhe të dështuara përjashtohen nga raporti.',
		excludeCalls: 'Përjashto thirrjet',
		under: 'nën',
		withStatus: 'me statusin',
		audioUsed: 'Audio e përdorur',
		videoUsed: 'Video e përdorur',
		contentSharing: 'Shpërndarja e përmbajtjes',
		queue: 'Rradha',
		export: 'Eksporto',
		region: 'Regjioni',
		hospital: 'Spitali',
		department: 'Departamenti',
		floor: 'Kati',
		queueOfNursesThatWereCalled: 'Rradha e infermiereve që janë thirrur.',
		generalSettings: 'Aranzhimi i përgjithshëm',
		closeSession: 'Mbyll sesionin',
		selectMemberToInvite: 'Selekto një anëtar për të dërguar ftesë',
		selectMeetingPlaceDevice: 'Selekto një vend ose pajisje për takim',
		deviceOnCall: 'Pajisja është në një thirrje tjetër.',
		deviceOnCallWithNurse: 'Pajisja është duke u monitoruar nga',
		deviceOffline: 'Pajisja nuk është në dispozicion',
		initiatingMonitoring: 'Duke filluar monitorimin...',
		notAnswering: 'Lidhja dështoi! Ju lutemi provoni përsëri.',
		leftCall: 'Ka dështuar rikonektimi në rrjet. Ju lutem provoni përsëri.',
		disconnected: 'Pacienti është shkëputur nga thirrja.',
		connected: 'Lidhur',
		reconnecting: 'Ju lutem prisni…po provohet te lidheni përsëri në rrjet.',
		addToMonitoringView: 'Shto në hapësirën e monitorimit',
		organization: 'Organizata',
		callLogs: 'Regjistrat e thirrjeve',
		collapse: 'Mbylle',
		recentLowerCase: 'Të fundit',
		hospitals: 'Spitalet',
		rounding: 'Vizitat',
		patientRequests: 'Kërkesat e Pacientit',
		solutions: 'Zgjidhjet',
		providers: 'Ofruesit',
		monitoring: 'Monitorimi',
		close: 'Mbylle',
		addHospital: 'Shto një spital',
		create: 'Krijo',
		createMeeting: 'Krijo takimin',
		monday: 'E Hënë',
		tuesday: 'E Martë',
		wednesday: 'E Mërkurë',
		thursday: 'E Enjte',
		friday: 'E Premte',
		saturday: 'E Shtunë',
		sunday: 'E Dielë',
		bloating: 'Fryerje / ënjtje',
		cough: 'Kollë',
		diarrhea: 'Barkqitje / Diarre',
		dizziness: 'Marramendje',
		fatigue: 'Lodhje',
		fever: 'Ethe',
		headache: 'Dhimbje koke',
		muscleCramp: 'Shtrëngim i muskujve',
		nausea: 'Të përziera',
		throatIrritation: 'Acarim i fytit',
		runnyNose: 'Hundë e lëngshme',
		throwingUpVomiting: 'Të vjella',
		soreThroat: 'Dhimbje të fytit',
		headPressure: 'Presioni i kokës',
		lowBodyTemperatureDescription: 'Temperatura e ulët e trupit',
		highBodyTemperatureDescription: 'Temperatura e lartë e trupit',
		backPain: 'Dhimbje e shpinës',
		appetiteChanges: 'Ndryshim oreksi',
		earache: 'Dhimbje veshi',
		sweating: 'Djersitje',
		sneezing: 'Teshtitje',
		fainting: 'Të fikët',
		skinRash: 'Skuqje e lëkurës',
		insomnia: 'Pagjumësi (Insomni)',
		itchySkin: 'Kruarje e lëkures',
		jointPainAndSwelling: 'Dhimbje e nyjeve dhe ënjtje',
		kneePain: 'Dhimbje në gjunjëve',
		legPain: 'Dhimbje e këmbëve',
		limbNumbness: 'Mpirje e gjymtyrëve',
		muscleAchesAndPains: 'Dhimbje e muskujve',
		nosebleeds: 'Gjakderdhje nga hunda',
		shoulderPain: 'Dhimbje e shpatullave',
		stomachPain: 'Dhimbje e lukthit',
		swellingInJoints: 'Ënjtje në nyje',
		tummyPain: 'Dhimbje barku',
		addDepartment: 'Shto një departament',
		support: 'Ndihmës',
		sla: 'SLA',
		na: 'N/A',
		addFloor: 'Shto një kat',
		addRoom: 'Shto një dhomë',
		fullScreen: 'Ekran i plotë',
		exitFullScreen: 'Dil nga ekrani i plotë',
		fitToScreen: 'Përshtat për ekran',
		screenshare: 'Shfaq ekranin',
		sound: 'Volumi',
		turnPatientTVOff: 'Ndal TV e Pacientit',
		turnPatientTVOn: 'Lësho TV e Pacientit',
		changeEmail: 'Ndrysho email',
		changePassword: 'Ndrysho fjalëkalimin',
		currentPassword: 'Fjalëkalimi aktual',
		enterCurrentPassword: 'Shkruani fjalëkalimin aktual',
		enterYourCurrentPassword: 'Shkruani fjalëkalimin aktual',
		enterNewPassword: 'Shkruani fjalëkalimin e ri',
		reEnterNewPassword: 'Rishkruani fjalëkalimin e ri',
		updatePassword: 'Përditëso Fjalëkalimin',
		editChangeAccountInfo: 'Ndryshoni informatat e llogarisë suaj',
		statusCurrentlyOnline: 'Për momentin jeni në dispozicion',
		statusCurrentlyOffline: 'Për momentin nuk jeni në dispozicion',
		goBackToHealthApp: 'Kthehuni prapa tek aplikacioni hellocare',
		passwordChangedSuccessfully: 'Fjalëkalimi juaj është ndërruar me sukses',
		emailChangedSuccessfully: 'Email-i juaj është ndërruar me sukses',
		oldPasswordIncorrect: 'Fjalëkalimi juaj momental është i pasaktë',
		errorDuringRequestProcessing: 'Një gabim ka ndodhur gjatë përpunimit të kërkesës suaj',
		passwordRequired: 'Fjalëkalimi duhet të shkruhet',
		atLeastCharacters: 'Fjalëkalimi duhet të ketë të paktën',
		characters: 'karaktere',
		notMatchCurrentPassword: 'Fjalëkalimi i ri nuk duhet të jetë i njëjtë me fjalëkalimin e vjetër',
		mustMatchPassword: 'Duhet të jetë i njëjtë me fjalëkalimin e ri',
		passwordConfirmationRequired: 'Kërkohet konfirmimi i fjalëkalimit',
		participantCameraOff: 'Kamera e {value}-it është e ndalur.',
		modifyUserInformation: 'Modifiko të dhënat e përdoruesit',
		zipCode: 'Kodi Postal',
		country: 'Vendi',
		city: 'Qyteti',
		state: 'Shteti',
		visitCostCurrency: 'Valuta për çmimin e vizitës',
		dateOfBirthRequired: 'Data e lindjes duhet të shkruhet',
		countryRequired: 'Shteti duhet të shkruhet',
		stateRequired: 'Shteti duhet të shkruhet',
		cityRequired: 'Qyteti duhet të shkruhet',
		zipCodeRequired: 'Kodi postal duhet të shkruhet',
		addressRequired: 'Adresa duhet të shkruhet',
		enterCity: 'Shkruaj qytetin tënd',
		enterZipCode: 'Shkruaj kodin postal',
		enterAddress: 'Shkruaj adresën tënde',
		enterVisitCost: 'Shkruaj çmimin e vizitës',
		writeWelcomeMessage: 'Shkruaj tekstin e mirëseardhjes',
		profileEditedSuccessfully: 'Profili juaj është edituar me sukses',
		profilePicture: 'Fotografia e profilit',
		updatePicture: 'Ndrysho fotografinë',
		shouldUploadProfilePicture: 'Vendos një foto të profilit',
		select: 'Selekto',
		visitCostRequired: 'Shkruaj çmimin e vizitës',
		visitCostCurrencyRequired: 'Shkruaj valutën e çmimit të vizitës',
		welcomeMessageRequired: 'Shkruaj mesazhin mikpritës',
		from: 'prej',
		to: 'deri',
		requestDoctor: 'Kërko një doktor',
		thankYou: 'Faleminderit',
		requestSentSuccessfully: 'Kërkesa juaj është dërguar me sukses',
		goToThe: 'Shko tek',
		toGetStarted: 'për të filluar',
		requests: 'Kërkesat',
		noConversation: 'Nuk keni ndonjë bisedë me {value}',
		startConvo: 'Fillo bisedën duke shkruar një mesazh apo ndonjë dosje',
		noRequestsFound: 'Nuk keni ende asnjë kërkesë!',
		noMatchesFound: 'Nuk kemi ndonjë rezultat',
		noDoctorsBasedOnSymptoms: 'Nuk është gjetur asnjë doktor që i përshtatet simptomave dhe problemeve tuaja shëndetësore',
		noHealthDataFound: 'Nuk keni ende të dhëna shëndetësore!',
		noHealthDataCurrently: 'Momentalisht nuk keni të shtuar asnjë të dhënë shëndetësore',
		newConnection: 'Lidhje e re',
		isNowConnection: 'tani është lidhur me ju.',
		removeConnection: 'Fshij lidhjen',
		callViewMessage: "Ju mund të thirrni, t'i shkruani apo të shikoni të dhënat mjekësore të {value} përmes faqes së pacientit",
		goToPatient: 'Shko tek pacienti',
		newConfiguration: 'Konfigurim i ri',
		addPrivacyConfiguration: 'Shto konfigurimin e privatësisë',
		editPrivacyConfiguration: 'Ndrysho konfigurimin e privatësisë',
		selectPrivacy: 'Zgjedh Privatësinë',
		createdBy: 'Krijuar nga',
		dateCreated: 'Data e krijimit',
		isHealthSystemActive: 'A është sistem i shëndetësisë aktiv',
		isPrivateModeOn: 'A është privatësia e aktivizuar',
		healthCareId: 'ID e Healthcare',
		privacyModeIsOn: 'Privatësia është aktivizuar',
		configurations: 'Konfigurimet',
		turnOffSurgicalTools: 'Ndal Modin e veglave kirurgjike',
		turnOffBabyDetection: 'Ndal Modin e foshnjes',
		turnOnSurgicalTools: 'Lësho Modin e veglave kirurgjike',
		turnOnBabyDetection: 'Lësho Modin e foshnjes',
		turnOffPatientPosition: 'Ndalo pozicionin e pacientit',
		turnOnPatientPosition: 'Lësho pozicionin e pacientit',
		turnOffBedAndRails: 'Ndalo Modin e Shtratit',
		turnOffSkeletonAndPrivacy: 'Ndalo Modin e Skeletonit',
		turnOnBedAndRails: 'Lësho Modin e Shtratit',
		turnOnSkeletonAndPrivacy: 'Lësho Modin e Skeletonit',
		turnOnObjectDetection: 'Lësho deketimin e objektit',
		turnOffObjectDetection: 'Ndalo deketimin e objektit',
		breathsPerMin: 'frymëmarrje për minutë',
		unknownDevice: 'Pajisje e panjohur',
		activities: 'Aktivitetet',
		inputValue: 'Vlera',
		privateMode: 'Gjendje private',
		doctorOnList: 'Doktor',
		nurseOnList: 'Infermier',
		patientOnList: 'Pacient',
		adminOnList: 'Administrator',
		superUserRoleValidation: "Roli i Super Përdoruesit nuk mund t'u caktohet shumë sistemeve shëndetësore",
		superUserHealthSystemValidation: 'Super Përdoruesi duhet të caktohet në një sistem shëndetësor',
		superUserOnList: 'Super Përdorues',
		superUser: 'Super Përdorues',
		superAdminOnList: 'Super Administrator',
		ownerOnList: 'Pronar',
		sentOnList: 'U dërgua',
		registeredOnList: 'U regjistrua',
		pendingOnList: 'Në pritje',
		cancelledOnList: 'U anulua',
		failedOnList: 'Dështoi',
		virtualSitter: 'Kujdestar virtual',
		undefinedOnList: 'I padefinuar',
		successfulOnList: 'I suksesshëm',
		partiallySuccessfulOnList: 'Pjesërisht i suksesshëm',
		disruptedOnList: 'I ndërprerë',
		partiallyDisruptedOnList: 'Pjesërisht i ndërprerë',
		missedOnList: 'I humbur',
		abortedOnList: 'I dështuar',
		droppedOnList: 'I rënë',
		on: 'Lëshuar',
		off: 'Ndalur',
		fitnessTracker: 'Gjurmues i palestrës',
		bodyWeightScale: 'Shkalla e peshës së trupit',
		weightScale: 'Pesha',
		weightScaleDevice: 'Peshorja',
		glucometer: 'Glukometër',
		infraredEarThermometer: 'Termometri me rreze infra të kuqe për vesh',
		thermometer: 'Termometër',
		iphone: 'iPhone',
		pulseOximeter: 'Oksimetër',
		surgicalToolsMissing: 'Mungojne mjetet e operimit',
		railDown: 'Korniza e shtratit u detektua.',
		timeOfMeasurement: 'Koha e matjes',
		fallDetected: 'Rrëzimi u detektua',
		custom: 'Kustomizo',
		auto: 'Automatik',
		audioSettings: 'Cilësimet Audio',
		audioTuning: 'Përshtrimi Audio',
		selectValue: 'Zgjidh Vlerën',
		selectOneOfTheOptions: 'Zgjidhni njërën nga opsionet',
		selectCustomValue: 'Zgjidh Vlerën e Kustomizueshme',
		hdmiSyncDelay: 'Vonesë e Sistemit HDMI',
		detected: 'U detektua (AL)',
		hasMeasured: 'has measured (AL)',
		callPatient: 'Thirr pacientin',
		callPatientDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të mund të telefonojnë pacientin ndërsa e monitorojnë atë.',
		patientCommunication: 'Mesazhi i Monitoruar',
		patientCommunicationDescription:
			'Kur ky është aktivizuar, pacientët do të jenë në gjendje të shohin një mesazh në ekranin e {helloName} që ata po monitorohen.',
		monitoringPrecautionsLegend: 'Legjenda e masave paraprake të monitorimit',
		acknowledge: 'Pranoj',
		hasAcceptedYourRequest: 'ka pranuar kërkesën tuaj për kategorinë {value}',
		hasRejectedYourRequest: 'ka refuzuar kërkesën tuaj për kategorinë {value}',
		hasCanceledRequest: 'ka anuluar kërkesën për kategorinë {value}',
		hasSentRequest: 'ka dërguar një kërkesë për kategorinë {value}',
		unread: 'Të palexuara',
		earlier: 'Më herët',
		new: 'Të reja',
		noEvents: 'Nuk ka ngjarje.',
		upload: 'Ngarko',
		alerts: 'Njoftimet',
		zipCodeDigitsMax: 'Kodi postal duhet të ketë 10 karaktere ose më pak',
		quickOptions: 'Opsionet e shpejta',
		addDependents: 'Shto vartësit',
		measureYourVitalSigns: 'Mat shenjat vitale',
		updateProfile: 'Përditëso profilin',
		upcomingAppointments: 'Terminet vijuese',
		goToMyAppointments: 'Shko tek terminet',
		goToMyRequests: 'Shiko të gjitha kërkesat',
		vitalSigns: 'Shenjat vitale',
		ewsAiVitalSigns: 'EWS AI Shenjat vitale',
		inputterFirstName: 'Emri i Shtuesit',
		inputterLastName: 'Mbiemri i Shtuesit',
		goToMyHealthData: 'Shko tek të dhënat e shëndetit',
		limitedSelectedSymptoms: 'Nuk mund të selektoni më shume se 5 siptoma.',
		comingSoon: 'Së shpejti',
		pleaseTryAgainLater: 'Ju lutem provoni më vonë',
		currentlyNoHealthDataMainAnalysis:
			"Momentalisht nuk keni të dhëna për analizat kryesore. Klikoni 'Shko tek të dhënat e shëndetit' për të parë të gjitha të dhënat.",
		currentlyNoVitalSigns:
			"{value} momentalisht nuk ka të dhëna për analizat kryesore. Klikoni 'Shko tek shenjat vitale' për të parë të gjitha të dhënat.",
		extremelyHighTemperature: 'The patient has an extremely high temperature! (AL)',
		highTemperature: 'The patient has a high temperature! (AL)',
		lowTemperature: 'The patient has a low temperature! (AL)',
		extremelyHighPressure: 'The patient has an extremely high pressure! (AL)',
		extremelyLowBloodPressure: 'The patient has an extremely low pressure! (AL)',
		highBloodPressure: 'The patient has high blood pressure! (AL)',
		lowBloodPressure: 'The patient has low blood pressure! (AL)',
		lowHeartRateDescription: 'The patient has a very low heart rate! (AL)',
		highHeartRateDescription: 'The patient has a very high heart rate! (AL)',
		overweight: 'The patient is overweight! (AL)',
		obese: 'The patient is obese! (AL)',
		underweight: 'The patient is underweight! (AL)',
		sleepDeficient: 'The patient is sleep deficient! (AL)',
		privacyMode: 'Modi i Privatësisë',
		microphoneMuted: 'Mikrofoni i mbyllur',
		microphoneOn: 'Mikrofoni i hapur',
		cameraOn: 'Kamera e lëshuar',
		cameraOff: 'Kamera e ndalur',
		hours: 'orët',
		checkDateOfBirth: 'Ju duhet të jeni të paktën 18 vjeçar/e në mënyrë që të regjistroheni',
		greaterThanEighteen: 'Ju duhet të jeni të paktën 18 vjeçar/e',
		privacyModeOff: 'Moda e privatësisë është ndalur',
		privacyModeOn: 'Moda e privatësisë është lëshuar',
		homePage: 'Ballina',
		newPatientRequests: 'Kërkesat e reja të pacientit',
		noNewRequests: 'Nuk keni kërkesa të reja',
		about: 'Rreth nesh',
		termsOfUse: 'Kushtet e përdorimit',
		goTo: 'Shko tek',
		domain: 'Domain',
		domainRequired: 'Sheno Domain',
		validGroupName: 'Emri i vlefshëm i grupit',
		group: 'Grupi',
		allCaughtUp: 'I keni parë të gjitha',
		noNewAppointmentsOrRequests: 'Nuk keni ndonjë termin apo kërkesë të re!',
		age: 'Mosha',
		cameraInUseHeader: 'Kamera është në përdorim - Ju lutem mbyllni aplikacionet tjera',
		cameraInUseParagraph1: 'Për të marrë pjese në këtë mbledhje me kamerë, ndaleni kamerën ne aplikacione të tjera',
		cameraInUseParagraph2: 'Rifreskoni faqen per ta inicializuar',
		failedToLoad: 'Deshtim ne ngarkim',
		hvrIndex: 'HVR Index',
		stressLevel: 'Nivel i stresit',
		mildlyCalm: 'Mesatarisht i qetë',
		highlyTense: 'Shume i stresuar',
		slightlyTense: 'Pak i stresuar',
		quietlyRelaxed: 'Mesatarisht i relakuar',
		deeplyRelaxed: 'Thellesisht i relakuar',
		vitalsCamera: 'Matjet me Kamere',
		noAppointmentRequests: 'Nuk keni asnjë kërkesë për takim!',
		noAcceptedAppointments: 'Nuk keni asnjë kërkesë të pranuar!',
		scheduleAvailability: 'Planifiko disponueshmërinë',
		schedulingAvailability: 'Planifikimi i bazuar në disponueshmërinë tuaj',
		addTopic: 'Shto temë',
		setupAccount: 'Konfiguroni llogarinë',
		noSetupAccount: 'Duket se nuk keni konfiguruar ende një llogari kalendarike, klikoni në butonin më poshtë për të vazhduar',
		setupCalendarAccount: 'Konfiguroni llogarinë tuaj të kalendarit',
		viewSomeonesProfile: 'Shikoni profilin e {value}',
		reSchedule: 'Planifikoni përsëri',
		chooseAvailableTime: 'Zgjedhni kohën tuaj të lirë për ',
		letAvailbleSchedule: 'Njoftoni pacientët kur jeni në dispozicion për të pranuar takime',
		setup: 'Caktoni',
		availability: 'disponueshmëria',
		noUpcomingAppointments: 'Nuk keni takime të ardhshme!',
		roomNoDeviceAssigned: 'Kjo dhomë nuk ka asnjë pajisje të caktuar',
		roomsNeedDevice: 'Dhomat duhet të kenë pajisje',
		inOrderToCall: `në mënyrë që t'i thirrni`,
		companyWebsite: 'Ueb Faqja e kompanisë',
		createCompany: 'Krijo Kompani',
		owner: 'Pronari',
		companyType: 'Lloji i kompanisë',
		website: 'Ueb faqja',
		companyId: 'Id e kompanisë',
		goBack: 'Kthehuni prapa',
		companySuccessfullyCreated: 'Kompania u krijua me sukses',
		meetingTime: 'Ora e takimit',
		meetingDate: 'Data e takimit',
		noPendingAppointments: 'Nuk keni asnjë kërkesë në pritje!',
		noRejectedAppointments: 'Nuk keni asnjë kërkesë të refuzuar!',
		patientEmail: 'Email e pacientit',
		familyMemberEmail: 'Email e familjarit',
		patientFirstName: 'Emri i pacientit',
		familyMemberFirstName: 'Emri i Familjarit',
		patientLastName: 'Mbiemri i pacientit',
		familyMemberLastName: 'Mbiemri i familjarit',
		assignPatient: 'Cakto pacientin',
		assignmentMethod: 'Metoda e caktimit',
		modalLogsTitle: 'Të dhënat e pajisjes',
		modalLogsFailure: 'Dështoj të merr të dhënat e pajisjes. Pajisja është fikur.',
		modalLogsWentWrong: 'Diçka shkoi gabim. Ju lutemi provoni prapë.',
		modalLogsSuccess: 'Të dhënat e pajisjes janë regjistruar në sistem të Solaborate. Mund ta mbyllni këtë mesazh.',
		modalLogsButton: 'Merr të dhënat e pajisjes',
		rebootExportLogsButton: 'Merr të dhënat e pajisjes të rindezur',
		rebootExportLogsNoLogsYet: 'Kjo pajisje nuk ka të dhëna të rindezjes.',
		exportAsCSV: 'Shkarko si CSV',
		endpointWhitelisting: 'Lista e bardhë e pikës fundore',
		csv: 'CSV',
		reportedOn: 'Raportuar Në',
		id: 'Identifikuesi',
		allowed: 'Lejohet',
		denied: 'Mohuar',
		protocol: 'Protokolli',
		response: 'Përgjigje',
		searchPatient: 'Kërko pacientin',
		newPatient: 'Pacient i ri',
		existingPatient: 'Pacient ekzistues',
		requiredField: 'Fushë obligative',
		selectedPatient: 'Pacienti i zgjedhur',
		nextDays: 'ditësh',
		nextTime: 'Pas',
		nextHours: 'orësh',
		nextHour: 'Gjatë orës së ardhme',
		nextMinutes: 'minutave',
		availableHours: 'Orët e punës',
		availableDays: 'Ditët e punës',
		break: 'Pushim',
		checkSelectedBreaks: 'Rishikojini orët e pushimit',
		sundays: 'Të dieleve',
		mondays: 'Të hënave',
		tuesdays: 'Të marteve',
		wednesdays: 'Të mërkureve',
		thursdays: 'Të enjteve',
		fridays: 'Të premteve',
		saturdays: 'Të shtuneve',
		month: 'Muaj',
		week: 'Javë',
		day: 'Ditë',
		remote: 'Në distancë',
		clearData: 'Pastro të dhënat',
		resetDevice: 'Rivendos pajisjen',
		th: ' ',
		fileUploadError: 'Dosja {value} ekziston',
		conversationHistory: 'Historia e bisedave',
		noDevices: 'Asnjë pajisje',
		oneDevice: '{value} pajisje',
		moreThanOneDevice: '{value} pajisje',
		healthSystemRequired: 'Zgjedh sistemin shëndetësor',
		hospitalRequired: 'Zgjedh Spitalin',
		emailRequired: 'Shkruani email adresën',
		userAlreadyExists: 'Ky përdorues veçse ekziston',
		privacy: 'Privatësia',
		enablePrivacyMode: 'Aktivizo modalitetin e privatësisë',
		privacyDescription:
			'Kur kjo është e aktivizuar, kushdo që e telefonon këtë pajisje do të shohë një pamje të turbullt të kamerës.',
		futureConfigurations: 'Konfigurimet e së ardhmes',
		false: 'pasaktë',
		true: 'saktë',
		forwarded: 'Drejtuar',
		acknowledged: 'Pranuar',
		rotate: 'Rrotullo',
		filter: 'Filtro',
		appointment: 'terminin',
		editMeeting: 'Edito takimin',
		companyDetails: 'Detajet e Kompanisë',
		companySettings: 'Aranzhimi i kompanisë',
		companyAddress: 'Adresa e kompanisë',
		remotePatientMonitoring: 'Monitorimi i pacientit në distancë',
		continuousPatientMonitoring: 'Monitorimi i Vazhdueshëm i Pacientit',
		numberOfPatients: 'Numri i Pacientëve',
		highRisk: 'Rrezik i lartë',
		moderateRisk: 'Rrezik i mesëm',
		searchByNameOrHello: 'Kërkoni pacient me emër ose {value}',
		optional: 'Opsionale',
		memberStatus: 'Statusi i/e anëtarit/es',
		disableAccess: 'Çaktivizo aksesin',
		uploadPicture: 'Ngarko foto',
		profileImageDescription: `Kjo foto do të përdoret si imazh i profilit tuaj dhe do të mund të shikohet nga pacientët në uebfaqe dhe aplikacionin celular.
		Imazhi mund të jetë në formatin .jpg ose .png dhe një kufi të madhësisë prej 2 MB.`,
		pleaseUploadPicture: 'Ju lutem ngarkoni një foto të profilit',
		maxSizePic: `Madhësia e fotografisë nuk mund të tejkalojë 600 KB.`,
		commandNotSupported: `Kjo komandë nuk përkrahet nga televizioni i pacientit.`,
		userIdleConfigurations: 'Konfigurimet e përdoruesit pasiv',
		minutes: 'Minuta',
		editedBy: 'Ndryshuar nga',
		dateEdited: 'Data e ndryshimit',
		historyFor: 'Historia për',
		backToConfigs: 'Kthehu te konfigurimet',
		setTimeOut: 'Vendos kohen e pritjes',
		viewHistory: 'Shiko historinë',
		minutesGreaterThanZero: 'Minutat duhet të jenë më shume se 0',
		minutesRequired: 'Minutat duhet të shkruhen',
		userIdleFormTitle: 'Përdoruesi duhet të kyçet përsëri pas një periudhe të pasivitetit',
		selectHealthSystemOrAll: 'Zgjedh sistemin shëndetësor apo zgjedh të gjithë për të aplikuar periudhën e pasivitetit',
		setInActivityPeriod: 'Vendos periudhen e pasivitetit',
		userIdleNotifedPeriod: `Përdoruesi do të njoftohet për 30 sekonda, nëse nuk e konfirmon prezencen atëher do të çkyçet automatikisht`,
		configurationSuccessfullyAdded: 'Konfigurimi u shtua me sukses',
		imHere: 'Jam këtu',
		idleMessage: 'Ju keni qenë pasiv për {value} minuta.',
		idleMessageDescription: `Ju do të çkyçeni nësë nuk e konfirmoni prezencen tuaj brenda {value} sekondave`,
		seeDoctorNow: 'Takoje doktorin tani',
		waitingRoomDoctor: 'Thirrja juaj e drejtpërdrejtë me {value1} pritet të fillojë në {value2} ose më pak',
		doctorAvailableNow: 'është në dispozicion tani',
		doctorUnAvailableNow: 'nuk është në dispozicion tani',
		hasPatientsInWaitingList: ' ka {value} pacientë tjerë në listën e pritjes',
		goToWaitingRoom: 'Shkoni në dhomën e pritjes',
		pleaseSpecifySymptoms: `Ju lutem specifikoni simptomat që të gjeni një doktor sa më të përshtatshëm per ju.`,
		pleaseSelectAvailableDoctor: 'Ju lutem zgjedhni një nga doktorët në dispozicion në mënyrë që të vazhdoni me terminin tuaj.',
		seeTheAvailableDoctors: `Shihni te gjithë doktorët në dispozicion`,
		pleaseWaitUntilDoctorCalls: `Ju lutem prisni derisa doktori të ju telefonojë`,
		liveCallExpectedToStart: 'Thirrja juaj me Dr. {value} pritet të fillojë për',
		orLess: 'ose më pak',
		searchForDoctors: 'Kërko dhe selekto një doktor',
		additionalHealthData: 'Të dhëna shtesë',
		additionalNotes: 'Shënime shtesë',
		clickHere: 'Klikoni këtu',
		orDragAndDrop: 'ose tërhiq dhe lësho për të ngarkuar një dosje',
		leaveWaitingRoom: 'Dil nga dhoma e pritjes',
		deviceSettings: 'Aranzhimi i pajisjes',
		languages: 'Gjuhët',
		aiModels: 'Modelet AI',
		selectMode: 'Zgjedhni modin',
		mainDisplay: 'Ekrani kryesor',
		secondDisplay: 'Ekrani i dytë',
		secondaryDisplay: 'Ekrani sekondar',
		approval: 'Aprovim',
		approvalNeeded: 'Për ta ndryshuar këte ju duhet aprovim nga pacienti',
		changeSettingsRequestAccepted: 'Patienti {patient} ka {acceptedOrRejected} kerkesen tuaj',
		markAllAsRead: 'Kthe të gjitha në të lexuara',
		liveCallRequest: 'Kërkesë për thirrje',
		requestTime: 'Koha e kërkesës',
		notNow: 'Jo Tani',
		hasRequestedAliveCall: 'ka kërkuar një thirrje me ju',
		haveRequestedAliveCall: 'kanë kërkuar një thirrje me ju',
		andNumberOthers: 'dhe {value} të tjerë',
		typeHere: 'Shëno këtu',
		notSpecified: 'E pa specifikuar',
		changeDoctor: 'Ndërro doktorin',
		changedYourMind: 'Nese keni ndërruar mendje për doktorin tuaj ju mund ta zgjedhni një tjetër',
		diagnoses: 'Diagnozat',
		procedures: 'Procedurat',
		medicalInfoProcedures: 'Procedurat',
		notes: 'Shënimet',
		medicalInfoNotes: 'Shënimet',
		notesDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të jenë në gjendje të shkruajnë shënime ndërsa monitorojnë një pacient.',
		showNotesOnExpand: 'Shfaq shënimet vetëm në zgjerim',
		showNotesUnderTile: 'Shfaq shënimet nën çdo pllakë',
		defaultVideoMonitoring: 'Monitorimi i Paracaktuar i Videove',
		defaultAmbientMonitoring: 'Monitorimi i Paracaktuar Ambiental',
		customize: 'Personalizo',
		prescriptions: 'Recetat',
		medicalInfoPrescriptions: 'Recetat',
		configurationExists: 'Ky konfigurim veçse ekziston!',
		updateRequested: 'Përditësimi është kërkuar',
		updateNow: 'Update Now',
		softwareUpdate: 'Përditësim i softuerit',
		selectDoctor: 'Zgjedh doktorin',
		tvMode: 'Moda e TV',
		gamingMode: 'Moda e lojrave',
		cleanMode: 'Moda e pastër',
		widgetMode: 'Modi i Miniaplikacioneve',
		autoAnswer: 'Auto Përgjigje',
		appInstaller: 'Instaluesi i applikacioneve',
		audioLevel: 'Niveli i zërit',
		portraitMode: 'Modaliteti Portret',
		landscape: 'Peizazh',
		portrait: 'Portret',
		portraitReverse: 'Portret i kundërt',
		deviceOrientation: 'Orientimi i Ekranit',
		connectPatientsHelloToWifi: `Lidh {value} e pacientit me Wifi`,
		and: 'dhe',
		maxSymptomsNo: 'Numri maksimal i simptomave është 10',
		haveCompleted: 'keni kompletuar',
		haveCanceled: 'keni anuluar',
		yourRequestWith: 'kërkesën tuaj me',
		yourLiveCallWith: 'thirrjen tuaj direkte me',
		doctorRequestResponse: 'Doktori ka {value} kërkesën tuaj',
		goBackToDoctors: 'You can go back and request a call with an other doctor',
		deleteHealthSystemMember: 'A jeni të sigurt se dëshironi të fshini këtë sistem shëndetësor nga ky anëtar?',
		dateValidation: 'Ju lutem zgjedhni datën e fillimit më herët se datën e përfundimit.',
		twentyHrsBetween: 'Ju lutem zgjedhni datën e fillimit 23:59 orë më herët se datën e përfundimit..',
		notAvailableNow:
			'Fatkeqësisht, ofruesi nuk është në dispozicion në këtë kohë. Ju lutemi, zgjedhni një nga kohët e disponueshmërisë më poshtë:',
		notAvailableToday: 'Fatkeqësisht, ofruesi nuk është në dispozicion sot. Ju lutemi, zgjedhni një datë tjetër.',
		showForm: 'Shfaq formën',
		priority: 'Prioriteti',
		selectPriority: 'Zgjedhni prioritetin',
		changesSaved: 'Ndryshimet u ruajtën.',
		poolRoleRequired: 'Roli i grupit duhet të zgjedhet.',
		requestSentToAllDoctors: 'Kërkesa është dërguar tek të gjithë doktorët',
		firstNameMinLength: 'Emri duhet të jetë të paktën 2 karaktere',
		lastNameMinLength: 'Mbiemri duhet të jetë të paktën 2 karaktere',
		pleaseWritePhoneNumber: 'Ju lutem shkruani numrin tuaj të telefonit celular',
		pleaseSelectPrimarySpecialty: 'Ju lutem zgjedhni një specializim primar',
		pleaseWriteLicenseNumber: 'Ju lutem zgjedhni një numër të licensës',
		pleaseWriteNPINumber: 'Ju lutem shkruani numrin tuaj NPI',
		npiMaxLength: 'Numri NPI duhet të jetë 10 ose më pak karaktere',
		atLeastOneState: 'Duhet të zgjedhet të paktën një shtet',
		selectedItems: 'Të zgjedhura',
		valueItemSelected: '{value} zgjedhura',
		roles: 'Role',
		activeDirectoryInvite: 'Ftesë nga Active Directory',
		doctor: 'Doktor',
		superadmin: 'Super Administrator',
		superuser: 'Super Përdorues',
		noAdded: 'Nuk keni shtuar {value}',
		addAnother: 'Shto edhe një {value}',
		cancelVisit: 'Anulo vizitën',
		submitWrapUp: 'Parashtro përmbledhjen',
		addNote: 'Shto shënim',
		addDiagnose: 'Shto Diagnozë',
		addProcedure: 'Shto Procedurë',
		addedNotes: 'Shënimet e shtuara',
		title: 'Titulli',
		selectPharmacy: 'Zgjedhni farmacinë',
		pharmacy: 'Farmacia',
		noMoreHealthSystems: 'Nuk ka sisteme të tjera shëndetësore.',
		whereToSendPrescriptions: 'Ku dëshironi ti dërgoni ilaçet?',
		findPharmacy: 'Gjeni Barnatore',
		miles: 'Milje',
		homeDelivery: 'Dërgesë në shtëpi',
		pickUp: 'Marr vet',
		errorLoading: 'Problem në ngarkim',
		searchPharmacies: 'Kërkoni barnatore',
		startCall: 'Fillo thirrjen',
		changePharmacy: 'Ndërro Farmacinë',
		enterDescription: 'Shkruani përshkrimin këtu',
		enterTopic: 'Shkruani titullin këtu',
		editAppointment: 'Edito terminin',
		discardChanges: 'Fshini ndryshimet?',
		discard: 'Fshini',
		discardDescription: 'Duke mbyllur modalin do të fshini të gjitha të dhënat që keni futur.',
		dosage: 'Doza',
		frequency: 'Shpeshtësia',
		duration: 'Kohëzgjatja',
		dispense: 'Zbatimi',
		refills: 'Rimbushjet',
		isRequired: 'është e nevojshme',
		maxLengthIs: 'Numri me i madh i lejuar është',
		medicineSelection: 'Zgjedhja e ilaçit është',
		dispensedAsWritten: 'të shpërndahen siç është e shkruar sipas nevojës mjekësore',
		noteToPharmacist: 'Shënim farmacistit',
		patientInstructions: 'Udhëzime pacientit',
		quantityToDispense: 'Sasia për tu shpërndarë',
		timesAday: 'herë në ditë',
		capsule: 'kapsulë',
		addedPrescriptions: 'Recetat e shtuara',
		typeOfDosage: 'Lloji i dozës',
		orUntilDiretedToStop: `(ose) derisa t'i është thënë t'i ndërprejë`,
		onlyAsNeeded: `Vetëm sipas nevojës`,
		default: 'Parazgjedhur',
		numberOfDays: 'numri i ditëve',
		appointmentCreated: 'Termini u krijua me sukses',
		appointmentUpdated: 'Termini u editua me sukses',
		appointmentDetails: 'Detajet e terminit tuaj janë:',
		change: 'Ndrysho',
		confirm: 'Konfirmo',
		successfulSignIn: 'Kyçja u realizua me sukses',
		successfulSignInDescription: '{value} është lidhur me sukses në hellocare.',
		errorSignIn: 'Kyçja dështoi',
		errorSignInDescription: 'Nuk mund të lidhej me {value}, ju lutemi provoni përsëri.',
		invalidFileType: 'Lloji i skedarit nuk është valid.',
		hideForm: 'Fsheh formën',
		addSuperUser: 'Shto super përdorues',
		myWorkflow: 'Rrjedha ime e punës',
		directWorkflow: 'Rrjedha direkte e punës',
		throughNurses: 'Përmes infermiereve',
		selectMyWorkflow: 'Zgjedh rrjedhen e punës',
		nurses: 'Infermierët',
		clickHereAddNurse: 'Kliko këtu per të shtuar një infermier/e',
		addNurse: 'Shto infermier/e',
		inviteNurses: 'Fto infermierët',
		sectorIsRequired: 'Emri i sektorit nuk mund të jetë i zbrazët',
		timeZoneMustBeSet: 'Zona kohore duhet të jetë e caktuar',
		sectorNameMinLength: 'Emri i sektorit duhet të jetë të paktën 2 karaktere',
		writeSectorName: 'Shkruani emrin e sektorit',
		writeDeviceSerialNumber: 'Shkruani numrin serik te pajisjes',
		writeTvSerialNumber: 'Shkruani numrin serik te televizorit',
		healthSystemMinLength: 'Emri i sistemit shëndetësor duhet të jetë të paktën 2 karaktere',
		nextStep: 'Hapi Tjetër',
		minLengthIs: 'Numri më i vogël i lejuar është',
		areYouSureDeleteItem: 'Jeni i sigurtë se dëshironi ta fshini këtë {value}?',
		addPrescription: 'Shto recetë',
		medicalInfo: 'Info mjekësore',
		addMedicalInfo: 'Shto {value}',
		diagnose: 'diagnozë',
		note: 'shënim',
		prescription: 'recetë',
		titleIsRequired: 'Titulli duhet të shkruhet',
		appointmentSlotIsRequired: 'Ju lutem zgjedhni një interval kohe të takimit',
		personIsRequired: 'Personi duhet të shkruhet',
		meetingOptionIsRequired: 'Lloji i takimit duhet të zgjedhet',
		descriptionIsRequired: 'Përshkrimi duhet të shkruhet',
		descriptionMustHaveLetter: 'Përshkrimi nuk mund të ketë vetëm hapësira, numra ose simbole. Duhet të përmbajë shkronja.',
		rebootDevice: 'Ristarto pajisjen',
		factoryReset: 'Rikthim ne gjendjen e fabrikes',
		rebootDeviceQuestion: 'Pse dëshironi ta ristartoni pajisjen',
		resetDeviceQuestion: 'Pse dëshironi të rivendosni pajisjen',
		rebootDeviceReason: 'Përshkruani arsyen e ristartimit...',
		resetDeviceReason: 'Ju lutemi, përshkruani arsyen e fillimit të rivendosjes së fabrikës...',
		resetDeviceUndone:
			'Ky veprim nuk mund të zhbëhet. Jeni i sigurt që dëshironi të filloni një rivendosje në gjendje fabrike me këtë pajisje',
		clearDataQuestion: 'Pse doni të pastroni të dhënat e pajisjes',
		clearDataReason: 'Përshkruani arsyen e pastrimit te dhenave',
		areYouSureDeletePrescription: 'Jeni i sigurtë se dëshironi ta fshini këtë recetë?',
		newAppointmentRequest: 'Ju keni një kërkesë për termin nga',
		onDate: 'në',
		has: 'ka',
		yourAppointmentInvitation: 'kërkesën tuaj për termin',
		youHaveAppointmentWith: 'Keni një termin me',
		inMinutesOn: 'për {value} minuta në',
		hasAcceptedAppointment: 'ka pranuar kërkesën tuaj për termin',
		hasRejectedAppointment: 'ka refuzuar kërkesën tuaj për termin',
		hasCanceledAppointment: 'ka anuluar kërkesën tuaj për termin',
		atDate: 'në',
		youHaveAppointmentRequest: 'Ju keni një kërkesë për termin nga',
		updated: 'të ndryshuar',
		rescheduled: 'të ri-caktuar',
		editDetails: 'Ndrysho detajet',
		otherDevices: 'pajisje tjera',
		inviteDoctor: 'Fto Doktorin',
		inactiveConference: 'Pacienti është larguar',
		inactiveConferenceDesc: 'Pacienti është larguar nga dhoma virtuale',
		joinCall: 'Bashkohu në thirrje',
		expandLatestHealthData: 'Shfaq të dhënat e fundit shëndetësore',
		collapseLatestHealthData: 'Mbyll të dhënat e fundit shëndetësore',
		minutesInWaitingRoom: 'minuta në pritje',
		rejectedRequest: 'ka refuzuar kërkesën tuaj',
		selectHelloDevice: 'Selekto pajisjen {value}',
		otherMedicalInfo: 'Të dhënat tjera mjekësore',
		mobile: 'Mobil',
		laptop: 'Laptop',
		youHaveLengthPatients: 'Ju keni {length} {patients} në pritje',
		bodyFat: 'Masa dhjamore',
		muscleMass: 'Masa muskulare',
		bodyWater: 'Masa e ujit',
		symptoms: 'Simptomat',
		transferToDoctor: 'Transfero tek doktori',
		transferToDoctorWarning:
			'Transferimi i thirrjes tek Dr. {doctor} do te rezultojë me perfundimin e thirrjes me {patient}. A jeni te sigurtë që do të vazhdoni?',
		aiPrivacyMode: 'Gjendja e privatësisë AI',
		noMoreThanTwoAI: 'Nuk mund të shtoni më shumë se dy modele AI',
		soundOn: 'Zëri i hapur',
		soundOff: 'Zëri i ndalur',
		nightVisionOn: 'Night Vision i hapur',
		nightVisionOff: 'Night Vision i ndalur',
		reportAiDetection: 'Raporto detektim të AI',
		detectionReported: 'Detektimi i AI u raportua me sukses',
		expand: 'Zgjero',
		visitWith: 'Vizita me',
		mainDetails: 'Detajet kryesore',
		lengthOfVisit: 'Kohezgjatja e vizitës',
		viewDetails: 'Shiko detajet',
		reportAsFalse: 'Raporto si të pavertetë',
		callEmergency: 'Thirr emergjencën',
		callNurse: 'Thirr infermierin/en',
		markFalseAlert: 'Shenjo si njoftim të pavertetë',
		markAsSeen: 'Shenjo që është parë',
		resultsFor: 'Rezultate për {value}',
		patientExists: 'Ky pacient veçse ekziston!',
		dataAcquisition: 'Përvetësimi i të dhënave',
		allRequests: 'Të gjitha kërkesat',
		opened: 'Hapur',
		cancelAppointmentTitle: 'Anulo takimin?',
		cancelAppointment: 'Anulo takimin',
		cancelAppointmentQuestion: 'A jeni të sigurtë se dëshironi ta anuloni këtë takim?',
		alertHistory: 'Historia e njoftimeve',
		healthData: 'Të dhënat e shëndetit',
		roundingHealthData: 'Të dhënat e shëndetit',
		healthDataAlerts: 'Njoftimet për të dhënat e shëndetit',
		artificialIntelligence: 'Inteligenca Artificiale',
		wasDetected: 'është detektuar në',
		timeline: 'Pasqyra kohore',
		youNeedAuthorizedNurses: `Duhet që të keni të paktën një infermier/e të autorizuar për ta ndërruar rrjedhën e punës ne "Përmes infermierëve/eve`,
		timeSlotExists: 'Ju veçse keni një takim në atë interval kohor.',
		hideEmergencyButtons: 'Fsheh butonat emergjent',
		showEmergencyButtons: 'Shfaq butonat emergjent',
		appointmentDetail: 'Detajet e takimit',
		invitee: 'I/e ftuar',
		selectOneOption: 'Zgjedh një nga opsionet e mëposhtme',
		noCanceledAppointments: 'Nuk keni asnjë kërkesë të anuluar!',
		editOrder: 'Ndrysho renditjën',
		reorderSessions: 'Reorder Sessions',
		rearrangeMonitoringSessions: 'Tërhiqni dhe lëshoni për të rirregulluar sesionet e monitorimit',
		code: 'Kodi',
		alreadyAddedBreakTime: 'Ju tashmë e keni shtuar atë kohë të pushimit',
		completeAppointmentTitle: 'Përfundo takimin?',
		completeAppointmentQuestion: 'A jeni të sigurtë se dëshironi ta përfundoni këtë takim?',
		markAsCompleted: 'Shënoni si të përfunduar',
		contactNurses: 'Kontakto infermierët',
		contactNurse: 'Kontakto infermierët',
		contactNursesDescription:
			'Kur kjo është e aktivizuar, përdoruesi do të jetë në gjendje të kontaktojë infermierët duke zgjedhur 3 opsione: Dërgoni një mesazh në grup, Thirrni një infermiere në detyrë ose kontaktoni një infermiere specifike.',
		falseAlert: 'Alarm i rremë',
		forwardToNurses: 'Dërgo te infermierët',
		forwardToFrontlineNurses: 'Përcjelle te infermierët e vijës së parë',
		forwardToNursesDescription:
			'Rrjedha e punës e komunikimit për sinjalizimet që do të përcillen nga infermierja virtuale ose eSitter tek infermieret pranë shtratit.',
		sendGroupMessage: 'Dërgo mesazh në grup',
		callNursesOnDuty: 'Thirr infermierët në detyrë',
		specificNurse: 'Infermier/e specifik/e',
		stopTalking: 'Kliko për të ndaluar së foluri',
		checkBreakTime: 'Ju lutem shtoni kohën e pushimit brenda orëve në dispozicion',
		teams: 'Ekipet',
		sendMessage: 'Dërgo mesazh',
		message: 'Mesazh',
		chat: 'Bisedat',
		sort: 'Sorto',
		viewLatestAlerts: 'Shiko njoftimet e fundit',
		sharedAttachments: 'Bashkëngjitje të ndara me ju',
		links: 'Vegëzat',
		sharedLinks: 'Vegëzat e ndara me ju',
		images: 'Imazhet',
		sharedImages: 'Imazhet e ndara me ju',
		videos: 'Videot',
		sharedVideos: 'Videot e ndara me ju',
		noTeamMembers: 'Nuk ka anëtarë në ekip',
		noTeamsYet: 'Nuk ka ekipe ende',
		noCheckedInNurse: 'Nuk ka puntorë shëndetësorë në dispozicion!',
		nursesUnavailable: 'Nuk ka infermierë ne dispozicion. Provoni përsëri më vonë.',
		receivedAlert: 'pranoi një alarm',
		sentAlert: 'dërgoi një alarm',
		addedComment: 'shtoi një koment',
		markedThe: 'shënoi',
		alertAcknowledged: 'alarmin si të pranuar',
		wasAddedAs: 'u shtua si një',
		hospitalCareTaker: 'kujdestar në spital',
		alertDetails: 'Detajet e alarmit',
		measured: 'ka matur',
		forwardedToNurses: 'e dërgoi alarmin te infermierët',
		raiseAlert: 'Ngrit një shqetësim',
		raiseAnAlertManually: 'Ngritni një alarm manualisht',
		raiseAlertDescription: `Nëpërmjet këtij opsioni, infermierja do të jetë në gjendje të ngrejë një alarm manualisht për atë pacient. Ky sinjalizim do t'u përcillet infermiereve në Bedside ose Frontline.`,
		typeOfAlert: 'Lloji i alarmit',
		isAtRisk: 'është në rrezik për shkak të',
		bloodType: 'Tipi i gjakut',
		latestVitalSigns: 'Të dhënat e fundit vitale',
		latestDiagnosis: 'Diagnoza e fundit',
		chronicDiseases: 'Sëmundjet kronike',
		allergies: 'Alergjitë',
		goToAllScripts: 'Shko tek Allscripts EHR',
		alertAsFalse: 'alarmin si të rremë',
		manualAlerts: 'Njoftimet manuale',
		forwardToMSNurses: 'Dërgo te MS infermierët',
		raisedAnAlert: 'ngriti një alarm',
		badURL: 'URL i gabuar. Formati i URL duhet të jetë kështu https://www.google.com ose http://www.google.com',
		symbolAndSpacesNotAllowed: 'Simbolet dhe hapësirat nuk lejohen!',
		symbolsNotAllowed: 'Simbolet nuk lejohen!',
		call911: 'Thirr emergjencën',
		call911Description: 'Kur kjo të aktivizohet, përdoruesit do të mund të telefonojnë 911 me një klik.',
		addComment: 'Shto koment',
		saveComment: 'Ruaj komentin',
		forwardToNoraApp: 'Dërgo te Nora App',
		mainMedicalFamilyCenter: 'Qendra kryesore e mjekësisë familjare',
		medicalFamilyCenter: 'Ambulanta',
		digitalClinic: 'Klinika digjitale',
		checkIn: 'Regjistrohu',
		checkOut: 'Largohu',
		checkInPage: 'Regjistrimi',
		noCheckInAllowed: 'Regjistrimi nuk lejohet',
		userIsCheckedIn: 'Përdoruesi me email: {value} është regjistruar.',
		hangUp: 'Ndale thirrjen',
		calling: 'Duke thirrur',
		emergencyCall: 'Thirrje emergjente',
		callEnded: 'Thirrja mbaroi.',
		typeNameForPool: 'Shkruani emrin për këtë grup',
		selectRoles: 'Zgjedh rolet',
		viewDevices: 'Shiko pajisjet',
		assignDevicesList: 'Lista e pajisjeve të përcaktuara',
		monitoringSettings: 'Aranzhimi i monitorimit',
		editMonitoringSettings: 'Ndrysho aranzhimin e monitorimit',
		nightVision: 'Vizioni i Natës',
		nightVisionDescription: 'Kur kjo është e aktivizuar, përdoruesit do të mund të kenë vizion kur dhoma është e errët.',
		EHRDescription: 'Qasja në të dhënat EHR të pacientit.',
		microsoftTeams: 'Microsoft Teams',
		microsoftTeamsDescription: 'Rrjedha e punës e komunikimit me aplikacionin Microsoft Team të spitalit',
		walkieTalkie: 'Walkie Talkie',
		walkieTalkieDescription:
			'Transmetim audio për të gjitha infermieret pranë shtratit dhe infermieret virtuale të regjistruara në katin e caktuar.',
		addDigitalClinician: 'Shto Klinicist Digjital',
		digitalClinicianOnList: 'Klinicist Digjital',
		maxSizeExceeded: 'Provoni të ngarkoni një skedar nën 2 MB',
		maxCompanySizeExceeded: 'Provoni të ngarkoni një skedar nën 600 KB',
		acceptedFilesTypesAre: 'Tipet e fotografive që pranohen janë: {value}',
		appointmentDateRequired: 'Data e terminit duhet të selektohet',
		timeSlotRequired: 'Intervali kohor duhet të selektohet',
		editSafeRanges: 'Ndrysho vlerat optimale',
		thresholdAlertDescription: `Shkruani vlerat minimale edhe maksimale për secilën të dhënë shëndetësore bazuar në gjendjen e pacientit.
		Nëse ndonjë prej të dhënave shëndetësore të pacientit është nën ose mbi vlerat e ruajtura minimale edhe maksimale, atëherë do 
		të dërgohet një njoftim.`,
		cityMustHaveLetter: 'Qyteti duhet të përmbajë patjetër edhe shkronja',
		doctorPooling: 'Grupi i doktorëve',
		doctorList: 'Lista e Doktorëve',
		addDoctor: 'Shto një doktor',
		searchDoctor: 'Kërko një doktor',
		selectedDoctors: 'Doktorët e zgjedhur',
		sureToDeleteDoctor: 'A jeni të sigurtë që dëshironi ta fshini doktorin {value}?',
		deleteDoctor: 'Fshij Doktorin',
		checkInPatient: 'Regjistro një pacient',
		selectPatient: 'Zgjedhni pacientin',
		patientHealthDetails: 'Simptomat dhe të dhëna shtesë për shëndetin',
		measureVitalSigns: 'Mat shenjat vitale të pacientit',
		searchForPatients: 'Kërkoni pacientët',
		addNewPatient: 'Shto një pacient të ri',
		addPatient: 'Shto pacientin',
		results: 'Rezultatet',
		showConsent: 'Autorizuar nga pacienti',
		idNumber: 'Numri personal',
		selectThePatient: 'Zgjedh pacientin',
		typeSymptoms: 'Shkruaj simptomat dhe shenjat këtu',
		selectedSymptoms: `Pasi t'i përzgjedhni simptomat, ato do të shfaqen këtu`,
		howLongSymptoms: 'Për sa kohë i keni simptomat?',
		preExistingCondition: 'A keni ndonjë gjendje ekzistuese? Nëse po, ju lutem shkruani poshtë:',
		anyAllergies: 'A keni ndonjë alergji?',
		measurePatientVitalSigns: `Ju lutem matni shenjat vitale të pacientit`,
		saveAndSelectPatient: 'Ruaj dhe zgjedh pacientin',
		addManually: 'Shto manualisht',
		userExists: 'Ky përdorues veçse ekziston!',
		checkFutureDate: 'Data e lindjes nuk mund të jetë në të ardhmen',
		checkedIn: 'Regjistruar',
		checkedOut: 'Pa regjistruar',
		placement: 'Vendi',
		seeDoctorInDC: 'Takoje doktorin në klinikë digjitale',
		patientIsInVisit: 'Pacienti është tashmë në vizitë!',
		doctorAvailableForCall: `është në dispozicion për një thirrje tani`,
		healthDataSafeRanges: 'Vlerat optimale të të dhënave shëndetësore',
		medicalReport: 'Raporti mjekësor',
		doctorsStampSignature: 'Nënshkrimi dhe vula e doktorit',
		doctorFullName: 'Emri i plotë i doktorit',
		printReports: 'Printo raportet',
		printReport: 'Printo raportin',
		capsulesDosage: '{value} kapsula',
		oneCapsule: '{value} kapsulë',
		specialty: 'Specializimi',
		refillsLowercase: 'rimbushje',
		ok: 'Në rregull',
		measuredIn: 'matur në',
		beforeContinue: 'Para se të vazhdoni',
		completeRegistration:
			'Ne ju rekomandojmë që të plotësoni informatat tuaja personale dhe profesionale në formularin e mëposhtëm.',
		dragAndDropOr: 'Tërhiqni dhe vendosni ose',
		click: 'klikoni këtu',
		uploadProfilePicture: 'për të ngarkuar foton e profilit tuaj',
		addLicense: 'Shto licensë',
		licenseNo: 'Numri i licensës',
		selectDate: 'Zgjedh datën',
		selectCountry: 'Zgjedh vendin',
		selectSpecialty: 'Zgjedh specializimin',
		saveAndContinue: 'Ruaj & Vazhdo',
		position: 'Pozita',
		addPosition: 'Shto pozitë',
		company: 'Kompania',
		educationHistory: 'Historia e edukimit',
		school: 'Shkolla',
		degree: 'Niveli',
		startYear: 'Viti i fillimit',
		endYear: 'Viti i përfundimit',
		employmentHistory: 'Historia e punësimit',
		addEducation: 'Shto edukimin',
		licenses: 'Licensat',
		reset: 'Fshij',
		companyNameRequired: 'Emri i kompanisë duhet të shkruhet',
		doctorsStamp: 'Vula e doktorit',
		doctorsSignature: 'Nënshkrimi i doktorit',
		companyWebsiteRequired: 'Ueb faqja e kompanisë duhet të shkruhet',
		companyAddressRequired: 'Adresa e kompanisë duhet të shkruhet',
		somethingWrongWithConference: 'Diçka shkoi gabim gjatë krijimit të konferencës!',
		patientIsOnWaitingRoom: 'Pacienti është në dhomën e pritjes tani!',
		currentSignature: 'Nënshkrimi aktual',
		deviceOfflineNow: 'Pajisja nuk është në dispozicion për momentin. Ju lutem provoni përsëri!',
		personalNumber: 'Numri personal',
		pleaseWriteIdNumber: 'Ju lutem shkruani numrin tuaj të ID-së',
		idNumberLength: 'Numri i ID-së duhet të ketë 10 karaktere dhe nuk duhet të fillojë me numrin zero.',
		patientHealthCardNumberLength:
			'Numri i kartës shëndetësore të pacientit duhet të ketë 10 deri 20 karaktere dhe nuk duhet të fillojë me numrin zero.',
		diabetes: 'Diabeti',
		oxygen: 'Oksigjeni',
		distanceWalkingRunning: 'Distanca e ecjes dhe vrapimit',
		temperature: 'Temperatura',
		pi: 'Indeksi i perfusionit',
		numberOfTimesFallen: 'Numri i rrëzimeve',
		pleaseUploadProfilePicture: 'Ju lutem ngarkoni një fotografi',
		currentlyWorkingHere: 'Aktualisht punoj këtu',
		currentlyStudyingHere: 'Aktualisht studioj këtu',
		high: 'E lartë',
		low: 'E ulët',
		criticalHigh: 'Shumë e lartë',
		criticalLow: 'Shumë e ulët',
		french: 'Frëngjisht',
		german: 'Gjermanisht',
		dutch: 'Holandisht',
		turkish: 'Turqisht',
		spanish: 'Spanjisht',
		englishUnitedStates: 'Anglisht (Shtetet e Bashkuara)',
		serbian: 'Serbisht',
		chinese: 'Kinezisht',
		russian: 'Rusisht',
		mandarinChinese: 'Mandarin kineze',
		graduateSchool: 'Fakulteti',
		nursePractitioner: 'Infermier praktikant',
		emergencyMedicinePhysician: 'Mjek i emergjencës',
		hospitalist: 'Spitalist',
		internist: 'Internist',
		pediatrician: 'Pediatër',
		psychiatrist: 'Psikiatër',
		childPsychologist: 'Psikolog i fëmijëve',
		counselor: 'Këshillues',
		psychologist: 'Psikolog',
		psychotherapist: 'Psikoterapeut',
		other: 'Tjetër',
		newVersionAvailable: 'Versioni i ri i aplikacionit është i gatshëm',
		checkInOtherPatient:
			'Nëse dëshironi të kontrolloni një pacient tjetër, atëherë të gjitha ndryshimet aktuale që keni bërë do të humbasin. A jeni të sigurtë që dëshironi të fshini ndryshimet tuaja dhe të filloni me procesin e regjistrimit?',
		discardAndContinue: 'Fshini ndryshimet dhe vazhdoni',
		cancelCall: 'Anulo thirrjen',
		deviceOfflineTryLater: 'Pajisja nuk është në dispozicion. Provoni përsëri më vonë.',
		deviceOnCallTryLater: 'Pajisja për momentin është në një thirrje. Provoni përsëri më vonë.',
		deviceOfflineTryLaterExtended: '{value1} nuk është në dispozicion. Provoni përsëri më vonë.',
		deviceOnCallTryLaterExtended: '{value1} për momentin është në një thirrje. Provoni përsëri më vonë.',
		cannotConnect: 'Nuk mund të lidheni. Ju lutem provoni përsëri.',
		callDeclined: 'Thirrja është refuzuar',
		callEndedTryAgain: 'Thirrja mbaroi. Ju lutem provoni përsëri.',
		failedReconnect: 'Dështoi rilidhja me rrjetin. Ju lutem provoni përsëri.',
		idleCallEnded: 'Ju keni qenë pasiv për një kohë të gjatë. Thirrja ka përfunduar.',
		time: 'Koha',
		riskOfFall: 'Rrezik rrëzimi',
		patientNeedsHelp: 'Pacientit i duhet ndihmë',
		patientRequiresHelp: 'Pacienti kërkon ndihmë',
		otherActivity: 'Aktivitet tjetër',
		searchPatientByName: `Ju lutem kërkoni pacientin në bazë të emrin dhe mbiemrin për të parë informatat rreth vizitave të tij / saj`,
		switchToHelloSource: 'Klikoni për të kaluar në këtë burim',
		tvActiveOnThiSource: 'TV është aktiv në këtë burim',
		powerOnTV: 'Lësho TV',
		powerOffTV: 'Ndal TV',
		macAddress: 'Mac Adresa',
		macUpperAddress: 'Adresa mac',
		appVersion: 'Verzioni i App-it',
		androidVersion: 'Verzioni Android',
		osVersion: 'Verzioni i OS',
		ipAddress: 'IP Adresa',
		stethoscopeAudioRecordings: 'Incizimet e zërit në stetoskop',
		selectPatientAndContinue: 'Ju lutem zgjedhni një nga pacientët në mënyrë që të vazhdoni me procedurat e mëtejshme',
		unit: 'Njësia',
		pleaseWriteHeight: 'Ju lutem shkruani gjatësinë tuaj',
		pleaseWriteWeight: 'Ju lutem shkruani peshën tuaj',
		pleaseWriteTotalCholesterol: 'Ju lutem shkruani kolesterolin total',
		pleaseWriteHdlCholesterol: 'Ju lutem shkruani kolesterolin HDL',
		pleaseWriteWaistCircumference: 'Ju lutem shkruani perimetrin e belit tuaj',
		centimeters: 'Centimetër',
		kilograms: 'Kilogram',
		pleaseSelectCountry: 'Ju lutem zgjedhni një shtet',
		pleaseWriteCity: 'Ju lutem shkruani një qytet',
		pleaseWriteZipCode: 'Ju lutem shkruani një kod postar',
		pleaseWriteAddress: 'Ju lutem shkruani një adresë',
		pleaseWriteLicense: 'Ju lutem shkruani një licensë',
		pleaseWriteSchool: 'Ju lutem shkruani shkollën',
		pleaseSelectDegree: 'Ju lutem zgjedhni një nivel',
		pleaseWriteStartDate: 'Ju lutem shkruani datën e fillimit',
		pleaseWriteCorrectDate: 'Ju lutem shkruani një datë korrekte',
		startDateCannotBeHigher: 'Data e fillimit nuk mund të jetë më e lartë se sot.',
		startDateCannotBeThatLower: 'Data e fillimit nuk mund të jetë më e ulët se 120 vjet nga tani.',
		endDateCannotBeSameAsStartDate: 'Data e mbarimit duhet të jetë të paktën 1 vit më e madhe se data e fillimit.',
		pleaseWriteEndDate: 'Ju lutem shkruani datën e mbarimit',
		pleaseWriteCompany: 'Ju lutem shkruani emrin e kompanisë',
		pleaseWritePosition: 'Ju lutem shkruani pozitën',
		clear: 'Fshije',
		noDiagnosesFound: 'Nuk ka diagnoza',
		noPrescriptionsFound: 'Nuk ka receta',
		noNotesFound: 'Nuk ka shënime',
		noProcedureFound: 'Nuk ka procedura',
		setupAvailability: 'Cakto disponueshmërinë',
		noVisitsFound: 'Nuk ka vizita',
		treeView: 'Shfaq hierarkinë',
		listView: 'Shfaq dhomat',
		currentlyLoggedInAs: 'Aktualisht jeni të kyçur si',
		logOutHere: 'Dilni këtu',
		pleaseLogOutFromThisAccount: 'Ju lutem dilni nga kjo llogari dhe shkoni tek email-i juaj për ta pranuar ftesën.',
		thisDeviceIsOnACall: 'Kjo pajisje është në një thirrje.',
		onePatient: 'Pacient',
		multiplePatients: 'Pacientë',
		noOptions: 'Asnjë opsion',
		anotherProcedure: 'Procedurë',
		daysNo: 'ditë',
		addExistingPatient: 'Shto një pacient ekzistues',
		addOrUpdate: 'Shto ose ndrysho',
		locationAndAddress: 'Vendi & Adresa',
		signatureAndStamp: 'Nënshkrimi & Vula',
		employment: 'Punësimi',
		education: 'Edukimi',
		signatureDescription: `Kur t'i ruani ndryshimet tuaja, çfarëdo që shkruani në hapësirën për shkrim, do të bëhet nënshkrimi juaj.`,
		stampDescription: 'Rekomandohet që vula të jetë transparente dhe në formatin PNG.',
		visceralFatGrade: 'Shkalla e yndyrës së brendshme',
		noRooms: 'Nuk ka dhoma për momentin.',
		dateFormat: 'Formati i datës',
		timeFormat: 'Formati i kohës',
		companyRegion: 'Regjioni i kompanisë',
		companyOwner: 'Pronari i kompanisë',
		invite: 'Ftesa',
		emailExists: 'Emaili ekziston',
		gridView: 'Shfaq si grup',
		singleView: 'Shfaq si listë',
		unknown: 'E panjohur',
		measuredAt: 'Matur më',
		patientName: 'Emri i pacientit',
		patientMRN: 'MRN e pacientit',
		of: 'nga',
		firstNameNonNumber: 'Emri duhet të përmbajë vetëm shkronja',
		lastNameNonNumber: 'Mbiemri duhet të përmbajë vetëm shkronja',
		parentNameNonNumber: 'Emri i prindit duhet të përmbajë vetëm shkronja',
		cityNameNonNumber: 'Emri i qytetit duhet të përmbajë vetëm shkronja',
		allergyNameNonNumber: 'Emri i Alergjisë duhet të përmbajë vetëm shkronja',
		dateOfBirthNotValid: 'Data e lindjes nuk është valide',
		minHeightIs: 'Gjatësia minimale është',
		maxHeightIs: 'Gjatësia maksimale është',
		minWeightIs: 'Pesha minimale është',
		maxWeightIs: 'Pesha maksimale është',
		minTotalCholesterolIs: 'Kolesteroli total minimal është',
		maxTotalCholesterolIs: 'Kolesteroli total maksimal është',
		minHdlCholesterolIs: 'Kolesteroli HDL minimal është',
		maxHdlCholesterolIs: 'Kolesteroli HDL maksimal është',
		minWaistCircumferenceIs: 'Perimetri i belit minimal është',
		maxWaistCircumferenceIs: 'Perimetri i belit maksimal është',
		defaultMeasurementUnits: 'Njësitë e paracaktuara të matjes',
		userCanChangeMeasurement:
			'Njësitë e zgjedhura do të jenë të paracaktuara për çdo përdorues që është i regjistruar në kompaninë tuaj, por çdo përdorues do të jetë në gjendje ti ndryshoj këto të dhëna për vete.',
		walkingRunningDistanceRequired: 'Kërkohet distanca e ecjes + vrapimit',
		weightRequired: 'Kërkohet pesha',
		bodyTemperatureRequired: 'Kërkohet temperatura',
		heightRequired: 'Kërkohet gjatësia',
		bloodGlucoseRequired: 'Kërkohet sheqeri në gjak',
		dateFormatRequired: 'Kërkohet formati i datës',
		timeFormatRequired: 'Kërkohet formati i kohës',
		cameraNameRequired: 'Kërkohet emri i kamerës',
		enterCameraName: 'Shkruani emrin e kamerës këtu',
		uniqueEmail: 'Email adresat duhet të jenë unike',
		companies: 'Kompanitë',
		verificationEmailFollowInstructions: 'Ju keni marrë një email verifikimi, ju lutemi ndiqni udhëzimet.',
		success: 'Sukses',
		requiredCompanyName: 'Kërkohet emri i kompanisë',
		requiredWebsiteName: 'Kërkohet website URL i kompanisë',
		milesUnit: 'mi (Milje)',
		kmUnit: 'km (Kilometra)',
		lbsUnit: 'lbs (Paund)',
		kgUnit: 'kg (Kilogram)',
		fahrenheitUnit: '°F (Fahrenheit)',
		celsiusUnit: '°C (Celsius)',
		ftUnit: 'ft (Feet)',
		cmUnit: 'cm (Centimetra)',
		mgUnit: 'mg/dL (Miligram për Decilitra)',
		mmolUnit: 'mmol/L (Milimole për Litër)',
		hourFormat12: '12-orë',
		hourFormat24: '24-orë',
		continueAs: 'Vazhdoni si',
		rememberAsDefaultChoice: 'Mbaje mend këtë si zgjedhjen time të paracaktuar',
		myUserRoles: 'Rolet e mia',
		switch: 'Ndërro',
		addPatientRole: 'Shto rolin e pacientit',
		addDoctorRole: 'Shto rolin e doktorit',
		primarySpecialty: 'Specializimi primar',
		selectStates: 'Zgjedh shtetin(et)',
		statesLicensed: 'Shtetet e licencuara në:',
		addARole: 'Shto një rol',
		resetTwoFa: 'Reseto autentifikimin me 2 faktorë',
		selectAuth: 'Zgjedh autentifikimin me 2 faktorë',
		pleaseSelectRole: 'Ju lutem zgjedhni një rol që dëshironi të shtoni te ky anëtar',
		switchRole: 'Ndërro rolin',
		providerOrPatient: 'Kaloni në një llogari të ofruesit ose pacientit',
		admin: 'Administrator',
		selectRole: 'Zgjedhni një rol',
		digitalClinician: 'Klinicist Digjital',
		areYouSureDeleteRole: 'A jeni i sigurtë që dëshironi ta fshini këtë rol nga ky anëtar?',
		deleteRole: 'Fshini rolin?',
		memberRole: 'rol',
		hrs: 'orë',
		physicalTherapyExercises: 'Ushtrime fizike',
		addNewExercise: 'Shto ushtrim të ri',
		selectTypeOfExercise: 'Zgjedh llojin e ushtrimit',
		exerciseSettings: 'Aranzhimi i ushtrimit',
		addExerciseSettings: 'Shto aranzhim të ushtrimit',
		noAddedExercise: ' nuk ka ushtrime fizike!',
		exercise: 'Ushtrim',
		selectCategory: 'Zgjedh kategorinë',
		regulateExerciseIntensity: 'Rregulloni intensitetin e ushtrimit',
		selectDatesForExercise: 'Zgjedh datat për ushtrime',
		editExercise: 'Ndrysho ushtrimin',
		containOnlyNumbers: 'Duhet të përmbajë vetëm numra',
		rangeExceeded: 'Numri duhet të jetë ndërmjet {value1} dhe {value2}',
		connectionType: 'Lloji i konektimit',
		physicalExercises: 'Ushtrime fizike',
		physicalTherapy: 'Terapia fizike',
		medicalInfoPhysicalTherapy: 'Terapia fizike',
		physicalTherapist: 'Fizioterapeuti',
		startingDate: 'Data e fillimit',
		targetedDays: 'Ditët e synuara',
		pTPrescriptionReport: 'Raporti i terapisë fizike',
		dataEditedSuccessfully: 'Të dhënat u ruajtën me sukses',
		exerciseDate: 'Data e ushtrimit',
		noPhysicalExercisesFound: 'Nuk u gjet asnjë terapi fizike',
		downloadReport: 'Shkarko raportin',
		downloadPatientReport: 'Shkarkoni raportin e pacientit',
		participantProfile: 'Profili i pjesëmarrësit',
		bridge: 'Ura',
		togglePatientsTvOFF: `Është i fikur`,
		togglePatientsTvON: `Është i ndezur`,
		toggleGridView: 'Ndërro ne pamje rrjetë',
		toggleLayoutView: 'Ndërro pamjen',
		invitePeople: 'Ftoni njerëz',
		showVitals: 'Shfaq shenjat vitale',
		moreOptions: 'Më shumë opcione',
		phoneNumberExists: 'Ky numër ekziston.',
		pleaseEnterValidNumber: 'Ju lutem shkruani një numer valid.',
		directLink: 'Vegzë direkte',
		sendLinkToPeople: `Dergo kete vegëz tek personat qe deshiron t'i ftosh`,
		addEmail: 'Shto email',
		inviteViaEmail: 'Fto përmes email',
		pleaseEnterToAddEmail: `Shtypni 'Enter' per te shtuar email-in.`,
		inviteViaSms: 'Fto përmes SMS',
		inviteSms: 'Fto përmes SMS',
		pressEnterToAddPhoneNumber: `Shtypni 'Enter' per te shtuar numrin e telefonit.`,
		joinViaPhone: 'Bashkohu përmes telefonit',
		accessCode: 'Shto kodin e hyrjes',
		sendInvite: 'Dërgoni ftesën',
		addOneOrMorePplEmailSmsLink: 'Shtoni një ose më shumë njerez përmes email-it, sms apo vegzës',
		pleaseEnterValidEmail: 'Please enter a valid email.',
		conferenceLoading: 'Konferenca është duke filluar',
		cameraIsOff: 'Kamera është ndalur',
		joinNow: 'Bashkohu tani',
		youAreLoggedInAs: 'Jeni i kyçyr si',
		youAreAboutToParticipate: 'Ju do të merrni pjesë në një takim për telemjekësinë.',
		pleaseIntroduceYourself: 'Ju lutem prezantohuni',
		yourName: 'Emri juaj',
		askToJoin: 'Kerko të bashkangjitesh',
		conferenceNotActive: 'Konferenca nuk është aktive!',
		joiningConference: 'Duke inkuadruar në konferencë',
		firstNameRequired: 'Emri është obligativ',
		lastNameRequired: 'Mbiemri është obligativ',
		seeingOrHearingPatient:
			'Ju nuk jeni duke {value5} {value2} sepse është aktivizuar butoni fizik i privatësisë për {value}. Ky buton mund të çaktivizohet vetëm manualisht. Ju lutemi, kontaktoni administratorin tuaj. {value4}',
		alsoTvIsMuted: 'Gjithashtu, televizori është në heshtje ose i fikur. Ju lutemi sigurohuni që {value} mund të dëgjojë.',
		tvIsMuted: 'Televizori është në heshtje ose i fikur. Ju lutemi sigurohuni që {value} mund të dëgjojë.',
		thePatient: 'pacientin',
		theBaby: 'foshnjën',
		connectingToHello: 'Duke u lidhur me {value}',
		loadingCamera: 'Duke u inicializuar kamera',
		cameraAndMicBlocked: 'Kamera dhe mikrofoni juaj janë të bllokuar',
		cameraBlocked: 'Kamera juaj ështe e bllokuar',
		micBlocked: 'Mikrofoni juaj ështe i bllokuar',
		clickMicOrCamera: `Klikoni {value} ikonen e bllokuar ne qoshin e djathte te ekranit`,
		camera: 'Kamera',
		microphone: 'Mikrofoni',
		maximizeFeed: 'Ekran i plotë',
		minimizeFeed: 'Dilni nga ekrani i plotë',
		allowAccessRefreshPage: 'Lejoni qasje dhe rifreskoni faqen',
		youHaveLeftMeeting: 'Ju e keni lenë mbledhjen',
		meetingEndedFamilyMember:
			'Seanca është përfunduar nga spitali. Butonat e privatësisë janë aktivizuar në pajisjen {value}. Mund të shihni beben kur butonat e privatësisë janë çaktivizuar.',
		babyOutsideVisitingHoursMessage: 'Ju nuk mund të vizitoni beben tuaj jashtë orarit të vizitave.',
		unSupportedTV: 'Unsupported TV',
		commandIsNotSupportedPatientTV: `Kjo komandë nuk perkrahet nga TV i pacientit.`,
		areUsureUwantToLeaveSession: `A jeni të sigurtë që dëshironi të largoheni nga ky sesion?`,
		copy: `Kopjo`,
		copied: `Është kopjuar`,
		failedToCopyLink: `Kopjimi i vegzës ka dështuar!`,
		copyCorrelationId: `Kopjo ID-në e lidhshmërisë`,
		thereIsActiveConference: 'There is an already active conference',
		callTransferredToAnotherClient: 'Thirrja është transferuar te klienti tjetër.',
		callAnsweredElsewhere: 'Jeni përgjigjur në thirrje në një pajisje tjetër.',
		rejoin: 'Ri-bashkohu',
		submitPhysicalTherapy: 'Parashtro terapinë fizike',
		universalSafetyInterventions: 'Ndërhyrjet universale të sigurisë',
		fallRisk: 'Rrezik i rënies',
		dailyWeight: 'Pesha ditore',
		fallHarmRisk: 'Rënie me rrezik dëmtimi',
		silentHeartAttack: 'Infarkti i heshtur në zemër',
		addSuperAdmin: 'Shto Super Admin',
		privacyOn: 'Privatësia e lëshuar',
		privacyOff: 'Privatësia e ndalur',
		settings: 'Azhorminet',
		safeRanges: 'Vlerat optimale',
		pressureInjury: 'Lëndimet nga presioni',
		getOutOfBed: 'Duke u ngritur nga shtrati',
		screenMode: 'Modaliteti i ekranit',
		speakerAudioVolume: 'Volumi i audios së altoparlantit',
		displayOrientationExtended: 'Orientimi i ekranit është zgjeruar',
		roundingDefaultCamera: 'Rrumbullakimi i kamerës së parazgjedhur',
		monitoringDefaultCamera: 'Monitorimi i kamerës së parazgjedhur',
		faceDown: 'Fytyra teposhtë',
		rails: 'Mbrojtjet anësore',
		left: 'Majtas',
		right: 'Djathtas',
		top: 'Lart',
		bottom: 'Poshtë',
		skeletonMode: 'Modaliteti i skeletit',
		physicalTherapies: 'Terapitë fizike',
		addExercise: 'Shto ushtrim',
		seconds: 'sekonda',
		hour: 'orë',
		callType: 'Lloji i thirrjes',
		details: 'Detajet',
		exportCSV: 'Eksporto regjistrat CSV',
		exportToCSV: 'Eksporto në CSV',
		cannotBeNDaysDifference: 'Diferenca në data nuk mund të jetë më shumë se {value} ditë',
		reportSentSuccessfully: 'Raporti është dërguar me sukses.',
		toAccessCheckEmail: 'Për të hyrë dhe shkarkuar raportin, ju lutemi vazhdoni në adresën tuaj të emailit.',
		reportIsUnderway: 'Raporti është në proces.',
		reportUnderwayDescription: 'Raporti juaj është në proces dhe mund të zgjasë pak kohë në varësi të madhësisë së të dhënave.',
		thankYouForPatience: 'Faleminderit për durimin tuaj!',
		listOfRooms: 'Lista e dhomave',
		exportCSVInBackground:
			'Eksportimi CSV do të krijohet në sfond. Pasi të përfundojë, do të dërgohet te {value} në një bashkëngjitje.',
		talkToPatient: 'Bisedo me pacientin',
		talkToNurse: 'Bisedo me infermier/e',
		patientView: 'Pamja e pacientit',
		ongoing: 'Në vazhdim',
		past: 'Të kaluara',
		screenUsed: 'Ekrani i përdorur',
		callDetails: 'Detajet e thirrjes',
		endCallForRoom: 'Ndale thirrjen për dhomë',
		retry: 'Provo sërish',
		callDurationSoFar: 'Kohëzgjatja e thirrjes (deri tani)',
		noOngoingConferences: 'Nuk ka konferenca të vazhdueshme për momentin.',
		refresh: 'Rifreskoni',
		areYouSureToTerminate:
			'Jeni i sigurtë që dëshironi ta përfundoni këtë sesion? Kjo do të përfundojë thirrjen midis mjekut dhe pacientit.',
		terminateRequestTimeOut: 'Përfundoni kohën e pritjes së kërkesës',
		retryRequest: 'Dëshiron të provoni sërish? Kjo do të përfundojë thirrjen midis mjekut dhe pacientit.',
		sessionTerminatedSuccessfully: 'Sesioni është përfunduar me sukses.',
		terminateRoomSession:
			'Jeni i sigurtë që dëshironi ta përfundoni sesionin në dhomë? Kjo do të përfundojë thirrjen midis mjekut dhe pacientit.',
		roomSessionTerminated: 'Sesioni i dhomës është përfunduar me sukses.',
		sessionAlreadyEnded: 'Ky sesion veçse ka përfunduar.',
		terminationInitiated: 'Përfundimi për këtë sesion është iniciuar nga një administrator tjetër.',
		noPermissionToTerminate: 'Nuk jeni i autorizuar për ta përfunduar këtë sesion.',
		assignedDevices: 'Pajisjet e përcaktuara',
		assignedDevice: 'Pajisja e përcaktuar',
		data: 'Të dhënat',
		availabilitySetup: 'Disponueshmërinë',
		participants: 'Pjesëmarrësit',
		nursesCurrentlyDuty: 'Punëtor shëndetesorë janë në detyrë për momentin',
		noCategory: 'Nuk ka kategori',
		required: 'E kërkuar',
		fillRequiredFields: 'Ju lutem plotësoni fushat e kërkuara',
		ai: 'IA',
		noMoreThanOneAiType: 'Nuk mund të aktivizoni më shumë se një sinjalizim të IA në të njëjtën kohë.',
		maxBirthdayLimit: 'Ju duhet të jeni më së shumti 120 vjeçar/e',
		year: 'Viti',
		video: 'Video',
		file: 'Dosja',
		invalidLink: 'Linku nuk është valid. Ju lutem provoni përsëri!',
		measureCompleted: 'Matja ka përfunduar',
		couldNotGetPatientData: 'Nuk mund të merrnim të dhënat e pacientit',
		tryAgainWithoutMoving: 'Provoni përsëri pa lëvizur',
		pressurizationSlow: 'Matja e shtypjes e ngadalësuar! Ju lutem shikoni manshetën!',
		wait5minutes: 'Prisni 5 minuta dhe provoni përsëri',
		deviceIsConnecting: '{value} - Pajisja është duke u lidhur',
		doToRetakeMeasure: 'Ju mund të {value} për të rimarrë matjen!',
		doToReconnect: `Mund të {value} për t'u rilidhur!`,
		toRemeasureTemperature: 'Për të rimarrë matjen, shtypni butonin e matjes së termometrit.',
		measureHasFinished: '{value} ka përfunduar matjen',
		deviceIsConnected: '{value} - Pajisja është lidhur',
		deviceDisconnected: '{value} - Lidhja me pajisjen është shkëputur',
		pleaseWaitConnection: 'Ju lutemi prisni derisa të vendoset lidhja',
		pleaseStandOnScale: 'Ju lutemi qëndroni në peshore për të filluar matjen.',
		valueIsDisconnected: '{value} është shkëputur',
		valueIsConnecting: '{value} është duke u lidhur',
		valueIsConnected: '{value} është lidhur',
		deviceCouldNotConnect: '{value} - Nuk mund te lidhemi me pajisjen. Shqyrtoni bluetooth',
		deviceMeasuring: '{value} - Pajisja është duke bërë matjet',
		deviceConnectFail: '{value} - Lidhja me pajisjen ka dështuar',
		deviceMeasureStopped: '{value} - Matja u ndalua',
		deviceMeasureCompleted: '{value} - Matja ka përfunduar',
		deviceReconnecting: '{value} - Pajisja është duke u lidhur përsëri',
		problemWithReader: 'Problem me lexuesin. Provoni me një shirit të ri!',
		testStripRemoved: 'Test shiriti është larguar gjatë matjes. Bëni një test të ri me test shirit të ri!',
		problemStrip:
			'Problem me shiritin e provës (shirit i lagësht, i hequr shumë shpejt, shumë gjak i aplikuar në shirit). Provoni përsëri me një shirit të ri!',
		lowRoomTemperature: 'Temperatura e dhomës është e ulët. Temperatura optimale është 10°C - 40°C / 50°F - 104°F',
		highRoomTemperature: 'Temperatura e dhomës është shumë e lartë. Temperatura optimale është 10°C - 40°C / 50°F - 104°F',
		communicationError:
			'{value} - Gabim i komunikimit. Shtypni dhe mbani shtypur butonin M (Memory) për 7 sekonda për të rivendosur pajisjen',
		newTestNewStrip: 'Bëni një test të ri me test shirit të ri!',
		getBlood: 'Merrni gjakun!',
		measureStarted: 'Matja ka filluar',
		stripIn: 'Shiriti u vendos',
		stripOut: 'Hiqni shiritin',
		totalCholesterol: 'Kolesteroli total',
		hdlCholesterol: 'Kolesteroli HDL',
		waistCircumference: 'Perimetri i belit',
		healthInformation: 'Informatat shëndetësore',
		currentEverydaySmoker: 'Aktualisht duhanpirës çdo ditë',
		currentSomedaySmoker: 'Aktualisht duhanpirës ndonjë ditë',
		formerSmoker: 'Kam qenë duhanpirës në të kaluarën',
		neverSmoker: 'Kurrë nuk kam qenë duhanpirës',
		sufferFromHypertension: 'A vuan nga hipertensioni?',
		medicalStatus: 'A keni status medikamentesh?',
		areYouSmoker: 'A jeni duhanpirës?',
		haveDiabeticStatus: 'A keni status diabetik?',
		patientIsBeingRegistered: 'Pacienti është duke u regjistruar',
		patientAlreadyExists: 'Pacienti ekziston',
		pleaseSelectOneOfTheOptionsBelow: 'Ju lutem selektoni një nga opsionet më poshtë',
		diabetesMellitusI: 'Diabetes Mellitus tipi I',
		diabetesMellitusII: 'Diabetes Mellitus tipi II',
		submit: 'Dërgo',
		dateTimeFormats: 'Formatet e datës dhe orës',
		seeingAndHearing: 'parë dhe dëgjuar',
		seeing: 'parë',
		hearing: 'dëgjuar',
		patientRequestedHelp: 'Pacienti kërkoi ndihmë',
		patientSitting: 'Pacienti u detektua joaktiv në një pozicion në karrige.',
		patientStanding: 'Pacienti u detektua joaktiv në pozicionin në këmbë.',
		patientLyingSide: 'Pacienti u detektua joaktiv në pozicionin anësor.',
		patientLyingRightSide: 'Pacienti u detektua joaktiv në pozicionin e djathtë anësor.',
		patientLyingLeftSide: 'Pacienti u detektua joaktiv në pozicionin e majtë anësor.',
		patientLyingFront: 'Pacienti u detektua joaktiv në pozicionin shtrirë.',
		patientFaceDown: 'Pacienti u detektua joaktiv në pozicionin shtrirë përmbys.',
		patientInactive: 'Pacienti u detektua joaktiv.',
		closed: 'Mbyllur',
		or: 'ose',
		history: 'Historia',
		availableDoctors: 'Doktorët në dispozicion',
		alreadySelected: 'Veçse i/e selektuar',
		alreadyInPool: 'Veçse është në grup',
		cantAddMoreDoctors: 'Nuk mund ti shtoni më shumë se 5 doktor përnjëherë!',
		cam: 'kamerës',
		mic: 'mikrofonit',
		camAndMic: 'kamerës dhe mikrofonit',
		hellocareMode: 'Modi hellocare',
		aiWidgetMode: 'Modi i AI Miniaplikacioneve',
		telemedicineMode: 'Modi i Telemedicinës',
		hospitalMode: 'Modi i Spitalit',
		nicuMode: 'Modi NICU',
		virtualExaminationMode: 'Modi i Ekzaminimit virtual',
		simplifiedMode: 'Modi i thjeshtuar',
		eVideonMode: 'Modi eVideon',
		hellocareEngagement: 'Lidhja me hellocare',
		signOut: 'Dilni',
		primaryHealthcare: 'Kujdes primarë shëndetësor',
		healthSystemType: 'Lloji i sistemit shëndetësor',
		selectHealthSystemType: 'Zgjedh llojin e sistemit shëndetësor',
		patientGettingOutOfBed: 'Pacienti po ngrihet nga shtrati',
		clickToLoginAnotherAcc: 'Klikoni këtu për tu kyçur në një llogari tjetër.',
		notAssignedHs: 'Ju nuk jeni caktuar ne asnjë sistem shëndetësor për momentin',
		memberNoHs: `Ky anëtar aktualisht nuk është i caktuar në asnjë sistem shëndetësor`,
		digitalClinicMode: 'Modi i Klinikës Digjitale',
		impulsiveConfused: 'Impulsiv, I/e hutuar',
		pullingO2: 'Largim oksigjeni',
		nonRedirectable: 'I padëgjueshëm',
		precautions: 'Masa paraprake',
		precautionsDescription:
			'Kur kjo është e aktivizuar, infermierët do të jenë në gjendje të vendosin masa paraprake si rreziku i rënies për pacientin, në mënyrë që ata të mund të vizualizojnë lehtësisht dhe të renditin pacientët bazuar në listën e masave paraprake.',
		terminateSession: 'Përfundo sesionin?',
		terminateRoomCall: 'Përfundo sesionin e dhomës?',
		selectRoom: 'Zgjedhni dhomën',
		selectLocationReassign: 'Zgjedhni një lokacion prej sistemit shëndetësor më lartë për të menagjuar spitalet ne të.',
		selectLocation: 'Zgjedhni lokacionin',
		selectHsToManage: 'Zgjedhni sistemin shëndetësor që dëshironi ta menagjoni.',
		reAssignDevice: 'Ricaktoni pajisjen',
		regionSaved: 'Regjioni u ruajt',
		somethingWrong: 'Diçka shkoi gabim!',
		hi: 'Përshëndetje',
		selectOptionsBelow: 'Selekto opsionet më poshtë',
		willBeReceivingCalls: 'Do të pranoj thirrje nga pacientët',
		willNotBeReceivingCalls: 'Nuk do të pranoj thirrje nga pacientët',
		selectHealthSystems: 'Selekto sistemet shëndetësore',
		selectReceivingCalls: 'Selekto nëse do të pranosh thirrje nga pacientët.',
		selectHealthSystemsReceiveCalls: 'Selekto sistemet shëndetësore nga të cilat do të pranosh thirrje.',
		selectLevelReceiveCalls: 'Selekto nivelin ku do të pranosh thirrje.',
		neckExercises: 'Ushtrime të qafës',
		legExercises: 'Ushtrime të këmbës',
		shoulderExercises: 'Ushtrime të shpatullës',
		hipExercises: 'Ushtrime të pelvikut',
		extendedLegExercise: 'Ushtrimi i zgjatjes së këmbës',
		singleLegGluteKicksExercise: 'Ushtrimi Single Leg Glute Kicks',
		standingMarchExercise: 'Ushtrimi i qëndrimit në këmbë',
		hamstringStretchExercise: 'Ushtrimi i shtrirjes',
		standingQuadStretchExercise: 'Ushtrimi i qëndrimit në një këmbë',
		neckExercise: 'Ushtrimi i qafës',
		neckStretchExercise: 'Ushtrimi i shtrirjes së qafës',
		liftingHeadSidewaysExercise: 'Ushtrimi i ngritjes së kokës anash',
		shoulderExercise: 'Ushtrimi i shpatullës',
		armRaisesExercise: 'Ushtrimi i ngritjes së krahut',
		rotatorCuffExercise: 'Ushtrimi Rotator Cuff',
		shoulderExtensionExercise: 'Ushtrimi i zgjatjes së shpatullave',
		seatedShoulderRaiseExercise: 'Ushtrimi ulur i ngritjes së shpatullave',
		hipExercise: 'Ushtrimi i pelvikut',
		hipMarchingExercise: 'Ushtrimi Hip Marching',
		sideLyingLegLiftExercise: 'Ushtrimi për ngritjen e këmbës së shtrirë anash',
		fireHydrantExercise: 'Fire Hydrant Exercise',
		maximumRangeOfMotion: 'Rangu maksimal i lëvizjes',
		leftSideNumberOfRepetition: 'Numri i përsëritjeve në anën e majtë',
		rightSideNumberOfRepetition: 'Numri i përsëritjeve në anën e djathtë',
		numberOfDaysToAchieveTheTarget: 'Numri i ditëve për ta arritur qëllimin',
		degreeIncrementInTargetROM: 'Rritja e nivelit në ROM',
		holdingTimeInSeconds: 'Koha e mbajtjes në sekonda',
		numberOfRepetition: 'Numri i përsëritjeve',
		numberOfRepetitionForRightNeck: 'Numri i përsëritjeve në anën e djathtë',
		addPhysicalTherapy: 'Shto një terapi fizike',
		remotePairedWithDevice: `Një dirigjues është i lidhur me këtë pajisje. Dëshironi t'a shkëputni?`,
		remoteNotPairedWithDevice: `Kjo pajisje nuk ka dirigjues të lidhur. Nëse dëshironi të lidhni një dirigjues, shypni dhe mbani të shtypur butonin OK dhe Back derisa te shihni një dritë të kuqe pulsusese në dirigjues, dhe shtypni Lidh në Ueb.`,
		remotePairedSuccessfully: 'Dirigjuesi u lidh me sukses.',
		remoteUnpairedSuccessfully: 'Dirigjuesi u shkëput me sukses.',
		pairingTimeout: 'Koha mbaroi. Ju lutem provoni përsëri.',
		pair: 'Lidh',
		unpair: 'Shkëput',
		dismiss: 'Largo',
		tryingToPairRemote: 'Duke tentuar të lidhë dirigjuesin...',
		tryingToUnpairRemote: 'Duke tentuar të shkëputë dirigjuesin...',
		remoteControl: 'Dirigjuesi',
		patientStandingWalking: 'U detektua humbje e ekuilibrit',
		personIsDetectedWalking: 'U detektua humbje e ekuilibrit. Ju lutemi kontrolloni pacientin!',
		analyticPositionDescription: 'Pacienti u pozicionua {value} për {value1}',
		lyingFront: 'shtrirë përpara',
		lyingSide: 'shtrirë anash',
		lyingDown: 'shtrirë poshte',
		sitting: 'ulur',
		walking: 'duke ecur',
		inviteUsers: 'Fto përdorues',
		cannotEnableDataAcquisition:
			'Nuk mund të aktivizoni marrjen e të dhënave pasi kjo pajisje aktualisht ka privatësinë të aktivizuar.',
		cannotEnablePrivacyMode:
			'Nuk mund të aktivizoni privatësinë pasi kjo pajisje ka aktualisht të aktivizuar marrjen e të dhënave',
		careEventsForNurses: 'Ngjarjet e Kujdesit për Infermierët/et',
		roundingCareEventsForNurses: 'Ngjarjet e Kujdesit për Infermierët/et',
		careEventsForNursesDescription:
			'Ngjarjet e kujdesit ndaj pacientit si Pranimi, Konsultimi, Trajtimi dhe Lirimi i kryer nga VCP-të regjistrohen.',
		careEventsForSafetyCompanions: 'Ndërhyrjet dhe sinjalet për Shoqëruesit e Sigurisë dhe Infermierët',
		careEvents: 'Ngjarjet e kujdesit',
		careEvent: 'Ngjarje e kujdesit',
		recordings: 'Inqizimet',
		savedCareEvent: 'Ngjarja e Kujdesit e Ruajtur',
		eventDetails: 'Detajet e Ngjarjes',
		homeMedListCompleted: 'Home Med Lista e Kompletuar',
		admissionDataCompleted: 'Të dhënat e pranimit janë plotësuar',
		safetyCompanions: 'Shoqërues të sigurisë',
		companionModeName: 'Modaliteti shoqërues',
		frontlineNurses: 'Infermiere në krye të frontit',
		ipoCompleted: 'IPOC e kompletuar',
		dischargeInstructionsCreated: 'Udhëzimet e shkarkimit u krijuan',
		dischargeTeachingCompleted: 'Mësimi i shkarkimit përfundoi',
		performed2ndRNMedVerification: 'Kryhet Verifikimi i 2-të RN Med',
		notifiedMD: 'MD i Njoftuar',
		performedMentorship: 'Mentorimi i perfunduar',
		performedSepsisScreenNotification: 'Sepsis Ekran Notifikimi i perfunduar',
		completed24HourChartCheck: 'Kontrolli i grafikut 24 orësh i përfunduar',
		performedClinicalRounding: 'Cikli klinik i përfunduar',
		providedUpdateToFamily: 'Përditësimi për Familjen u bë',
		assistedWithCodeBlue: 'Ndihmuar me kodin blu',
		otherCareEventForNurse: 'Ngjarje tjetër e kujdesit për infermierin/en',
		redirectedPatientToPreventFall: 'Ridrejtuar pacientin për të parandaluar rënien',
		notifiedBedsideStaffToAssistPatientToPreventFall:
			'Stafi i njoftuar pranë shtratit për të ndihmuar pacientin për të parandaluar rënien',
		notifiedBedsideStaffToAssistPatient: 'Njoftohet stafi pranë shtratit për të ndihmuar pacientin',
		calledRapidResponse: 'Reagimi i Shpejtë i Thirrur',
		preventedHarmToStaffMember: 'Parandaluar dëmtimin e anëtarit të stafit',
		typeOfCareEvent: 'Shto event për kujdesje',
		stopRecording: 'Ndaloni inqizimin',
		record: 'Inqizo',
		startRecording: 'Starto inqizimin',
		videoRecordingSaving: 'Inqizimi i video-s është duke u ruajtur',
		chooseValidDate: 'Ju lutem zgjedhni një datë valide',
		patientHistory: 'Historia e pacientit',
		additionalHealthDataSymptoms: 'Të dhëna shtesë dhe simptomat',
		patientRegistration: 'Regjistrimi i pacientit',
		parentName: 'Emri i prindit',
		patientHealthCardNumber: 'Numri i kartës së shëndetit të pacientit',
		maritalStatus: 'Gjendja martesore',
		single: 'Beqar/e',
		married: 'I/e martuar',
		divorced: 'I/e divorcuar',
		widowWidower: 'I/e ve',
		registerNewPatient: 'Regjistro pacient të ri',
		getPatientData: 'Merr të dhënat e pacientit',
		alreadyWaitingForRequest: 'Ju tashmë jeni duke pritur për një kërkesë',
		whatReasonOfVisit: 'Cila është arsyeja e vizitës tuaj sot?',
		reasonOfVisit: 'Arsyeja e vizitës',
		cannotMeasureVS:
			'Shenjat vitale nuk mund të maten nga webi nëse nuk jeni në thirrje me mjekun. Shkoni ose shkarkoni aplikacionin hellocare në celular për të matur shenjat vitale.',
		takeBreak: 'Bëj një pushim',
		resumeWorking: 'Vazhdo punën',
		symptomsDuration: 'Kohëzgjatja e simptomave',
		frontDeskCheckIn: 'Regjistrimi ne Sportel',
		startVisit: 'Fillo vizitën',
		minutesWaitingRoom: 'Minuta në dhomën e pritjes',
		markVisitAsCompleted: 'Shëno vizitën si të kompletuar',
		inPerson: 'Takim personal',
		virtualCall: 'Thirrje virtuale',
		instructionsForConsultation: 'Udhëzime për konsultim',
		instructionsForDiagnosticResearch: 'Udhëzime për hulumtime diagnostikuese',
		healthInstitutionWherePatientInstructed: 'Institucioni shëndetësor ku pacienti u udhëzua',
		consultant: 'Konstultanti',
		purposeOfInstruction: 'Arsyeja e udhëzimit',
		medicalHistory: 'Historia medicinale',
		examinations: 'Ekzaminimet',
		icd9Code: 'Kodi ICD-9',
		currentTreatment: 'Tretmani momental',
		codeMedicines: 'Kodi medicinal',
		workingDiagnosis: 'Diagnoza',
		icd10Code: 'Kodi ICD-10',
		organizationalUnit: 'Njësia organizative',
		organizationalUnitCode: 'Kodi i njësisë organizative',
		addressOfInstitution: 'Adresa e institucionit',
		consultantDoctor: 'Doktori konsutant',
		procedureCode: 'Kodi i procedurës',
		icpc: 'ICPC',
		addForms: 'Shto forma',
		visitDetails: 'Detajet e vizitës',
		forms: 'Format',
		numberOfVisits: 'Numri i vizitave',
		lastVisit: 'Vizita e fundit',
		showVisitHistory: 'Shfaq historinë e vizitës',
		remove: 'Fshij',
		nextVisit: 'Vizita e rradhës',
		waitingList: 'Lista e pritjes',
		nextTest: 'Testi i rradhës',
		startTesting: 'Starto testimin',
		instructions: 'Udhëzimet',
		test: 'Testi',
		sample: 'Mostra',
		enterResults: 'Shto rezultatet',
		submitResults: 'Dërgo rezultatet',
		checkInPatients: 'Regjistro pacientët',
		doYouHaveAllergies: 'A keni ndonjë alergji?',
		waitingResults: 'Pritja e rezultateve',
		healthInstitution: 'Institucioni shëndetësor',
		codeHealthInstitution: 'Kodi i institucionit shëndetësor',
		addedForms: 'Format e shtuara',
		addAnotherTest: 'Shto një test të ri',
		doesPatientHaveAllergies: 'A ka pacienti ndonjë alergji?',
		noResponseFromPatient: 'Nuk ka përgjigje nga pacienti',
		whatTypeOfAllergies: 'Çfarë lloj alergjie ka pacienti?',
		food: 'Ushqim',
		environmental: 'Mjedisore',
		biological: 'Biologjike',
		foodAllergies: 'Alergjitë ndaj ushqimeve',
		medicationAllergies: 'Alergjitë ndaj medikamenteve',
		addAllergies: 'Shto alergji',
		registerPatient: 'Shto pacientin',
		menuSettings: 'Aranzhimet e menus',
		editMenuSettings: 'Ndrysho aranzhimet e menus',
		helloIsDisconnected: '{value} është i shkyçur!',
		connectionCannotEstablish:
			'Lidhja me {value} nuk mund të realizohet. Kontrollo lidhjen e internetit në pajisje dhe provo sërish.',
		cannotChangeWorkflow: 'Nuk mund ta ndryshoni rrjedhën',
		noCompletedAppointments: 'Nuk keni asnjë kërkesë të përfunduar!',
		cholesterol: 'Kolesteroli',
		totalCholesterolTooltip:
			'Kolesteroli total i gjakut është një masë e kolesterolit LDL, kolesterolit HDL dhe përbërësve të tjerë lipidikë. Mjeku juaj do të përdorë numrin tuaj total të kolesterolit kur të përcaktojë rrezikun tuaj për sëmundje të zemrës dhe mënyrën më të mirë për ta menaxhuar atë.',
		hdlCholesterolToolip:
			'HDL (lipoproteina me densitet të lartë), ose kolesteroli "i mirë", absorbon kolesterolin dhe e çon atë përsëri në mëlçi. Më pas mëlçia e nxjerr atë nga trupi. Nivelet e larta të kolesterolit HDL mund të ulin rrezikun për sëmundje të zemrës dhe goditje në tru.',
		waistCircumferenceTooltip: `Një mënyrë tjetër për të vlerësuar rrezikun e mundshëm të sëmundjes është matja e perimetrit të belit. Yndyra e tepërt e barkut mund të jetë serioze sepse ju vë në rrezik më të madh për zhvillimin e kushteve të lidhura me obezitetin, si diabeti i tipit 2, presioni i lartë i gjakut dhe sëmundjet e arterieve koronare. Pra, beli juaj mund t'ju thotë se keni një rrezik më të lartë për të zhvilluar kushte të lidhura me obezitetin.`,
		meetingWithDoctor: 'Takim me Dr.',
		meetingWithPatient: 'Takim me pacientin:',
		accountIsDeactivated: 'Llogaria juaj nuk është aktive',
		helloUser: 'Tung {value}',
		reachAdministrator: 'Ju lutemi kontaktoni administratorin tuaj për informacione të mëtejshme',
		numberOfSamples: 'Numri i mostrave',
		numberOfTests: 'Numri i testeve',
		emergencyCases: 'Rastet emergjente',
		startEmergencyCase: 'Starto rast emergjent',
		waitingForResults: 'Në pritje të rezultateve',
		searchPatientNameOrNumber: 'Kërko emrin apo numrin personal të pacientit',
		addResults: 'Shto rezultate',
		enterNoteHere: 'Shkruaj shenimet këtu',
		network: 'Rrjeti',
		profileName: 'Emri i profilit',
		cidr: 'CIDR',
		wiFiBand: 'Brezi i WiFi',
		minBandwidth: 'Shpejtësia minimal e transmetimit në kbps',
		maxBandwidth: 'Shpejtësia maksimale e transmetimit në kbps',
		addConfiguration: 'Shto konfigurim',
		bandwidth: 'Shpejtësia e transmetimit',
		wifiFrequency: 'WiFi Frekuenca',
		minShouldBeLessThanMax: 'Minimum duhet të jetë më i vogel se maksimumi.',
		minBandwidthRequired: 'Shpejtësia minimal e transmetimit është obligative.',
		maxBandwidthRequired: 'Shpejtësia maksimale e transmetimit është obligative.',
		minBandwidthLessThan: `Shpejtësia minimal e transmetimit nuk mund të jetë më pak se 500.`,
		maxBandwithMoreThan: `Shpejtësia maksimale e transmetimit nuk mund të jetë më shumë se 5000.`,
		wiFiBandRequired: 'Brezi i WiFi është obligativ.',
		chooseUnit: 'Zgjedhni njësinë',
		applyConfig: 'Apliko konfigurimin.',
		selectHospital: 'Zgjedhni spitalin',
		selectFloor: 'Zgjedhni katin',
		setMinBandwidth: 'Cakto shpejtësinë minimal të transmetimit',
		setMaxBandwidth: 'Cakto shpejtësinë maksimale të transmetimit',
		chooseWiFiBand: 'Zgjedhni WiFi brezin',
		roleIsRequired: 'Roli është obligativ.',
		invalidIpAddress: 'IP Adresa apo rangu CIDR është jo valide.',
		webSiteAccess: 'Qasja në uebfaqe',
		setIpAddress: 'Cakto IP Adresen.',
		maximumIpAddresses: `Nuk mund të shtoni më shumë se {value} IP Adresa një herësh.`,
		provideCIDRRange:
			'Jepni një gamë adresash duke përdorur shënimin CIDR (p.sh. 192.168.99.0/24), ose një IP adresë (p.sh. 192.168.99.0). Ju gjithashtu mund të jepni listën e IP Adresave ose rangje të adresave duke përdorur enter.',
		setIpAddressOrRange: 'Cakto IP Adresen ose vargun.',
		deleteNetworkConfigConfirmation: 'A jeni të sigurtë që dëshironi të fshini këtë konfigurim të rrjetit?',
		deleteAdConfirmation: 'A jeni i sigurt që dëshironi të fshini këtë direktori aktive?',
		deleteGroupRoleConfirmation: 'A jeni i sigurt që dëshironi të fshini këtë rol të grupit të direktoriumit aktiv?',
		representsRangeOfIps: 'Reprezenton varg të IP-ve',
		historyForConfig: 'Historia për konfigurimin',
		notAuthorizedFromThisIP: 'Ju nuk jeni të autorizuar të qaseni në faqe nga IP Adresa: ',
		notAuthorizedToAccessThisPage: 'Ju nuk jeni të autorizuar për të hyrë në këtë faqe.',
		ifYouThinkThereAreMistakes: 'Nëse mendoni që ka ndodhur ndonjë gabim, ju lutem kontaktoni administratorin tuaj.',
		activeDirectory: 'Active Directory',
		addAd: 'Shto AD',
		addGroupRoles: 'Shto Rolet e grupit',
		groupRoles: 'Rolet e grupit',
		groupRolesDescription: 'Grupi i roleve të drejtorisë aktive',
		groupRolesRequired: 'Sheno Rolin e grupit',
		azureAdUrl: 'Cakto Azure AD Url',
		setValidGroupName: 'Cakto emrin e vlefshëm të grupit',
		setValidGroupNameRequired: 'Sheno emrin e grupit të drejtorisë aktive',
		setValidGroupNameDescription: 'Emri i vlefshëm i grupit të drejtorisë aktive',
		clientId: 'Klient Id',
		clientIdRequired: 'Sheno Klient Id ',
		clientIdDescription: 'Cakto ID-në e klientit të {value}',
		clientSecret: 'Klient Sekret',
		clientSecretRequired: 'Sheno Klient Sekret',
		clientSecretDescription: 'Vendos sekretin e klientit të {value}',
		numberOfRepetitionForLeftNeck: 'Numri i përsëritjeve për qafë',
		inviteToLaboratory: 'Fto në laborator',
		startTests: 'Starto testet',
		patientNoShowUp: 'Pacienti nuk u paraqit',
		markSamplesAsTaken: 'Shëno mostrat si të marra',
		markProcessAsCompleted: 'Shëno procesin si të kompletuar',
		editPatient: 'Edito Pacientin',
		searchPatientNameId: 'Mund të filloni të kërkoni pacientë nëpërmjet emrit ose numrit të tyre personal',
		medicalForms: 'Format mjekësore',
		formsDisplayedHere: 'Formularët që shtoni do të shfaqen këtu.',
		totalNumberOfSessions: 'Numri Total i Sesioneve',
		completedVisits: 'Vizitat e përfunduara',
		sessionDuration: 'Kohëzgjatja e Sesionit',
		completedConferences: 'Konferenca të përfunduara',
		notShownUp: 'Nuk u shfaq',
		inProgress: 'Në progres',
		ongoingVisits: 'Vizitat momentale',
		registryNumber: 'Numri i regjistrit',
		visitStart: 'Fillimi i vizitës',
		visitEnd: 'Fundi i vizitës',
		inviteToRoom: 'Fto në dhomë',
		currentVisit: 'Vizita momentale',
		consultationReport: 'Raporti i konsultimit',
		instructionsForGivingParenteralTherapy: 'Udhëzime për dhënien e terapisë peranterale',
		laboratory: 'Laboratori',
		radiology: 'Radiologjia',
		searchToAdd: 'Kërko për të shtuar {value} për {value2}',
		tests: 'teste',
		xRayScans: 'skanime x-ray',
		medicationNameHere: 'Shkruaj medikametin',
		medicationType: 'Tipi i medikamentit',
		addAnotherMedication: 'Shto medikament',
		selectData: 'Selekto të dhënat',
		addOtherData: 'Shto të dhëna tjera',
		returnReportFromLab: 'Raporti kthyes nga laboratori',
		returnReportFromRadiology: 'Raporti kthyes nga radiologjia',
		addOtherNote: 'Shto shënim tjetër',
		addMedicalForms: 'Shto format mjekësore',
		searchPatientNameIdVisitNumber: 'Kërko sipas emrit të pacientit, ID-së ose numrit të regjistrit të vizitës',
		nextDay: 'dite',
		frontDesk: 'Sporteli',
		injectionRoom: 'Dhoma e injeksioneve',
		infusionRoom: 'Dhoma e infusioneve',
		capacity: 'Kapaciteti',
		writeCapacity: 'Shkruani kapacitetin',
		writePriority: 'Shkruani prioritetin',
		startBreak: 'Fillimi i pauzes',
		endBreak: 'Fundi i pauzes',
		startSchedule: 'Fillimi i orarit',
		endSchedule: 'Fundi i orarit',
		personalInformationTitle: 'informatat personale',
		locationAndAddressTitle: 'vendin & adresën',
		licensesTitle: 'licensat',
		signatureAndStampTitle: 'nënshkrimin dhe vulën',
		employmentTitle: 'punësimin',
		educationTitle: 'edukimin',
		visitHistoryFor: 'Historia e vizitave për',
		patientNoVisits: 'Pacienti nuk ka asnjë vizitë.',
		openCases: 'Rastet e hapura',
		closedCases: 'Rastet e mbyllura',
		instruction: 'Udhëzim',
		therapyDescription: 'Përshkrim i terapisë',
		checkUp: 'Kontrollë',
		reCheckUp: 'Ri-Kontrollë',
		referral: 'Referim',
		referToOneOfTheDoctors: 'Refero pacientin te një nga doktorët',
		patientQueue: 'Lista e pritjes së pacientëve',
		referToThisRoom: 'Refero pacientin te kjo dhomë',
		waitingTime: 'Koha e pritjes',
		referralDetails: 'Detajet e referimit',
		queueNumber: 'Numri i rendit të pritjes',
		patientAddedDoctorWaitingList: 'Pacienti është shtuar në listën e pritjes së doktorit',
		itinerary: 'Itinerari',
		additionalNote: 'Shenim shtesë',
		visitIn: 'Vizitë në',
		doctorCheckUp: 'Kontroll te doktori',
		laboratoryTesting: 'Testim laboratori',
		frontDeskPatientCheckinPending: 'Sportel - Regjistrimi i pacientit - Në pritje',
		frontDeskPatientCheckinInvited: 'Sportel - Kontrolli i pacientit - I ftuar',
		frontDeskPatientCheckinInprogress: 'Sportel - Kontrolli i pacientit - Në vazhdim',
		frontDeskPatientNotShowedUp: 'Sportel - Pacienti nuk u shfaq',
		frontDeskPatientCheckinCompleted: 'Sportel - Kontrolli i pacientit u krye',
		frontDeskPatientCheckinCancelled: 'Sportel - Regjistrimi i pacientit u anulua',
		waitingForDoctor: 'Në pritje të mjekut',
		doctorInvitedPatient: 'Pacienti i ftuar nga doktori',
		doctorInvitedPatientNotShowedUp: 'Pacienti i ftuar nga mjeku - Nuk shfaqet',
		inDoctorVisit: 'Në vizitë me mjekun',
		doctorVisitCompleted: 'Vizita e mjekut - Përfundoi',
		doctorVisitCancelled: 'Vizita e mjekut - Anulohet',
		patientWaitingForLaboratoryTest: 'Testi laboratorik - Në pritje të pacientit',
		laborantInvitedPatient: 'Pacienti i ftuar në Lab',
		laborantInvitedPatientNotShowedUp: 'Pacienti i ftuar në laborator - Nuk shfaqet',
		laboratoryTestInProgress: 'Testi laboratorik - Në vazhdim',
		laboratoryVisitCompleted: 'Vizita laboratorike - Përfunduar',
		laboratoryVisitCancelled: 'Vizita laboratorike - e anuluar',
		otherInformation: 'Informata tjera',
		preExistingDescription: 'Gjendje ekzistuese',
		typeOfAllergies: 'Çfarë lloji të alergjisë keni?',
		searchOrAddAllergies: 'Kërko ose shto alergji të reja',
		typeAllergyAndPressEnter: 'Shkruani alergjinë tuaj dhe klikoni enter',
		pleaseSelectOneOfTheOptionsAbove: 'Ju lutem selektoni një nga opsionet më lartë',
		environmentalAllergies: 'Alergjitë mjedisore',
		biologicalAllergies: 'Alergjitë biologjike',
		writePreExistingCondition: 'Shkruani gjendjen ekzistuese',
		pleaseWritePreExistingCondition: 'Ju lutem shkruani gjendjen ekzistuese',
		patientToBeTestedFor: 'Pacienti duhet të testohet për',
		creatinine: 'Kreatinina',
		lucose: 'Lukoza',
		hemogram: 'Hemogrami',
		sedimentation: 'Sedimentimi',
		triglycerides: 'Trigliceridet',
		urea: 'Urea',
		urine: 'Urina',
		setToPending: 'Kthe ne pritje',
		numberOfAnalysis: 'Numri i analizave',
		glucose: 'Glukoza',
		assignNewDoctor: 'Cakto doktor të ri',
		reassignToQueue: 'Ricakto në list tek {value}',
		pleaseWriteCorrectValues: 'Shkruani vlerat korrekte',
		caseId: 'ID e rastit',
		checkInNumber: 'Numri i regjistrimit',
		addNecessaryVitalSigns: 'Shtoni çdo shenjë të nevojshme jetësore duke klikuar butonin "Shto të dhëna tjera".',
		sediment: 'Sediment',
		erythrocyteSedimentationRate: 'Shkalla e rendimetit te eritrociteve',
		erythrocyteCount: 'Numri i eritrociteve',
		hemoglobin: 'Hemoglobina',
		femtolitre: 'Femtolitri',
		picograms: 'Pikogramet',
		hematocrit: 'Hematokriti',
		ldlCholesterol: 'LDL Kolesteroli',
		vldCholesterol: 'VLD Kolesteroli',
		view: 'Pamja',
		color: 'Ngjyra',
		reaction: 'Reaksioni',
		specificWeight: 'Pesha specifike',
		proteins: 'Proteina',
		acetone: 'Aceton',
		bilirubin: 'Bilirubini',
		urobilinogen: 'Urobilinogjeni',
		nitrites: 'Nitritet',
		babyCrying: 'Foshnja duke qarë',
		babyCryingDetected: 'Foshnja u detektua duke qarë.',
		availableLaboratorians: 'Laborantët në dispozicion',
		referToOneOfTheLaboratorians: 'Refero te një prej laborantëve',
		referToThisLaboratorian: 'Refero tek ky laborant',
		leukocyteCount: 'Numri i leukociteve',
		patientAddedNurseWaitingList: 'Pacienti u shtua ne listën e pritjes tek {value}',
		auditLogs: 'Regjistrat e auditimit',
		client: 'Klienti',
		logType: 'Lloji i regjistrit',
		inputter: 'Shtuesi i të dhënave',
		inputterId: 'Id e shtuesit të të dhënave',
		inputterType: 'Tipi i shtuesit të të dhënave',
		subjectType: 'Tipi i subjektit',
		subjectName: 'Emri i subjektit',
		subjectId: 'Id e subjektit',
		tenantId: 'Id e tenantit',
		objectType: 'Tipi i objektit',
		objectId: 'Id e objektit',
		sourceOfInput: 'Burimi i hyrjes',
		actioneeName: 'Emri i veprimtarit',
		actioneeObjectId: 'Actionee Object Id',
		actioneeObjectType: 'Actionee Object Type',
		noAuditLogs: 'Nuk ka regjistra të auditimit',
		filterLogs: 'Filtro regjistrat',
		user: 'Përdoruesi',
		resetFilter: 'Fshij filtrimin',
		waistUnit: 'Njësia e belit',
		totalCholesterolUnit: 'Njësia e kolesterolit total',
		hdlCholesterolUnit: 'Njësia e HDL kolesterolit',
		contactInformation: 'Kontakti',
		updateYour: 'Përditëso',
		update: 'Përditëso',
		profilePictureTitle: 'fotografinë e profilit',
		addressTitle: 'adresën',
		contactInformationTitle: 'informatat tuaja',
		healthInformationTitle: 'informatat shëndetësore',
		manageProfileInformation: 'Menaxhoni informatat e profilit',
		keepAccountSecure: 'Mbani llogarinë tuaj të sigurtë',
		changePharmacyLocation: 'Ndërroni lokacionin e farmacisë suaj',
		manageApps: 'Menaxhoni aplikacionet tuaja',
		changeAppLanguages: 'Ndërroni gjuhën e aplikacionit',
		changeMeasurementUnits: 'Ndryshoni njësitë matëse',
		helpDescription: 'Rreth Nesh, Bisedë e drejtperdrejtë e mbështetjes, Ftoni Njerëz',
		appLanguage: 'Gjuha e aplikacionit',
		help: 'Ndihma',
		uploadNewPicture: 'Ngarko fotografi të re',
		postalCode: 'Kodi postar',
		otherData: 'Të dhëna tjera',
		selectOption: 'Zgjedhni opsionin',
		selectRails: 'Zgjedhni Shinat për të dërguar njoftim',
		showFullProfile: 'Shfaq profilin e plotë',
		fillMandatoryFields: 'Ndryshimet nuk mund të ruhen nëse nuk plotësoni fushat obligative',
		endDateLaterThanStartDate: 'Data e përfundimit duhet të jetë më vonë se data e fillimit',
		endDateLaterThanToday: 'Data e përfundimit nuk duhet të jetë më vonë se data sot',
		rtgPulmonary: 'RTG Pulmonare',
		rtgSkeleton: 'RTG - Skeleti',
		rtgChest: 'RTG - Krahrori',
		rtgUpperExtremities: 'RTG - Ekstremitetet e sipërme',
		rtgLowerExtremities: 'RTG - Ekstremitetet e poshtme',
		rtgSpine: 'RTG - Shtylla kurrizore',
		ultrasonography: 'Echo - Ultrasonografi',
		injection: 'Injektimi',
		infusion: 'Infuzioni',
		recording: 'Inqizimi',
		recordTheSession: 'Inqizo sesionin',
		recordingDescription:
			'Kur kjo të aktivizohet, përdoruesit do të mund të regjistrojnë seancën e monitorimit e cila do të ruhet në profilin e pacientit.',
		pleaseChooseAnotherDate: 'Ju lutem zgjedhni një datë tjetër.',
		availableNursesIn: 'Infermieret në dispozicion në {value}',
		referToOneOfNurses: 'Refero tek një nga infermieret',
		infusionTherapy: 'Terapia e infuzionit',
		injectionTherapy: 'Terapia e injeksionit',
		deleteRegion: 'Fshini regjionin?',
		atLeastOneRegion: 'Sistemet shëndetësore duhet të kenë të paktën një regjion!',
		deleteHS: 'Fshini sistemin shëndetësor?',
		regionCannotBeEmpty: 'Regjioni nuk mund të jetë i zbrazët',
		regionExists: 'Regjioni me këtë emër veçse ekziston',
		healthSystemExists: 'Sistemi shëndetësor me këtë emër veçse ekziston',
		positive: 'Pozitiv',
		negative: 'Negativ',
		inTraces: 'Në gjurmë',
		clearUrine: 'Kthjellët',
		turbid: 'Trubullt',
		reddish: 'E kuqrremtë',
		yellow: 'E verdhë',
		acidic: 'Acidik',
		instructionsForInjection: 'Instrukcione për dhënjen e terapisë parenterale (Injeksion)',
		instructionsForInfusion: 'Instrukcione për dhënjen e terapisë parenterale (Infuzion)',
		noOngoingVisit: 'Aktualisht nuk ka asnjë vizitë të hapur.',
		noShowUpVisit: 'Aktualisht nuk ka asnjë rast ku pacientët nuk janë paraqitur.',
		noCompletedVisits: 'Aktualisht nuk ka asnjë vizitë të përfunduar.',
		radiologyScanes: 'Skanimet radiologjike',
		comment: 'Komenti',
		enterComment: 'Shkruaj komentin',
		radiologyRoom: 'Dhoma e radiologjisë',
		newPassword: 'Fjalëkalimi i ri',
		readTermsAndConditions: 'Lexo termat dhe kushtet tona',
		readPrivacyPolicy: 'Lexo politikën tonë të privatësisë',
		chemicals: 'Kemikatet',
		distance: 'Distanca',
		weightAndLeanBodyMass: 'Lidhur me peshën dhe masën trupore pa dhjam',
		relatedBodyTemperature: 'Lidhur me temperaturën trupore',
		heightAndWaistCircumference: 'Lidhur me gjatësinë dhe perimetrin e belit',
		walkingAndRunning: 'Lidhur me distancën e ecjes dhe vrapimit',
		bloodGlucoseAndCholesterol: 'Lidhur me glukozën në gjak, kolesterolin total dhe kolesterolin HDL',
		selectUnit: 'Zgjedh njësinë',
		poolingFlow: 'Rrjedha e grupit',
		editGeneralSettings: 'Ndrysho aranzhimet e përgjithshme',
		durationDays: 'ditë',
		timesADay: 'herë në ditë',
		parenteralTherapyReport: 'Raporti i terapisë parenterale',
		returnReportFromDiagnostics: 'Raporti kthyes nga diagnostika',
		searchToAddDiagnosis: 'Kërko diagnoza për të shtuar',
		addDiagnoses: 'Shto Diagnoza',
		previousDiagnoses: 'Diagnozat paraprake',
		presets: 'Presets',
		createPreset: 'Krijo paracaktim',
		createNewPreset: 'Krijo paracaktim të ri',
		typePresetDescription: 'Shkruani emrin e paracaktuar',
		editPreset: 'Ndrysho paracaktimin',
		addNewPreset: 'Shto paracaktim të ri',
		deletePreset: 'Fshi Preset',
		areYouSureToDeletePreset: 'A jeni i sigurtë që doni të fshini paracaktimin {value}?',
		goToPreset: 'Shkoni te Paracaktimi',
		noPresets: 'Nuk ka paracaktime të disponueshme, provo të shtosh një të tillë.',
		presetChangeDescription: 'Mund ta ndryshoni në çdo kohë',
		presetName: 'Emri i paracaktuar',
		activePreset: 'Paracaktimi i aktivizuar:',
		noActivePreset: 'Nuk ka paracaktim aktiv',
		resetCamera: 'Rivendos kamerën',
		updateToCurrentPosition: 'Përditëso te pozicioni aktual',
		pleaseWriteValidDate:
			'Ju lutemi shtoni përvoja më të fundit. Për fat të keq, aktualisht nuk i mbështesim përvojat që kanë kaluar 60 vjet.',
		returnReportFromParentalTherapy: 'Raporti kthyes nga terapia parenterale',
		number: 'Numri',
		refer: 'Refero',
		sectorExistsMessage: '{value} me këtë emër veçse ekziston',
		youAreAlreadyOnCall: 'Ju veçse jeni në një thirrje',
		deviceNotAvailableForCall: 'Pajisja nuk është në dispozicion për thirrje për momentin.',
		couldNotGetDeviceState: 'Gjendja e pajisjes nuk mund të merret.',
		visitingHours: 'Orari i vizitave',
		addVisitingHours: 'Shto oraret e vizitave',
		selectAll: 'Kliko të gjitha',
		deSelectAll: 'Largo të gjitha',
		areYouSureConfigurations: `A jeni të sigurtë që dëshironi t'i ruani konfigurimet e reja?`,
		enforceConfiguration: 'Zbatoni konfigurimet',
		conferenceId: 'ID e Konferencës',
		initiator: 'Iniciatori',
		roomCount: 'Numri i Dhomave',
		origin: 'Origjina',
		usedAudio: 'Audio e Përdorur',
		usedVideo: 'Video e Përdorur',
		usedScreen: 'Ekran i Përdorur',
		selectContentToDownload: 'Zgjidhni përmbajtjen që dëshironi të shkarkoni',
		applyGlobalSettings: 'Aplikoni cilësimet globale',
		overrideLocalConfiguration:
			'Duke e shtypur këtë opsion, ju i mbishkruani konfigurimet lokale që janë në hierarkinë e mëposhtme',
		confirmationModal: 'Konfirmim',
		activeQueueError: 'Ju keni pacienta në radhën tuaj të pritjes. Ju lutem kompletoni radhën.',
		chooseRole: 'Zgjedhni një rol',
		selectRoleOrAll: 'Zgjedh një rol apo zgjedh të gjithë për të aplikuar periudhën e pasivitetit',
		addToQueue: 'Shto në rradhë tek {value}',
		rejectSession: 'Refuzo seancën',
		confirmRejectSession: 'Je i sigurt që dëshiron ta refuzosh këtë seancë?',
		reject: 'Refuzo',
		rejectSessionReason: 'Arsyeja e refuzimit (Opsionale)',
		patientsInQueue: '{value} pacientë në radhë',
		availableNurses: 'Infermieret në dispozicion',
		virtualSitterOnList: 'Kujdestar virtual',
		visitor: 'Vizitor',
		addVirtualSitter: 'Shto një kujdestar virtual',
		virtualsitter: 'Kujdestar virtual',
		nextVisitIn: 'Vizita e rradhës është në {value}',
		pleaseGoToFrontDesk: 'Ju lutem shkoni në frontdesk për tu caktuar në ndonjë rradhë',
		hdmiPort: 'Porti HDMI',
		defaultSource: 'Burimi i parazgjedhur',
		ringtoneVolume: 'Volumi i ziles',
		port: 'Porti',
		'port/siteId': 'Porti / ID e Faqes',
		siteId: 'ID e Faqes',
		apiKey: 'Çelësi i API',
		pleaseSelectHealthSystem: 'Ju lutem zgjedhni një sistem shëndetësor.',
		cannotSelectNrMoreThanValue: 'Nuk mund të zgjidhni më shumë se {value} {item},',
		pleaseSelectHospital: 'Ju lutem zgjedhni një spital.',
		pleaseSelectDepartment: 'Ju lutem zgjedhni një departament.',
		pleaseSelectSector: 'Ju lutem zgjedhni një {value}.',
		pleaseSelectHDMIPort: 'Ju lutem zgjedhni një port HDMI.',
		pleaseSelectTV: 'Ju lutem zgjedhni një TV.',
		ringtoneVolumeValidation: 'Volumi i ziles duhet të jetë në mes të 0 dhe 100.',
		pleaseSelectEndCallSource: 'Ju lutem zgjedhni një TV me burim të thirrjes.',
		pleaseSelectProfileName: 'Ju lutem zgjedhni një emër të profilit.',
		pleaseSelectCompanionDevice: 'Ju lutem zgjedhni një pajisje shoqëruese nëse është e disponueshme',
		pleaseSetURL: 'Ju lutem vendosni një URL.',
		pleaseSetSiteId: 'Ju lutem vendosni ID e faqes.',
		pleaseSetClientId: 'Ju lutemi vendosni një ID klienti',
		pleaseSetSecret: 'Ju lutem vendosni një sekret.',
		pleaseSetUserName: 'Ju lutem vendosni një emër përdoruesi',
		portValidation: 'Porti duhet të jetë një numër më i madh se 0.',
		addHSConfiguration: 'Shto konfigurim të sistemit shëndetësor',
		addTVConfiguration: 'Shto konfigurim të TV',
		selectHSForConfiguration: 'Zgjedhni sistemin shëndetësor që të mund të zgjedhni spitalin për të aplikuar konfigurimet.',
		chooseHospitalForChanges: 'Zgjedhni spitalin ku dëshironi të aplikoni ndryshime.',
		chooseSectorForChanges: 'Zgjedhni {value} ku dëshironi të aplikoni ndryshime.',
		selectDepartment: 'Zgjedhni Departamentin',
		chooseTVSetup: 'Zgjedhni konfigurimin e TV',
		chooseTVSetupType: 'Zgjedhni llojin e konfigurimit të TV',
		selectHelloCareHDMI: 'Zgjedhni portin HDMI te hellocare',
		selectHDMIPort: 'Zgjedhni portin HDMI ku hellocare do të futet në prizë.',
		tvManagement: 'Menaxhimi i TV',
		selectTVOption: 'Zgjedhni opsionin ku TV do të kthehet pas përfundimit të telefonatës.',
		selectTeleHealthProfile: 'Zgjedhni profilin e teleshëndetit',
		selectPreConfiguredProfile: 'Zgjedhni profilin e para-konfiguruar të teleshëndetit. Lëreni të zbrazët nëse nuk ka.',
		helloCareRingtone: 'Zilja e hellocare-it',
		applyToAllDevices: 'Ky cilësim do të zbatohet për të gjitha pajisjet nën këtë spital.',
		setProfileName: 'Vendosni emrin e profilit',
		setURL: 'Vendosni URL',
		setPort: 'Vendosni portin',
		setSiteId: 'Vendosni ID e Faqes',
		setAPIKey: 'Vendosni Çelësin e API',
		secret: 'Sekreti',
		setSecret: 'Vendosni sekretin',
		setScope: 'Cakto shtrirjen',
		pleaseSetScope: 'Ju lutemi caktoni shtrirjen.',
		pleaseSetTenantId: 'Ju lutemi vendosni ID-në e qiramarrësit.',
		facilityAccessKey: 'Çelësi i hyrjes në objekt',
		pleaseSetFacilityAccessKey: 'Ju lutemi vendosni çelësin e hyrjes në objekt.',
		setTheme: 'Vendosni temën',
		pleaseSetTheme: 'Ju lutemi vendosni temën.',
		tokenUrl: 'URL-ja e Tokenit',
		pleaseSetTokenUrl: 'Ju lutemi vendosni URL-në e Tokenit',
		deleteConfiguration: 'Fshini konfigurimin?',
		areYouSureToDeleteConfig: 'A jeni të sigurtë se dëshironi ta fshini këtë konfigurim?',
		assignVisitor: 'Cakto një vizitor',
		radiologyScans: 'Skanime radiologjike',
		visitors: 'Vizitorët',
		disableVisits: 'Deaktivizo vizitat',
		disableVisitsDescription:
			'Kur kjo aktivizohet, përdoruesit do të caktojnë kohën maksimale që telefonatat me pacientin do të çaktivizohen për familjen dhe miqtë.',
		enableVisits: 'Aktivizo vizitat',
		selectTime: 'Zgjedhni kohën',
		areYouSureDisableVisits:
			'Duke aktivizuar këtë opsion, ju vendosni kohën maksimale që do të çaktivizohen telefonatat me pacientin për Familjen dhe Miqtë. Pasi të kalojë koha e zgjedhur, telefonatat do të lejohen në bazë të rolit të përdoruesit dhe orarit të vizitave në spital.',
		liveExaminations: 'Ekzaminime të drejtpërdrejta',
		selectOneOfExaminations: 'Ju lutemi zgjedhni një nga ekzaminimet për të filluar matjen',
		ear: 'Veshi',
		lungs: 'Mushkëritë',
		throat: 'Fyti',
		skin: 'Lëkura',
		ecg: 'ECG',
		eCG: 'ECG',
		spirometer: 'Spirometri',
		abdomen: 'Abdomeni',
		lungsAir: 'Spirometri',
		connectDevice: '{value} me {value1}',
		confirmIotProperlyPlaced: 'Ju lutemi konfirmoni me pacientin që pajisja është vendosur siç duhet.',
		toCheckConnectToDevice: 'Për të {value} {value2}, pacienti duhet të lidh {value3}.',
		toStartMeasureTemperature: 'Për të filluar matjen e temperaturës, pacienti duhet të shtypë butonin e matjes në pajisje.',
		check: 'kontrolluar',
		toMeasure: 'matur',
		otoscope: 'Otoskopi',
		dermatoscope: 'Dermatoskopi',
		ultrasound: 'Ultrazëri',
		deviceIsConnectedStartMeasurement: '{value} është lidhur, mund të filloni matjen!',
		startMeasuring: 'Fillo matjen',
		previousMeasurements: 'Matjet e mëhershme',
		showMore: 'Shfaq më shumë',
		checkMePro: 'Checkme Pro',
		initiateDevice: 'Inicializo {value}',
		idNumberExists: 'Ky numër personal veçse ekziston. Ju lutem provoni përsëri!',
		infectiousDisease: 'Sëmundje Infektive',
		cardiologist: 'Kardiolog',
		pulmonologist: 'Pulmonolog',
		nephrologist: 'Nefrolog',
		callDuringVisitingHours: 'Ju mund ta thirrni pacientin vetëm gjatë orëve të vizitave.',
		completeProfileTerms: 'Kontrolloni këtu për të treguar se keni lexuar dhe jeni dakord me ',
		terms: 'termet',
		disabledFor: 'Deaktivizuar për',
		familyFriends: 'Familja dhe shokët',
		otherPeople: 'Njerëz të tjerë',
		hierarchy: 'Hierarkia',
		roomOnList: 'Dhoma',
		selectHSHierarchy: 'Zgjedhni hierarkinë e sistemit shëndetësor',
		stopMeasuring: 'Ndale matjen',
		showHistory: 'Trego historinë',
		applyVisitingHoursAllDays: 'Aplikoni orarin e vizitave në të gjitha ditët e javes',
		addedConfigsRecurring: 'Konfigurimet janë të përseritura bazuar në ditën specifike të javës',
		deleteMember: 'Fshini anëtarin?',
		error: 'Gabim',
		takingCareOfbaby: 'Kujdesi për bebe',
		takingCareOfbabyConfirm:
			'Duke aktivizuar këtë opsion, do të caktohesh në grupin e bisedës me anëtarët e familjes së këtij foshnja. Konfirmo?',
		takingCareOfbabyConfirmUnAssigned:
			'Duke aktivizuar këtë opsion, do të largohesh nga grupi i bisedës me anëtarët e familjes së këtij foshnje. Konfirmo?',
		nicuChat: 'NICU Biseda',
		patientMonitoring: 'Monitorimi i pacientit',
		nicuCheckIn: 'Regjistrimi NICU',
		nicuCheckOut: 'Largohu nga NICU',
		kiosk: 'Kiosk',
		triage: 'Triazh',
		includeRoles: 'Përfshi rrolet',
		isSingleSignOutEnabled: 'Aktivio Daljen e Vetme',
		deviceIsBusy: 'Pajisja është zenë',
		ntpConfigurations: 'Konfigurimet NTP',
		ntpUrl: 'Endpoint-i NTP',
		ntpPort: 'Porti NTP',
		setNtpUrl: 'Cakto endpoint-in NTP',
		setNtpPort: 'Cakto portin NTP',
		setNtpUrlPort: 'Cakto endpoint & portin NTP',
		pleaseEnterNtpUrl: 'Ju lutem shkruani endpoint NTP',
		pleaseEnterNtpPort: 'Ju lutem shkruani portin NTP',
		atLeastOneNtpUrlIsRequired: 'Të paktën një endpoint e NTP duhet të shkruhet',
		enterValidUrl: 'Shkruaj një url valide',
		portIsRequired: 'Porti duhet të shkruhet',
		urlPortCannotBeSame: 'Fushat e endpoint dhe port duhet të jenë unike',
		turnOnGettingOutOfBed: 'Lësho duke u ngritur nga shtrati',
		turnOffGettingOutOfBed: 'Ndal duke u ngritur nga shtrati',
		turnOnInactiveTime: 'Lësho kohën joaktive të pacientit',
		turnOffInactiveTime: 'Ndal kohën joaktive të pacientit',
		patientInQueue: 'Ju keni pacientë në radhën e pritjes. Ju lutem plotësoni radhën!',
		medicalVisitInitiated: 'Vizita mjekësore veçse ka filluar. Ju lutem plotësoni vizitën!',
		disableAi: 'Deaktivizo AI',
		disableAiTemporarily: 'Çaktivizo përkohësisht AI',
		disableAiDescription:
			'Kur kjo të aktivizohet, përdoruesit do të jenë në gjendje të çaktivizojnë një rast përdorimi të AI që është i ndezur për një kohë të dëshiruar dhe pas përfundimit të kësaj kohe, AI do të vazhdojë aktivitetin e saj.',
		enableAi: 'Aktivizo AI',
		sendRailsAlert: 'Për çfarë shina të dërgojë një njoftim',
		areYouSureDisableAi:
			'Duke aktivizuar këtë opsion, ju vendosni kohën kur sinjalizimet e AI do të çaktivizohen për ju dhe pacientin. Pasi të ketë kaluar koha e zgjedhur, funksioni AI do të aktivizohet dhe ju do të merrni sinjalizime.',
		defaultCamera: 'Kamera e parazgjedhur',
		selectDefaultCamera: 'Zgjedh kamerën e parazgjedhur',
		selectedDefaultCameraDescription: 'Kamera e zgjedhur do të shfaqet si e paracaktuar në çdo lloj thirrje.',
		defaultCameraDescription:
			'Para se të zgjidhni Kamerën {value} si kamerën parazgjedhur, ju lutemi sigurohuni që është e lidhur me pajisjen.',
		scheduleDataAcquisition: 'Planifikoni marrjen e të dhënave',
		scheduleTimeForDataAcquisition: 'Planifikoni kohën për marrjen e të dhënave',
		dataAcquisitionSchedule: 'Orari i marrjes së të dhënave',
		snoozeFalseAlert: 'Ky alarm do të shtyhet për 5 minuta.',
		snoozeAcknowledgeAlert: 'Ky alarm do të shtyhet për 15 minuta derisa pacienti merr ndihmë.',
		configureFeatureFlags: 'Konfiguro veçoritë',
		featureFlags: 'Veçoritë',
		featureFlagsDescription:
			'Veçoritë ofrojnë një konfigurim të centralizuar kontrolli për aktivizimin ose çaktivizimin e veçorive specifike në të gjitha nivelet e një kompanie.',
		selectSector: 'Zgjedhni {value}',
		sector: 'Sektori',
		pleaseSelectFloor: 'Ju lutem zgjedhni një kat.',
		generalAndMenuSettings: 'Aranzhimet e menus dhe të përgjithshme',
		adminPreviousConfigs: 'Duke zgjedhur këtë opsion, ju anashkaloni konfigurimet e mëparshme të sektorëve më poshtë.',
		maxPortNumber: 'Numri maksimal i portit është',
		portCannotBeNegative: 'Porti nuk mund të jetë numër negativ',
		wearablesDescription: `Kur kjo të aktivizohet, pajisja Wearables do të jetë e disponueshme për t'u përdorur gjatë ekzaminimit të drejtpërdrejtë`,
		bodyComposition: 'Përbërja e trupit',
		snoreDetection: 'Detektimi i gërhitjes',
		caloriesBurned: 'Kaloritë e djegura',
		makeSureDeviceIsOn: 'Sigurohuni që pajisja e pacientit të jetë e ndezur.',
		samsungWatch: 'Samsung Watch',
		vitalKit: 'Vital Kit',
		showPreviousMeasurements: 'Trego matjet e mëparshme',
		patientHasMeasured: '{value1} ka {value2}',
		ecgDoesNotShowSigns: 'Kjo EKG nuk tregon shenja të fibrilacionit atrial',
		cannotCheckForSignsOfHeartAttack:
			'Ne nuk mund të kontrollojmë për shenja të një sulmi në zemër. Nëse besoni se keni një urgjencë mjekësore,',
		callEmergencyServices: 'telefononi shërbimet e urgjencës',
		deviceIsMeasuring: '{value} është duke matur',
		waitForCompletionToEnsure: 'Prisni përfundimin për të siguruar rezultate të sakta!',
		removeDeviceToCompleteMeasure: 'Hiqeni pajisjen nga gishti për të përfunduar matjen.',
		retakeMeasurement: 'Bëni përsëri matjen',
		theear: 'veshin',
		thelungs: 'mushkëritë',
		theheart: 'zemrën',
		thethroat: 'fytin',
		theheartRate: 'të rrahurat e zemrës',
		theoxygenSaturation: 'saturimin e oksigjenit',
		thetemperature: 'temperaturën',
		theskin: 'lëkurën',
		thebloodPressure: 'presionin e gjakut',
		thebloodGlucose: 'glukozën',
		theweight: 'peshën',
		theabdomen: 'abdomenin',
		theotoscope: 'otoskopin',
		thestethoscope: 'stetoskopin',
		thepulseOximeter: 'oksimetrin',
		thethermometer: 'termometrin',
		thedermatoscope: 'dermatoskopin',
		theglucometer: 'glukometrin',
		thescale: 'peshoren',
		thespirometer: 'spirometrin',
		theultrasound: 'ultrazërin',
		selectAvailableDevices: 'Zgjedhni një nga pajisjet në dispozicion për të filluar ekzaminimin.',
		medicalDevices: 'Pajisjet Mjekësore',
		medicalDevicesDescription: `Kur kjo të aktivizohet, të gjitha pajisjet IoT do të jenë të disponueshme për përdoruesit për t'u përdorur gjatë ekzaminimit të drejtpërdrejtë`,
		aiUsingCamera: 'AI duke përdorur kamerën',
		guidePatientOtoscope:
			'Udhëzoni pacientin për të vendosur spekulën ovale në kamerën cilindrike të pajisjes dhe vendoseni siç duhet në vesh.',
		guidePatientHeartLungs:
			'Udhëzoni pacientin për të vendosur pajisjen siç duhet në trup duke përdorur ilustrimin e drejtuar në ekran.',
		guidePatientThroat: 'Udhëzoni pacientin që të fusë prerësin e gjuhës në pajisje dhe ta vendosë siç duhet në gojë.',
		guidePatientHeartRate: 'Udhëzoni pacientin që të vendosë gishtin e madh të djathtë në elektrodën e djathtë poshtë.',
		guidePatientForMeasurement: 'Udhëzoni pacientin se si të bëjë matjen.',
		guidePatientDiabetes: `Sigurohuni që pajisjet e pacientit të jenë të ndezura dhe të lidhura me Bluetooth.
		Udhëzoni pacientin se si të bëjë matjen.`,
		guidePatientECG:
			'Udhëzoni pacientin që ta kthejë pajisjen horizontalisht në të djathtë. Vendosni gishtin e madh të djathtë në elektrodën poshtë djathtas. Vendosni gishtin e madh të majtë në elektrodën e majtë poshtë. Vendosni gishtin tregues të majtë në elektrodën e sipërme majtas.',
		startDateLaterThanToday: 'Data e fillimit nuk duhet të jetë më vonë se data sot',
		pleaseWriteAllergy: 'Ju lutem shkruani të paktën një alergji dhe klikoni Enter!',
		calls: 'Thirrjet',
		tv: 'TV',
		web: 'Web',
		callCareTeam: 'Thirrni ekipin e kujdesit',
		examKit: 'Gjitha-Në-Një Ekzaminim Vegla',
		examKitDescription: `Kur kjo është e aktivizuar, pajisja "Gjitha-Në-Një Ekzaminim Vegla" do të jetë e disponueshme për t'u përdorur gjatë ekzaminimit të drejtpërdrejtë`,
		aiMeasurements: 'AI Matjet',
		AIMeasurementsDescription:
			'Kur kjo të aktivizohet, matja e shenjave vitale do të jetë e disponueshme përmes AI gjatë Ekzaminimit Live',
		biobeatPatch: 'Biobeat Patch',
		biobeatPatchDescription: `Kur kjo është e aktivizuar, pajisja Biobeat Patch do të jetë e disponueshme për t'u përdorur gjatë ekzaminimit të drejtpërdrejtë`,
		lifesignalsPatch: 'Lifesignals Patch',
		lifeSignalsPatchDescription: `Kur kjo është e aktivizuar, pajisja Lifesignal Patch do të jetë e disponueshme për t'u përdorur gjatë ekzaminimit të drejtpërdrejtë`,
		healthDataDescription: 'Kur kjo është e aktivizuar, do të shfaqen të gjitha të dhënat shëndetësore te pacientit në thirrje.',
		summaryDescription: 'Kur kjo është e aktivizuar, përmbledhja e informacionit mjekësor do të shfaqet në një vend',
		visitsDescription: 'Kur kjo është e aktivizuar, do të shfaqet i gjithë historiku i vizitave për pacientin në thirrje.',
		alertHistoryDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të përditësuar dhe parë historikun e rrezikut për shenjat vitale.',
		medicalInfoPatientDiagnosis: 'Diagnoza e pacientit',
		patientDiagnosesDescription:
			'Kur kjo është e aktivizuar, mjekët do të kenë mundësinë të shtojnë diagnostifikim për pacientin gjatë thirrjes.',
		proceduresDescription:
			'Kur kjo është e aktivizuar, mjekët do të kenë mundësinë të shtojnë procedurën për pacientin gjatë thirrjes.',
		roundingNotesDescription:
			'Kur kjo është e aktivizuar, mjekët do të kenë mundësinë të shtojnë shënime për pacientin gjatë thirrjes.',
		prescriptionsDescription:
			'Kur kjo është e aktivizuar, mjekët do të kenë mundësinë të shtojnë një recetë për pacientin gjatë thirrjes.',
		physicalTherapyDescription:
			'Kur kjo është e aktivizuar, mjekët do të kenë mundësinë të shtojnë ushtrime të reja për pacientin gjatë thirrjes.',
		wrapUpPage: 'Përmbyllja e sesionit',
		sessionWrapUpDescription: `Kur kjo të aktivizohet, mjekëve do t'u paraqitet një faqe ku ata mund të shqyrtojnë dhe shtojnë informacionin mjekësor.`,
		previous: 'E Mëparshme',
		ehr: 'EHR',
		eHR: 'EHR',
		liveExamination: 'Ekzaminim i drejtpërdrejtë',
		prescriptionAlerts: 'Alarmet për recetë',
		weather: 'Moti',
		familyCalling: 'Thirrje familjare',
		moods: 'Disponimi',
		educationalVideos: 'Video edukative',
		cannotSwitchExaminationTab: 'Nuk mund të kaloni në një ekzaminim tjetër përderisa pajisja është në modalitetin e lidhjes.',
		viewPatient: 'Shiko pacientin',
		callSettings: 'Aranzhimi i thirrjeve',
		roomSignCallSettings: 'Cilësimet e Thirrjeve për Tabela Dhomash',
		providerAndPatientCallSettings: 'Cilësimet e Thirrjeve për Ofruesin dhe Pacientin',
		wallpaper: 'Wallpaper & Sfond',
		audioOnly: 'Fillo vetëm me audio',
		audioVideo: 'Fillo me audio dhe video',
		showMonitoring: 'Shfaq faqen e monitorimit',
		hideMonitoring: 'Fsheh faqen e monitorimit',
		allowViewPatient: 'Lejo opsionin "Shiko pacientin"',
		hideViewPatient: 'Fsheh opsionin "Shiko pacientin"',
		talkToPatientRequired: 'Duhet të zgjedhni një lloj të thirrjes me pacientin',
		monitoringRequired: 'Ju duhet të zgjedhni nëse Monitorimi duhet të shfaqet apo jo',
		viewPatientRequired: 'Duhet të zgjedhni nëse "Shiko Pacientin" duhet të shfaqet apo jo',
		selectCallOptions: 'Zgjedh mes opsioneve të dhëna për secilin tip të thirrjeve',
		others: 'Tjerat',
		callTypes: 'Tipet e thirrjeve',
		digitalclinician: 'Klinicist Digjital',
		theecg: 'EKG-në',
		thelungsAir: 'FEV1/PEF',
		thebloodPressureMonitor: 'tensiometrin',
		arabic: 'Arabisht',
		highHeartRate: 'Ritmi i lartë i zemrës',
		lowHeartRate: 'Ritmi i ulët i zemrës',
		lowBodyTemperature: 'Temperaturë e ulët e trupit',
		highBodyTemperature: 'Temperaturë e lartë e trupit',
		highBloodGlucose: 'Glukozë e lartë në gjak',
		criticalHighBodyTemperature: 'Temperaturë e lartë kritike e trupit',
		lowBMIUnderweight: 'Nënpeshë me BMI të ulët',
		highBMI: 'BMI e lartë',
		criticalHighBMI: 'BMI e lartë kritike',
		systolicLowBloodPressure: 'Presion i ulët sistolik i gjakut',
		diastolicLowBloodPressure: 'Presion i ulët diastolik i gjakut',
		systolicCriticalLowBloodPressure: 'Presion i ulët kritik sistolik i gjakut',
		diastolicCriticalLowBloodPressure: 'Presion i ulët kritik diastolik i gjakut',
		systolicHighBloodPressure: 'Presion i lartë sistolik i gjakut',
		diastolicHighBloodPressure: 'Presion i lartë diastolik i gjakut',
		systolicCriticalHighBloodPressure: 'Presion i lartë kritik sistolik i gjakut',
		diastolicCriticalHighBloodPressure: 'Presion i lartë kritik diastolik i gjakut',
		lowOxygenSaturation: 'Saturim i ulët me oksigjen',
		criticalLowOxygenSaturation: 'Saturim kritik i ulët me oksigjen',
		thehighHeartRate: 'ritëm të lartë të zemrës',
		thelowHeartRate: 'ritëm të ulët të zemrës',
		thelowBodyTemperature: 'temperaturë të ulët të trupit',
		thehighBodyTemperature: 'temperaturë të lartë të trupit',
		thecriticalHighBodyTemperature: 'temperaturë të lartë kritike të trupit',
		thelowBMIUnderweight: 'nënpeshë me BMI të ulët',
		thehighBMI: 'BMI të lartë',
		thecriticalHighBMI: 'BMI të lartë kritike',
		thesystolicLowBloodPressure: 'presion të ulët sistolik të gjakut',
		thediastolicLowBloodPressure: 'presion të ulët diastolik të gjakut',
		thesystolicCriticalLowBloodPressure: 'presion të ulët kritik sistolik të gjakut',
		thediastolicCriticalLowBloodPressure: 'presion të ulët kritik diastolik të gjakut',
		thesystolicHighBloodPressure: 'presion të lartë sistolik të gjakut',
		thediastolicHighBloodPressure: 'presion të lartë diastolik të gjakut',
		thesystolicCriticalHighBloodPressure: 'presion të lartë kritik sistolik të gjakut',
		thediastolicCriticalHighBloodPressure: 'presion të lartë kritik diastolik të gjakut',
		thelowOxygenSaturation: 'saturim të ulët me oksigjen',
		thecriticalLowOxygenSaturation: 'saturim kritik të ulët me oksigjen',
		thehighBloodGlucose: 'glukozë të lartë në gjak',
		medicalItemExists: `Kjo {value} veçse ekziston!`,
		pleaseSelectValidImageType: 'Ju lutem zgjedhni një nga këto lloje valide të fotove: png, jpeg, tiff, gif, bmp',
		bloodPressureMonitor: 'Tensiometri',
		companyImage: 'Imazhi i kompanisë',
		visitingHoursEnd: 'Visiting hours will end in ',
		locked: 'E bllokuar',
		unlocked: 'E zhbllokuar',
		callSettingStatus: '{value} {value2} i lejuar',
		is: 'është',
		isNot: 'nuk është',
		audioRoutingOptions: 'Opsionet e burimit të zërit',
		selectAudioRoutingOptions: 'Zgjedh opsionin e burimit të zërit për çdo lloj thirrje',
		customConfigurations: 'Konfigurime të personalizuara',
		basedOnDeviceType: 'Bazuar në tipin e pajisjes',
		selectOneOfRoutingOptions: 'Zgjedh një nga opsionet e burimit të zërit',
		audioRoutingDeviceType: 'Zgjedh konfigurime specifike në bazë të pajisjes',
		notAuthorizedHealthSystem: 'Ju nuk jeni të autorizuar të përdorni këtë Sistem Shëndetësor.',
		switchHsVirtualSitter: 'Ju lutemi ndërroni Sistemin Shëndetësor për të përdorur veçoritë e Kujtestarit Virtual.',
		bedDefinition: 'Përkufizim krevati EHR',
		bed: 'Krevat',
		switchHsFeature: 'Ju lutemi ndërroni Sistemin Shëndetësor për të përdorur këtë veçori.',
		tablet: 'Tablet',
		alwaysFromHello: 'Gjithmonë nga HELLO',
		routesToTvPatientNurse: 'Përmes TV kur pacienti sheh infermieren',
		routesToTvSourceAvailable: 'Përmes TV kur ofrohet burimi',
		alwaysPillowSpeaker: 'Gjithmonë nga altoparlanti',
		alwaysFromPhone: 'Gjithmonë nga Telefoni',
		alwaysFromTablet: 'Gjithmonë nga Tableti',
		routeExternalDetected: 'Përmes altoparlantit të jashtëm kur detektohet',
		callStartAudioOnly: 'Thirrja fillon vetem me Audio',
		callStartAudioVideo: 'Thirrja fillon me Audio & Video',
		notAuthorizedMonitoring: 'Ju nuk jeni të autorizuar të përdorni Monitoronin.',
		areYouSureToRemoveMember: 'A jeni të sigurt që doni të fshini këtë anëtar nga dhoma?',
		isCompanyLevelConfiguration: 'Konfigurimi i nivelit të kompanisë',
		autoSynchronizeLogin: 'Sinkronizimi automatik gjatë hyrjes',
		youAlreadyHaveAddedThisDomain: 'Keni Shtuar Më Parë Ky Domen',
		theProvidedDomainIsInvalid: 'Domeni i dhënë është jo valid (p.sh hellocare.ai)',
		formShouldContainOneDomainEmail:
			'Një Domain i paktën është i nevojshëm, ju lutem shkruani dhe shtypni Enter për të shtuar një Email Domain.',
		maximumEmailDomainsAllowedAre5: 'Numri Maksimal i Domenëve të Email-it është 5',
		accountCreationFailed: `Krijimi i llogarisë dështoi. Nëse problemi vazhdon, kontaktoni ekipin tonë të mbështetjes për ndihmë.`,
		accountSynchronizationFailed: `Sinkronizimi i llogarisë dështoi. Nëse problemi vazhdon, kontaktoni ekipin tonë të mbështetjes për ndihmë.`,
		accountBeingSynchronizedMayTakeMinutes: `Llogaria juaj është në proces sinchronizimi. Kjo mund të zgjasë disa minuta!`,
		autoAdd: 'Shtimi automatik',
		autoUpdate: 'Përditësimi automatik',
		autoDelete: 'Fshirja automatike',
		adUserDoesNotExist:
			'Ky përdorues ekziston tashmë ose Llogaria e përdoruesit nuk ekziston në listën e përdoruesve të Ofruesve të Jashtëm të Identitetit dhe nuk mund të hyjë në aplikacion. Ju lutemi kontrolloni nëse llogaria është e saktë.',
		additionalDataMissing: `Të dhënat shtesë të peshores nuk janë të disponueshme për shkak të mungesës së informacionit të gjatësisë, peshës dhe gjinisë në profilin e pacientit.`,
		missingWeight: 'Të dhënat shtesë të peshores nuk janë të arritshme për shkak të mungesës së peshës',
		missingHeight: 'Të dhënat shtesë të peshores nuk janë të arritshme për shkak të mungesës së gjatësisë',
		missingGender: 'Të dhënat shtesë të peshores nuk janë të arritshme për shkak të mungesës së gjinisë',
		unrealisticWeight: 'Pesha nuk korrespondon me gjatësinë e pacientit',
		shoesOn:
			'Peshorja nuk mund të dallojë rezistencën e trupit. Sigurohuni që këmbët tuaja të zbathura të jenë vendosur saktë në 4 elektroda',
		emailNotValid: 'Emaili nuk është i vlefshëm',
		emailDuplicated: 'Email i dyfishuar',
		taskCompleted: 'Detyra e përfunduar',
		noEmailsExelFile: 'Nuk ka email në excel-in tuaj',
		errorType: 'Lloji i gabimit',
		bulkUpload: 'Ngarkim në masë',
		emailList: 'Lista e postës elektronike',
		medium: 'E mesme',
		sensitivity: 'Sensitivity',
		highSensitivity:
			'Ky modalitet është krijuar për të shkaktuar një alarm edhe në lëvizjet më të vogla të një personi larg shtratit të tij.',
		mediumSensitivity:
			'Ky modalitet është krijuar për të shkaktuar një alarm kur një person është larguar mesatarisht nga shtrati i tij.',
		lowSensitivity:
			'Ky modalitet është krijuar për të shkaktuar një alarm vetëm kur një person është larguar plotësisht nga shtrati i tij.',
		standOnScale: 'Qëndroni në peshore',
		deviceDisconnect: 'Lidhja dështoi',
		stethoscopeMeasurements: 'Matjet e {value} me stetoskop',
		heartStetho: 'zemrës',
		lungsStetho: 'mushkërive',
		clickAnyPoint: 'Klikoni në cilindo nga regjistrimet më poshtë për të startuar skedarin dhe për të parë grafikun e matjes',
		availableRecordings: 'Regjistrimet ne dispozicion',
		measurement: 'Matja',
		pause: 'Pauzo',
		more: 'Më shumë',
		switchToFrontBack: 'Kaloni në pjesën e {value}',
		frontView: 'përparme',
		backView: 'pasme',
		totalMeasurements: '{value} Matje',
		allRoleCustomizationsDisabled: 'Veçoritë e Kostumizimit të Rrolit mund të jenë të çaktivizuar për rolin tuaj.',
		point: 'Pika',
		selectOneOfThePoints: 'Zgjedh një nga pikat për të filluar matjen!',
		selectAnotherPoint: 'Zgjedh një pikë tjetër',
		front: 'Para',
		bulkView: 'Paracaktuar',
		defaultView: 'Ne masë',
		returnReport: 'Raporti i Kthimit',
		medicalAssessment: 'Vlerësimi Mjekësor',
		therapy: 'Terapia',
		diagnosticResearch: 'Hulumtimi diagnostik',
		menuOptions: 'Opsionet e menysë',
		mainConcerns: 'Shqetësimet kryesore',
		anamnesis: 'Anamneza',
		clickToSave: 'Klikoni për të ruajtur',
		addMoreAllergies: 'Shto më shumë Alergjit',
		patientAcknowledgedAllergies: 'Pacienti pranoi se kishte alergjitë e mëposhtme',
		doesHaveAllergies: 'A ka Alergji ky pacient?',
		writeAllergyAndEnter: 'Shkruani alergji dhe shtypni Enter për të shtuar',
		noAddedAllergies: 'Nuk ka alergji të shtuara',
		searchForDiagnoses: 'Kërkoni për kodin ose emrin dhe shtypni enter për të shtuar diagnozën',
		addedDiagnoses: 'Diagnozat e shtuara',
		recommendations: 'Rekomandime',
		prescribeDrugs: 'Përshkruani barna',
		useToPrescribe: 'Përdorni për të përshkruar {value}',
		drugs: 'Barna',
		prescribeInfusions: 'Përshkruani infuzione',
		prescribeInjections: 'Përshkruani injeksione',
		addedTests: 'Testet e shtuara',
		useToReferPatientTo: 'Përdoreni për të referuar pacientin te {value}.',
		searchforTheCodeAndEnter: 'Kërkoni kodin dhe shtypni enter për të shtuar procedurën.',
		commentForTechnicians: 'Komentet për teknikët.',
		visitSummaries: 'Përmbledhjet e vizitave',
		maxEmail: 'Maximumi i email-ave është 100',
		missingPatientInfo: 'Mungon informacioni i pacientit',
		conversationFiles: 'Dosjet e bisedes',
		conversationPhotos: 'Fotot e bisedes',
		noPermission: 'Nuk keni qasje!',
		sendRequestFailed: 'Kërkesa dështoi. Ju lutemi provoni përsëri!',
		conferenceFailed: 'Konferenca dështoi. Ju lutemi provoni përsëri!',
		requestHasBeenSent: 'Kërkesa është dërguar!',
		manageBedside: 'Menaxho pranë shtratit',
		serialNumber: 'Numri serial',
		roomSignage: 'Sinjalistika e dhomës',
		respiration: 'Frymëmarrja',
		facialSkinAge: 'Mosha e lëkurës së fytyrës',
		stressIndex: 'Indeksi i stresit',
		cardiacWorkload: 'Ngarkesa kardiake',
		heartAttackRisk: 'Rreziku i sulmit në zemër',
		strokeRisk: 'Rreziku i goditjes',
		extremelyLowOxygen: 'Pacienti ka një saturim jashtëzakonisht të ulët të oksigjenit!',
		lowOxygen: 'Pacienti ka një saturim të ulët të oksigjenit!',
		appleWatch: 'Apple Watch',
		selectTimer: 'Zgjedhni kohën',
		snoozeAlert: 'Ky alarm do të shtyhet për {value} derisa pacienti merr ndihmë.',
		translationServices: 'Shërbimet e perkthimit',
		selectedTranslator: 'Përkthyesi i zgjedhur',
		areYouSureRemoveTranslation: 'Jeni i sigurt që dëshironi të hiqni shërbimin e përkthimit?',
		providerName: 'Emri i ofruesit',
		cardiacOutput: 'Output kardiak',
		cardiacIndex: 'Indeksi kardiak',
		reloadLatestFeatures: 'Ju lutemi, {value} faqen për të hyrë në veçoritë dhe përmirësimet më të fundit',
		minute: 'minutë',
		generateQRCode: 'Gjeneroni kodin QR',
		clickGenerateQRCode: 'Ju lutemi, klikoni Gjeneroni kodin QR për të autorizuar Vital Kit',
		scanGeneratedQRCode:
			'Kodi QR është krijuar. Ju lutemi kërkojini infermieres/it pranë shtratit të skanojë kodin QR me All-in-one Exam Kit.',
		removedAek: 'All-in-one Exam Kit është hequr nga seanca.',
		spirometerMeasurements: 'Matjet me spirometër',
		spirometerTooltip: `Një spirometër është një pajisje diagnostike që mat sasinë e ajrit që mund të thithësh dhe të nxjerrësh. Ai gjithashtu gjurmon kohën që ju duhet për të nxjerrë plotësisht frymën pasi të merrni frymë thellë. Një test spirometrie kërkon që ju të merrni frymë në një tub të lidhur me një makinë të quajtur spirometër.`,
		guidePatientSpirometer: 'Udhëzoni pacientin që ta vendosë pajisjen siç duhet',
		otoscopeMeasurements: 'Matjet e {value} me otoskop',
		dermatoscopeMeasurements: 'Matjet e lëkurës me dermatoskop',
		darkMode: 'Modaliteti i errët',
		handWashing: 'Larja e duarve',
		handsDisinfected: 'Duart e dezinfektuara',
		handsDisinfectedSuccessfully: 'Duart u dezinfektuan me sukses!',
		lastUpdate: 'Përditësimi i fundit',
		interpreter: 'Përkthyes',
		resourceAssigned: 'Kërkesa është caktuar',
		skinTemperature: 'Temperatura e lëkures',
		oralTemperature: 'Temperatura orale',
		e2ee: 'Thirrjet E2EE',
		dialIn: 'Telefono Brenda',
		dialOut: 'Telefono Jashtë',
		ptzCam: 'Kontrollet PTZ',
		pTZControlsDescription:
			'Kur kjo aktivizohet, përdoruesit do të kenë mundësi të kontrollojnë PTZ të pajisjes {helloName} dhe kamerës USB {huddleName} dhe të krijojnë shënime për pozicionet e caktuara të kameras.',
		faceDownDescription:
			'Kur kjo aktivizohet, përdoruesit do të mund të njoftohen përmes AI kur një pacient kthehet në pozicion me fytyrë te poshtë.',
		aIPrivacyModeDescription:
			'Kur kjo aktivizohet, përdoruesit do të jenë në gjendje të fshehin pacientin dhe të vizatojnë një figurë të thjeshtë në vendin e tij për të mbrojtur privatësinë e tij.',
		handWashingDescription:
			'Kur kjo aktivizohet, administratorët e spitaleve do të jenë në gjendje të monitorojnë/përsejtinë ofruesit e shërbimeve shëndetësore përmes AI nëse ata respektojnë higjienën e spitalit.',
		fallDetectionDescription: 'Kur kjo aktivizohet, përdoruesit do të mund të njoftohen përmes AI kur një pacient bie në tokë.',
		patientMobility: 'Lëvizshmëria e pacientit',
		patientWalkingDescription:
			'Kur kjo aktivizohet, përdoruesit do të mund të njoftohen përmes AI kur një pacient ka rrezik ndërsa lëviz.',
		railsDescription:
			'Kur kjo aktivizohet, përdoruesit do të mund të njoftohen përmes AI kur një pacient vë poshtë mbrojtjen anësore.',
		inactiveTimeDescription:
			'Kur kjo aktivizohet, përdoruesit do të mund të njoftohen përmes AI kur një pacient ka qenë joaktiv për një periudhë të caktuar kohe.',
		gettingOutOfBedDescription:
			'Kur kjo të aktivizohet,  përdoruesit do të mund të njoftohen përmes AI kur një pacient lëshon një rriel nga krevati i tyre.',
		aIVitalSignsDescription:
			'Kur kjo të aktivizohet, përdoruesit do të jenë në gjendje të monitorojnë shenjat vitale të pacientëve duke përdorur Inteligjencën Artificiale.',
		biobeatVitalSigns: 'Biobeat shenjat vitale',
		biobeatVitalSignsDescription:
			'Nëpërmjet këtij opsioni, përdoruesit do të mund të mundësojnë monitorimin e shenjave vitale të Biobeat në kohë reale.',
		smartWatchVitalSigns: 'Shenjat vitale të orës inteligjente',
		smartWatchVitalSignsDescription:
			'Nëpërmjet këtij opsioni, përdoruesit do të mund të mundësojnë monitorimin e shenjave vitale live të orës inteligjente.',
		dialInDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të ftuar njerëzit në telefonatë nëpërmjet numrit të telefonit.',
		dialOutDescription: 'Kur kjo është e aktivizuar, do të shfaqet opsioni për të thirrur njerëzit përmes numrit të telefonit.',
		inviteViaEmailDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të ftuar njerëzit në telefonatë përmes emailit.',
		translationServiceDescription: 'Kur kjo është e aktivizuar, do të shfaqen shërbimet e përkthimit në gjuhë të ndryshme.',
		inviteViaSmsDescription: 'Kur kjo është e aktivizuar, do të shfaqet opsioni për të ftuar njerëzit në thirrje me SMS.',
		meetingUrl: 'URL i takimit',
		meetingUrlDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të ftuar njerëzit në thirrje nëpërmjet lidhjes.',
		roundingNightVisionDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të parë në modalitetin e shikimit të natës gjatë thirrjes.',
		gridViewDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të shfaqur të gjithë pjesëmarrësit në thirrje me pamje grup.',
		fitToScreenDescription: `Kur kjo është e aktivizuar, do të shfaqet opsioni për ta bërë ekranin e plotë ose për t'u përshtatur me ekranin gjatë thirrjes.`,
		screenSharing: 'Ndarja e ekranit',
		screenSharingDescription: 'Kur kjo është e aktivizuar, do të shfaqet opsioni për të ndarë ekranin gjatë thirrjes.',
		invitingPeople: 'Fetesa e njerëzëve',
		generalFeatures: 'Veçoritë e Përgjithshme',
		communicatingWithThePatient: 'Komunikimi me pacientin',
		communicatingWithNurses: 'Komunikimi me infermieret',
		automaticAlerts: 'Alarmet automatike',
		cannotBeEmpty: 'Nuk mund të jetë e zbrazur',
		viewPatientTooltip:
			"Ky opsion do t'ju lejojë të shikoni dhe dëgjoni pacientin; pacienti nuk do të jetë në gjendje të ju shohë ose të dëgjojë juve, përveç nëse ju e aktivizoni gjatë transmetimit.",
		ttpTooltip:
			'Ky opsion do të fillojë një thirrje audio me pacientin, ata do të jenë në gjendje të dëgjojnë ju dhe ju do të jeni në gjendje të i dëgjoni ata. Gjatë thirrjes, mund të ndërroheni kamerën tuaj ose të pacientit, ose madje të ndani ekranin tuaj.',
		userNoAccess: 'Ju nuk keni leje për të përfunduar këtë veprim.',
		reload: 'Refreskoni',
		babyRoomDeviceBusy: 'Ju nuk mund ta shihni foshnjen sepse {value} është në thirrje',
		rpmProgram: 'RPM programi',
		rpmPrograms: 'RPM programet',
		addRpmProgram: 'Shto RPM program',
		pleaseWriteProgramName: 'Ju lutemi shkruani emrin e programit',
		careTeam: 'Ekipi i kujdesit',
		pleaseSelectCareTeam: 'Ju lutemi zgjedhni anëtarët e ekipit të kujdesit',
		primaryCarePhysician: 'Mjeku i kujdesit primar',
		specialist: 'Specialist/e',
		pharmacist: 'Farmacist/e',
		careGiver: 'Kujdestar/e',
		selectMember: 'Zgjedhni një anëtar/e',
		survey: 'Anketa',
		viewSurvey: 'Shiko anketën',
		viewCareTeam: 'Shiko ekipin e kujdesit',
		score: 'Pikët',
		heartRateSpo2: 'Ritmi i zemrës dhe SpO2',
		mod: 'Mesatare',
		searchByName: 'Kërko pacientin me emër',
		pleaseSelectRpmProgram: 'Ju lutemi zgjedhni një program RPM',
		selectProgram: 'Zgjedhni një program',
		pleaseSelectMember: 'Ju lutemi zgjedhni një {value}',
		pleaseSelectDoctor: 'Ju lutem zgjedhni një doktor',
		program: 'Programi',
		createProgram: 'Krijo program',
		deleteProgram: 'Fshi programin?',
		areYouSureDeleteProgram: 'Jeni të sigurtë që dëshironi ta fshini këtë program?',
		heartLungsAuscultation: 'Auskultimi i zemrës dhe i mushkërive',
		creatingProgram: 'Krijimi i programit',
		enterDifferentName: 'Shkruani një emër të ndryshëm nga programet e paracaktuara.',
		programName: 'Emri i programit',
		measurementsSelection: 'Përzgjedhja e matjeve',
		pickMeasurements: 'Zgjedhni matjet dhe pajisjet që dëshironi të aktivizoni për këtë program.',
		selectedMeasurements: 'Matjet e zgjedhura',
		measurements: 'Matjet',
		pleaseSelectMeasurement: 'Ju lutemi zgjedhni të paktën një matje.',
		pleaseSelectDevice: 'Ju lutemi zgjedhni të paktën një pajisje për çdo matje të zgjedhur.',
		editingProgram: 'Editimi i programit',
		editProgram: 'Edito programin',
		programExists: 'Ky program veçse ekziston!',
		maximumDevices: 'Matja duhet të përmbajë deri në dy pajisje!',
		assignPatch: 'Cakto Patch',
		wearables: 'Wearables',
		provider: 'Furnizuesi',
		wearableId: 'ID-ja e pajisjes',
		wearableType: 'Lloji',
		patchWearableType: 'Patch',
		watchWearableType: 'Orë',
		inactive: 'Joaktiv',
		viewFullData: 'Shiko të dhënat e plota',
		showEcg: 'Shfaq EKG',
		monitor: 'Monitoro',
		aspDescription:
			'Alexa Smart Properties për kujdesin shëndetësor e bën të lehtë dhe me kosto efektive për spitalet dhe ofruesit që të kujdesen për pacientët e tyre. Alexa Smart Properties thjeshton vendosjen dhe menaxhimin e pajisjeve të aktivizuara Alexa në shkallë dhe lejon pronat të mundësojnë aftësitë Alexa të përshtatshme për HIPAA, duke lidhur ofruesit e kujdesit me pacientët përmes përvojave zanore.',
		alexaSmartProperties: 'Karakteristikat e zgjuara të Alexa',
		alexaSmartPropertiesActivated: 'Karakteristikat Smart Alexa u aktivizuan!',
		aspFailed: 'ASP nuk mund të aktivizohej, ju lutemi provoni përsëri më vonë',
		nutritionist: 'Nutricionist',
		providerError: 'Ju lutemi zgjedhni një furnizues.',
		wearableTypeError: 'Ju lutemi zgjedhni llojin e pajisjes.',
		wearableIdEmpty: 'Ju lutemi shënoni ID-në e pajisjes.',
		invalidWearableId: 'Ju lutemi shënoni një ID të vlefshme.',
		patchIdAlreadyExists: 'Patch ID tashmë ekziston',
		patchIdEmpty: 'ID-ja e patch-it nuk mund të jetë e zbrazët.',
		baseURL: 'Url bazë',
		addBaseUrl: 'Shto url bazë',
		addApiKey: 'Shto çelësin Api',
		eCareManager: 'Menaxher i E-Care',
		secretKey: 'Çelësi sekret',
		nodeId: 'Nyja ID',
		nodeLevel: 'Niveli i nyjës',
		carelogs: 'Regjistrat e kujdesit',
		carelogDetails: 'Detajet e regjistrit të kujdesit',
		visitInfo: 'Informatat e vizitës',
		patientInfo: 'Informatat e pacientit',
		endVisit: 'Përfundimi i vizitës',
		careMemberType: 'Lloji i anëtarit të kujdesit',
		liveMeasurements: 'Matjet',
		medicalRecordNumber: 'Numri i dosjes mjekësore',
		patientsCareLogs: `Regjistrat e kujdesit të {value}`,
		possessiveSessions: 'Sesionet e {value}-it',
		at: 'në {value}',
		removePosition: 'Fshij pozitën',
		removeLicense: 'Hiqni licensën',
		removeEducation: 'Fshij edukimin',
		ewsScore: 'Pikët EWS',
		lowRisk: 'Rrezik i ulët',
		ewsSettings: 'Aranzhimi i EWS',
		removeOption: 'Fshi opsionin',
		removeQuestion: 'Fshi pyetjen',
		display: 'Shfaqja',
		theme: 'Theme',
		defaultTheme: 'Theme Parazgjedhur',
		light: 'E Ndriçuar',
		dark: 'E Errët',
		question: 'Pyetja',
		option: 'Opsioni',
		addNewSurveyDescription: 'Asnjë pyetësor nuk është shtuar ende.',
		addNewSurvey: 'Shto pyetësor të ri',
		addNewQuestion: 'Shto pyetje të re',
		addMoreOptions: 'Shto më shumë opsione',
		atLeastTwoOptions: 'Ju lutemi zgjedhni të paktën dy opsione për pyetje',
		valuesCannotBeEmpty: 'Vlerat nuk mund të jenë të zbrazëta',
		options: 'Opsionet',
		patchNotExist: 'Ky patch nuk ekziston',
		telemetry: 'Telemetria',
		deleteFileForMyself: 'Fshij dosjen për mua',
		deleteFileWarning: 'Jeni të sigurtë që dëshironi ta fshini këtë dosje?',
		conferenceEndedParticipantInviteDenied:
			'Nuk keni leje të mjaftueshme për ta telefonuar këtë pjesëmarrës. Ju lutemi kontaktoni administratorin tuaj.',
		participantInviteDenied: 'Nuk keni leje të mjaftueshme për të thirrur {value}. Ju lutemi kontaktoni administratorin tuaj.',
		switchingToOtherTab:
			'Ky sesion monitorimi do të përfundojë dhe dritaret do të mbyllen kur të largoheni nga kjo faqe. Jeni të sigurt se dëshironi të largoheni?',
		key: 'Çelës',
		selectConfiguration: 'Zgjidh Konfigurimin',
		selectConfigurationDesc: 'Zgjidhni Konfigurimin që dëshironi të aplikoni',
		nodeName: 'Emri i Nyjes',
		scope: 'Fushë',
		issuer: 'Lëshuesi',
		returnUrl: 'URL e kthimit',
		oauthScopes: 'Shkallëzime OAuth',
		dictate: 'Dikto',
		write: 'Shkruaj',
		statAlarmInCSV: 'Alarm STAT',
		statAlarm: 'Alarm Statik',
		statAlarmDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të shohin një ikonë shtesë brenda çdo video monitorues dhe ndryshimi i saj do të luajë një alarm në anën e pacientit.',
		statAlarms: 'Alarmet e Statit',
		activationTime: 'Koha e Aktivizimit',
		deactivationTime: 'Koha e Çaktivizimit',
		editRoomNameRounding: 'Edito Emrin e dhomës',
		editRoomNameMonitoring: 'Edito Emrin e dhomës',
		editRoomNameRoundingDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të mund të modifikojnë emrat e dhomave te Vizitat',
		editRoomNameMonitoringDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të mund të modifikojnë emrat e dhomave te Monitorimi',
		enableStatAlarm: 'Aktivizo alarmin statik',
		disableStatAlarm: 'Çaktivizo alarmin statik',
		alexaEnabled: 'Alexa',
		enableAlexa: 'Aktivizo Alexa për këtë kompani',
		enableAlexaConfiguration:
			'Duke aktivizuar këtë veçori, spitalet do të kenë mundësi të përdorin Alexa në pajisjet e tyre {helloName}.',
		pleaseSetPassword: 'Ju lutem vendosni një fjalëkalim.',
		errorStatAlarm: 'Ka ndodhur një gabim gjatë ndryshimit të alarmit statik të {value}. Ju lutemi, provoni përsëri!',
		shippingAddress: 'Adresa e Transportit',
		requestedBy: 'Kërkuar nga',
		shipped: 'Dërguar',
		logistics: 'Logjistika',
		dashboard: 'Paneli',
		totalNumberOfCalls: 'Numri total i thirrjeve',
		sessionByRole: '{value} Seancat nga Roli',
		totalNumberSessions: 'Numri Total i Sesioneve',
		averageSessionDuration: 'Kohëzgjatja Mesatare e Sesioneve',
		totalDuration: 'Kohëzgjatja Totale',
		durationInSeconds: 'Kohëzgjatja në sekonda',
		sessionsByHour: 'Sesionet sipas Orës',
		session: 'Sesioni',
		sessions: 'Sesionet',
		ambient: 'Ambient',
		monitoringSessions: 'Seancat e Monitorimit',
		totalSessionsDuration: 'Kohëzgjatja totale e seancave',
		outgoingSessionsDescription: 'Sesione Bisedimi me Pacientin të Nisura nga Ekipi i Kujdesit',
		incomingSessionsDescription: 'Sesione Bisedimi me Pacientin të Nisura nga Pacientët',
		viewPatientSessionsDescription: 'Shikoni seancat e pacientëve të iniciuara nga ekipi i kujdesit',
		familyVisits: 'Vizitat familjare',
		avgSessions: 'Sesionet mesatare në orë',
		highestVolumeOfSessions: 'Volumi më i lartë i sesioneve',
		totalSessions: 'Sesionet totale',
		sessionByType: 'Sesionet sipas llojit',
		externalIdentityProviders: 'Furnizuesit e Identitetit të Jashtëm',
		externalIdentityProviderMembers: 'Antarët e Furnizuesit të Identitetit të Jashtëm',
		selectNurse: 'Zgjedhni infermier/e',
		noMembersAdded:
			'Asnjë {value} nuk është shtuar ende. Për të filluar shtimin e {value}, thjesht klikoni në butonin e mësipërm. Ju mund të shtoni deri në {value2} {value}.',
		doctors: 'Mjekët',
		atLeastOneMember: 'Ju lutemi zgjedhni të paktën një anëtar!',
		addAdditionalMember:
			'Mund të shtoni {value} shtesë përveç atyre të përfshirë tashmë në program. Për të filluar shtimin e {value}, thjesht klikoni në butonin e mësipërm. Ju mund të shtoni deri në {value2} {value}.',
		editRpmProgram: 'Edito programin e RPM',
		levelOfConsciousness: 'Niveli i vetëdijes',
		supplementOxygen: 'Suplement Oksigjen',
		phoneNotReachable: 'Numri i telefonit nuk është i arritshëm',
		sipNotAvailable: 'Telefonimi nuk është i disponueshëm për momentin.',
		integrations: 'Integrimet',
		eCareApiKey: 'Çelësi API e-care',
		eCareBaseUrl: 'URL e bazës e-care',
		deviceWiFiTryingToConnect: `Duke u përpjekur për t'u lidhur me {value}`,
		deviceWiFiScanFailed: 'Skanimi i {value} dështoi. Ju lutemi prisni pak dhe provoni përsëri.',
		diviceWiFiScanNotFound: '{value} nuk u gjet, ju lutemi provoni përsëri.',
		deviceWiFiConnectionFailed: 'Lidhja me {value} dështoi. Ju lutemi provoni përsëri.',
		deviceWiFiDisconnected: '{value} u shkëput. Ju lutemi provoni përsëri.',
		length: 'Gjatësia',
		viewAllProviders: 'Shiko të gjithë provajderët',
		totalNumberOfProviders: 'Numri total i provajderëve',
		lastSessionDate: 'Data e sesionit të fundit',
		noProvidersFound: 'Nuk ka provajdera me sesione te kompletuara në listë',
		sessionStart: 'Fillimi i sesionit',
		sessionEnd: 'Përfundimi i sesionit',
		sessionStatus: 'Statusi i sesionit',
		sessionDetails: 'Detajet e sesionit',
		callStart: 'Fillimi i thirrjes',
		callEnd: 'Fundi i thirrjes',
		allowMicPermissions:
			"hellocare ka nevojë për qasje në mikrofonin tuaj në mënyrë që pacientët të mund t'ju dëgjojnë. Lejo qasjen e mikrofonit në cilësimet e shfletuesit të uebit.",
		turnOnDictation: 'Aktivizo diktimin',
		turnOffDictation: 'Çaktivizo diktimin',
		somethingWentWrongSpecific: '{value}. Diçka shkoi gabim!',
		unitCannotBeEmpty: 'Njesia nuk mund të jetë e zbrazët',
		roomCannotBeEmpty: 'Dhoma nuk mund të jetë e zbrazët',
		bedCannotBeEmpty: 'Krevati nuk mund të jetë i zbrazët',
		total: 'Total',
		reason: 'Arsyeja',
		subjectIdentification: 'Identifikimi i subjektit',
		employeeDirection: 'Drejtimi i punonjësve',
		handHygieneAudits: 'Kontrollet e higjienës së duarve',
		handHygieneViewed: 'Shikuar higjiena e duarve',
		exportExcel: 'Eksporto Excel',
		exportExcelBackground:
			'Eksportimi Excel do të krijohet në sfond. Pasi të përfundojë, do të dërgohet te {value} në një bashkëngjitje.',
		complianceReport: 'Raporti i Pajtueshmërisë me Higjienën',
		screenShareNotAllowed: 'Dikush tjetër po ndan ekranin e tij.',
		screenSharingNotAllowed: 'Nuk mund ta ndash ekranin tënd ndërsa dikush tjetër po ndan.',
		ptzCamSettingsTitle: '20x PTZ Parametrat e Kamerës',
		reverseCamera: 'Ktheje kamerën',
		allowPermissions: 'Ju lutem lejoni kamerën dhe mikrofonin për të thirrur pacientin.',
		guest: 'Vizitor',
		selectSurvey: 'Zgjedhni llojin e pyetësorit',
		generic: 'I përgjithshëm',
		safetyRange: 'Vlerat optimale',
		createSurvey: 'Krijo pyetësor',
		addQuestionsDescription: 'Ju mund të filloni të shtoni pyetje duke klikuar në butonin e mësipërm.',
		typeOfSurvey: 'pyetësor {value} ',
		editSurvey: 'Edito pyetësorin',
		surveyType: 'Lloji i pyetësorit',
		surveys: 'Pyetësorët',
		scheduledDate: 'Data e planifikuar',
		questions: 'Pyetjet',
		rapidRequestType: 'E shpejtë',
		admissionRequestType: 'Pranim',
		regularRequestType: 'E rregullt',
		failedToGetParticipantInfo: 'Dështoi në marrjen e informacionit të pjesëmarrësit. Ju lutemi provoni përsëri.',
		failedToGetYourInfo: 'Dështoi në marrjen e informacionit tuaj. Ju lutemi provoni përsëri.',
		failedToGetParticipantInfoName: 'Dështoi në marrjen e informacionit të {value}. Ju lutemi provoni përsëri.',
		failedToGetInitiatorInfo: 'Dështoi në marrjen e informacionit të iniciatorit. Ju lutemi provoni përsëri.',
		ascAlphaOrder: '(A-Z)',
		descAlphaOrder: '(Z-A)',
		ascNumOrder: '(0-9)',
		descNumOrder: '(9-0)',
		queueManagement: 'Menaxhimi i radhës',
		rapidRequestTypeCalls: 'Thirrje të shpejta',
		admissionRequestTypeCalls: 'Thirrjet për pranim',
		regularRequestTypeCalls: 'Thirrjet e rregullta',
		manageQueue: 'Menaxho radhën',
		viewQueue: 'Shiko Radhën',
		doctorsQueue: `'s Radha`,
		transferPatientsToDoctor: 'Transferimi i pacientëve te mjeku',
		noAvailableDoctor: 'Nuk ka mjek të disponueshëm në këtë spital!',
		successfullyTransferPatient: 'Transferimi i suksesshëm',
		requestSuccessfullyTransferPatient: 'Transferimi i pacientit u dërgua me sukses!',
		incomingPatientRequest: 'Kërkesa e pacientit në hyrje!',
		changeRpmProgram: 'Ndrysho RPM',
		reasonToConnect: 'Arsye për të lidhur',
		acuity: 'Ngutësi',
		clinicalConcern: 'Shqetësim Klinik',
		nurseInfo: 'Informacioni i Infermierit',
		clinicalContact: 'Kontakti Klinik',
		isNurseClinicalContact: 'A është Kontakt Klinik Infermier',
		rapidResponse: 'Përgjigje e Shpejtë',
		edAdmitDiscussion: 'Diskutim për Pranim në ED',
		drugOrderOrClarification: 'Porosi për Drogë / Klarifikim',
		abnormalVital: 'Vlera Vitale Jo Normale',
		abnormalLab: 'Lab Jo Normale',
		criticalLab: 'Lab Kritike',
		patientDeclining: 'Pacientët Refuzojnë',
		otherEmergent: 'Të Tjerë Situata të Mjegullta',
		reviewOutsideFacilityTransfer: 'Rishikim i Transferimit Jashtë Fakultetit',
		drnOrDniClarification: 'Klarifikim DRN ose DNI',
		otherNonEmergent: 'Të Tjerë Situata Jo të Mjegullta',
		admitIcu: 'Pranim në ICU',
		admitNonIcu: 'Pranim jashtë ICU',
		admitBackup: 'Pranim Backup',
		immediate: 'I menjëhershëm',
		ext: 'Shtesë',
		callPhoneNumber: 'Thirr Numrin e Telefonit',
		lifeSignals: 'Sinjalet e Jetës',
		lifeSignalDescription:
			'Kur kjo është e aktivizuar, pajisja Lifesignal Patch do të jetë e disponueshme për t`u përdorur gjatë monitorimit',
		unableToGetInfo: 'Në pamundësi për të marrë informacionin e {value}.',
		unableToGetInfoGeneralMessage: 'Nuk mund të merret informacioni i pjesëmarrësve.',
		callNumber: 'Thirre',
		assignWearable: 'Caktoni pajisje',
		wearableHistoryTooltip: 'Historiku',
		unassignWearable: 'A jeni të sigurtë se doni ta largoni këtë pajisje nga ky pacient?',
		unassignWearableTooltip: 'Hiqni përcaktimin e pajisjes',
		roleCustomization: 'Përshtatja e roleve',
		monitoringPrecautionsDescription:
			'Kur kjo është e aktivizuar, {value} do të mund të shohë legjendën e masave paraprake gjatë monitorimit.',
		poolingFlowDescription: 'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të ndryshojë rrjedhën e grupit.',
		conversationHistoryDescription: 'Kur kjo është e aktivizuar, {value} do të mund të shikojë historikun e bisedave.',
		notificationsDescription: 'Kur kjo është e aktivizuar, {value} do të mund të shikojë njoftimet.',
		savedSessionsDescription: 'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të ruajë pamjen e sesioneve.',
		conversationFilesDescription:
			'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të ndajë skedarë gjatë bisedës me përdoruesit e tjerë.',
		conversationPhotosDescription:
			'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të ndajë fotografi gjatë bisedës me përdoruesit e tjerë.',
		homePageDescription:
			'Kur kjo është e aktivizuar, {value} do të shohë një listë të pacientëve që presin të bashkohen në thirrje.',
		waitingRoomDescription:
			'Kur kjo është e aktivizuar, {value} do të shohë një listë të pacientëve që presin të bashkohen në thirrje.',
		aiNotificationsDescription:
			'Kur ky opsion është i aktivizuar, përdoruesi do të jetë në gjendje të shohë Njoftimet e Sinjalizimeve AI të Rounding-ut.',
		patientsDescription: 'Kur kjo është e aktivizuar, {value} do të shikojë një listë të pacientëve që i janë caktuar atij/asaj.',
		apppointmentsDescription: `Kur kjo është e aktivizuar, {value} do të ketë opsionin për të vendosur disponueshmërinë dhe për të krijuar takime për pacientët.`,
		doctorRegistrationForm: 'Formulari i Regjistrimit',
		registrationFormPhotosDescription: `Kur kjo të aktivizohet, mjekëve do t'u kërkohet të plotësojnë një formular regjistrimi për të vazhduar me rolin e tyre.`,
		roundingDescription: `Kur kjo të aktivizohet, {value} do të jetë në gjendje të fillojë vizitat me pacientët.`,
		rpmDescription: 'Kur kjo të aktivizohet, {value} do të jetë në gjendje të inicojë programe RPM për pacientët.',
		chatDescription: 'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të fillojë bisedën me përdoruesit e tjerë.',
		teamsDescription: 'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të fillojë bisedën me përdoruesit e tjerë.',
		monitoringDescription: 'Kur kjo të aktivizohet, {value} do të jetë në gjendje të nisë seancat e monitorimit.',
		visitorsDescription:
			'Kur kjo është e aktivizuar, {value} do të jetë në gjendje të shohë një listë të vizitorëve për dhomën specifike.',
		dashboardDescription:
			'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të shikojë të dhënat në kohë reale për Sistemin e Shëndetit të caktuar.',
		auditLogsDescription:
			'Kur ky është aktivizuar, përdoruesi do të jetë në gjendje të shohë regjistrat për sistemin e shëndetit të caktuar.',
		queueManagementDescription: 'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të menaxhojë radhën e mjekëve.',
		organizationDescription:
			'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të shtojë spitalet e reja, departamentet, kate dhe dhomat.',
		callLogsDescription:
			'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të shohë thirrjet e mëparshme dhe ato që po vazhdojnë.',
		userManagementDescription:
			'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të shikojë anëtarët dhe anëtarët e ftuar.',
		configurationsDescription:
			'Kur kjo është e aktivizuar, {value} do të ketë mundësinë të konfigurojë pajisjen, rrjetin dhe ngjarjet e kujdesit.',
		noDataToDownload: 'Nuk ka të dhëna për të shkarkuar',
		noAssignedWearable:
			'Asnjë pajisje nuk është caktuar për këtë pacient. Për të caktuar një pajisje, klikoni butonin "+" në të djathtë.',
		enableMultipleFeedAudioDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të mund të aktivizojnë audion kur monitorojnë pajisje të shumta.',
		patientInformation: 'Informacione për pacientin',
		voiceOver: 'Voice Over',
		telehealthDescription:
			'Një zgjidhje e integruar televizive, u mundëson individëve të shtruar në spital të shijojnë filma dhe përmbajtje argëtuese, duke përmirësuar qëndrimin e tyre pa kërkuar lëvizshmëri fizike.',
		evideoDescription:
			'eVideon është një platformë gjithëpërfshirëse e përvojës së pacientit e krijuar për të rritur angazhimin dhe komunikimin në objektet e kujdesit shëndetësor. Ai ofron zgjidhje interaktive multimediale që ofrojnë edukim të personalizuar, argëtim dhe mjete komunikimi për pacientët, duke përmirësuar kënaqësinë e tyre dhe përvojën e përgjithshme spitalore.',
		pcareDescription: `Me platformën e angazhimit të pacientëve të pCare, pacientët kanë akses në mjete interaktive për edukim, argëtim dhe komunikim. Ai fuqizon pacientët duke ofruar informacion dhe burime të personalizuara shëndetësore, duke reduktuar ankthin dhe duke nxitur një përfshirje më të madhe në kujdesin e tyre.`,
		getwellDescription: `Angazhimi i pacientëve dhe platforma e komunikimit të Getwell lehtëson një lidhje të pandërprerë midis pacientëve dhe ofruesve të tyre të kujdesit shëndetësor. Ai ofron një sërë mjetesh për edukimin, komunikimin dhe koordinimin e kujdesit, duke u mundësuar pacientëve të aksesojnë informacionin e personalizuar shëndetësor, të ndërveprojnë me ekipin e tyre të kujdesit dhe të marrin pjesë aktive në planet e tyre të trajtimit.`,
		patientExperience: 'Përvoja e pacientit',
		rtls: 'RTLS',
		rtlsDescription: 'Kur kjo të aktivizohet, pacientët do të shohin që infermierja është në dhomë.',
		roomModes: 'Modet e Dhomës',
		helloCareDescription: `Kur kjo është e aktivizuar, ekrani do të shfaqë informacione të tilla si data, emri i dhomës dhe emri i pacientit.`,
		nicuDescription: 'Kur kjo është e aktivizuar, ekrani do të shfaqë një pamje për dhomën e fëmijës.',
		backgroundAI: 'Prapavij AI',
		customizeRoom: 'Përshtatni Dhomën',
		roomTypes: 'Llojet e Dhomave',
		babyRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Dhomën e Bebës si lloj dhomash në nivel dhomash.',
		frontDeskRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Front Desk si lloj dhomash në nivel dhomash.',
		doctorRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Doktorin si lloj dhomash në nivel dhomash.',
		laboratoryRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Laboratorin si lloj dhomash në nivel dhomash.',
		injectionRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Dhomën e Injeksioneve si lloj dhomash në nivel dhomash.',
		infusionRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Dhomën e Infuzioneve si lloj dhomash në nivel dhomash.',
		digitalClinicRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Klinikën Dijitale si lloj dhomash në nivel dhomash.',
		pharmacyRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Farmacinë si lloj dhomash në nivel dhomash.',
		radiologyRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Radiologjinë si lloj dhomash në nivel dhomash.',
		kioskRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Kioskin si lloj dhomash në nivel dhomash.',
		triageRoomTypeDescription:
			'Kur ky opsion është i aktivizuar, do të jeni në gjendje të vendosni Triage si lloj dhomash në nivel dhomash.',
		intakeForm: 'Formulari i Marrjes',
		intakeFormDescription:
			'Kur kjo është e aktivizuar, provajderët do të jenë në gjendje të mbledhin informacione për pacientët.',
		doctorPoolingDescription: 'Kur kjo është e aktivizuar, ekrani do të shfaqë listën e mjekëve të caktuar në atë spital.',
		scpHealthForm: 'Formulari Shëndetësor SCP',
		baycareForm: 'Formulari Baycare',
		aiWidgetDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë pajisje shtesë si shenjat vitale, takimet e ardhshme, thirrja e një kujdestari, shenjat vitale të AI, terapia fizike.',
		widgetDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë miniaplikacione si shenjat vitale, takimet e ardhshme, thirrja e një kujdestari, edukimi, recetat e sotme.',
		digitalClinicDescription: `Kur kjo është e aktivizuar, ekrani do të shfaqë informacione të tilla si data, emri i dhomës, emri i pacientit dhe matjet më të fundit të pacientit.`,
		telemedicineDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë listën e mjekëve të disponueshëm dhe llojin e thirrjeve që përdoruesit mund të nisin.',
		hospitalDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë miniaplikacione si lista e familjes dhe miqve, aplikacionet, telefononi një kujdestar, arsimi, recetat e sotme.',
		virtualExaminationDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë listën e mjekëve të disponueshëm që përdoruesi mund të fillojë thirrjet.',
		simplifiedDescription: `Kur kjo është e aktivizuar, ekrani do të shfaqë informacione të tilla si data, emri i dhomës dhe emri i pacientit.`,
		eVideonDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë zgjidhje ndërvepruese të kujdesit për pacientin që angazhojnë, edukojnë dhe argëtojnë pacientët dhe vizitorët, ndërkohë që thjeshtojnë rrjedhat e punës për stafin.',
		patientExperienceDescription:
			'Kur kjo është e aktivizuar, ekrani do të shfaqë zgjidhje ndërvepruese të kujdesit për pacientin që angazhojnë, edukojnë dhe argëtojnë pacientët dhe vizitorët, ndërkohë që thjeshtojnë rrjedhat e punës për stafin.',
		mdmDescription: 'Platforma e përvojës së pacientit MDM rrit angazhimin e pacientit gjatë gjithë vazhdimësisë së kujdesit.',
		azureAd: 'Azure AD',
		duo: 'Dou',
		pingFederate: 'Ping Federate',
		okta: 'OKTA',
		hillRom: 'Hill-Rom',
		epic: 'Epic',
		cerner: 'Cerner',
		telehealth: 'Telehealth',
		evideon: 'eVideon',
		pcare: 'pCare',
		getwell: 'GetWell',
		sonifi: 'Sonifi',
		mdm: 'MDM',
		globo: 'Globo',
		amn: 'AMN',
		sonifiDescription:
			'Përmirësoni përvojën e pacientit me zgjidhjen e integruar të SONIFI Health, duke bashkuar komunikimin, edukimin dhe argëtimin pa probleme. Ngritni ofrimin e kujdesit duke mundësuar angazhimin pa mundim të pacientit brenda dhomave të spitalit.',
		addUnitPhoneNumber: 'Shto Numrin e Telefonit të Njësisë',
		unitPhoneNumber: 'Numri i Telefonit të Njësisë',
		noIntegrationTypesAvailable: 'Nuk ka lloje të integrimit të disponueshëm',
		pleaseSetupIntegrationType:
			'Ju lutemi konfiguroni fillimisht një lloj integrimi. Kontaktoni ofruesin tuaj të shërbimit për ndihmë.',
		errorVoiceOver: 'Ka ndodhur një gabim gjatë ndërrimit të njoftimit "Rri i qetë" të {value}. Ju lutemi, provoni përsëri!',
		enableStayStill: 'Aktivizo njoftimin "Rri i qetë"',
		disableStayStill: 'Aktivizo njoftimin "Rri i qetë"',
		stoppedAlarmPopup: 'Alarmi u ndal pas përgjigjes nga {value}',
		activeAlarmTriggered: 'Alarmi aktiv është nxitur nga',
		statAlarmStarted: 'Alarmi statik ka nisur',
		statAlarmStopped: 'Alarmi statik ka ndaluar',
		complete: 'Përfundoni',
		hasStoppedStatAlarm: 'ndaluar alarmin',
		voiceOverAlarmStopped: 'Njoftimi manual u deaktivizua',
		voiceOverAlarmStarted: 'Njoftimi manual u aktivizua',
		addTranslatorInCall: 'Shtoni përkthyesin në bisedë',
		whiteboard: 'Tabela e Bardhë',
		whiteboardType: 'Lloji i tabelës së bardhë',
		stoppedAlertPopup: 'Njoftimi manual u ndal pas përgjigjes nga {value}',
		selectAlarm: 'Zgjedhni alarmin',
		availableAlarms: 'Alarmet në dispozicion',
		selectOtherAlarm: 'Zgjedhni një alarm tjetër',
		alarm: 'Alarmi',
		alarmCount: 'Alarmi {value}',
		scheduled: 'Planifikuar',
		repeat: 'Përsëriteni',
		weekly: 'Javore',
		monthly: 'Mujore',
		selectSchedule: 'Zgjedhni llojin e planifikimit',
		ivBagFluidLevel: 'Niveli i lëngut në pako të infuzionit',
		admissions: 'Pranimet',
		discharges: 'Lëshimet',
		admissionsAndDischarges: 'Pranimet dhe Lëshimet',
		avgAdmissionsPerHour: 'Mesatarja e pranimit në orë',
		avgDischargesPerHour: 'Mesatarja e lëshimet në orë',
		highestAdmissionsPerHour: 'Numri më i lartë i pranimit në orë',
		highestDischargesPerHour: 'Numri më i lartë i lëshimeve në orë',
		ivBagDescription:
			'Kur ky është i aktivizuar, përdoruesit do të jenë në gjendje të shohin përditësime të reja në kohë reale të nivelit të lëngut në pako të infuzionit dhe do të njoftohen kur niveli është i ulët.',
		monitoringHealthDataDescription:
			'Kur ky është aktivizuar, skeda "Të dhënat e Shëndetit" nën Sinjalizimet në monitorim duhet të shfaqet.',
		ivBagCheck: 'Niveli i lëngut në pakon IV është i ulët. Ju lutemi kontrolloni IV-në.',
		warningAlertAt: 'Paralajmërimi në',
		enableIvBagWarning: 'Për të aktivizuar këtë opsion, duhet të siguroni së pari një vlerë paralajmërimi.',
		patientEngagement: 'Angazhimi i pacientit',
		nurseCallsSystems: 'Sistemet e Thirrjeve për Infermierë',
		configurationTypeRequired: 'Kërkohet lloji i konfigurimit',
		externalTypeDescription:
			'Kur kjo të aktivizohet, përdoruesit do të jenë në gjendje si një autoritet global i vërtetimit që lejon klientët, punonjësit dhe partnerët të kenë qasje të sigurtë në të gjitha aplikacionet që u nevojiten nga çdo pajisje.',
		ehrDescription:
			'Kur kjo të aktivizohet, përdoruesit do të jenë në gjendje të qasen një regjistër të unifikuar të pacientit për vendimmarrje më të mirë klinike dhe kujdes të përmirësuar për pacientin.',
		translationTypeDescription:
			'Kur kjo të aktivizohet, përdoruesit do të jenë në gjendje të përkthejnë pa probleme përmbajtjen dhe komunikimin në shumë gjuhë.',
		doctorQueue: 'Rrjedha e Doktorit',
		lowIvBagFluidLevel: 'Niveli i ulët i lëngut në pako të infuzionit',
		bedIdentifier: 'Identifikuesi i Krevatit',
		enterEmailAddress: 'Shkruani email adresën',
		addDomain: 'Shtoni Domenin',
		enterEmailDomain: 'Futni Domenin e Email-it',
		familyMemberNotAssigned: 'Nuk jeni caktuar ende për asnjë pacient!',
		dependants: 'Vartësit',
		cannotBeFamilyMember: 'Pacienti nuk mund të jetë një anëtar i familjes për veten e tij',
		patientGettingOutOfChair: 'Largimi i pacientit nga karrigeja u detektua',
		personGettingOutOfChairMessage: 'Pacienti po largohet nga karrigeja',
		sessionsQueue: 'Rrjedha e Sesionit',
		sessionsQueueDescription:
			'Kur ky është aktivizuar, Doktori do të ketë mundësi të shohë një rrjedhë sesioni që mund të bashkohet ose të refuzohet.',
		patientIsInactiveToastMessage:
			'Është koha të kthejmë pacientin nga ana tjetër. Ju lutem njoftoni infermierin pranë shtratit.',
		forwardToNursesBtn: 'Dërgo tek infermierët',
		patientInactiveInformation: 'Është koha të kthejmë pacientin nga ana tjetër.',
		careEventsForNursesDesc:
			'Ndërhyrjet e pacientit si Pranimi, Konsultimi, Trajtimi, Lirimi, Alarmit Stat dhe Modaliteti i Privatësisë të kryera nga VCP-të janë të regjistruara.',
		measuringIv: 'Duke matur nivelin e lëngut IV...',
		digitalBackground: 'Sfond digjital',
		selectPreferences: 'Zgjidh preferencat',
		backgroundImage: 'Imazhi i sfondit',
		backgroundImagesAllowed: 'Ofroni opsione imazhi për përdoruesit që të zgjedhin efektet e sfondit gjatë telefonatave video.',
		presentImage: 'Imazhet prezente',
		addImage: ' Shto imazhin',
		blurryBackgroundDefault: 'Cakto sfondin e turbullt si parazgjedhje',
		blurryBackground: 'Sfondi turbullt',
		enabled: 'Aktivizuar',
		notEnabled: 'Jo i aktivizuar',
		viewMedications: 'Shikoni ilaçet',
		todaysMedication: 'Ilaçet e sotme',
		patientSkippedMedication: 'Pacienti ka anashkaluar ilaçet',
		notifyPatientForMedication: 'Njoftoni pacientin që të marrë ilaçet',
		featureChanged: 'Veqoria e Ndryshuar',
		variant: 'Varianti',
		changedForRole: 'Ndryshuar për Rrolin',
		skipped: 'Anashkaluar',
		taken: 'Marrë',
		displayCaregiverName: 'Shfaq Emrin e Kujdestarit',
		displayCaregiversNameDescription:
			'Kur ky opsion aktivizohet, pacienti do të mund të shohë emrin e plotë (p.sh. Linda Andreson) ose emrin e parë dhe shkronjën e parë të mbiemrit (p.sh. Linda A.) të kujdestarit që shfaqet gjatë bisedës mes pacientit dhe kujdestarit. Në raste kur ky opsion është i çaktivizuar, pacienti nuk do të jetë në gjendje të shohë as emrin as ndonjë shkronjë të kujdestarëve.',
		sessionMaintenance: 'Kjo seancë u mbyll nga mirëmbajtja.',
		exceededCharactersLimit: 'Keni kaluar maksimumin e lejuar të karaktereve. Ju lutemi rregulloni ose hiqni shënimet e tepërta.',
		helloCareEngagement: 'Angazhimi i Hellocare',
		homeWidget: 'Kreu',
		whiteboardURL: 'Whiteboard URL',
		engagementWhiteboardURLDescription: 'Fut Whiteboard URL për të hyrë në funksionalitetin e tabelës dixhitale të hellocare',
		home: 'Kreu',
		engagementHomeDescription:
			'Qendra e Komunikimit dhe Informacionit për Pacientët: Një qendër që lehtëson komunikimin e pacientëve dhe siguron përditësime të rëndësishme. Përfshin një tabelë të bardhë interaktive për përdorim të gjerë.',
		educationWidget: 'Arsim',
		engagementEducationDescription:
			'Burime Edukative të Kujdesit Shëndetësor: Një depozitë e videove edukative të lidhura me shëndetësinë, që ndihmojnë në rritjen e njohurive dhe angazhimit të pacientëve.',
		liveTvWidget: 'TV Live',
		engagementLiveTVDescription:
			'Kanale të Argëtimit dhe Informacionit: Një zgjedhje e kanaleve të ndryshme televizive nëpër janre, duke siguruar që pacientët kanë akses në opcione argëtimi dhe informacioni.',
		engagementMoviesDescription:
			'Biblioteka e Filmave: Një katalog filmash që mbulon janre të ndryshme, ofrojë argëtim me kërkesë.',
		musicWidget: 'Muzikë',
		engagementMusicDescription:
			'Stacione Radio Muzikore: Akses në stacione radio muzikore, të kategorizuara sipas janre, për relaksim dhe kënaqësi të pacientëve.',
		moodsWidget: 'Humori',
		engagementMoodsDescription:
			'Video të Relaksimit: Një koleksion video-sh që nxisin humorin, përfshirë skena si oxhaka dhe plazhe, për të promovuar rehatinë dhe mirëqenien e pacientëve.',
		appsWidget: 'Aplikacione',
		engagementAppsDescription:
			'Aplikacione të Argëtimit: Integrim me aplikacionet e njohura të argëtimit si Netflix, Amazon Prime dhe YouTube Music, duke zgjeruar zgjedhjet e argëtimit të pacientëve.',
		familyAndFriendsWidget: 'Familja & Miqtë',
		engagementFamilyFriendsDescription:
			'Lidhja me Pacientët: Një karakteristikë që mundëson pacientëve të mbeten të lidhur me të dashurit e tyre përmes video-telefonatave, duke promovuar mbështetjen emocionale.',
		orderMealsWidget: 'Porositni Vaktet',
		engagementOrderMealDescription:
			'Mundësi e Lehtë për Porositjen e Vakteve: Një opsion i përshtatshëm për pacientët të porosisin vakte sipas preferencave të tyre, duke përmirësuar përvojën e tyre të ngrënies.',
		whiteBoardWidget: 'Tabela e Bardhë',
		engagementWhiteboardDescription:
			'Qendra e Informacionit Interaktiv: Një tabelë e bardhë e shumëfunksionale që shërben si një qendër e informacionit interaktiv, lejon shënime, shfaqjen e përmbajtjes dhe integrimin e video.',
		careTeamWidget: 'Ekipi i Kujdesit',
		engagementCareTeamDescription:
			'Akses në Ekipin e Kujdesit: Pacientët mund të kenë lehtësisht akses dhe komunikim me anëtarët e ekipit të tyre të dedikuar të kujdesit, duke lehtësuar koordinimin e kujdesit.',
		healthDataWidget: 'Të Dhënat e Shëndetit',
		engagementHealthDataDescription:
			'Monitorimi dhe Informacionet e Shëndetit: Akses në të dhënat e shëndetit nga pajisjet e ndryshme të monitorimit dhe Interneti i Objekteve (IoT), duke ofruar një pamje të përgjithshme të shëndetit të pacientëve.',
		sessionAborted: 'Sesioni është anuluar. Ju lutemi provoni përsëri.',
		sessionDisrupted: 'Sesioni është ndërprerë. Ju lutemi provoni përsëri.',
		perfect: 'Perfekt',
		good: 'Mirë',
		bad: ' Keq',
		mediocre: 'Mesatare',
		noCareEvents: 'Nuk ka ngjarje përkujdesi të disponueshme.',
		askAdministratorForCareEvents: 'Ju lutem pyesni administratorin tuaj të shtojë ngjarje përkujdesi.',
		providerCamera: 'Kamera e ofruesit',
		providerMic: 'Mikrofoni i ofruesit',
		providerMicDesc: 'Duke e aktivizuar këtë, mikrofoni i ofruesit do të jetë i hapur kur fillohet thirrja.',
		patientCamera: 'Kamera e Pacientit',
		patientMicrophone: 'Mikrofoni i Pacientit',
		patientMicrophoneDesc: 'Duke e aktivizuar këtë, mikrofoni i pacientit do të jetë i hapur kur fillohet thirrja.',
		disableProviderCameraDesc: 'Duke e aktivizuar këtë, kamera e ofruesit do të jetë e hapur kur fillohet thirrja.',
		patientMicDesc: 'Duke e aktivizuar këtë, mikrofoni i pacientit do të jetë i hapur kur fillohet thirrja.',
		patientCameraDesc: 'Duke e aktivizuar këtë, kamera e pacientit do të jetë e hapur kur fillohet thirrja.',
		enableLiveCaptions: 'Aktivizo nënshkrimet live',
		disableLiveCaptions: 'Çaktivizo nënshkrimet live',
		closedCaptions: 'Nënshkrime live',
		liveCaptionsDescription:
			'Kur kjo të aktivizohet, {value} do të mund të shohë titrat e drejtpërdrejtë të pjesëmarrësit që flet.',
		participantRemovedByAdministrator: 'Ju jeni hequr nga kjo konferencë nga një administrator.',
		wantToTurnOnFallPrevention: 'Dëshironi të aktivizoni parandalimin e rrëzimit nga AI në monitorim?',
		continueNoAi: 'Jo, vazhdo pa AI',
		aiMonitoringOn: 'Aktivizo monitorimin AI',
		aiFallPrevention: 'Parandalimi i Rrëzimit nga AI',
		helloBackground: 'Prapavija ne Hello',
		setHelloBackground: 'Zgjedhni një imazh që do të shfaqet në pamjen e pacientit përpara se të fillojë seanca.',
		helloWallpaper: 'Tapiceria ne Hello',
		setHelloWallpaper: 'Zgjedhni një imazh që do të shfaqet pas ikonave në ekranin e aplikacionit.',
		addCareEvent: 'Shto ngjarje kujdesi',
		nameOrEmail: 'Emri ose Email',
		careEventHistory: 'Historiku i ngjarjeve të kujdesit',
		talkToPatientSettings: 'Cilësimet e Bisedës me Pacientin',
		talkToPatientSettingsDesc:
			'Përdorni opsionet më poshtë për të specifikuar se çfarë do të shohë dhe dëgjojë ofruesi dhe pacienti kur bëjnë një thirrje te ose nga pajisja hello.',
		callSettingsForRoomSignDesc:
			'Përdorni opsionet më poshtë për të specifikuar se çfarë do të shohin dhe dëgjojnë ofruesi dhe pacienti kur bëjnë ose marrin një thirrje nga shenja e dhomës.',
		viewPatientSettings: 'Shiko Cilësimet e Pacientit',
		viewPatientSettingsDesc: 'Përdorni këtë opsion për të shfaqur ose fshehur opsionin Shiko Pacientin në anën e ofruesit.',
		videoMonitoring: 'Video Monitorimi',
		ambientMonitoring: 'Monitorimi i ambientit',
		ambientAndVideoMonitoring: 'Monitorimi i Ambientit dhe Video',
		ambientMonitoringDescription: 'Kur kjo është e aktivizuar, ofruesi do të jetë në gjendje të marrë sinjalizime.',
		unoccupied: 'E lirë',
		ambientMonitoringNotActive: 'Monitorimi I Ambientit Jo Aktiv',
		receiveAlerts: 'Për të marrë sinjalizime,',
		inOrderToReceiveAlerts: 'për të marrë sinjalizime.',
		alert: 'Alarm',
		manageAlert: 'Menaxhoni Alarmin',
		closeAlert: 'Mbyllni Alarmin',
		selectViewOrAddRooms: 'Zgjidhni një nga pamjet e ruajtura ose filloni duke shtuar dhoma në pamjen aktuale.',
		maxAmbientMonitoringProviders: 'Kjo pajisje ka arritur maksimumin e ofruesve të lejuar të Monitorimit të Ambientit.',
		exportRebootDeviceLogs: 'Kjo pajisje nuk ka ende regjistra të rindezjes.',
		logs: 'Regjistrat',
		removeParticipant: 'Hiq pjesëmarrësin',
		confirmParticipantRemoval: 'Konfirmo largimin e pjesëmarrësit',
		confirmationOfRemovalParticipant: 'A jeni të sigurt se doni ta hiqni {value} nga thirrja?',
		youAreRemovedFromConference: 'Ju jeni larguar nga kjo konferencë.',
		helloSpeakerSelected: 'Zgjedhja e zërit të brendshëm të pajisjes Hello është bërë',
		tvSpeakerSelected: 'Zgjedhja e zërit të televizorit është bërë',
		switchToTv: 'Kalo në zërin e televizorit',
		switchToHello: 'Kalo në zërin e pajisjes Hello',
		viewing: 'Duke shikuar',
		fetchingPrecautionsFailed: 'Marrja e masave paraprake ka dështuar.',
		selectCareEvent: 'Për të aktivizuar shënimet, zgjidhni një ngjarje kujdesi',
		supportChat: 'Bisedë e Mbështetjes',
		supportChatDescription:
			'Kur kjo është aktivizuar, përdoruesit do të kenë bisedën e mbështetjes në dispozicion brenda aplikacionit.',
		prefix: 'Prefiksi',
		displayFullName: 'Duke shfaqur emrin e plotë',
		displayCredentialsName: 'Duke treguar emrin e parë, shkronjën e parë të mbiemrit dhe kredencialet',
		deleteConfirmation: 'Konfirmim i fshirjes',
		failedToGetCaptions: 'Nuk u arritën të merren titrat! Ju lutemi provoni përsëri në një moment.',
		careEventsDashboard: 'Paneli i ngjarjeve të kujdesit',
		created: 'Krijuar',
		departmentFloorRoom: 'Departamenti > Kati > Dhoma',
		careEventType: 'Lloji i ngjarjes së kujdesit',
		admission: 'Pranimi',
		discharge: 'Shkarkimi',
		allEvents: 'Të gjitha ngjarjet e kujdesit',
		myCareEvents: 'Ngjarjet e Kujdesit tim',
		selectCareTeam: 'Zgjedhni ekipin e kujdesit',
		assignCareTeam: 'Caktoni ekipin e kujdesit',
		newCareEvent: 'Ngjarje e re e kujdesit',
		searchRoomName: 'Kërkoni emrin e dhomës',
		careEventsDescription: 'Kur kjo është e aktivizuar, {value} do të mund të shikojë ngjarjet e kujdesit.',
		statusAlreadyExists: 'Ky status tashmë ekziston!',
		patientCannotBeEmpty: 'Pacienti nuk mund të jetë i zbrazët',
		nurseCannotBeEmpty: 'Infermieri/ja nuk mund të jetë i/e zbrazët',
		addPatientWithEvent:
			'Caktoni një pacient ekzistues ose të ri në një dhomë për të lidhur ngjarjen e kujdesit me të dhënat e tyre/',
		selectExistingPatientWithEvent:
			'Zgjedhni një pacient për të shtuar një ngjarje kujdesi dhe caktoni një anëtar të ekipit të kujdesit për ta menaxhuar atë si detyrë.',
		searchPatientByMrn: 'Kërkoni pacientin me emër ose MRN',
		aIDetectionDrawings: 'Skicat e Zbulimit të AI',
		eventHistory: 'Historia e Ngjarjeve',
		eventCompleted: '{value} i përfunduar',
		eventCreated: 'Ngjarja e {value} u krijua',
		statusUpdated: 'Statusi i përditësuar',
		youUpdatedStatus: 'Ju keni përditësuar statusin në një ngjarje',
		describeChange: 'Përshkruani pse e bëtë këtë ndryshim',
		yourInsightsHelp:
			'Vështrimet tuaja na ndihmojnë të mbajmë shënime të sakta dhe të sigurojmë kujdesin më të mirë për të gjithë të përfshirët',
		youReassignedCareMember: 'Ju keni ricaktuar një ekip kujdesi në një ngjarje',
		reassigned: 'U ricaktua',
		assignedCareTeam: 'Ekipi i caktuar i kujdesit',
		patientIsAtRoom: 'Pacienti është në dhomë',
		sameExerciseSameDay: 'Ushtrimi i njëjtë, në të njëjtën ditë, është krijuar tashmë!',
		youUpdatedPriority: 'Ju keni përditësuar prioritetin në një ngjarje',
		priorityUpdated: 'Prioriteti i përditësuar',
		selectStatus: 'Zgjedhni statusin',
		clearAll: 'Pastro të gjitha',
		filters: 'Filterat',
		dateRange: 'Kufiri i datës',
		selectWithinDateRange: 'Gama e datave nuk mund të jetë më shumë se {value} ditë',
		selectValidDateRange: 'Ju lutemi zgjidhni një interval të vlefshëm datash!',
		selectValidEndTime: 'Zgjidh një kohë të vlefshme përfundimi!',
		patientPlacement: 'Vendosja e pacientit',
		skipSslVerification: 'Kalo verifikimin SSL',
		mrnNoMatch: 'Numri i Regjistrimit Mjekësor (MRN) nuk përputhet me regjistrat tanë.',
		healthAlerts: 'Njoftimet shëndetësore',
		aiAlerts: 'AI njoftimet',
		activeAlerts: 'Njoftimet aktive',
		showAll: 'Shfaq të gjitha',
		showLess: 'Shfaq më pak',
		allHospitals: 'Të gjitha Spitalet',
		allDepartments: 'Të gjitha Departamentet',
		allFloors: 'Të gjitha Katet',
		removeRoomMonitoring: 'Dëshironi të hiqni dhomën {value}?',
		sureRemoveRoomMonitoring: 'Ju lutem konfirmoni që dëshironi të hiqni këtë dhomë nga sesioni aktual i monitorimit.',
		removeSessionMonitoring: 'Dëshironi të mbyllni sesionin aktual?',
		sureRemoveSessionMonitoring: 'Ju lutem konfirmoni që dëshironi të mbyllni sesionin aktual.',
		interventions: 'Ndërhyrjet',
		addInterventions: 'Shto Ndërhyrjet',
		interventionsHistory: 'Historia e Ndërhyrjeve',
		newInterventions: 'Ndërhyrje të reja',
		noInterventions: 'Nuk ka ndërhyrje të disponueshme',
		askAdministratorForInterventions: 'Ju lutemi pyesni administratorin tuaj të krijojë një listë ndërhyrjesh për të zgjedhur.',
		selectInterventions: 'Për të shtuar shënime shtesë, zgjidhni një opsion.',
		aditionalNotes: 'Shënime Shtesë',
		addAdditionalNotes: 'Shto Shënime Shtesë',
		allRooms: 'Të gjitha Dhomat',
		aiSettings: 'Cilësimet e AI-së',
		dataCollectionAlerts: 'Lejo Përmbledhjen e të Dhënave për Alarmin e Rreme',
		dataCollectionAlertsDescription: 'Ky opsion e lejon sistemin tonë të marrë fotografi nëse raportohet një alarm i rremë.',
		patientNotes: 'Shënime për Pacientin',
		addNotes: 'Shtoni shënime rreth pacientit',
		enterPatientName: 'Vendos Emrin e Pacientit',
		patientAge: 'Moshë e Pacientit',
		enterPatientAge: 'Vendos Moshën e Pacientit',
		nurseNameNumber: 'Infermier(i) - Emër/Numër',
		enterNurseNameNnumber: 'Vendos Infermier(i) - Emër/Numër',
		nurseAssistantNameNumber: 'Asistent(i) Infermier(i) - Emër/Numër',
		enterNurseAssistantNameNumber: 'Vendos Asistent(i) Infermier(i) - Emër/Numër',
		tlNameNumber: 'TL - Emër/Numër',
		enterTlNameNumber: 'Vendos TL - Emër/Numër',
		primaryAdverseEvent: 'Ngjarje Kryesore e Padëshiruar',
		enterPrimaryAdverseEvent: 'Vendos Ngjarjen Kryesore të Padëshiruar',
		primaryPatientCondition: 'Kushtet Kryesore të Pacientit',
		enterPrimaryPatientCondition: 'Vendos Kushtet Kryesore të Pacientit',
		primaryPatientRiskFactor: 'Faktorët Kryesorë të Rrezikut të Pacientit',
		enterPrimaryPatientRiskFactor: 'Vendos Faktorët Kryesorë të Rrezikut të Pacientit',
		titleBarNotes: 'Shënime Shtesë të Shiritit të Titullit',
		enterTitleBarNotes: 'Vendos Shënime Shtesë të Shiritit të Titullit',
		additionalPatientNotes: 'Shënime Shtesë të Pacientit',
		enterAdditionalPatientNotes: 'Vendos Shënime Shtesë të Pacientit',
		enterPatientInformation: 'Vendos Informacionin e Pacientit',
		voiceAnnouncements: 'Njoftime Verbale',
		patientPrivacy: 'Privatësia e Pacientit',
		patientOutOfRoom: 'Pacienti është jashtë dhomës',
		pleaseDoNotGetUp: 'Vëmendje: Ju lutemi mos ngrihuni!',
		pleaseDoNotTouchThat: 'Ju lutemi mos prekni atë!',
		pleaseStopWhatYouAreDoing: 'Vëmendje: Ju lutemi ndaloni atë që po bëni!',
		stopPlease: 'Ndaloni, ju lutem!',
		someoneIsOnTheWay: 'Fola me infermierin tuaj, dikush është në rrugë për në dhomën tuaj.',
		useYourCallLight: 'Ju lutemi përdorni dritën tuaj të thirjes.',
		doYouNeedYourNurse: 'A keni nevojë për infermierin tuaj?',
		staffInjury: 'Lëndim i Stafit',
		isolationExposure: 'Ekspozimi në Izolim',
		medicalDeviceInterference: 'Ndërhyrja e Pajisjeve Mjekësore',
		elopement: 'Ikje,',
		intentionalSelfHarm: 'Vetëdëmtimi qëllimtar',
		suicide: 'Vetëvrasja',
		delirium: 'Deliri',
		dementia: 'Demencia',
		substanceWithdrawal: 'Terheqja nga Substanca',
		brianInjury: 'Lëndim i Trurit',
		psychiatricDisorder: 'Çrregullimi Psikiatrik',
		stroke: 'Goditja Cerebrale',
		infectiousDiseaseIsolation: 'Izolimi i Sëmundjes Infektive',
		poorShortTermMemory: 'Kujtesë e Kufizuar e Afatshkurtër',
		poorComprehension: 'Kuptim i Kufizuar',
		impulsivity: 'Impulsivitet',
		agitation: 'Ngacmimi',
		anxiety: 'Anksiozitet',
		distortedPerceptionOfReality: 'Perceptimi i Rreme i Realitetit',
		angerFrustration: 'Zemërim/Rezultat Frustrues',
		none: 'Asnjë',
		patientIsOutOfTheRoom: 'Pacienti është Jashtë Dhomës',
		surgery: 'Operacion',
		patientisGoingDownForACTScan: 'Pacienti po shkon për skanim ACT',
		patientIsGoingDownForAnMRI: 'Pacienti po shkon për një MRI',
		ambulating: 'Ambulimi',
		paracentesis: 'Paracentesis',
		sunshinePrivileges: 'Privilegjet e Diellit',
		transferring: 'Transferimi',
		erCp: 'ER CP',
		dialysis: 'Dializa',
		patientReturnedToRoom: 'Pacienti u kthye në dhomë',
		roomWithoutPatientData: 'Kjo dhomë nuk ka të dhëna për pacientin.',
		isIntervening: 'po ndërhyn',
		fitToScreenVideoFeeds: 'Përshtatja në ekran të transmetimeve video',
		savingImage: 'Ruajtja e imazhit',
		imageSaved: 'Imazhi u ruajt!',
		savingImageFailed: 'Ruajtja e imazhit dështoi!',
		helloDevice: 'Pajisja Hello',
		systemExecutedActions: 'Veprime të Kryera të Sistemit',
		digitalSign: 'Shenjë dixhitale',
		aiConfigurations: 'Konfigurimet e AI',
		railsDetection: 'Detektimi i shinave',
		statAlarmActivation: 'Aktivizimi i alarmit statistikor në sinjalizimet e AI',
		toastMessage: 'Mesazh',
		alertOnMonitoringFeed: 'Alarmi për monitorim',
		soundOnAiAlert: 'Tingulli në alarmin AI',
		patientAudio: 'Audioja e pacientit',
		allowPatientAudio:
			'Shfletuesit kërkojnë ndërveprimin e përdoruesit përpara se të luajnë audio. Thjesht klikoni në rregull për të vazhduar.',
		allow: 'Lejo',
		transferOwnership: 'Transferimi i pronësisë',
		transferOwnershipDescription:
			'Largimi nga telefonata do të transferojë pronësinë te një pjesëmarrës tjetër dhe nuk do ta përfundojë telefonatën.',
		leaveCall: 'Lini thirrjen',
		directCallEcm: 'Thirrje direkte nga eCM',
		activeCareEvent: 'Ekziston një ngjarje aktive e kujdesit për pacientin aktual në dhomën e zgjedhur!',
		setTimeZone: 'Cakto fushe-horare',
		selectedTimeZone: 'Zona kohore e zgjedhur',
		searchPlaceTimeZone: 'Kërko Vend ose Zonë Kohore',
		updateCareEvents:
			'Ka përditësime të reja në dispozicion për ngjarjet e kujdesit. Klikoni butonin në të djathtë për të përditësuar',
		updateList: 'Përditëso listën',
		host: 'Pritësi',
		path: 'Rruga',
		isSubscribed: 'Është i abonuar',
		multipleBedRoom: 'Dhomë me shumë krevate',
		multipleBedRoomDesc: 'Kjo do të kufizojë pamjen nga ana e vizitorit. Kjo duhet të shtohet mbi Llojin e Dhomës.',
		careTeamReport: 'Raporti i Ekipit të Kujdesit',
		patientReport: 'Raporti i Pacientit',
		drafts: 'Skicat',
		forward: 'Forward',
		interventionsAndAlerts: 'Interventions and Alerts',
		monitorPatient: 'Monitoro pacientin',
		head: 'Koka',
		rail: 'Korniza',
		foot: 'Këmba',
		admittedDate: 'Data e pranimit',
		dischargedDate: 'Data e shkarkimit',
		viewSessions: 'Shiko Sesionet',
		endTime: 'Ora e Mbarimit',
		intervention: 'Ndërhyrja',
		actionTaken: 'Veprimi i Ndërmarrë',
		interventedBy: 'Ndërhyrë nga',
		hidePatientProfile: 'Fshih profilin e pacientit',
		showPatientProfile: 'Shfaq profilin e pacientit',
		nameNumber: 'Emri/numri',
		additionalTitleBarNotes: 'Shënime Shtesë Në Krye të Titullit',
		fullySilentMode: 'Modaliteti i qetë',
		cameraSettings: 'Cilësimet e kamerës',
		cameraUse: 'Përdorimi i kamerës',
		selectOneOfCameraSettings: 'Zgjidhni një nga cilësimet e kamerës',
		singleCameraUse: 'Përdorimi i një kamere të vetme',
		multiCameraUse: 'Përdorimi i shumë kamerave',
		genericDescription:
			'Një Anketë përgjithshme është krijuar për pacientët që ta plotësojnë në kohën e tyre të përshtatshme ose sa herë që e ndiejnë të nevojshme. Kjo anketë do të jetë e qasshme për pacientët në faqen e tyre kryesore.',
		safetyRangeDescription:
			'Anketa e Vlerave optimale është krijuar që pacientët ta plotësojnë sa herë që matjet e tyre tejkalojnë kufijtë e vendosur të sigurisë për një shenjë vitale.',
		scheduledDescription:
			'Anketat e planifikuara janë strukturuar për të shërbyer si kontrollë mujore, javore ose ditore për pacientin.',
		removeFeed: 'Largo nga monitorimi',
		turnVideo: 'Shto Video',
		turnVideoOn: 'Lësho video',
		sessionType: 'Lloji i sesionit',
		voiceAnnouncementsDescription:
			'Aktivizimi i Ridrejtimit me Zë do të shtojë të njëjtën si një tipar brenda monitorimit nga ku ofruesit mund ta përdorin për të komunikuar me pacientin thjesht duke e luajtur atë si një tingull të pararegjistruar.',
		patientOutOfRoomDescription:
			'Aktivizimi i Pacientit jashtë Dhomës do të shtojë të njëjtën si një tipar brenda monitorimit nga ku ofruesit mund të dokumentojnë rastet kur pacienti për disa arsye (të paraqitura si një lisë) është jashtë dhomës.',
		snoozeTimerForAlert: 'Koha e shtyerjes për alarmin {value}',
		snoozeTimer: 'Koha e shtyerjes',
		primaryPatientRisk: 'Rreziku Kryesor i Pacientit',
		sessionByTime: 'Sesion Për Kohen',
		totalHours: 'Total Orë',
		transferPatient: 'Transfero pacientin',
		openLifeSignals: 'Hap LifeSignals',
		isOldUserExperience: 'Është Përvojë e Vjetër e Përdoruesit',
		isEnabledForHs: 'Është Aktivizuar Për Sistemet Shëndetësore',
		isEnabledForHsDescription:
			'Kur ky opsion është i aktivizuar, Administratorët e Sistemeve Shëndetësore do të jenë në gjendje të modifikojnë Flamurin e Veçorive "Është Përdorues i Vjetër"',
		isOldUserExperienceDescription:
			'Kur kjo aktivizohet, përvoja e përdoruesit të kompanisë ose sistemit shëndetësor do të jetë ajo e vjetra.',
		userExperience: 'Përvoja e Përdoruesit',
		mixedMonitoringView: 'Pamje e monitorimit të përzier (Video dhe ambientale)',
		mixedMonitoringViewDescription:
			"Kur aktivizohet, ofruesit do të shohin 'Monitorimi Ambiental' si një aftësi/skedë shtesë brenda pamjes së pemës dhe kështu do të mundësojnë pamjen e monitorimit të përzier (transmetimi i videos + monitorimi ambiental).",
		invitationNote: 'Shënim ftese',
		invitationNotePlaceholder: 'Shto një shënim ftese që ta shohë i ftuari.',
		invitationNoteDescription: 'Të gjithë pjesëmarrësit do ta shohin këtë shënim. Maksimumi 160 karaktere.',
		pinLock: 'Kyçja PIN',
		disablePIN: 'Duke çaktivizuar kyçjen e PIN-it, ai nuk do të jetë i nevojshëm kur të përdorni cilësimet.',
		pinMinValue: 'Kodi PIN duhet të jetë së paku 1000',
		changePin: 'Ndrysho PIN-in',
		disablePinBtn: 'Çaktivizo PIN-in',
		disablePinLock: 'Çaktivizo kyçjen PIN',
		newPinLock: 'Kyç i ri PIN',
		searchByPatientNameMrn: 'Kërko sipas emrit të pacientit ose MRN',
		talkToPatientDescription: 'Kur kjo është e aktivizuar, përdoruesi do të jetë në gjendje të flasë me pacientin',
		viewPatientDescription: 'Kur kjo është e aktivizuar, përdoruesi do të jetë në gjendje të shikojë pacientin',
		checkFeatureAvailability: 'Ju lutemi kontaktoni administratorin tuaj për ta pasur këtë veçori të disponueshëm',
		badgeId: 'ID e Njështetësit',
		hillRomId: 'ID e Hill Rom',
		userPicture: 'Foto e Përdoruesit',
		badgeAuthorization: 'Autorizimi i Njështetësit',
		hl7v2: 'HL7 v2',
		hl7v2Description: 'Kur ky është aktivizuar, përkufizimi i krevatit EHR do të shfaqet ne seksionet e duhura.',
		enablingPrivacyMode:
			'Aktivizimi i këtij funksioni do të bëjë që pamja e pacientit tuaj të bëhet blur për kohën e zgjedhur. Ju lutemi shkruani kohën kur dëshironi të bëhet ekrani juaj blur ose zgjidhni nga opsionet e paracaktuara.',
		enterSelectTimer: 'Shkruani ose zgjidhni kohëmatësin në minuta.',
		writeWholeNumber: 'Ju lutemi shkruani një numër ndërmjet 1 dhe 60.',
		isActive: 'Është Aktiv',
		badgeStatus: 'Statusi i Njështetësit',
		badgeDeleteMsg: 'A jeni të sigurt se doni të çaktivizoni këtë punëtor?',
		aspectRatio: 'Raporti i pamjes',
		badgingAccess: 'Qasje e Njështetësit',
		centrakBadge: 'Njështetësi i Centrak',
		proxBadge: 'Njështetësi Prox',
		overviewTab: 'Kur kjo është e aktivizuar, skeda "Përmbledhje" do të jetë e disponueshme.',
		converationTab: 'Kur kjo të aktivizohet, skeda "Biseda" do të jetë e disponueshme.',
		filesTab: 'Kur kjo është e aktivizuar, skeda "Dosjeve" do të jetë e disponueshme.',
		healthTab: 'Kur kjo të aktivizohet, skeda "Shëndeti" do të jetë e disponueshme.',
		physicalTherapyTab: 'Kur kjo të aktivizohet, skeda "Ushtrimet fizike" do të jetë e disponueshme.',
		visitTab: 'Kur kjo të aktivizohet, skeda "Vizitat" do të jetë e disponueshme.',
		alertHistoryTab: 'Kur kjo të aktivizohet, skeda "Historia e njoftimeve" do të jetë e disponueshme.',
		transfer: 'Transfero',
		activate: 'Aktivizo',
		moreParticipants: 'Pjesëmarrës tjerë',
		clickToViewParticipants: 'Klikoni për të parë pjesëmarrësit e tjerë',
		currentlyInThisCall: 'Aktualisht në thirrje ',
		regeneratePin: 'Rigjenero PIN',
		hide: 'Fshih',
		pca: 'PCA',
		roomInfo: 'Informacioni i dhomes',
		pain: 'Dhimbje',
		painLevel: 'Niveli i dhimbjes',
		manageablePainLevel: 'Niveli i dhimbjes që mund të menaxhohet',
		roomServiceNumber: 'Numri i shërbimit në dhomë',
		isolations: 'Izolimet',
		patientInfoDescription:
			'Kur kjo është e aktivizuar, infermierët do të shohin "Informacionin e Pacientit" si një kuti brenda pamjes së dhomës.',
		monitoringWhiteboardDescription:
			'Kur kjo është e aktivizuar, ofruesit do ta shohin "Tabelën e Bardhë" si një kuti brenda pamjes së dhomës dhe do të jenë në gjendje të përditësojnë tabelën e bardhë të pacientit nga Monitorimi ose Monitorimi i Ambientit.',
		listOfParticipants: 'Lista e Pjesëmarrësve',
		participant: 'Pjesëmarrësi',
		userAutoGeneratedPin: 'PIN i gjeneruar automatikisht',
		autoGeneratedPinDesc:
			'Kur kjo është aktivizuar, një PIN i gjeneruar automatikisht nga sistemi do të jetë në dispozicion në menaxhimin e përdoruesit për të interaguar me tabelën e bardhë.',
		podcastsWidget: 'Podkaste',
		podcastsWidgetDescription: 'Biblioteka e Podkasteve: Katalog i podkasteve që mbulojnë tema të ndryshme.',
		gamesWidget: 'Lojëra',
		gamesWidgetDescription: 'Lojëra: Një listë e ndryshme të aplikacioneve të lojërave.',
		hdmiInWidget: 'HDMI-IN',
		hdmiInWidgetDescription: 'HDMI-IN: Luaj përmbajtje nga kutia e vendosur ose pajisjet e tjera transmetuese.',
		roomControlsWidget: 'Kontrollet e Dhomasë',
		roomControlsWidgetDescription: 'Kontrolli i Dhomasë: Lejo pacientin të përdorë kontrollat e dhomës.',
		settingsWidget: 'Cilësimet',
		settingsWidgetDescription: 'Cilësimet: Opsione për personalizimin e përvojës tuaj në Whiteboard.',
		whiteboardPiPWidget: 'Whiteboard PiP',
		whiteboardPiPWidgetDescription:
			'Whiteboard PiP: Luajtje e një seleksioni të ndryshme të kanaleve televizive nëpër zhanre në një format imazh-në-imazh.',
		transferOwnershipMessage: 'Tani je zotëruesi i kësaj telefonate.',
		lying: 'I shtrirë',
		goingHome: 'Duke Shkuar në Shtëpi',
		rideConfirmed: 'A është konfirmuar udhëtimi',
		healthSummary: 'Përmbledhje shëndetësore',
		diet: 'Dietë',
		bradenScore: 'Pikët Braden',
		sensoryPerceptions: 'Perceptimet Senzoriale',
		skinMoisture: 'Lagështia e Lëkurës',
		mobility: 'Mobiliteti',
		nutrition: 'Ushqyerja',
		frictionShear: 'Fërkimi & Shkëputja',
		within24Hours: 'Brenda 24 orëve',
		is2or3days: '2-3 ditë',
		more3days: 'Më shumë se 3 ditë',
		rideIsConfirmed: 'Udhëtimi është konfirmuar',
		rideIsNotConfirmed: 'Udhëtimi nuk është konfirmuar',
		familyContact: 'Kontakti i Familjes',
		pcaPhoneNumber: 'Nr. i Tel. i PCA-s',
		nursePhoneNumber: 'Nr. i Tel. i Infermieres',
		completelyLimited:
			'Tërësisht i kufizuar: pa përgjigje (nuk bën zë, nuk tërhiqet ose nuk kap) ndaj shtypjes. Aftësi e kufizuar për të ndjerë dhimbje në pjesën më të madhe të trupit',
		completelyLimitedLabel: 'Tërësisht i kufizuar',
		veryLimited: 'Shumë i kufizuar: Përgjigje vetëm ndaj stimujve të dhimbshëm. Nuk mund të komunikojë siç duhet dhimbjen',
		veryLimitedLabel: 'Shumë i kufizuar',
		slightlyLimited: 'Pak i kufizuar: përgjigje ndaj urdhërave verbale por ndjen discomfort në 1 ose 2 ekstremitete',
		slightlyLimitedLabel: 'Pak i kufizuar',
		noImpairment: 'Pa pengesa: përgjigje ndaj urdhërave verbale, nuk ka deficit ndjesor',
		noImpairmentLabel: 'Pa pengesa',
		constantlyMoistDesc: '1 - Vazhdimisht i lagësht: lëkura mbahet pothuajse vazhdimisht e lagur',
		constantlyMoist: 'Vazhdimisht i lagësht',
		veryMoistDesc: '2 - Shumë i lagësht: lëkura zakonisht është e lagur',
		veryMoist: 'Shumë i lagësht',
		occasionallyMoistDesc:
			'3 - Herë pas here i lagësht: lëkura është herë pas here e lagur dhe kërkon ndërrim të rrobave një herë në ditë',
		occasionallyMoist: 'Herë pas here i lagësht',
		rarelyMoistDesc: '4 - Rrallëherë i lagësht: lëkura është e thatë',
		rarelyMoist: 'Rrallëherë i lagësht',
		bedfastDesc: '1 - I shtruar në shtrat: i kufizuar në shtrat',
		bedfast: 'I shtruar në shtrat',
		chairfastDesc:
			'2 - Kufizim në karrige: pacienti ka aftësinë për të ecur shumë të kufizuar por nuk mund të mbajë peshën e tij',
		chairfast: 'Kufizim në karrige',
		walksOccasionallyDesc: '3 - Eci herë pas here: mund të ecë për distanca shumë të shkurtra',
		walksOccasionally: 'Eci herë pas here',
		walksFrequentlyDesc: '4 - Eci shpesh: del nga dhoma të paktën dy herë në ditë dhe mund të ecë pa ndihmë',
		walksFrequently: 'Eci shpesh',
		completelyImmobileDesc: '1 - Krejtësisht i palëvizshëm: nuk mund të bëjë as lëvizje të lehta',
		completelyImmobile: 'Krejtësisht i palëvizshëm',
		veryLimitedDesc:
			'2 - Shumë i kufizuar: mund të ndryshojë pozicionin e trupit herë pas here por nuk mund të bëjë ndryshime të rëndësishme në mënyrë të pavarur',
		veryLimitedTitle: 'Shumë i kufizuar',
		slightlyLimitedDesc:
			'3 - Pak i kufizuar: bën ndryshime në trup në mënyrë të pavarur por nuk ka forcën për ta bërë atë shpesh',
		slightlyLimitedTitle: 'Pak i kufizuar',
		noLimitationDesc: '4 - Pa kufizim: pacienti mund të ndryshojë pozicionin pa ndihmë',
		noLimitation: 'Pa kufizim',
		veryPoorDesc:
			'1 - Shumë keq: pacienti kurrë nuk ha një vakt të plotë. Pi lëngje keq dhe nuk mund të hajë më shumë se ⅓ e ushqimit',
		probablyInadequateDesc: '2 - Ndoshta i pamjaftueshëm: pacienti ha vetëm rreth ½ e ushqimit të ofruar',
		probablyInadequate: 'Ndoshta i pamjaftueshëm',
		adequateDesc: '3 - I mjaftueshëm: nuk mund të hajë të gjithë vaktin',
		adequate: 'I mjaftueshëm',
		excellentDesc: '4 - Shkëlqyer: pacienti zakonisht ha një total prej 4 ose më shumë porcione',
		problemDesc: '1 - Problem: Ngritja pa rrëshqitur kundër çarçafëve është e pamundur',
		problem: 'Problem',
		potentialProblemDesc: '2 - Problem i mundshëm: Pacienti kërkon ndihmë minimale për të minimizuar fërkimin dhe prerjen',
		potentialProblem: 'Problem i mundshëm',
		noApparentProblemDesc:
			'3 - Asnjë problem i dukshëm: aftësia për të lëvizur në shtrat dhe në karrige në mënyrë të pavarur. Aftësia për të mbajtur një pozicion të mirë në shtrat dhe në karrige.',
		noApparentProblem: 'Asnjë problem i dukshëm',
		rateCallQuality: 'Vlerëso Cilësinë e Thirrjes',
		rateCallQualityDesc: 'Kjo lejon pjesëmarrësit të japin vlerësim në fund të thirrjes.',
		veryPoor: 'Shumë keq',
		poor: 'Keq',
		veryGood: 'Shumë mirë',
		excellent: 'Shkëlqyeshëm',
		howWasQuality: 'Si ishte cilësia e thirrjes',
		rateCall: 'Vlerëso Thirrjen',
		roundingCareEvents: 'Rrethimi i Ngjarjeve të Kujdesit',
		roundingCareEventsDesc:
			'Duke e aktivizuar këtë mundësi, mund të caktoni arsyen e thirjes para thirrjes së rrethimit ose gjatë thirrjes',
		excludeCpmPatients: '"Përjashtoni pacientët e CPM',
		removeFromRpm: 'Fshije nga RPM',
		removePatient: 'Fshij pacientin',
		areYouSureRemovePatient: 'Jeni i sigurt që dëshironi ta fshini pacientin nga programi RPM?',
		makeHost: 'Bëni mikpritës',
		noPinGenerate: 'Ju nuk keni një PIN ekzistues. Ju lutemi gjeneroni një.',
		missedCallsNotifications: 'Njoftimet e Thirrjeve të Humbura',
		missedCallFrom: 'Thirrje e Munguar nga',
		questionProvider: 'Pyetje për kujdestarin tuaj',
		safetyPatientNeeds: 'Sinjalizime të Sigurisë/Nevojat e Pacientëve',
		newMedicationAndSideEffects: 'Medikamentet e reja dhe efektet anësore',
		whatMattersToMe: 'Çfarë ka rëndësi për mua',
		planForToday: 'Plani për sot',
		painMedAvailable: 'Medikament për dhimbje',
		lastDoseGiven: 'Doza e fundit e dhënë',
		nextDose: 'Doza tjetër',
		generatePin: 'Gjenero PIN',
		aiDetectionDrawingsDescription:
			'Nëse ky është i aktivizuar, personi që merr përgjegjësinë do të shohë vizatime rreth njerëzve dhe objekteve të zbuluara nga modeli AI.',
		soundOnAiAlertDescription:
			'Nëse ky është aktivizuar, personi që merr përgjegjësinë do të dëgjojë një tingull paralajmërimi kur një alarë AI aktivizohet. Tingulli do të luajë në pajisjen që ata po përdorin për të monitoruar pacientët.',
		caseManager: 'Menaxheri i rastit',
		caseManagerPhone: 'Telefoni i menaxherit të rastit',
		showStats: 'Shfaq statistikat',
		mrn: 'MRN',
		sharedView: 'Pamja e ndarë',
		sharedBy: 'Ndajtur nga {value1} {value2}',
		logisticsDescription: 'Kur ky është aktivizuar, {value} do të jetë në gjendje të shohë dhe të përdorë skedarin logjistik.',
		thisMonth: 'Këtë muaj',
		thisWeek: 'Këtë javë',
		sharedInChat: 'Të ndara në bisedë',
		handoverTo: 'Dorëzoni tek',
		selectSitterNurse: 'Zgjidh infermieren/kujdestarin virtual',
		handoverSession: 'Dorëzoni sesionin',
		mobileApp: 'Aplikacioni Mobil',
		noSnooze: 'Pa shtyerje',
		triggerAlertsMoreThanOnePerson: 'Shkakto alarma nëse është më shumë se 1 person në dhomë',
		thresholdValues: 'Kufinjtë',
		thresholdsForRailDown: 'Kufinjtë për mbështetësen e poshtme të shtratit',
		thresholdsForRailUp: 'Kufinjtë për mbështetësen e sipërme të shtratit',
		aiModelCheckTimer: 'Kontrolli i kohëmatësit të AI modelit',
		triggerAlertsRailsCovered: 'Shkakto alarma nëse mbështetëset janë të mbuluara',
		setAsDefault: 'Cakto si parazgjedhje',
		enterNumber: 'Shkruani numrin',
		writeAndClickEnter: 'Shkruani dhe klikoni Enter',
		selectOneCheckbox: 'Ju lutem zgjedhni të paktën një opsion, me një të vetëm si parazgjedhje',
		provideThreshold: ' Ju lutem jepni një vlerë për çdo nga vlerat e pragut',
		pleaseSelectOption: 'Ju lutem zgjedhni një opsion',
		fillRequiredFieldsAi: 'Ju lutem plotësoni fushat e kërkuara në skedarin e cilesimeve AI',
		preferredLanguage: 'Gjuha e preferuar',
		updateRpmList: 'Ka përditësime të reja në dispozicion për listën e RPM pacientëve',
		roundingWhiteboardDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të parë dhe përditësuar tabelën e bardhë të pacientit gjatë thirrjes.',
		sleepScheduleTitle: 'Orari i gjumit',
		sleepScheduleDescription:
			"Orari i Gjumit fik automatikisht ekranin bazuar në rregullimet e mëposhtme, dhe gjatë kësaj periudhe mund të ndizet duke prekur ekranin. Ekranit do t'i kthehet gjumi bazuar në kohën e skadimit të ekranit.",
		addSleepSchedule: 'Shto Orarin e Gjumit',
		bodyPositionToSendAlert: 'Pozicioni i trupit për të dërguar alarmin për {value}',
		side: 'Anë',
		down: 'Poshtë',
		inactiveTime: 'Njoftuesi kohor për kohën joaktive',
		aiAlertsDescription:
			'Kur kjo është e aktivizuar, do të shfaqen të dhënat e përmbledhjes për sinjalizimet e AI bazuar në rastet e përdorimit.',
		downloadButtonAtPatientReport: 'Butoni Shkarko në Raportin e Pacientit',
		downloadBtnPatientReportDesc:
			'Kur flamuri i veçorisë është aktivizuar, Admini/Super Përdoruesi mund të shkarkojë raportin për një pacient të caktuar.',
		dashboardHandHygiene: 'Higjena e Doreve',
		hHDashboardDesc:
			'Kur ky opsion është aktivizuar, Admini/ Super User do të jetë në gjendje të shohë të dhëna rreth Higjenës së Doreve.',
		liveCaptions: 'Titrat e drejtpërdrejtë',
		liveCaptionsEmptyMessage: 'Pjesëmarrësit duhet të flasin për të krijuar tituj të drejtpërdrejtë.',
		scheduleNightVision: 'Caktoni vizionin e natës',
		removeSchedule: 'Hiqeni orarin',
		nightVisionError: 'Ju lutemi plotësoni orën e fillimit dhe mbarimit për të vazhduar!',
		nightVisionInfo: 'Orët e shfaqura pasqyrojnë zonën kohore lokale të dhomës',
		assignedSessionFrom: 'Keni një seancë të caktuar nga',
		disregardOpenHospital: 'Nëse dëshironi ta injoroni dhe të monitoroni dhoma të tjera, ju lutemi hapni një spital,',
		chooseDepartmentFloorRoom: 'zgjidhni departamentin, klikoni në një kat dhe zgjidhni një dhomë.',
		whiteboardTooltip: "Ky opsion do t'ju mundësojë të shikoni dhe modifikoni tabelën e bardhë të pacientit.",
		callsNotifications: 'Njoftimet e Thirrjeve',
		answeredCalls: 'Thirrjet e Përgjigjura',
		missedCalls: 'Thirrjet e Mungura',
		callBack: 'Thirrje e Kthyer',
		openSession: 'Hap Sesionin',
		silentModeDescription:
			'Kur kjo është aktivizuar, ne mund të lëshojmë AI pa monitoruar pacientin dhe të marrim njoftime në mënyrë të heshtur për qëllime trajnimi dhe raportimi.',
		whiteboardDisplayControl: 'Kontrolli i Shfaqjes së Tabelës',
		selectPatientPosition: 'Zgjedhni pozicionin e pacientit për të dërguar alarmin',
		patientPositions: 'Pozicionet e pacientit',
		sendPatientPositionAlert: 'Për cilin pozicion të pacientit të aktivizohet një alarm',
		upInAChair: 'Në një karrige',
		supine: 'Shtrirë në shpinë',
		prone: 'Shtrirë përmbys',
		lateral: 'Anash',
		screenTimeout: 'Koha e skedarës',
		useRestroom: 'A ke nevojë të përdorësh tualetin?',
		needDrink: 'A ke nevojë për një pije?',
		sorryDidNotHear: 'Më vjen keq, nuk të dëgjova. Të lutem përsërit përgjigjen tënde.',
		needMeToRepeat: 'A ke nevojë të përsëris atë që thashë?',
		allowPatientSignUp: 'Lejo regjistrimin e pacientëve',
		auditLog: 'Regjistër Auditimi',
		waitingForResult: 'Në pritje të rezultatit',
		galaxyWatch: 'Shiko Galaxyn',
		monitoringFeature: 'Funksionet e monitorimit',
		clientThemeColor: 'Ngjyra e temës së klientit',
		twoFactorProviderType: 'Lloji i ofruesit të dy faktorëve',
		lengthUnit: 'Njësia e gjatësisë',
		patientPrivateMode: 'Modaliteti privat i pacientit',
		nICUChat: 'Biseda në NICU',
		rpm: 'RPM',
		bloodGlucoseUnit: 'Njësia e sheqerit në gjak',
		heightUnit: 'Njësia e lartësisë',
		temperatureUnit: 'Njësia e temperaturës',
		weightUnit: 'Njësia e peshës',
		talkToPatientRoute: 'Rruga e bisedës me pacientin',
		monitoringRoute: 'Rruga e monitorimit',
		viewPatientRoute: 'Shikoni rrugën e pacientit',
		switchE2EE: 'Ndrysho në E2EE',
		pTZCam: 'Kamera PTZ',
		helloCameraName: 'Emri i kameras Hello',
		externalCameraName: 'Emri i kameras së jashtme',
		multipartyCalls: 'Thirrje multiparti',
		roundingSummary: 'Përqasja e përmbledhjes',
		screensharing: 'Ndaj ekranit',
		roundingNightVision: 'Vizioni natës i përçartjes',
		digitalClinicianMode: 'Modaliteti dixhital i mjekut',
		liveTVWidget: 'Widget i TV live',
		roundingCareEventsForSafetyCompanions: 'Ngjarjet e kujdesit për rrethimi për shoqëruesit e sigurisë',
		healthSystemDefaultBackground: 'Sfondi i paracaktuar i sistemit shëndetësor',
		callBackground: 'Sfondi i thirrjes',
		providerMicrophone: 'Mikrofoni i ofruesit',
		helloDefaultBackground: 'Sfondi i paracaktuar Hello',
		patientAdtDashboard: 'Pult i ADT të pacientit',
		virtualPatientAdmission: 'Pranimi virtual i pacientit',
		manualPatientAdmission: 'Pranimi manual i pacientit',
		ehrPatientAdmission: 'Pranimi i pacientit në EHR',
		isAudioCall: 'Është një thirrje audio?',
		alertsCenterDashboard: 'Paneli i qendrës së alarmit',
		aiDetectionDrawings: 'Vizatimet e zbulimit të AI',
		messageDoctor: 'Mesazhi mjekut',
		staffDuress: 'Stafi i dhunës',
		allowDataCollection: 'Lejo mbledhjen e të dhënave',
		verbalRedirection: 'Përcjellje verbale',
		hillRoom: 'Dhoma e kodrës',
		patientsOverview: 'Përmbledhja e pacientëve',
		patientsFiles: 'Dosjet e pacientëve',
		patientsHealth: 'Shëndeti i pacientëve',
		patientsPhysicalTherapy: 'Fizioterapia e pacientëve',
		patientsVisits: 'Vizitat e pacientëve',
		patientsAlertHistory: 'Historiku i alarmit të pacientëve',
		isNewUserExperience: 'Është një përvojë e re e përdoruesit?',
		isExperienceHealthSystemChangeable: 'Është e ndryshueshme sistemi i shëndetit të përvojës?',
		providerCentricReport: 'Raporti qendror i furnizuesit',
		patientCentricReport: 'Raporti qendror i pacientit',
		userAutoGeneratedPIN: 'PIN-i i gjeneruar automatikisht nga përdoruesi',
		vcpRoleDisplayName: 'Emri i shfaqjes së rolit të VCP',
		patientInfoBox: 'Kutia e informacionit të pacientit',
		whiteboardBox: 'Kutia e tablosë së bardhë',
		aiDetectionDrawing: 'Vizatimi i zbulimit të AI',
		soundOnAIAlert: 'Zëri në alarmin e AI',
		patientAdmission: 'Pranimi i pacientit',
		whiteboardWidgetSettings: 'Cilësimet e widgetit të tablosë së bardhë',
		monitoringMessage: 'Mesazhi i monitorimit',
		whiteboardPiP: 'Tablo PiP e bardhë',
		whiteboardBoxRounding: 'Rrethimi i kutisë së tablosë së bardhë',
		careNotifications: 'Njoftime për Kujdesin',
		ir: 'IR',
		ct: 'CT',
		xray: 'Rrezatim',
		orAcronym: 'Salla Operative',
		swallowTest: 'Testi i Gëlltitjes',
		mri: 'MRI',
		inHallway: 'Në korridor',
		cathLab: 'Laboratori i Kateterit',
		restroom: 'Tualet',
		restroomByRn: 'Tualet pranë RN',
		s529: 'S529',
		j516: 'J516',
		dc: 'Shkarkim',
		nuclearMed: 'Mjekësia Bërthamore',
		epLab: 'Laboratori EP',
		liveSitterUntilAm: 'Kujdestar i drejtpërdrejtë deri në mëngjes',
		s732: 'S732',
		giLab: 'Laboratori GI',
		tee: 'TEE',
		pegTubePlacement: 'Vendosja e Tubit PEG',
		moveTo527: 'Lëviz në 527',
		walkingWithNurse: 'Ecje me Infermieren',
		eeg: 'EEG',
		additionalDiet: 'Ushqim Shtesë',
		discontinueMonitoring: 'Ndaloni monitorimin',
		behaviorsImproved: 'Sjellja u përmirësua',
		requiresOneOnOneObservation: 'Kërkohet Vëzhgim i Njëtrajtshëm',
		leftAma: 'Lëshoi AMA',
		familyPatientRefuses: 'Familja/ pacienti refuzon',
		shiftChange: 'Ndryshim i ndërrimit',
		stopVideoMonitoring: 'Përse dëshironi të ndërpreni monitorimin me video të këtij pacienti.',
		closingMonitoring: 'Mbylle Monitorimin',
		discontinuePatientMonitoring: 'Nderpreje monitorimin e pacientit',
		discontinuingMonitoringReason: 'Arsyeja e nderprejes së monitorimit',
		transferToAnotherUnit: 'Transferuar në njësi tjetër',
		selectedIncorrectRoom: 'Pacienti i gabuar; duke kaluar te pacienti i saktë',
		technicalIssue: 'Dhomë e shkëputur për shkak të problemeve teknike',
		patientDischargedDiscontinue: 'Pacienti është liruar. Ju lutemi ndaloni monitorimin.',
		patientTransferredDiscontinue: 'Pacienti është transferuar. Ju lutemi ndërprisni monitorimin.',
		patientDischarged: 'Patient është liruar',
		workflow: 'Rrjedha e punës',
		aiNotifications: 'Njoftime të AI',
		whiteboardControls: 'Kontrollat e Tabelës së Bardhë',
		whiteboardControlsDesc:
			'Kur kjo është e aktivizuar, furnizuesit do të jenë në gjendje të kontrollojnë tablone nga ndërfaqja në internet',
		discontinueMonitoringDescription:
			'When this is enabled, providers will have to select a reason for discontinuing monitoring.',
		roomSign: 'Tableti dhomës',
		roundingRoomSignDescription:
			'Kur kjo është e aktivizuar, do të shfaqet opsioni për të parë dhe përditësuar Tabletin e dhomës së pacientit gjatë dhe jashtë thirrjes.',
		careConsiderations: 'Kujdesi i veçantë',
		selectLevelofBradenScore: 'Zgjidhni nivelin e pikëve Braden',
		hearingImpaired: ' A është pacienti me dëmtime në dëgjim?',
		interpreterNeeded: 'A është i nevojshëm një përkthyes?',
		whatLanguageIsNeeded: 'Cila gjuhë është e nevojshme?',
		noWhiteboardAssigned: 'Nuk është caktuar asnjë pajisje shoqëruese në dhomë',
		inviteParticipants: 'Pjesëmarrës të ftuar',
		cancelAllInvites: 'Anulo të gjitha ftesat',
		cancelInviteDescription:
			'Kur ky është aktivizuar, përdoruesi do të jetë në gjendje të anulojë një ftesë të vetme, ose të gjitha ftesat.',
		invalidConferenceInvitation: 'Kjo ftesë nuk është më e vlefshme.',
		invalidConferenceLink: 'Ky link nuk është më i vlefshme.',
		invalidConferenceParameters: 'Informacioni për konferencën është i pasaktë.',
		addPeople: 'Shtoni Persona',
		sentInvites: 'Ftesat e Dërguara',
		multipartyCalling: 'Thirrje me shumë palë',
		hospitalAtHome: 'Spitali në shtëpi',
		patientNameDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Emri i Pacientit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		patientAgeDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Mosha e Pacientit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		nurseNameNumberDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Emri/Nr. i Infermierit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		nurseAssistantNameNumberDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Emri/Nr. i Asistentit të Infermierit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		tlNameNumberDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Emri/Nr. i Liderit të Ekipit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		primaryAdverseEventDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Ngjarja kryesore e Padëshiruar' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		primaryPatientConditionDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Gjendja kryesore e Pacientit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		primaryPatientRiskFactorDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Faktori kryesor i Rrezikut të Pacientit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		preferredLanguageDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Gjuha e Preferuar' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		titleBarNotesDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Shënime në Shiritin e Titullit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		additionalPatientNotesDescription:
			"Kur është aktivizuar, infermierët do të shohin 'Shënime Shtesë të Pacientit' si një hyrje brenda seksionit të Informacionit të Pacientit.",
		patientInfoWidget: 'Informatat e pacientit',
		patientInfoConfigsTitle:
			'Fushat e mëposhtme do të shfaqen brenda Kutisë së Informacionit të Pacientit kur të aktivizohen. Ju lutemi aktivizoni fushat që dëshironi të shfaqni.',
		imbalance: 'Çekuilibri',
		imbalanceType: 'Lloji i çekuilibrit',
		recentMetrics: 'Metrikat e fundit',
		orders: 'Porositë',
		schedule: 'Orari',
		painScale: 'Shkalla e dhimbjes',
		assignedNurse: 'Infermiere e caktuar',
		product: 'Produkti',
		sponsor: 'Sponsor',
		payer: 'Paguesi',
		phaseOfCare: 'Faza e Kujdesit',
		admitDate: 'Data e pranimit',
		appointmentWith: 'Appointment with',
		timeADay: 'Takimi me',
		dateOfBirthAge: 'Data e lindjes (mosha)',
		timeAday: 'herë në ditë',
		hospitalAtHomeDescription:
			"Aktivizimi i kësaj veçorie do t'i lejojë kujdestarët të shohin pacientët e tyre në shtëpi, duke ofruar një ndërfaqe të personalizuar për të menaxhuar në mënyrë efektive informacionin e pacientit, planet e trajtimit dhe komunikimin.",
		currentPosition: 'Pozicioni aktual',
		configuredFor: 'Konfiguruar për',
		configuredPositions: 'Pozicione të konfiguruara',
		patientPositionMonitoring: 'Pozicioni i pacientit',
		pressureInjuryStats: 'Statistikat e lëndimeve nga presioni',
		standing: 'Në këmbë',
		rightLateral: 'Në anën e djathtë',
		leftLateral: 'Në anën e majtë',
		pressureInjuryHistoryDescription: 'Personi zbuluar i papërgjegjshëm për {value1} në pozicionin {value2}.',
		pressureInjuryHistoryTime: 'Nga {value1} në {value2}',
		noHistoryFound: 'Nuk u regjistrua historik për këtë pacient.',
		writeNewVerbalRedirection: 'Shkruaj një përcjellje të re verbale...',
		atLeastOne: 'Të paktën 1 duhet të zgjidhet.',
		onState: 'Lëshuar',
		offState: 'Ndalur',
		isEnabledMessage: '{value1} ktheu {value2} {value3}',
		sensitivityOptionsMessage: '{value1} përditësoi sensitivitetin nga {value2} në {value3} për {value4}',
		snoozeTimeMessage: '{value1} përditësoi kohën e shtyerjes nga {value2} në {value3} për {value4}',
		notificationOptionsMessage: '{value1} përditësoi njoftimet nga {value2} në {value3} për {value4}',
		statAlarmMessage: '{value1} përditësoi stat alarmin nga {value2} në {value3} për {value4}',
		railsOptionsMessage: '{value1} përditësoi {value2} nga {value3} në {value4}',
		pressureInjuryPositionsMessage: '{value1} përditësoi pozicionet e pacientit nga {value2} në {value3}',
		ivBagValueMessage: '{value1} përditësoi vlerën e IV bag nga {value2} në {value3}',
		pressureInjuryTimerMessage: '{value1}  përditësoi kohëmatësin e lëndimit nga presioni nga {value2} në {value3}',
		atLeastOneMustBeSet: 'Të paktën një duhet të vendoset.',
		sleepScheduleTimeError: 'Koha e përfundimit duhet të jetë të paktën 1 minutë pas kohës së fillimit.',
		allHealthSystems: 'Të gjitha sistemet shëndetësore',
		tomorrow: 'Nesër',
		never: 'Kurrë',
		gobConfigurationMessage: '{value1} konfigurimi: Ndjeshmëria {value2}',
		alertConfigurationMessage: '{value1} konfigurimi: {value2}',
		compliant: 'I përmbajtur',
		nonCompliant: 'I papërshtatshëm',
		totalIdentifications: 'identifikime totale',
		method: 'Metoda',
		evidence: 'Prova',
		handHygiene: 'Higjiena e duarve',
		rn: 'RN',
		primaryDisplayLogo: 'Logoja Kryesore e Shfaqjes',
		primaryDisplayLogoDescription: 'Ngarkoni logon kryesore të spitalit tuaj. Kjo do të shfaqet dukshëm në të gjithë platformën.',
		secondaryDisplayLogo: 'Logoja Sekondare e Shfaqjes',
		secondaryDisplayLogoDescription: `Ngarkoni një logo sekondare për t'u përdorur në zona ose shfaqje specifike.`,
		compliace: 'Përputhshmëri',
		subjectIdentifications: 'Identifikimi i subjekteve',
		addTranslationService: 'Shto Shërbim Përkthimi',
		healthSystemMustBeSet: 'Sistemi shëndetësor duhet të jetë i caktuar',
		evidenceReport: 'Raporti i provave',
		stats: 'Statistikat',
		missingInformation: 'Nuk mund të lidhësh në thirrje për shkak të mungesës së informacionit.',
		selectReasonPlease: 'Ju lutem zgjedhni një arsye!',
		ecm: 'ECM',
		smartFHIR: 'SMART mbi FHIR',
		nurseCalling: 'Thirrja e Infermierit',
		allPatients: 'Të gjithë pacientët',
		aiVoiceAnalysis: 'Analiza e Zërit me AI',
		aiVoiceDescription:
			'Kur kjo është e aktivizuar, përdoruesit do të mund të marrin njoftime përmes AI kur zbulohen zëra nga spitali.',
		sendAiVoiceAlert: 'Për cilat ngjarje zëri të merrni njoftime',
		snoring: 'Gërhitje',
		coughing: 'Kollitje',
		cryingBaby: 'Foshnja që Qan',
		screaming: 'Britmë',
		lowPriorityAlarm: 'Alarm me prioritet të ulët',
		mediumPriorityAlarm: 'Alarm me prioritet të mesëm',
		highPriorityAlarm: 'Alarm me prioritet të lartë',
		alarmOff: 'Alarmi i ndalur',
		aiAudioAnalysisEvents: '{value1} ndërroi ngjarjet e azhurnuara të zërit AI nga {value2} në {value3}',
		sneezingDetected: 'Teshtima u detektua.',
		snoringDetected: 'Gërhitja u detektua.',
		coughingDetected: 'Kollitja u detektua.',
		cryingBabyDetected: 'E qara e foshnjës u detektua.',
		screamingDetected: 'Ulërima u detektua.',
		helpDetected: 'Ndihma e pacientit u detektua.',
		lowPriorityAlarmDetected: 'Alarmi me prioritet të ulët u detektua.',
		mediumPriorityAlarmDetected: 'Alarmi me prioritet mesatar u detektua.',
		highPriorityAlarmDetected: 'alarmi me prioritet të lartë u detektua.',
		alarmOffDetected: 'Ndalja e alarmit u detektua.',
		alertDetected: '{value} u detektua',
		alertsEvidence: 'Dëshmi e Alarmit',
		alertsEvidenceDescription:
			'Kur kjo është e aktivizuar, përdoruesit mund të shohin dëshmi të kornizave të lidhura me një alarm AI të shkaktuar.',
		otherOptions: 'Opsione të tjera',
		end: 'Mbyll',
		audio: 'Audio',
		stop: 'Ndal',
		liveExam: 'Provimi i Drejtpërdrejtë',
		handsNotDisinfected: 'Duart jo të dezinfektuara',
	},
};

export default keys;
