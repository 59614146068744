import { useEffect, useState, useRef, forwardRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isIOS } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { enums, participant as CallsParticipant } from '@solaborate/calls';
import { Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { ClosingOwnerViews } from 'calls/constants/index.js';
import {
	ParticipantAudio,
	PrivacyNotification,
	Loader,
	Avatar,
	CameraControls,
	Icon,
	Dropdown,
	List,
	Modal,
	Button,
	IconButton,
	ParticipantAudioLevel,
	DragToZoomVideo,
} from 'calls/components/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useControllerTracks,
	useLocalParticipant,
	useHelloDeviceState,
	useScreenType,
} from 'calls/hooks/index.js';
import { ControlsActions, TvProtocols, UserRoles, UserTypes, WindowSize } from 'calls/enums/index.js';
import { isMedicalInfoModalsOpen } from 'calls/helpers/index.js';
import {
	PinIcon,
	UnPinIcon,
	VolumeUpIcon,
	VolumeOffIcon,
	VideocamIcon,
	VideocamOffIcon,
	LiveCaptionsIcon,
	LiveCaptionsDisabledIcon,
	TransferOwnershipIcon,
} from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getCallsButtonColor, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import SocketEvents from 'constants/socket-events.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { ButtonType } from 'constants/enums.js';

const StyledLoadingMainParticipant = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		color: var(--gray-0);
	}
`;

const FullScreenButton = styled.button`
	position: fixed;
	top: ${LightTheme.spacing[2]}px;
	left: ${LightTheme.spacing[2]}px;
	padding: 0;
	line-height: 0;
	background: var(--gray-0);
	z-index: 10;
`;

const StyledParticipantActions = styled.div`
	position: absolute;
	top: ${LightTheme.spacing[2]}px;
	right: ${LightTheme.spacing[2]}px;
	z-index: 1;
`;

const StyledMainParticipantInfo = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: ${LightTheme.spacing[2]}px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
	z-index: 1;

	> div {
		display: flex;
		align-items: center;

		> svg {
			margin-right: 6px;
		}

		button {
			margin-right: 6px;
			padding: ${LightTheme.spacing[1]}px;
			span {
				font-size: 12px;
			}
		}
	}

	> p {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		font-size: 16px;
		color: var(--gray-0);
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $position: string, $isDarkMode: boolean }, never>}
 */
const LimitedVisitorSideView = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	width: 20%;
	left: ${props => (props.$position === 'left' ? '0' : 'auto')};
	right: ${props => (props.$position === 'right' ? '0' : 'auto')};
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};

	@media (max-width: ${WindowSize.TABLET}px) {
		display: none;
	}
`;

const MainParticipantView = forwardRef(
	/**
	 * @param {object} props
	 * @param {LocalParticipant | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */ ({ participant, activeTrackType }, ref) => {
		const intl = useIntl();
		const conference = useConference();
		const conferenceConfigs = useConferenceConfigurations();
		const localParticipant = useLocalParticipant();
		const socketFunctions = useContext(SocketFunctionsContext);
		const isHelloParticipant = participant instanceof RemoteHelloParticipant;
		const [loadingConference, setLoadingConference] = useState(true);
		const [userInterActivityModal, setUserInteractivityModal] = useState(false);
		const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);
		const [isCCEnabled, setIsCCEnabled] = useState(isHelloParticipant ? participant.isCCEnabled : false);

		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);
		const isCurrentlyPinnedParticipant = conferenceConfigs.pinnedParticipantId === participant.id;

		const [showControls, setShowControls] = useState(false);

		const isCameraPrivacyOn = deviceState?.isCameraPrivacyOn;
		const isMicPrivacyOn = deviceState?.isMicPrivacyOn;
		const isTvMuted = conferenceConfigs.tvVolumeRange <= 0 && conferenceConfigs.tvVolumeRange >= -100 && deviceState?.tvPowerOn;

		const mainRef = useRef(null);
		const audioRef = useRef(null);

		const screenType = useScreenType();
		const videoTrack = tracks[activeTrackType];

		const toggleTrack = trackType => {
			participant.remoteTrackController.toggle(trackType);
			setShowControls(false);
		};

		const togglePinnedParticipant = () => {
			conferenceConfigs.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : participant.id);
		};

		const showLiveCaptionsButton = () =>
			isHelloParticipant &&
			getSomeRoleConfigurationsValues(conferenceConfigs.roundingConfigurations, [RoundingSettings.LiveCaptions]);

		const onToggleCC = isEnabled => {
			if (isHelloParticipant) {
				participant.toggleCC(isEnabled);
			}
		};

		const onRemoveParticipant = participantId => {
			if (participant instanceof LocalParticipant || !localParticipant.isOwner) {
				return;
			}
			conferenceConfigs.setRemoveParticipantModal({
				isOpen: true,
				modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: participantDisplayedName })}`,
				onSubmit: () => {
					if (!localParticipant.isOwner) {
						return;
					}
					conference.removeParticipant(participantId);
					conferenceConfigs.setRemoveParticipantModal({
						isOpen: false,
						message: '',
						onSubmit: () => {},
					});
				},
			});
		};

		const getMainAvatarSize = () => {
			let avatarSize = 96;

			const gridCount = conferenceConfigs.gridCount;
			const isInviteParticipantsModalViewOpen = conferenceConfigs.isInviteParticipantsModalViewOpen;
			const isMedicalInfoOpen = isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls);
			const isPatientMeasurementsOpen =
				conferenceConfigs.isLiveExaminationOpen ||
				conferenceConfigs.isPatientHistoryOpen ||
				conferenceConfigs.isTelemetryModalOpen;
			const isWhiteboardVisible = conferenceConfigs.isWhiteboardVisible;
			if (
				gridCount === 2 &&
				(isInviteParticipantsModalViewOpen || isMedicalInfoOpen || isPatientMeasurementsOpen || isWhiteboardVisible)
			) {
				avatarSize = 48;
			}

			return avatarSize;
		};

		const [participantDisplayedName, setParticipantDisplayedName] = useState(participant.alias || participant.name);
		const [participantNameWithInterpreter, setParticipantNameWithInterpreter] = useState('');

		const makeHost = async participantId => {
			const response = await conference.transferOwnership(participantId);
			if (!response) {
				conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
				return;
			}
			localParticipant.isGuest = true;
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, false);
			ClosingOwnerViews.forEach(({ action, value }) => {
				conferenceConfigs.onConfigurationToggleAction(action, value ?? null);
				if (action === ControlsActions.TOGGLE_LIVE_EXAMINATIONS) {
					socketFunctions.toggleHealthData({
						isEnabled: false,
						helloDeviceId: participantId.objectId,
						conferenceId: conference.conferenceId,
						participantId: localParticipant.id,
						toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
						measurementType: '',
					});
				}
			});
		};

		useEffect(() => {
			const displayName = participant.alias || participant.name;
			setParticipantDisplayedName(displayName);

			const nameWithInterpreter = `${displayName} ${
				participant.interpreterId ? `(${intl.formatMessage({ id: 'interpreter' })})` : ''
			}`;
			setParticipantNameWithInterpreter(nameWithInterpreter);
		}, [participant, intl]);

		useEffect(() => {
			setLoadingConference(false);
		}, [tracks]);

		useEffect(() => {
			const onLoaded = ({ target }) => {
				if (target) {
					const { videoHeight, videoWidth } = target;
					conferenceConfigs.setIsMainParticipantPortraitVideo(videoWidth <= videoHeight);
				}
			};

			if (ref.current) {
				ref.current.addEventListener('loadedmetadata', onLoaded);
			}

			return () => {
				if (ref.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					ref.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [conferenceConfigs, ref]);

		useEffect(() => {
			if (audioRef.current && tracks[Mic]) {
				audioRef.current.play().catch(error => {
					console.error(error);
					setUserInteractivityModal(true);
				});
			}
		}, [tracks]);

		useEffect(() => {
			return participant.on(event => {
				if (event instanceof CallsParticipant.CCStateChanged) {
					setIsCCEnabled(event.isCCEnabled);
				}
			});
		}, [participant]);

		return (
			<main ref={mainRef}>
				{loadingConference && !videoTrack && (
					<StyledLoadingMainParticipant>
						<Loader $color='white' />
						<p>
							{conference.callType === enums.CallTypes.SECURITYCAM
								? translate('connectingToHello', { value: conferenceConfigs.helloName })
								: translate('loadingCamera')}
						</p>
					</StyledLoadingMainParticipant>
				)}
				{videoTrack && (
					<DragToZoomVideo
						ref={ref}
						mainRefElement={mainRef.current}
						participant={participant}
						activeTrackType={activeTrackType}
					/>
				)}
				{!videoTrack && !loadingConference && (
					<Avatar
						size={getMainAvatarSize()}
						src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
						name={participantDisplayedName}
					/>
				)}
				{conference.callType === enums.CallTypes.SECURITYCAM && isHelloParticipant && (
					<ParticipantAudio track={tracks[Mic]} ref={audioRef} autoPlay={false} />
				)}
				{isHelloParticipant &&
					(isCameraPrivacyOn || isMicPrivacyOn || isTvMuted) &&
					!conferenceConfigs.isGridView &&
					!screenType.isSmall &&
					TvProtocols.CEC !== deviceState.tvBrand && <PrivacyNotification participant={participant} />}
				{!isPatientView && conferenceConfigs.gridCount > 2 && (
					<StyledParticipantActions>
						<Dropdown isOpen={showControls} onClickOutside={() => setShowControls(false)}>
							<Dropdown.Button onClick={() => setShowControls(prevState => !prevState)} />
							<Dropdown.Items renderIntoPortal={true}>
								<List>
									{isHelloParticipant && !localParticipant.isGuest && (
										<>
											<List.Item onClick={() => toggleTrack(Cam)}>
												{tracks[Cam] && (
													<VideocamIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{!tracks[Cam] && (
													<VideocamOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{translate(tracks[Cam] ? 'cameraOff' : 'cameraOn')}
											</List.Item>
											<List.Item onClick={() => toggleTrack(Mic)}>
												{tracks[Mic] && (
													<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{!tracks[Mic] && (
													<VolumeOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{translate(tracks[Mic] ? 'muteAudio' : 'unMuteAudio')}
											</List.Item>
										</>
									)}
									<List.Item
										onClick={() => {
											togglePinnedParticipant();
											setShowControls(false);
										}}>
										{isCurrentlyPinnedParticipant && (
											<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
										)}
										{!isCurrentlyPinnedParticipant && (
											<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
										)}
										{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
									</List.Item>
									{!(participant instanceof LocalParticipant) && localParticipant.isOwner && (
										<List.Item onClick={() => onRemoveParticipant(participant.id)}>
											<Icon name='close' size={18} />
											{translate('removeParticipant')}
										</List.Item>
									)}
									{showLiveCaptionsButton() && (
										<List.Item
											onClick={() => {
												onToggleCC(!isCCEnabled);
												setShowControls(false);
											}}>
											{isCCEnabled && (
												<LiveCaptionsIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
											)}
											{!isCCEnabled && (
												<LiveCaptionsDisabledIcon
													height={18}
													width={18}
													color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
												/>
											)}
											{translate(isCCEnabled ? 'disableLiveCaptions' : 'enableLiveCaptions')}
										</List.Item>
									)}
									{!isHelloParticipant &&
										[UserTypes.DOCTOR, UserTypes.NURSE].includes(participant?.role) &&
										localParticipant.isOwner && (
											<List.Item
												onClick={() => {
													makeHost(participant.id);
													setShowControls(false);
												}}>
												<TransferOwnershipIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
												{translate('makeHost')}
											</List.Item>
										)}
								</List>
							</Dropdown.Items>
						</Dropdown>
					</StyledParticipantActions>
				)}
				{conferenceConfigs.gridCount > 2 && (
					<StyledMainParticipantInfo>
						<div>
							{isCurrentlyPinnedParticipant && <PinIcon color={LightTheme.colors.grayZero} width={16} height={16} />}
							{!isHelloParticipant && (
								<>
									{!tracks[Mic] && (
										<IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
									)}
									{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
								</>
							)}
						</div>
						{!isHelloParticipant && (
							<p>
								{participant instanceof LocalParticipant ? translate('you') : participantNameWithInterpreter}
								{tracks[ScreenShare] && activeTrackType === ScreenShare && ` (${intl.formatMessage({ id: 'presenting' })})`}
							</p>
						)}
					</StyledMainParticipantInfo>
				)}
				{videoTrack && screenType.isSmall && [UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) && (
					<FullScreenButton
						onClick={() => {
							if (document.fullscreenElement) {
								document.exitFullscreen();
								return;
							}
							if (isIOS) {
								ref.current.webkitEnterFullScreen();
								return;
							}
							ref.current.requestFullscreen();
						}}>
						<span className='material-icons'>fullscreen</span>
					</FullScreenButton>
				)}
				{isHelloParticipant && conferenceConfigs.isMultiBed && getUserRole() === UserRoles.VISITOR && (
					<>
						<LimitedVisitorSideView $position='left' $isDarkMode={conferenceConfigs.isDarkMode}></LimitedVisitorSideView>
						<LimitedVisitorSideView $position='right' $isDarkMode={conferenceConfigs.isDarkMode}></LimitedVisitorSideView>
					</>
				)}
				{userInterActivityModal && (
					<Modal onDismiss={() => setUserInteractivityModal(false)} title={intl.formatMessage({ id: 'patientAudio' })}>
						<Modal.Content>
							<p>{translate('allowPatientAudio')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								onClick={() => {
									setUserInteractivityModal(false);
									if (audioRef.current) {
										audioRef.current.play();
									}
								}}>
								Okay
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{isHelloParticipant && conferenceConfigs.isCameraControlsOpen && (
					<CameraControls helloDevice={participant} activeTrackType={activeTrackType} />
				)}
			</main>
		);
	}
);
MainParticipantView.displayName = 'MainParticipantView';

export default MainParticipantView;
