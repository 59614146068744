export const FeedColors = {
	red: '#e84118',
	blue: '#5390FF',
	orange: '#ff9f43',
	yellow: '#fbc531',
	pink: '#f368e0',
	black: '#000',
	cyan: '#6BDCFF',
	purple: '#852FC8',
	darkBlue: '#5e75aa',
	gray: '#d3d3d3',
	lightBlue: '#add8e6',
};

export const precautions = [
	{
		title: 'suspiciousBehavior',
		color: FeedColors.purple,
		id: 0,
	},
	{
		title: 'suicideWatch',
		color: FeedColors.cyan,
		id: 1,
	},
	{
		title: 'falls',
		color: FeedColors.yellow,
		id: 2,
	},
	{
		title: 'isolation',
		color: FeedColors.red,
		id: 3,
	},
	{
		title: 'seizure',
		color: FeedColors.pink,
		id: 4,
	},
	{
		title: 'hospice',
		color: FeedColors.black,
		id: 5,
	},
	{
		title: 'detox',
		color: FeedColors.orange,
		id: 6,
	},
	{
		title: 'pullingO2',
		color: FeedColors.lightBlue,
		id: 7,
	},
	{
		title: 'impulsiveConfused',
		color: FeedColors.gray,
		id: 8,
	},
	{
		title: 'nonRedirectable',
		color: FeedColors.blue,
		id: 9,
	},
];

export const VerbalRediretionLanguages = {
	ENGLISH: 1,
	SPANISH: 2,
	ARABIC: 3,
	MANDARIN_CHINESE: 4,
	TAGALOG: 5,
	VIETNAMESE: 6,
	PUNJABI: 7,
	TURKISH: 8,
};

export const MindRayCodes = {
	TEMPERATURE: 150344,
	HEART_RATE: 147842,
	RESPIRATORY_RATE: 151578,
	OXYGEN: 150456,
	BLOOD_PRESSURE_SYSTOLIC: 150037,
	BLOOD_PRESSURE_DIASTOLIC: 150038,
};
